/* import __COLOCATED_TEMPLATE__ from './fulfillment-product.hbs'; */
import Component from '@glimmer/component';

import type FulfillmentProductModel from 'ticketbooth/models/fulfillment-product';

interface CartDeliveryMethodFulfillmentProductSignature {
  Args: {
    product: FulfillmentProductModel;
    selectedProduct: FulfillmentProductModel | null;
    defaultProduct: FulfillmentProductModel | null;
    onChange: (product: FulfillmentProductModel) => Promise<void>;
  };
}

export default class CartDeliveryMethodFulfillmentProductComponent extends Component<CartDeliveryMethodFulfillmentProductSignature> {
  get isSelected() {
    const { product, selectedProduct, defaultProduct } = this.args;
    return selectedProduct
      ? product === selectedProduct
      : product === defaultProduct;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CartDeliveryMethod::FulfillmentProduct': typeof CartDeliveryMethodFulfillmentProductComponent;
    'cart-delivery-method/fulfillment-product': typeof CartDeliveryMethodFulfillmentProductComponent;
  }
}
