import type { SyncHasMany } from '@ember-data/model';
import { hasMany } from '@ember-data/model';

import AnalyticsModel from 'ticketbooth/models/analytics-model';
import type RecommendationModel from 'ticketbooth/models/recommendation';

export default class RecommendableModel extends AnalyticsModel {
  // @ts-ignore
  @hasMany('recommendation', {
    inverse: 'recommendable',
    async: false,
    as: 'recommendable'
  })
  recommendations!: SyncHasMany<RecommendationModel>;

  get hasRecommendations() {
    return this.recommendations.slice().length > 0;
  }

  get googleAnalyticsSku(): string {
    return this.id;
  }

  get googleAnalyticsName(): string {
    return '';
  }

  get googleAnalyticsCategory(): string {
    return '';
  }

  get googleAnalyticsBrand(): string {
    return '';
  }

  get googleAnalyticsVariant(): string {
    return '';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    recommendable: RecommendableModel;
  }
}
