/* import __COLOCATED_TEMPLATE__ from './accent.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ButtonTbAccentSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

const ButtonTbAccentComponent =
  templateOnlyComponent<ButtonTbAccentSignature>();

export default ButtonTbAccentComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Button::Tb::Accent': typeof ButtonTbAccentComponent;
    'button/tb/accent': typeof ButtonTbAccentComponent;
  }
}
