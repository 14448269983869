import sortBy from 'lodash-es/sortBy';

import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';

/**
 * Events with `check-for-single-seats` do not allow selecting seats which
 * leave a single seat in between or at the end (except in case of
 * `allow-single-seat-at-row-end`)
 */
export function hasSingleSeat(
  seats: SeatAssignmentModel[],
  selected: SeatAssignmentModel[],
  allowSingleSeatAtRowEnd: boolean
): boolean {
  return selected.some(seat =>
    singleSeatsCausedBy(seats, selected, seat, allowSingleSeatAtRowEnd)
  );
}

/**
 * Logic based on SeatAssignment::causes_single_seat_on_grouping
 */
function singleSeatsCausedBy(
  seats: SeatAssignmentModel[],
  selected: SeatAssignmentModel[],
  seat: SeatAssignmentModel,
  allowSingleSeatAtRowEnd: boolean
): boolean {
  const { grouping } = seat;
  const leftSideSeats = seatsAtGrouping(seats, grouping - 1, grouping - 2);
  const rightSideSeats = seatsAtGrouping(seats, grouping + 1, grouping + 2);

  if (!allowSingleSeatAtRowEnd) {
    // Left (edge) seat is empty
    if (
      leftSideSeats.length === 1 &&
      isAdjacent(seat, leftSideSeats[0]) &&
      isEmpty(selected, leftSideSeats[0])
    ) {
      return true;
    }

    // Right (edge) seat is empty
    if (
      rightSideSeats.length === 1 &&
      isAdjacent(seat, rightSideSeats[0]) &&
      isEmpty(selected, rightSideSeats[0])
    ) {
      return true;
    }
  }

  // Left seat is empty, and following is filled / selected
  if (
    leftSideSeats.length === 2 &&
    isEmpty(selected, leftSideSeats[1]) &&
    !isEmpty(selected, leftSideSeats[0])
  ) {
    return true;
  }

  // Right seat is empty, and following is filled / selected
  if (
    rightSideSeats.length === 2 &&
    isEmpty(selected, rightSideSeats[0]) &&
    !isEmpty(selected, rightSideSeats[1])
  ) {
    return true;
  }

  return false;
}

function seatsAtGrouping(
  seats: SeatAssignmentModel[],
  ...groupings: number[]
): SeatAssignmentModel[] {
  const atGroup = seats.filter(({ grouping }) => groupings.includes(grouping));
  return sortBy(atGroup, ({ grouping }) => grouping);
}

/**
 * Seats are adjacent if groupings are adjacent numbers
 */
function isAdjacent(a: SeatAssignmentModel, b: SeatAssignmentModel): boolean {
  return Math.abs(a.grouping - b.grouping) === 1;
}

/**
 * Seat is empty if not filled && (not selected || not in cart)
 */
function isEmpty(
  selectedSeats: SeatAssignmentModel[],
  seat: SeatAssignmentModel
) {
  return seat.isAssignable && !(selectedSeats.includes(seat) || seat.isInCart);
}
