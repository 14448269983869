import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type CartModel from 'ticketbooth/models/cart';
import type ProductModel from 'ticketbooth/models/product';
import type CartProviderService from 'ticketbooth/services/cart-provider';

export default class ProductQuantity extends Helper {
  @service() cartProvider!: CartProviderService;

  get cart(): CartModel {
    return this.cartProvider.cart;
  }

  compute([product]: [ProductModel]): number {
    return this.cart.quantityForProduct(product);
  }
}
