/* import __COLOCATED_TEMPLATE__ from './products-list.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

import type ProductModel from 'ticketbooth/models/product';

interface ProductsListSignature {
  Args: {
    modelName: 'product' | 'donation-product' | 'benefit-product';
    tags?: string | null;
    filter?: object;
  };
  Blocks: {
    default: [ProductModel[]];
  };
}

const ProductsListComponent = templateOnlyComponent<ProductsListSignature>();

export default ProductsListComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductsList: typeof ProductsListComponent;
    'products-list': typeof ProductsListComponent;
  }
}
