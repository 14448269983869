import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import { memberAction } from 'ember-api-actions';

import { serializeAndPush } from 'tangram/utils/serialize-and-push';
import ENV from 'ticketbooth/config/environment';

import type MasterAllocationModel from './master-allocation';
import type SeatAssignmentModel from './seat-assignment';

/**
 * Cached version of a master allocation, calculating delta-seat-assignments
 */
export default class GridModel extends Model {
  @attr('number') x!: number;
  @attr('number') y!: number;
  @attr('string') backgroundUrl!: string;
  @attr('number') version!: number;

  @belongsTo('master-allocation', { async: false, inverse: null })
  masterAllocation!: MasterAllocationModel;

  @hasMany('seat-assignment', { async: false, inverse: 'grid' })
  seatAssignments!: SyncHasMany<SeatAssignmentModel>;

  @hasMany('seat-assignment', { async: false, inverse: null })
  deltaSeatAssignments!: SyncHasMany<SeatAssignmentModel>;

  get backgroundImageUrl(): string {
    if (ENV.environment === 'functional') {
      return `http://foo.ticketsolvedev.com:3000${this.backgroundUrl}`;
    }
    return this.backgroundUrl;
  }

  /**
   * This loads the `delta-seat-assignments` which provide the uncached diff
   * to the version that we have already loaded.
   *
   * Once the backend cache is rebuilt a new version will be created and the
   * `delta-seat-assignments` will be empty with the newest version.
   */
  loadDeltaForCurrentVersion = memberAction({
    type: 'get',
    path: '',
    before(this: GridModel, payload) {
      payload.version = this.version;
      return payload;
    },
    after: serializeAndPush
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    grid: GridModel;
  }
}
