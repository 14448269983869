import type ApplicationInstance from '@ember/application/instance';

import type ErrorsService from 'ticketbooth/services/errors';
import type IframeService from 'ticketbooth/services/iframe';

export function initialize(appInstance: ApplicationInstance): void {
  const iframeService = appInstance.lookup('service:iframe') as IframeService;
  const errorsService = appInstance.lookup('service:errors') as ErrorsService;

  if (iframeService.isActive) {
    errorsService.setPrefix('[iframe-setup]: ');
    iframeService.setup();
  }
}

export default { after: 'settings', initialize };
