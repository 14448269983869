/* import __COLOCATED_TEMPLATE__ from './waiting-list-link.hbs'; */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type WaitingListEntryModel from 'ticketbooth/models/waiting-list-entry';
import type MembershipService from 'ticketbooth/services/membership';

interface WaitingListLinkSignature {
  Args: {
    event: EventModel;
  };
}

export default class WaitingListLinkComponent extends Component<WaitingListLinkSignature> {
  @service private membership!: MembershipService;

  get waitingListEntry(): WaitingListEntryModel | null {
    const { member } = this.membership;

    return (
      member?.waitingListEntries
        .slice()
        .find(({ event }) => event === this.args.event) ?? null
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    WaitingListLink: typeof WaitingListLinkComponent;
    'waiting-list-link': typeof WaitingListLinkComponent;
  }
}
