import isToday from 'date-fns/isToday';

import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type ShowModel from 'ticketbooth/models/show';
import type EventCalendarService from 'ticketbooth/services/event-calendar';
import { findNextBookableEvent } from 'ticketbooth/utils/event';

export default class extends Route {
  @service private eventCalendar!: EventCalendarService;
  @service private router!: RouterService;

  async beforeModel(_transition: Transition) {
    const show = this.modelFor('listings.show') as ShowModel;

    if (show.displayEventsCalendar && !show.salesDisabled) {
      await this.transitionToEventCalendar(show);
    }
  }

  private async transitionToEventCalendar(show: ShowModel) {
    const events = await this.eventCalendar.loadEventsOfMonth(
      show,
      show.firstEventDate
    );

    // Always go to the today's event first if available - even if sold out
    const nextBookableEvent =
      events.find(({ day }) => isToday(day)) ?? findNextBookableEvent(events)!;

    this.router.replaceWith('listings.show.event.index', nextBookableEvent);
  }
}
