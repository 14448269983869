/* import __COLOCATED_TEMPLATE__ from './customer-form.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type Form from 'tangram/components/form';
import { mapOptions } from 'tangram/helpers/map-options';
import escapeRegExp from 'tangram/utils/escape-regex';
import type LocaleService from 'ticketbooth/services/locale';
import type { CustomerFormFieldsConfig } from 'ticketbooth/services/preload';
import type PreloadService from 'ticketbooth/services/preload';

const SEPARATOR = '---';

interface CheckoutFormCustomerFormSignature {
  Args: {};
  Blocks: {
    'before-terms-conditions': [];
  };
}

export default class CheckoutFormCustomerFormComponent extends Component<CheckoutFormCustomerFormSignature> {
  @service private preload!: PreloadService;
  @service private locale!: LocaleService;

  get displayLoqate() {
    return this.preload.getValue('loqate_enabled_ticketbooth');
  }
  get loqateKey() {
    return this.preload.getValue('loqate_license_code');
  }
  get displayOptInEmail() {
    return this.preload.getValue('display_opt_in_email');
  }
  get displayOptIn3rdParty() {
    return this.preload.getValue('display_opt_in_3rd_party');
  }
  get displayOptInMail() {
    return this.preload.getValue('display_opt_in_mail');
  }
  get displayOptInSMS() {
    return this.preload.getValue('display_opt_in_sms');
  }

  get allowedCountries(): string[] {
    return this.preload.getValue('allowed_countries') as string[];
  }
  get priorityCountries(): string[] {
    return this.preload.getValue('priority_countries') as string[];
  }

  get countriesOptions(): string[] {
    const { priorityCountries, allowedCountries } = this;
    return [
      ...priorityCountries,
      SEPARATOR,
      ...allowedCountries.filter(
        country => !priorityCountries.includes(country)
      )
    ];
  }

  get irelandRegions() {
    return mapOptions([this.preload.getValue('ireland_regions') as string[]]);
  }

  get currentShortLocale() {
    return this.locale.currentShortLocale;
  }

  @action
  searchCountries(change: Form['change'], searchTerm: string | null) {
    // Keep search term for validations in separate property
    change('countrySearchTerm', searchTerm);

    if (isBlank(searchTerm)) {
      return this.countriesOptions;
    }

    if (this.allowedCountries.includes(searchTerm!)) {
      // Auto select country if the user forgot to click the option in the dropdown
      change('country', searchTerm);
      return [searchTerm];
    }

    const regex = new RegExp(escapeRegExp(searchTerm!), 'i');
    return this.countriesOptions.filter(country => regex.test(country));
  }

  @action
  setValidCountry(
    change: Form['change'],
    property: 'country',
    value: string | null
  ) {
    if (!isBlank(value)) {
      if (value === SEPARATOR) {
        change(property, null);
      } else if (this.allowedCountries.includes(value!)) {
        // Set only allowed countries
        change(property, value);
      }
    }
  }

  @action
  changeEmail(change: Form['change'], property: string, email: string = '') {
    change(property, email.trim());
  }

  @action
  shouldDisplayFormField(name: keyof CustomerFormFieldsConfig) {
    return this.preload.customerFormFieldsForOnline[name].display;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutForm::CustomerForm': typeof CheckoutFormCustomerFormComponent;
    'checkout-form/customer-form': typeof CheckoutFormCustomerFormComponent;
  }
}
