import { tracked } from '@glimmer/tracking';
import { differenceInSeconds, isPast } from 'date-fns';
import without from 'lodash-es/without';

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { run, later } from '@ember/runloop';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

import type NotificationsService from 'tangram/services/notifications';
import type CartModel from 'ticketbooth/models/cart';
import { INCLUDE_ALL } from 'ticketbooth/utils/cart-api';
import type { UISessionData } from 'ticketbooth/utils/cart-ui-session';
import {
  fetchCartSession,
  saveCartSession
} from 'ticketbooth/utils/cart-ui-session';

import type TicketboothErrorsService from './errors';
import type LocaleService from './locale';

export default class CartProviderService extends Service {
  @service private store!: Store;
  @service private errors!: TicketboothErrorsService;
  @service private notifications!: NotificationsService;
  @service private locale!: LocaleService;

  private cartChangedCallbacks: Function[] = [
    this.updateSecondsLeft,
    this.errors.onCartChange
  ];
  private expiryCallbacks: Function[] = [this.onCartExpired];
  private cartItemsChangedCallbacks: Function[] = [this.displayCartWarnings];
  private countdown!: ReturnType<typeof setTimeout>;
  @tracked secondsLeft!: number;

  onCartChange(callback: Function) {
    this.cartChangedCallbacks = [...this.cartChangedCallbacks, callback];
  }
  offCartChange(callback: Function) {
    this.cartChangedCallbacks = without(this.cartChangedCallbacks, callback);
  }
  onCartItemsChange(callback: Function) {
    this.cartItemsChangedCallbacks = [
      ...this.cartItemsChangedCallbacks,
      callback
    ];
  }
  offCartItemsChange(callback: Function) {
    this.cartItemsChangedCallbacks = without(
      this.cartItemsChangedCallbacks,
      callback
    );
  }
  onExpiration(callback: Function) {
    this.expiryCallbacks = [...this.expiryCallbacks, callback];
  }
  offExpiration(callback: Function) {
    this.expiryCallbacks = without(this.expiryCallbacks, callback);
  }

  constructor() {
    super(...arguments);

    this.startCountdown();
  }

  willDestroy() {
    this.stopCountdown();
  }

  /**
   * Public cart model (should always be there)
   *
   * Note: Backend is using a cookie to remember the cart id (`_ticketsolve_session`)
   */
  get cart(): CartModel {
    return this._cart as CartModel;
  }

  /**
   * Internal cart reference (can be `null` during setup)
   */
  private get _cart(): CartModel | null {
    const carts = this.store.peekAll('cart').slice();
    // Take latest cart as fallback in case the cleanup fails in `clearStaleRecords`
    return carts[carts.length - 1];
  }

  get isEmpty() {
    return this._cart?.hasNoItems;
  }

  get isExpired() {
    return this._cart?.localExpiresAt
      ? isPast(this._cart.localExpiresAt)
      : false;
  }

  @action
  async reload(): Promise<void> {
    const cart = this._cart
      ? await this._cart.reapCart()
      : await this.store.queryRecord('cart', { include: INCLUDE_ALL });

    await this.onCartChanged(cart);
  }

  /**
   * Empty carts are purged on the backend after a certain time, so we can
   * technically receive a new one anytime. Therefore we make sure to remove the
   * outdated ones from the ember-data store as well.
   *
   * See also `PurgeOldCarts`
   */
  private clearStaleRecords(current: CartModel) {
    this.store
      .peekAll('cart')
      .filter(cart => cart !== current)
      .forEach(cart => cart.unloadRecord());
  }

  @action
  private startCountdown() {
    this.updateSecondsLeft();

    if (this.isExpired) {
      this.expiryCallbacks.forEach(async callback => await callback());
    }

    this.countdown = setTimeout(() => {
      run(() => this.startCountdown());
    }, 1000);
  }

  @action
  private stopCountdown() {
    clearTimeout(this.countdown);
  }

  @action
  private updateSecondsLeft() {
    if (this._cart?.localExpiresAt) {
      this.secondsLeft = differenceInSeconds(
        this._cart.localExpiresAt,
        new Date()
      );
    }
  }

  @action
  private async onCartExpired() {
    await this._cart?.reapCart();
  }

  @action
  async onCartChanged(cart: CartModel) {
    this.clearStaleRecords(cart);

    await Promise.all(this.cartChangedCallbacks.map(callback => callback()));
  }

  @action
  async onCartItemsChanged() {
    await Promise.all(
      this.cartItemsChangedCallbacks.map(callback => callback())
    );
  }

  @action
  private displayCartWarnings() {
    if (this.cart.hasSplitSeats) {
      this.notifications.error(this.locale.translate('cart.has_split_seats'));
    }
  }

  async createNewCart() {
    try {
      await this.reload();
    } catch (error) {
      if (this.errors.isQueueItError(error)) {
        this.notifyQueueResetAndReload();
      }
      throw error;
    }
  }

  notifyQueueResetAndReload() {
    this.notifications.error(
      'Your queue position has been reset. Reloading page in 3 seconds.'
    );

    later(() => {
      window.location.pathname = '/';
    }, 3000);
  }

  get uiSessionData() {
    return fetchCartSession(this.cart.id);
  }
  set uiSessionData(data: Omit<UISessionData, 'cartId'>) {
    saveCartSession(this.cart.id, data);
  }
}
