/* import __COLOCATED_TEMPLATE__ from './session-footer.hbs'; */
import Component from '@glimmer/component';
import type AnnoucerService from 'a11y-announcer';
import { differenceInSeconds, isAfter } from 'date-fns';
import { Machine } from 'xstate';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { useMachine } from 'ember-statecharts';

import type CurrencyService from 'tangram/services/currency';
import { matchesState } from 'tangram/utils/statecharts';
interface StateContext {
  seconds: number;
}
interface StateSchema {
  states: {
    noSession: {};
    inSession: {
      states: {
        idle: {};
        refreshing: {};
        hiding: {};
      };
    };
  };
}

type StateEvent = { type: 'SESSION_UPDATE' } | { type: 'REFRESH' };

interface SessionFooterComponentSignature {
  Args: {
    total?: number;
    expiresAt?: Date;
  };
}

export default class SessionFooterComponentComponent extends Component<SessionFooterComponentSignature> {
  @service currency!: CurrencyService;
  @service announcer!: AnnoucerService;
  get machine() {
    return this.statechart;
  }

  statechart = useMachine<StateContext, StateSchema, StateEvent, any, {}>(
    this,
    () => {
      return {
        machine: Machine<StateContext, StateSchema, StateEvent>({
          initial: 'noSession',
          states: {
            noSession: {
              id: 'noSession',
              on: {
                SESSION_UPDATE: {
                  target: 'inSession',
                  cond: 'hasSession'
                }
              }
            },
            inSession: {
              entry: 'announceTimeStart',
              initial: 'refreshing',
              states: {
                idle: {
                  entry: ['updateTime', 'announceTime'],
                  on: {
                    SESSION_UPDATE: [
                      {
                        target: 'hiding',
                        cond: 'hasNoSession'
                      },
                      {
                        target: 'refreshing'
                      }
                    ],
                    REFRESH: 'refreshing'
                  }
                },
                refreshing: {
                  always: [
                    {
                      target: 'idle',
                      cond: 'hasSession'
                    },
                    'hiding'
                  ],
                  on: {
                    SESSION_UPDATE: {
                      target: 'hiding',
                      cond: 'hasNoSession'
                    }
                  }
                },
                hiding: {
                  after: {
                    400: '#noSession'
                  },
                  on: {
                    SESSION_UPDATE: {
                      target: 'idle',
                      cond: 'hasSession'
                    }
                  }
                }
              }
            }
          },
          context: {
            seconds: -1
          }
        }).withConfig({
          guards: {
            hasSession: () =>
              !!this.args.expiresAt && isAfter(this.args.expiresAt, new Date()),
            hasNoSession: () =>
              !(
                !!this.args.expiresAt &&
                isAfter(this.args.expiresAt, new Date())
              )
          },
          actions: {
            updateTime: c => {
              c.seconds = differenceInSeconds(this.args.expiresAt!, new Date());
              this.refresh();
            },
            announceTimeStart: _ => {
              const minutes = Math.floor(
                differenceInSeconds(this.args.expiresAt!, new Date()) / 60
              );

              this.announcer.announce(
                `Tickets added, you have ${minutes} minutes left to complete checkout.`,
                'assertive'
              );
            },
            announceTime: c => {
              if (c.seconds % 120 !== 0) return;

              this.announcer.announce(
                `There are ${
                  c.seconds / 60
                } minutes left before your basket expires.`,
                'polite'
              );
            }
          }
        })
      };
    }
  );

  @matchesState('inSession')
  isInSession!: boolean;
  @matchesState('noSession')
  isNotInSession!: boolean;

  @matchesState({ inSession: 'hiding' })
  isHiding!: boolean;

  get seconds() {
    return this.machine.state!.context.seconds;
  }

  get sessionLeft() {
    if (this.seconds >= 0) {
      const minutes = Math.floor(this.seconds / 60);
      const seconds = Math.floor(this.seconds % 60);

      return `${minutes < 10 ? '0' : ''}${minutes}:${
        seconds < 10 ? '0' : ''
      }${seconds}`;
    }
    return '00:00';
  }

  @action
  sessionUpdate() {
    this.machine.send('SESSION_UPDATE');
  }

  @action
  refresh() {
    setTimeout(() => this.machine.send('REFRESH'), 1000);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SessionFooter: typeof SessionFooterComponentComponent;
    'session-footer': typeof SessionFooterComponentComponent;
  }
}
