/* import __COLOCATED_TEMPLATE__ from './cart.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import { task, timeout } from 'ember-concurrency';

import type EnvironmentService from 'tangram/services/environment';
import type CartModel from 'ticketbooth/models/cart';
import type CartProviderService from 'ticketbooth/services/cart-provider';

interface IframeHeaderCartSignature {
  Args: {
    cart: CartModel;
  };
}

const CHECKOUT_CONTENT_GROUP_ROUTES = [
  'cart',
  'checkout',
  'donations-prompt',
  'confirm-order',
  'extras-prompt',
  'order-completed',
  'products-prompt',
  'login-wall'
];

export default class IframeHeaderCartComponent extends Component<IframeHeaderCartSignature> {
  @service private environment!: EnvironmentService;
  @service private cartProvider!: CartProviderService;
  @service private router!: RouterService;

  @tracked isOpen = false;

  constructor(owner: unknown, args: IframeHeaderCartSignature['Args']) {
    super(owner, args);
    this.cartProvider.onCartItemsChange(this.runDisplayTask);
  }

  willDestroy() {
    super.willDestroy();
    this.cartProvider.offCartItemsChange(this.runDisplayTask);
  }

  @action
  private runDisplayTask() {
    const currentRoute = this.router.currentRouteName;
    if (!CHECKOUT_CONTENT_GROUP_ROUTES.includes(currentRoute)) {
      this.displayTask.perform();
    }
  }

  private displayTask = task({ restartable: true }, async () => {
    this.open();
    if (this.environment.isTest) {
      return;
    }
    await timeout(2500);
    this.close();
  });

  @action
  open() {
    this.isOpen = true;
  }

  @action
  close() {
    this.isOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'IframeHeader::Cart': typeof IframeHeaderCartComponent;
    'iframe-header/cart': typeof IframeHeaderCartComponent;
  }
}
