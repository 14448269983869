import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type {
  ParentInformationResponse,
  ResizeResponse,
  ScrollResponse
} from 'ticketbooth/utils/iframe-handling-receive-messages';

import type RouterScrollService from './-router-scroll';
import type CartProviderService from './cart-provider';
import type DocumentService from './document';
import type TicketboothErrorsService from './errors';
import type IframeMessengerService from './iframe-messenger';
import type IframePositionService from './iframe-position';
import type IframeRouterService from './iframe-router';
import type MembershipService from './membership';
import type { IframePathMapping } from './preload';
import type PreloadService from './preload';

export default class IframeService extends Service {
  @service private preload!: PreloadService;
  @service private document!: DocumentService;
  @service private errors!: TicketboothErrorsService;

  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;
  @service private router!: RouterService;
  @service private routerScroll!: RouterScrollService;
  @service private iframeMessenger!: IframeMessengerService;
  @service private iframeRouter!: IframeRouterService;
  @service private iframePosition!: IframePositionService;

  get isActive(): boolean {
    return this.preload.getValue('ticketbooth_iframe') && !!window.parent;
  }
  get homeUrl(): string {
    return this.preload.getValue('home_url');
  }
  get shouldRedirectToHomeUrl() {
    return (
      this.preload.getValue('ticketbooth_iframe_home_url_redirect') &&
      window.top === window.self
    );
  }
  get allowedTargetOrigins(): string[] {
    const postMessageUrls: string | null = this.preload.getValue(
      'iframe_post_message_destination_url'
    );
    if (isBlank(postMessageUrls)) {
      return [this.homeUrl];
    }
    return postMessageUrls!.split(',');
  }
  get iframePaths(): IframePathMapping {
    return this.preload.iframePaths;
  }
  get basePath(): string {
    const { iframePaths, homeUrl } = this;
    return typeof iframePaths.base === 'string' ? iframePaths.base : homeUrl;
  }

  async setup() {
    if (this.shouldRedirectToHomeUrl) {
      window.location.href = this.homeUrl;
      return;
    }
    if (!(await this.document.testCookieSupport())) {
      this.errors.log(new Error('No cookie support'));
      this.router.transitionTo('cookies-disabled');
      return;
    }

    const { iframeMessenger, iframeRouter, iframePosition } = this;
    const { cartProvider, membership, routerScroll } = this;

    // Pause all routing until we receive the parent information message as we
    // need to run some checks before allowing any transition. See also
    // `continueRouting`
    iframeRouter.pauseRouting();

    // Setup handler for receiving messages from the parent frame via IframeHandling
    iframeMessenger.addMessageHandler();

    // Send messages to the parent frame (IframeHandling) for the following events
    cartProvider.onCartChange(this.postUpdateCartMessage);
    membership.onMemberChange(this.postUpdateMemberMessage);
    membership.onLogin(this.postUpdateMemberMessage);
    iframeRouter.captureInitTransitionDone().then(() => {
      routerScroll.onScrollReset(this.postScrollToIframeMessage);
      routerScroll.onScrollBy(this.postScrollByMessage);
    });
    iframePosition.onScrollCaptureStart(this.postStartScrollCaptureMessage);
    iframePosition.onScrollCaptureEnd(this.postEndScrollCaptureMessage);

    // Handle received messages from parent frame (IframeHandling) for following events
    iframeMessenger.on('parentInformation', this.continueRouting);
    iframeMessenger.on('logout', this.logout);
    iframeMessenger.on('didResize', this.updateIframePosition);
    iframeMessenger.on('didScroll', this.updateIframeScrollPosition);

    iframeMessenger.pingIframeHandling();
  }

  /**
   * For every transition, compare the parent location path with the iframe paths
   * setting and redirect if incorrect.
   *
   * This is useful if the user navigates inside Ticketbooth to another page but
   * we actually want the parent frame to redirect instead (e.g. "Return to shows" or "Checkout" button).
   *
   * Example
   *   - "Return to shows" redirects the parent to the iframe path setting for 'shows' (e.g. /whats-on)
   *   - "Checkout" redirects the parent to the iframe path setting for 'cart' (e.g. /my-cart)
   */
  @action
  private continueRouting(message: ParentInformationResponse) {
    const { iframeRouter, router } = this;
    const {
      parentInformation: { location }
    } = message;

    const pausedTransition = iframeRouter.continueRouting();

    // Make sure we don't forget to retry the initial page load transition
    if (pausedTransition) {
      this.retryOrRedirectIfInvalid(pausedTransition, location);
    }

    // Continously check every new transition for a potential redirect
    router.on('routeWillChange', transition =>
      this.redirectIfInvalid(transition, location)
    );
  }

  @action
  private retryOrRedirectIfInvalid(transition: Transition, location: string) {
    const { isValid, path } = this.iframeRouter.isParentPathValid(
      transition,
      location
    );
    if (isValid) {
      transition.retry();
    } else {
      this.postRedirectMessage(path!);
    }
  }

  @action
  private redirectIfInvalid(transition: Transition, location: string) {
    const { isValid, path } = this.iframeRouter.isParentPathValid(
      transition,
      location
    );
    if (!isValid) {
      transition.abort();
      this.postRedirectMessage(path!);
    }
  }

  /**
   * Update cart item count in parent frame
   */
  @action
  private postUpdateCartMessage() {
    const { iframeMessenger } = this;
    const itemCount = String(this.cartProvider.cart.ticketsAndProductsCount);
    const result = 'null'; // TODO: Send readable cart diff

    iframeMessenger.postMessage({ event: 'updateCart', itemCount, result });
  }

  /**
   * Update member status in parent frame
   */
  @action
  private postUpdateMemberMessage() {
    const { iframeMessenger } = this;
    const loggedIn = String(this.membership.isLoggedIn);
    const name = this.membership.member?.nameWithObscuredEmail ?? '';

    iframeMessenger.postMessage({ event: 'memberStatus', loggedIn, name });
  }

  /**
   * Tell parent frame to redirect
   */
  @action
  private postRedirectMessage(path: string) {
    const { iframeMessenger, basePath } = this;
    const destinationUrl = new URL(path, basePath).toString();

    iframeMessenger.postMessage({ event: 'newDestinationUrl', destinationUrl });
  }

  /**
   * Tell parent frame to capture scroll/resize information and send via
   * `didResize` and `didScroll` messages
   */
  @action
  private postStartScrollCaptureMessage() {
    const { iframeMessenger } = this;

    iframeMessenger.postMessage({ event: 'startScrollCapture' });
  }

  /**
   * Tell parent frame to stop capturing scroll/resize information.
   */
  @action
  private postEndScrollCaptureMessage() {
    const { iframeMessenger } = this;

    iframeMessenger.postMessage({ event: 'endScrollCapture' });
  }

  @action
  private postScrollToIframeMessage() {
    const { iframeMessenger } = this;

    iframeMessenger.postMessage({ event: 'scrollToIframe' });
  }

  @action
  private postScrollByMessage(x: number, y: number) {
    const { iframeMessenger } = this;

    iframeMessenger.postMessage({ event: 'scrollBy', x, y });
  }

  /**
   * Logout member on request of the parent frame
   */
  @action
  private logout() {
    this.membership.logout();
  }

  /**
   * Receive iframe position data from parent frame
   *
   * Use in combination with `startScrollCapture` and `endScrollCapture`
   */
  @action
  private updateIframePosition({ didResize }: ResizeResponse) {
    this.iframePosition.change(didResize);
  }

  /**
   * Receive iframe scroll position data from parent frame
   *
   * Use in combination with `startScrollCapture` and `endScrollCapture`
   */
  @action
  private updateIframeScrollPosition({ didScroll }: ScrollResponse) {
    this.iframePosition.change({ scrollTop: didScroll });
  }
}
