import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';

export default class extends Controller {
  queryParams = ['tag'];

  @tracked
  tag!: string; // this is always defined because route would take care of redirecting if not

  get pageTitle() {
    return `Products for ${this.model.event.day.toLocaleDateString()}`;
  }
}
