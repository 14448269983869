import Model, { attr } from '@ember-data/model';

export default class MultipassModel extends Model {
  @attr('string') appleMultipassUrl!: string;
  @attr('string') googleMultipassUrl!: string;
  @attr('string') fromIndex!: string;
  @attr('string') toIndex!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    multipass: MultipassModel;
  }
}
