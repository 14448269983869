import orderBy from 'lodash-es/orderBy';
import uniq from 'lodash-es/uniq';
import moment from 'moment';

import type { SyncHasMany } from '@ember-data/model';
import { attr, hasMany, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';

import { memberAction } from 'ember-api-actions';

import { serializeAndPush } from 'tangram/utils/serialize-and-push';
import type SettingsService from 'ticketbooth/services/settings';

import AnalyticsModel from './analytics-model';
import type EventTicketPriceModel from './event-ticket-price';
import type MasterAllocationModel from './master-allocation';
import type ProductModel from './product';
import type ShowModel from './show';
import type TicketAllocationModel from './ticket-allocation';
import type VenueModel from './venue';
import type VenueLayoutModel from './venue-layout';

export default class EventModel extends AnalyticsModel {
  @service settings!: SettingsService;

  @attr('day') day!: Date;
  @attr('date') timeOfDay!: Date;
  @attr('date') openingTime!: Date | null;
  @attr('date') onlineBookingCutoff!: Date;
  @attr('number') maxTickets!: number;
  @attr('string') comment!: string;
  @attr('number') duration!: number;
  @attr('string') salesDisabledMessage!: string;
  @attr('boolean') soldout!: boolean;
  @attr('string') soldoutMessage!: string;
  @attr('boolean') waitingListEnabled!: boolean;
  @attr('json') waitingListExplanationSnippet!: object;
  @attr('string') eventAttribute!: string;
  @attr('string') bookingFeeNote!: string;

  @attr('string') firstAvailableZone!: string | null;
  @attr('boolean') private hasSeatSelection!: boolean;
  @attr('boolean') private seatChoiceBestAvailable!: boolean;
  @attr('boolean') checkForSingleSeats!: boolean;
  @attr('boolean') lowAvailability!: boolean;

  @belongsTo('show', { async: false, inverse: null })
  show!: ShowModel;

  @belongsTo('venue', { async: false, inverse: null })
  venue!: VenueModel;

  @belongsTo('venue-layout', { async: false, inverse: null })
  venueLayout!: VenueLayoutModel;

  @hasMany('product', { async: false, polymorphic: true, inverse: null })
  suggestedProducts!: SyncHasMany<ProductModel>;

  @hasMany('master-allocation', { async: false, inverse: 'event' })
  masterAllocations!: SyncHasMany<MasterAllocationModel>;

  /**
   * Sort similar to backend
   * (https://github.com/ticketsolve/ticketsolve/blob/15fb60366e29ba735551b7932cd567ec379b0c7e/app/models/event.rb#L304)
   */
  get sortedMasterAllocations(): MasterAllocationModel[] {
    const { masterAllocations } = this;
    return orderBy(
      masterAllocations.slice(),
      [
        ({ priority }) => priority,
        ({ size }) => size,
        ({ ticketZone }) => ticketZone.id
      ],
      ['desc', 'desc', 'desc']
    );
  }

  get ticketAllocations(): TicketAllocationModel[] {
    return this.sortedMasterAllocations
      .map(({ ticketAllocations }) => ticketAllocations.slice())
      .flat();
  }

  get visibleTicketPrices(): EventTicketPriceModel[] {
    return uniq(
      this.sortedMasterAllocations
        .map(({ visibleTicketPrices }) => visibleTicketPrices)
        .flat()
    );
  }

  async loadAllTicketPrices(): Promise<void> {
    // @ts-ignore - insufficient types at `hasMany()`
    if (this.hasMany('masterAllocations').value() == null) {
      await this.reloadAllTicketPrices({});
    }
  }

  private reloadAllTicketPrices = memberAction({
    type: 'get',
    path: '?include=master-allocations.ticket-allocations.event-ticket-prices,master-allocations.ticket-zone',
    after: serializeAndPush
  });

  get salesDisabled(): boolean {
    return !!this.salesDisabledMessage;
  }

  get googleAnalyticsSku(): string {
    return this.show.googleAnalyticsSku;
  }

  get googleAnalyticsName(): string {
    return this.show.googleAnalyticsName;
  }

  get googleAnalyticsCategory(): string {
    return this.show.googleAnalyticsCategory;
  }

  get googleAnalyticsBrand(): string {
    return '';
  }

  get googleAnalyticsVariant(): string {
    return `${this.formattedStartTime} @ ${this.venue.name} - ${this.id}`;
  }

  get allDay(): boolean {
    return this.timeOfDay === null;
  }

  get dateTime(): Date {
    const { day, timeOfDay } = this;
    const copy = new Date(day.getTime());
    if (this.allDay) {
      // Set to local midnight
      copy.setHours(0);
      copy.setMinutes(0);
      copy.setSeconds(0);
    } else {
      copy.setHours(timeOfDay.getUTCHours());
      copy.setMinutes(timeOfDay.getUTCMinutes());
      copy.setSeconds(timeOfDay.getUTCSeconds());
    }
    return copy;
  }

  get openingDateTime(): Date | null {
    const { day, openingTime } = this;
    if (!day || !openingTime) {
      return null;
    }
    const copy = new Date(day.getTime());
    copy.setHours(openingTime.getUTCHours());
    copy.setMinutes(openingTime.getUTCMinutes());
    copy.setSeconds(openingTime.getUTCSeconds());
    return copy;
  }

  get isWithinCutoffTime(): boolean {
    if (this.settings.getSetting('hideExpiredOnlineSalesGuardEvents')) {
      const now = new Date();
      return this.onlineBookingCutoff > now;
    }
    return true;
  }

  get formattedStartTime(): string {
    const day = moment(this.day).format('D MMM YYYY');
    const time = moment(this.timeOfDay).format('HH:mm');

    return `${day} - ${time}`;
  }

  get displaySeatSelection(): boolean {
    return this.hasSeatSelection && !this.seatChoiceBestAvailable;
  }

  get hasZoneMap() {
    const { assetAttachment } = this.venueLayout;
    return (assetAttachment?.originalUrl ?? '').length > 0;
  }

  get isBookable(): boolean {
    return !this.soldout && this.isWithinCutoffTime;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    event: EventModel;
  }
}
