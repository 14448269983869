import branding from '../modifiers/branding';

interface HeadingSignature {
  Args: {};
  Blocks: {
    default: [];
  };
}

const HeadingComponent: TemplateOnlyComponent<HeadingSignature> = <template>
  {{! @glint-nocheck: not typesafe yet }}
  <h1 class="text-3xl leading-loose"
    {{branding "fontFamily" "heading_font"}}
  >
    {{yield}}
  </h1>
</template>

export default HeadingComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Heading: typeof HeadingComponent;
    heading: typeof HeadingComponent;
  }
}
