/* import __COLOCATED_TEMPLATE__ from './donation-product.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CoverDonationProductSignature {
  Args: {};
}

const CoverDonationProductComponent =
  templateOnlyComponent<CoverDonationProductSignature>();

export default CoverDonationProductComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::DonationProduct': typeof CoverDonationProductComponent;
    'cover/donation-product': typeof CoverDonationProductComponent;
  }
}
