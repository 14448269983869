import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';

export default class extends Route {
  @service private checkoutFlow!: CheckoutFlowService;

  async beforeModel() {
    if (!this.checkoutFlow.donationsPromptStep.available) {
      this.checkoutFlow.goToNextStep('donations_prompt');
    }
  }
}
