/* import __COLOCATED_TEMPLATE__ from './cart.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import { task, timeout } from 'ember-concurrency';

import type Dropdown from 'tangram/components/dropdown';
import type EnvironmentService from 'tangram/services/environment';
import type CartModel from 'ticketbooth/models/cart';
import type CartProviderService from 'ticketbooth/services/cart-provider';

interface HeaderCartSignature {
  Args: {
    cart: CartModel;
    isLoading?: boolean;
  };
}

type DropdownActions = {
  show: Dropdown['show'];
  hide: Dropdown['hide'];
};

const CHECKOUT_CONTENT_GROUP_ROUTES = [
  'cart',
  'checkout',
  'donations-prompt',
  'confirm-order',
  'extras-prompt',
  'order-completed',
  'products-prompt',
  'login-wall'
];

export default class HeaderCartComponent extends Component<HeaderCartSignature> {
  @service private environment!: EnvironmentService;
  @service private cartProvider!: CartProviderService;
  @service private router!: RouterService;

  private dropdownActions!: DropdownActions;

  get displayCount() {
    const { cart } = this.args;
    return cart.ticketsAndProductsCount && cart.isNotConfirmed;
  }

  @action
  observeCartChanges(dropdownActions: DropdownActions) {
    this.dropdownActions = dropdownActions;
    this.cartProvider.onCartItemsChange(this.displayCartIfNotInCheckout);
  }

  willDestroy() {
    super.willDestroy();
    this.cartProvider.offCartItemsChange(this.displayCartIfNotInCheckout);
  }

  @action
  displayCartIfNotInCheckout() {
    const currentRoute = this.router.currentRouteName?.replace('.index', '');
    if (!CHECKOUT_CONTENT_GROUP_ROUTES.includes(currentRoute)) {
      this.displayCart();
    }
  }

  @action
  displayCart() {
    this.showCart.perform();
  }

  showCart = task({ restartable: true }, async () => {
    this.dropdownActions.show();
    if (this.environment.isTest) {
      return;
    }
    await timeout(2500);
    this.dropdownActions.hide();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Header::Cart': typeof HeaderCartComponent;
    'header/cart': typeof HeaderCartComponent;
  }
}
