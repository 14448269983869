import { belongsTo } from '@ember-data/model';

import PaymentModel from './payment';
import type RedemptionModel from './redemption';
import type RewardRedemptionModel from './reward-redemption';

export default class VoucherPaymentModel extends PaymentModel {
  @belongsTo('redemption', { async: false, inverse: null })
  redemption!: RedemptionModel;

  @belongsTo('reward-redemption', { async: false, inverse: null })
  rewardRedemption!: RewardRedemptionModel | null;

  get isRewardPayment(): boolean {
    return !!this.rewardRedemption;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'voucher-payment': VoucherPaymentModel;
  }
}
