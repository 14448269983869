import type ApplicationInstance from '@ember/application/instance';

import type ErrorsService from 'ticketbooth/services/errors';

export function initialize(appInstance: ApplicationInstance): void {
  const errorsService = appInstance.lookup('service:errors') as ErrorsService;

  errorsService.onSetup();
}

export default { after: 'settings', initialize };
