import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';

import Branding from 'tangram/modifiers/base-branding';
import type {
  BrandingOptions,
  LookAndFeelKey,
  CSSPropertyValue
} from 'tangram/modifiers/base-branding';
import { normalizeColor } from 'tangram/utils/color';
import type PreloadService from 'ticketbooth/services/preload';

/**
 * This modifier is meant to customize an element style based on the look and
 * feel settings in each account / subdomain
 */
export default class BrandingModifier<T = BrandingOptions> extends Branding<T> {
  @service private preload!: PreloadService;

  onInstall() {
    this.addHoverListeners();

    if (this.value) {
      this._element.setAttribute(
        `data-branding-${dasherize(this.property)}`,
        dasherize(this.key)
      );
    }
  }

  getLookAndFeel(key: LookAndFeelKey | CSSPropertyValue): CSSPropertyValue {
    const color = normalizeColor(key);
    return this.isColorProp(color)
      ? color
      : String(this.preload.lookAndFeel(key as LookAndFeelKey));
  }
}
