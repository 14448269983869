import isEmpty from 'lodash-es/isEmpty';

import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type EnvironmentService from 'tangram/services/environment';
import type TicketboothErrorsService from 'ticketbooth/services/errors';
import type PreloadService from 'ticketbooth/services/preload';

const TRUSTED_DOMAINS = [
  'd1bry8u3byci8.cloudfront.net',
  'dm7lxewn39lms.cloudfront.net',
  'd235gwso45fsgz.cloudfront.net',
  'dc40ra2rfm3rp.cloudfront.net',
  'd2qtepfzmrxn9w.cloudfront.net',
  'dfea8owrhnpxy.cloudfront.net',
  'd26mh6s6kbv14h.cloudfront.net',
  'd2vlzwpqfjjwbm.cloudfront.net',
  'd250t1ybnvyg6x.cloudfront.net',
  'd36fq82vfg15lr.cloudfront.net',
  'd2i6fv5xl70l3j.cloudfront.net',
  'di3zwf9gdu1b.cloudfront.net'
];

export default abstract class BasePaymentPageRoute extends Route {
  @service preload!: PreloadService;
  @service router!: RouterService;
  @service environment!: EnvironmentService;
  @service errors!: TicketboothErrorsService;

  abstract parentRouteName: string;

  private get isStrictPaymentPageEnabled() {
    return this.preload.getValue('ticketbooth_strict_payment_page') === true;
  }

  /**
   * Add CSP client-side to restrict new scripts from being loaded and data being sent out
   */
  addPaymentPageCSP() {
    if (!this.isStrictPaymentPageEnabled) {
      return;
    }

    const firstPartyOnly = (
      this.environment.isProduction
        ? TRUSTED_DOMAINS
        : ['http://localhost:4200', ...TRUSTED_DOMAINS]
    ).join(' ');

    const metaTag = document.createElement('meta');
    metaTag.setAttribute('http-equiv', 'Content-Security-Policy');
    metaTag.setAttribute(
      'content',
      [
        `form-action 'none'`,
        `base-uri 'none'`,
        `object-src 'none'`,
        `default-src 'none' *.sdiapi.com`,
        `frame-src 'self' https://hpp.realexpayments.com https://hpp.sandbox.realexpayments.com`,
        `script-src 'self' ${firstPartyOnly}`,
        `font-src 'self' data: ${firstPartyOnly}`,
        `connect-src 'self' ${firstPartyOnly}`,
        `manifest-src 'self' ${firstPartyOnly}`,
        `img-src 'self' ${firstPartyOnly}`,
        `style-src 'self' 'unsafe-inline' ${firstPartyOnly}`,
        `media-src 'self'`,
        `worker-src 'self' blob:`,
        `child-src 'self' blob:`
      ].join('; ')
    );
    document.head.appendChild(metaTag);
  }

  /**
   * Redirect the page when the transitioning away to make sure the CSP is not applied anymore
   */
  removePaymentPageCSP(transition: Transition) {
    if (this.isStrictPaymentPageEnabled) {
      try {
        if (!this.isExitTransition(transition)) {
          // Don't redirect when navigating in nested route (e.g. payments.voucher-payment)
          return;
        }
        transition.abort();
        window.location.replace(this.getRedirectURL(transition));
      } catch (error) {
        this.errors.log('removePaymentPageCSP failed', { extra: error });
        if (transition.isAborted) {
          transition.retry();
        }
      }
    }
  }

  private isExitTransition(transition: Transition): boolean {
    if (!transition.from) {
      return false;
    }

    const {
      from: { name: from },
      to: { name: to }
    } = transition;
    const { parentRouteName } = this;
    return from.includes(parentRouteName) && !to.includes(parentRouteName);
  }

  private getRedirectURL(transition: Transition) {
    const { name, params, queryParams } = transition.to;

    const args = [
      name,
      ...(isEmpty(params) ? [] : [...Object.values(params)]),
      ...(isEmpty(queryParams) ? [] : [{ queryParams: { ...queryParams } }])
    ] as const;

    // @ts-ignore
    return this.router.urlFor(...args);
  }
}
