import { attr } from '@ember-data/model';

import RedemptionModel from './redemption';

export default class RewardRedemptionModel extends RedemptionModel {
  isRewardRedemption = true;

  @attr('date') createdOn!: Date;
  @attr('date') updatedOn!: Date;

  get rewardsWereRedeemed() {
    return this.redeemedAmount > 0;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reward-redemption': RewardRedemptionModel;
  }
}
