/* import __COLOCATED_TEMPLATE__ from './iframe-header.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface IframeHeaderSignature {
  Args: {};
  Element: HTMLDivElement;
}

const IframeHeaderComponent = templateOnlyComponent<IframeHeaderSignature>();

export default IframeHeaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    IframeHeader: typeof IframeHeaderComponent;
    'iframe-header': typeof IframeHeaderComponent;
  }
}
