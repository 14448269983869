/* import __COLOCATED_TEMPLATE__ from './cart-delivery-method.hbs'; */
import Component from '@glimmer/component';

import type CartModel from 'ticketbooth/models/cart';

interface CartDeliveryMethodSignature {
  Args: {
    cart: CartModel;
    onChange: Function;
  };
}

export default class CartDeliveryMethod extends Component<CartDeliveryMethodSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CartDeliveryMethod: typeof CartDeliveryMethod;
  }
}
