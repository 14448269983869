import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import GoogleMapsApiService from 'ember-google-maps/services/google-maps-api';

// Keep this file as .js, as the the original google-maps-api service is also a js file

export default class TicketboothGoogleMapsApiService extends GoogleMapsApiService {
  @service preload;
  @service environment;

  /**
   * Override to build google maps url using account setting key
   *
   * Note: ember-google-maps compiles url at build time.
   */
  @action
  async buildGoogleMapsUrl(config) {
    const key = await this.preload.getValue(
      `${this.environment.key}_googlemaps_key`
    );
    let url = config.src;
    if (key) {
      url = url.replace(config.key, encodeURIComponent(key));
      url = `${url}&loading=async`;
    } else {
      if (!this.environment.isTest) {
        console.error(`No googlemaps_key found for this account`);
      }
    }
    return url;
  }
}
