import Service from '@ember/service';

export default class UserAgentService extends Service {
  get userAgent() {
    return navigator.userAgent;
  }

  get isAndriod() {
    return /andriod/i.test(this.userAgent);
  }

  get isiOS() {
    return /iPhone/i.test(this.userAgent);
  }

  get isMacOS() {
    return /iPad|iPod|Mac/i.test(this.userAgent);
  }

  get isApple() {
    return this.isiOS || this.isMacOS;
  }
}
