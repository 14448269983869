import Model, { attr, belongsTo } from '@ember-data/model';

import type EventModel from './event';
import type MemberModel from './member';

export default class WaitingListEntryModel extends Model {
  @attr('number') ticketsRequired!: number;

  @belongsTo('event', { async: false, inverse: null })
  event!: EventModel;

  @belongsTo('member', { async: false, inverse: 'waitingListEntries' })
  member!: MemberModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'waiting-list-entry': WaitingListEntryModel;
  }
}
