/* import __COLOCATED_TEMPLATE__ from './accessibility-instructions.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ShowHeaderAccessibilityInstructionsSignature {
  Args: {};
  Element: HTMLDivElement;
}

const ShowHeaderAccessibilityInstructionsComponent =
  templateOnlyComponent<ShowHeaderAccessibilityInstructionsSignature>();

export default ShowHeaderAccessibilityInstructionsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ShowHeader::AccessibilityInstructions': typeof ShowHeaderAccessibilityInstructionsComponent;
    'show-header/accessibility-instructions': typeof ShowHeaderAccessibilityInstructionsComponent;
  }
}
