import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type CartModel from 'ticketbooth/models/cart';
import type MoneyVoucherProductModel from 'ticketbooth/models/money-voucher';
import type CartProviderService from 'ticketbooth/services/cart-provider';

export default class ProductQuantity extends Helper {
  @service() cartProvider!: CartProviderService;

  get cart(): CartModel {
    return this.cartProvider.cart;
  }

  compute([product]: [MoneyVoucherProductModel]): number {
    return this.cart.quantityForGift(product);
  }
}
