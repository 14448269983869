/* import __COLOCATED_TEMPLATE__ from './show-header.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

import type ShowModel from 'ticketbooth/models/show';

interface ShowHeaderSignature {
  Args: {
    show: ShowModel;
    withoutLinks?: boolean;
  };
}

const ShowHeaderComponent = templateOnlyComponent<ShowHeaderSignature>();

export default ShowHeaderComponent;
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ShowHeader: typeof ShowHeaderComponent;
    'show-header': typeof ShowHeaderComponent;
  }
}
