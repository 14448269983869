import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type TicketboothErrorsService from 'ticketbooth/services/errors';
import type MembershipService from 'ticketbooth/services/membership';

export default class extends Route {
  @service private membership!: MembershipService;
  @service private cartProvider!: CartProviderService;
  @service private errors!: TicketboothErrorsService;

  private async loadSession() {
    return Promise.all([this.cartProvider.reload(), this.membership.reload()]);
  }

  async beforeModel() {
    try {
      await this.loadSession();
    } catch (error) {
      if (this.errors.isQueueItError(error)) {
        this.cartProvider.notifyQueueResetAndReload();
      }
      throw error;
    }
  }

  @action
  error(error: any, transition: Transition): boolean {
    this.errors.log(error, { extra: { transition } });

    // Transition to error route
    return true;
  }
}
