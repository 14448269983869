import type { Store } from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type EventCategoryModel from 'ticketbooth/models/event-category';
import { extractIdFromParam } from 'ticketbooth/utils/query-param';

type RouteParams = { event_category_id: string };

export default class EventCategoryRoute extends Route<EventCategoryModel> {
  @service store!: Store;

  async model({ event_category_id }: RouteParams): Promise<EventCategoryModel> {
    return await this.store.loadRecord(
      'event-category',
      extractIdFromParam(event_category_id)!,
      {
        reload: true
      }
    );
  }
}
