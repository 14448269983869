/* import __COLOCATED_TEMPLATE__ from './waiting-list-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { ObjectSchema } from 'yup';
import { number, object, string } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type ErrorsService from 'tangram/services/-errors-base';
import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import FormObject from 'tangram/utils/form-object';
import type EventModel from 'ticketbooth/models/event';
import type WaitingListEntryModel from 'ticketbooth/models/waiting-list-entry';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type MembershipService from 'ticketbooth/services/membership';

/**
 * User is logged in an only needs to provide number of tickets
 */
class LoggedInForm extends FormObject<LoggedInForm> {
  @tracked ticketsRequired!: number;
}

/**
 * User is logged out and provides login credentials + number of tickets
 */
class LoginForm extends FormObject<LoginForm> {
  @tracked ticketsRequired!: number;
  @tracked email!: string;
  @tracked password!: string;
}

/**
 * User has no account and provides signup details + number of tickets
 */
class SignupForm extends FormObject<SignupForm> {
  @tracked ticketsRequired!: number;
  @tracked email!: string;
  @tracked password!: string;
  @tracked firstName!: string;
  @tracked lastName!: string;
  @tracked phone!: string;
  @tracked passwordConfirmation!: string;
}

interface WaitingListFormSignature {
  Args: {
    event: EventModel;
  };
}

export default class WaitingListFormComponent extends Component<WaitingListFormSignature> {
  @service private membership!: MembershipService;
  @service private cartProvider!: CartProviderService;
  @service private store!: Store;
  @service private errors!: ErrorsService;
  @service private notifications!: NotificationsService;

  @tracked formObject!: LoggedInForm | LoginForm | SignupForm;
  @tracked schema!: ObjectSchema<ObjectShape>;

  get isLoggedInForm() {
    return this.formObject instanceof LoggedInForm;
  }
  get isLoginForm() {
    return this.formObject instanceof LoginForm;
  }
  get isSignupForm() {
    return this.formObject instanceof SignupForm;
  }
  get isLoginOrSignupForm() {
    return this.isLoginForm || this.isSignupForm;
  }

  get isOnWaitingList() {
    return this.waitingListEntry !== null;
  }
  get waitingListEntry(): WaitingListEntryModel | null {
    const { member } = this.membership;

    return (
      member?.waitingListEntries
        .slice()
        .find(({ event }) => event === this.args.event) ?? null
    );
  }

  constructor(owner: unknown, args: WaitingListFormSignature['Args']) {
    super(owner, args);

    if (this.isOnWaitingList) {
      // No form needed when the user is already on the waiting list
      return;
    }

    this.membership.onLogin(this.createLoggedInForm);

    if (this.membership.isLoggedIn) {
      this.createLoggedInForm();
    } else {
      this.createSignupForm();
    }
  }

  willDestroy() {
    this.membership.offLogin(this.createLoggedInForm);

    super.willDestroy();
  }

  @action
  private createLoggedInForm(form?: LoginForm | SignupForm) {
    this.formObject = new LoggedInForm({
      ticketsRequired: form?.ticketsRequired ?? 1
    });
    this.schema = object().shape({
      ticketsRequired: number().required().min(1)
    });
  }
  @action
  private createLoginForm(form?: SignupForm) {
    this.formObject = new LoginForm({
      ticketsRequired: form?.ticketsRequired ?? 1,
      email: form?.email ?? '',
      password: form?.password ?? ''
    });
    this.schema = object().shape({
      ticketsRequired: number().required().min(1),
      email: string().required(),
      password: string().required()
    });
  }
  @action
  private createSignupForm(form?: LoginForm) {
    const { cartCustomer } = this.cartProvider.cart;
    this.formObject = new SignupForm({
      ticketsRequired: form?.ticketsRequired ?? 1,
      email: form?.email ?? cartCustomer?.email ?? '',
      password: form?.password ?? '',
      passwordConfirmation: '',
      firstName: cartCustomer?.firstName ?? '',
      lastName: cartCustomer?.lastName ?? '',
      phone: cartCustomer?.phone ?? ''
    });
    this.schema = object().shape({
      ticketsRequired: number().required().min(1),
      firstName: string().required().max(96),
      lastName: string().required().max(96),
      phone: string().required().max(98),
      email: string().required(),
      password: string().required(),
      passwordConfirmation: string().required()
    });
  }

  @action
  swapSignupLoginForm() {
    if (this.formObject instanceof SignupForm) {
      this.createLoginForm(this.formObject);
    } else if (this.formObject instanceof LoginForm) {
      this.createSignupForm(this.formObject);
    }
  }

  @action
  async submit(form: LoggedInForm | LoginForm | SignupForm) {
    // Avoid reloading form during `loginMember` or `registerMember`
    this.membership.offLogin(this.createLoggedInForm);

    try {
      if (form instanceof LoginForm) {
        await this.loginMember(form);
      } else if (form instanceof SignupForm) {
        await this.registerMember(form);
      }
    } finally {
      this.membership.onLogin(this.createLoggedInForm);
    }

    try {
      await this.createWaitingListEntry(form);
    } catch (error) {
      if (this.membership.isLoggedIn && !(form instanceof LoggedInForm)) {
        // User must be logged in due to `loginMember` or `registerMember`
        this.createLoggedInForm(form);
      }
      throw error;
    }

    await this.membership.reload();
  }

  private async loginMember({ email, password }: LoginForm) {
    await this.membership.login(email, password);
  }

  private async registerMember(form: SignupForm) {
    await this.membership.signup(
      form.email,
      form.password,
      form.email,
      form.passwordConfirmation,
      {
        firstName: form.firstName,
        lastName: form.lastName,
        phone: form.phone
      }
    );
  }

  private async createWaitingListEntry({
    ticketsRequired
  }: LoggedInForm | LoginForm | SignupForm) {
    try {
      await this.store
        .createRecord('waiting-list-entry', {
          ticketsRequired,
          event: this.args.event
        })
        .save();
    } catch (error) {
      const list = getAllUserMessagesOrDefault(error);
      this.errors.log(error);
      this.notifications.error(list.join('. '));

      throw error;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    WaitingListForm: typeof WaitingListFormComponent;
    'waiting-list-form': typeof WaitingListFormComponent;
  }
}
