import Model, { attr } from '@ember-data/model';

import type { Locale } from 'tangram/helpers/humanize-locale';
import type { SnippetType } from 'ticketbooth/lib/locales';

export default class AccountModel extends Model {
  @attr('string') name!: string;
  @attr('string') customerType!: string;
  @attr('string') locale!: string;
  @attr('string') currency!: string;
  @attr('json') homeTitle!: SnippetType[];
  @attr('json') listingTitle!: SnippetType[];
  @attr('json') productsTitle!: SnippetType[];
  @attr('json') benefitsTitle!: SnippetType[];
  @attr('json') donationsTitle!: SnippetType[];
  @attr('json') inlineProductPromptTitle!: SnippetType[];
  @attr('boolean') displayAddAsGift!: boolean;
  @attr('boolean') displayShowPhotos!: boolean;
  @attr('boolean') collectCartComment!: boolean;
  @attr('boolean') displayShowDetails!: boolean;
  @attr('boolean') iframeDisplayInlineMemberLinks!: boolean;
  @attr('plain-array') iframePostMessageDestinationUrl!: string[];
  @attr('boolean') socialMediaEnabled!: boolean;
  @attr('boolean') amexEnabled!: boolean;
  @attr('string') membership!: string;
  @attr('json') iframePaths!: object;
  @attr('boolean') voucherEnabled!: boolean;
  @attr('boolean') allowOrderCancellations!: boolean;
  @attr('boolean') hideExpiredOnlineSalesGuardEvents!: boolean;
  @attr('string') serverEnvironment!: string;
  @attr('string') infrastructure!: string;
  @attr('boolean') useSso!: boolean;
  @attr('string') ssoEndpoint!: string;
  @attr('boolean') allowSingleSeatAtRowEnd!: boolean;
  @attr('boolean') onsaleMode!: boolean;

  get locales() {
    return this.locale.split(',').map(locale => locale.trim()) as Locale[];
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
