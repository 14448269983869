import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type IframeService from 'ticketbooth/services/iframe';

export default class IsIframeActive extends Helper {
  @service private iframe!: IframeService;

  compute() {
    return this.iframe.isActive;
  }
}
