import Component from '@glimmer/component';

import type { Store } from '@ember-data/store';
import { inject as service } from '@ember/service';

import { useMachine } from 'ember-statecharts';

import Loading from 'tangram/components/loading';
import { createMachine } from 'tangram/lib/machines/simple-load-records';
import ModalContent from 'ticketbooth/components/modal/content';
import translate from 'ticketbooth/helpers/translate';
import type EventModel from 'ticketbooth/models/event';
import type OrderModel from 'ticketbooth/models/order';
import type WalletModel from 'ticketbooth/models/wallet';

import AddToWalletLink from '../add-to-wallet-link';

interface EventHubWalletsModalArgs {
  Args: {
    order: OrderModel;
    event: EventModel;
    close: Function;
  };
}

export default class EventHubWalletsModal extends Component<EventHubWalletsModalArgs> {
  @service store!: Store;

  get wallets() {
    return this.statechart.state!.context.records;
  }

  get multipasses() {
    return this.wallets[0]?.multipasses.slice() ?? [];
  }

  get hasOneMultipass() {
    return this.multipasses.length === 1;
  }

  get isLoading() {
    return this.statechart.state!.matches('loading');
  }

  get isSucceeded() {
    return this.statechart.state!.matches('success');
  }

  statechart = useMachine(this, () => ({
    machine: createMachine<WalletModel>().withConfig({
      guards: {
        searchOnStartup: () => true
      },
      services: {
        search: async () => {
          return (
            await this.store.query('wallet', {
              ref: `${this.args.event.id}:${this.args.order.uuid}`,
              include: 'passes,multipasses'
            })
          ).slice();
        }
      }
    })
  }));

  <template>
    <ModalContent as |modal|>
      <modal.ui.header @close={{@close}}>
        <h2 data-test-wallet-title>{{translate "eventhub.wallets.qr_downloads"}}</h2>
      </modal.ui.header>
      <modal.ui.body class="md:min-w-120">
        {{#if this.isLoading}}
          <Loading />
        {{else if this.isSucceeded}}
          {{#each this.wallets as |wallet|}}
            {{#if wallet.multipasses}}
              <div class="text-lg leading-none mb-8">
                {{translate "eventhub.wallets.batch_download"}}
              </div>

              {{#each wallet.multipasses as |multipass|}}
                <div class="flex justify-between bg-gray-200 p-4 my-4 rounded items-center" data-test-wallet-multipass>
                  <div class="font-semibold">
                    {{#if this.hasOneMultipass}}
                      {{translate "eventhub.wallets.add_all_to_wallet"}}
                    {{else}}
                      {{translate "eventhub.wallets.add_from_to" from=multipass.fromIndex to=multipass.toIndex}}
                    {{/if}}
                  </div>
                  <AddToWalletLink @pass={{multipass}} />
                </div>
              {{/each}}

              <hr class="my-8 -mx-4" />
            {{/if}}

            {{#if wallet.passes}}
              <div class="text-lg leading-none mb-8">
                {{translate "eventhub.wallets.pass_download"}}
              </div>

              {{#each wallet.passes as |pass|}}
                <div class="flex justify-between bg-gray-200 p-4 my-2 rounded items-center" data-test-wallet-pass>
                  <div class="font-semibold">{{pass.walletName}}</div>
                  <AddToWalletLink @pass={{pass}} />
                </div>
              {{/each}}
            {{/if}}
          {{/each}}
        {{else}}
          <div class="text-red-500">{{translate "errors.default"}}</div>
        {{/if}}
      </modal.ui.body>
    </ModalContent>
  </template>
}
