import link from 'ember-link/helpers/link';
import or from 'ember-truth-helpers/helpers/or';

import translate from '../helpers/translate';
import ButtonLink from './button/link';

interface ReturnToShowsLinkSignature {
  Args: {
    ariaText?: string;
  };
  Blocks: {
    default: [];
  };
}

const ReturnToShowsLinkComponent: TemplateOnlyComponent<ReturnToShowsLinkSignature> =
<template>
  {{#let (link route="listings.shows") as |l|}}
    <ButtonLink @onClick={{l.transitionTo}}  data-test-show-browse-more-tickets
      aria-label={{translate (or @ariaText "a11y.shows.return_to_shows")}}>
      {{yield}}
    </ButtonLink>
  {{/let}}
</template>

export default ReturnToShowsLinkComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ReturnToShowsLink: typeof ReturnToShowsLinkComponent;
    'return-to-shows-link': typeof ReturnToShowsLinkComponent;
  }
}
