import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type MembershipService from 'ticketbooth/services/membership';

export default class extends Route {
  @service private membership!: MembershipService;
  @service private router!: RouterService;

  redirectOnLogin: Function | null = null;

  async beforeModel() {
    if (this.membership.isLoggedIn) {
      this.router.transitionTo('listings.shows');
    } else if (this.membership.isDisabled) {
      this.router.transitionTo('listings.shows');
    }
  }

  // TODO: Refactor when ember upgrade >= 3.22
  // https://github.com/emberjs/ember.js/commit/12e6ab68332a89dfff905cedfcbc9dabbd203478
  enter(transition: Transition) {
    // @ts-ignore - not public api - but necessary until activate hook receives the transition
    super.enter(transition);
    this.activateWithTransition(transition);
  }

  activateWithTransition({
    to: {
      queryParams: { path, identifier }
    }
  }: Transition) {
    this.redirectOnLogin = path
      ? () =>
          identifier
            ? this.router.transitionTo(path, identifier)
            : this.router.transitionTo(path)
      : () => this.router.transitionTo('listings.shows');

    this.membership.onLogin(this.redirectOnLogin!);
  }

  deactivate() {
    this.membership.offLogin(this.redirectOnLogin!);
    this.redirectOnLogin = null;
  }
}
