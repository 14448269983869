import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type RouterScrollService from 'ticketbooth/services/-router-scroll';
import type EventCalendarService from 'ticketbooth/services/event-calendar';

export default class extends Route {
  @service private eventCalendar!: EventCalendarService;
  @service private routerScroll!: RouterScrollService;
  @service private router!: RouterService;

  /**
   * Route is shared by events calendar and events list
   */
  async model() {
    const event = this.modelFor('listings.show.event') as EventModel;

    if (event.show.displayEventsCalendar) {
      const events = await this.eventCalendar.loadEventsOfMonth(
        event.show,
        event.dateTime
      );
      return { event, events };
    }

    return { event };
  }

  activate() {
    this.routerScroll.preserveScrollPosition = false;
    this.routerScroll.onScrollReset(this.doNotScrollAfterRouteActivation);
  }

  deactivate() {
    this.routerScroll.offScrollReset(this.doNotScrollAfterRouteActivation);
    this.routerScroll.preserveScrollPosition = false;
  }

  /**
   * Avoid `ember-route-scroll` to interfere with event calendar changing route model
   */
  @action
  private doNotScrollAfterRouteActivation() {
    this.routerScroll.preserveScrollPosition = true;
  }

  @action
  redirect(model: { event: EventModel }, transition: Transition) {
    if (
      model.event.displaySeatSelection &&
      transition.to.queryParams.l === 'i'
    ) {
      this.router.transitionTo('listings.show.event.seats');
    }
  }
}
