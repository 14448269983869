/* import __COLOCATED_TEMPLATE__ from './order-actions.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';

interface OrderActionsSignature {
  Args: {};
}

export default class OrderActionsComponent extends Component<OrderActionsSignature> {
  @action
  printPage() {
    window.print();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrderActions: typeof OrderActionsComponent;
    'order-actions': typeof OrderActionsComponent;
  }
}
