import { attr, belongsTo } from '@ember-data/model';

import type { Extra } from 'ticketbooth/utils/extras';

import type EventModel from './event';
import type EventTicketPriceModel from './event-ticket-price';
import LineItemModel, { TicketGroup } from './line-item';
import type SeatAssignmentModel from './seat-assignment';
import type TicketAllocationModel from './ticket-allocation';

export default class TicketLineItemModel extends LineItemModel {
  @attr() extras!: Extra[] | null;
  @attr('string') masterAllocationName!: string | null;
  @attr('string') readonly checkinCode!: string;
  @attr('string') readonly qrCodeImageLink!: string | null;
  @attr('boolean') giftaidable!: boolean;

  @belongsTo('event', { async: false, inverse: null })
  event!: EventModel;

  @belongsTo('event-ticket-price', { async: false, inverse: null })
  eventTicketPrice!: EventTicketPriceModel;

  @belongsTo('ticket-allocation', { async: false, inverse: null })
  ticketAllocation!: TicketAllocationModel;

  @belongsTo('seat-assignment', { async: false, inverse: null })
  seatAssignment!: SeatAssignmentModel | null;

  get hasExtras() {
    return this.extras !== null && this.extras.length > 0;
  }

  get isGroupLineItem() {
    return this.eventTicketPrice.groupSize > 1;
  }

  get isSeated(): boolean {
    return !!this.seatAssignment;
  }

  get googleAnalyticsSku(): string {
    return this.event.googleAnalyticsSku;
  }

  get googleAnalyticsName(): string {
    return this.event.googleAnalyticsName;
  }

  get googleAnalyticsCategory(): string {
    return this.event.googleAnalyticsCategory;
  }

  createGroup(lineItem: TicketLineItemModel): TicketGroup {
    return new TicketGroup(
      lineItem,
      this.eventTicketPrice,
      this.ticketAllocation,
      this.event
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-line-item': TicketLineItemModel;
  }
}
