/* import __COLOCATED_TEMPLATE__ from './recommendations-list.hbs'; */
import Component from '@glimmer/component';

import type Model from '@ember-data/model';

import type RecommendationModel from 'ticketbooth/models/recommendation';

import BenefitProductRecommendationCard from './recommendations-list/cards/benefit-product';
import DonationproductRecommendationCard from './recommendations-list/cards/donation-product';
import ProductRecommendationCard from './recommendations-list/cards/product';
import ShowRecommendationCard from './recommendations-list/cards/show';

interface RecommendationsListSignature {
  Args: {
    limit: number;
    recommendations: RecommendationModel[];
    context?: string;
  };
}

const VARIANT_CARDS = [
  'show',
  'product',
  'donation-product',
  'benefit-product'
] as const;
type CardVariant = (typeof VARIANT_CARDS)[number];

const CARD_COMPONENT: { [k in CardVariant]: any } = {
  show: ShowRecommendationCard,
  product: ProductRecommendationCard,
  'benefit-product': BenefitProductRecommendationCard,
  'donation-product': DonationproductRecommendationCard
};

export default class RecommendationsListComponent extends Component<RecommendationsListSignature> {
  get sortedRecommendations(): RecommendationModel[] {
    return this.args.recommendations.slice(0, this.args.limit);
  }

  get recommendees() {
    return this.sortedRecommendations.map(r => r.recommendee);
  }

  get multipleRecommendationClass() {
    if (this.sortedRecommendations.length === 2) {
      return `md:grid-cols-2`;
    } else if (this.sortedRecommendations.length === 3) {
      return `md:grid-cols-3`;
    } else if (this.sortedRecommendations.length === 4) {
      return `md:grid-cols-4`;
    }
    return '';
  }

  get showSummary() {
    return this.sortedRecommendations.length <= this.args.limit;
  }

  getComponentForVariant(recommendation: RecommendationModel) {
    const modelType = (recommendation.recommendee.constructor as typeof Model)
      .modelName as CardVariant;
    const variant = VARIANT_CARDS.includes(modelType) ? modelType : 'product';
    return CARD_COMPONENT[variant];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    RecommendationsList: typeof RecommendationsListComponent;
    'recommendations-list': typeof RecommendationsListComponent;
  }
}
