import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type NotificationsService from 'tangram/services/notifications';
import type { SubOrderEntity } from 'ticketbooth/domains/event-hub/sub-order';
import type ContextModel from 'ticketbooth/models/context';
import type EventModel from 'ticketbooth/models/event';
import type OrderModel from 'ticketbooth/models/order';
import { inject as domain } from 'ticketbooth/utils/domains';

type URLArgs = {
  orderId?: string;
  eventId?: string;
};
export type EventHubRouteModel = {
  order: OrderModel;
  event: EventModel;
  contexts?: ContextModel[];
};

export default class EventHubRoute extends Route<EventHubRouteModel | null> {
  @domain('event-hub/sub-order') SubOrder!: SubOrderEntity;
  @service notifications!: NotificationsService;

  @service router!: RouterService;

  queryParams = {
    orderId: { refreshModel: true },
    eventId: { refreshModel: true }
  };

  async model({ orderId, eventId }: URLArgs) {
    if (!orderId || !eventId) return this.redirectToShows();

    try {
      const { order, event, contexts } = await this.SubOrder.load(
        orderId,
        eventId
      );

      if (!order || !event) return this.redirectToShows();
      if (!this.SubOrder.isValid()) return this.redirectToShows();

      return { order, event, contexts };
    } catch (e: any) {
      this.notifications.error(e);
      return this.redirectToShows();
    }
  }

  redirectToShows() {
    this.router.transitionTo('listings.shows');
    return null;
  }
}
