/* import __COLOCATED_TEMPLATE__ from './event-ticket-price.hbs'; */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type EventTicketPriceModel from 'ticketbooth/models/event-ticket-price';
import { isTicketGroup } from 'ticketbooth/models/line-item';
import type TicketAllocationModel from 'ticketbooth/models/ticket-allocation';
import type CartProviderService from 'ticketbooth/services/cart-provider';

interface ShowEventsListEventTicketPriceSignature {
  Args: {
    event: EventModel;
    eventTicketPrice: EventTicketPriceModel;
    ticketAllocations: TicketAllocationModel[];
  };
}

export default class ShowEventsListEventTicketPriceComponent extends Component<ShowEventsListEventTicketPriceSignature> {
  @service private cartProvider!: CartProviderService;

  get lineItemGroup() {
    const { event, eventTicketPrice, ticketAllocations } = this.args;
    return this.cartProvider.cart.groupedLineItems.find(
      group =>
        isTicketGroup(group) &&
        group.compare(event, eventTicketPrice, ticketAllocations)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::EventTicketPrice': typeof ShowEventsListEventTicketPriceComponent;
    'show/events-list/event-ticket-price': typeof ShowEventsListEventTicketPriceComponent;
  }
}
