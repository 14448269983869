import Transform from '@ember-data/serializer/transform';

function hasTimeRepresentation(serialized: string) {
  return serialized.length !== 'YYYY-MM-DD'.length;
}

export default class DayTransform extends Transform<Date | null | undefined> {
  /**
   * Deserialize date from YYYY-MM-DD format to a local date at midnight
   *
   * Note: `new Date('YYYY-MM-DD')` automatically picks midnight in utc so we need to convert that
   * to the local midnight
   *
   * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#Date_Time_String_Format
   */
  deserialize(serialized: string | null | undefined): Date | null | undefined {
    if (typeof serialized === 'string') {
      if (hasTimeRepresentation(serialized)) {
        serialized = serialized.slice(0, 'YYYY-MM-DD'.length);
      }
      const utc = new Date(serialized);
      return new Date(
        utc.getUTCFullYear(),
        utc.getUTCMonth(),
        utc.getUTCDate()
      );
    } else if (serialized === null || serialized === undefined) {
      return serialized;
    } else {
      return null;
    }
  }

  /**
   * Serialize date in YYYY-MM-DD format, so the server does not have to make
   * an assumption about the user's timezone.
   *
   * Example: User selects 2019-04-10 in Vienna
   * -> 'date' transform sends '2019-04-09T22:00:00Z'
   * -> 'day' transform sends '2019-04-10'
   */
  serialize(date: Date | null | undefined) {
    // @ts-ignore
    if (date instanceof Date && !isNaN(date)) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1);
      const day = String(date.getDate());
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } else {
      return null;
    }
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    day: Date | null | undefined;
  }
}
