import type Store from '@ember-data/store';
import type ApplicationInstance from '@ember/application/instance';

import ticketsolveLogo from '../images/ticketsolve_t_logo_plain_medium.png';

export function initialize(appInstance: ApplicationInstance): void {
  const store = appInstance.lookup('service:store') as Store;

  store.createRecord('asset-attachment', {
    id: 'fallback-photo',
    mediumUrl: ticketsolveLogo,
    dominantColors: '#ddd|#fafafa'
  });
}

export default { initialize };
