import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type EventModel from 'ticketbooth/models/event';

export default class extends Route {
  @service private router!: RouterService;
  @service store!: Store;

  queryParams = {
    zone: {}
  };

  beforeModel() {
    const event = this.modelFor('listings.show.event') as EventModel;

    if (!event.displaySeatSelection || event.soldout) {
      this.router.replaceWith('listings.show.event.index', event.id);
    }
  }

  async model() {
    const event = this.modelFor('listings.show.event') as EventModel;

    await this.store.findRecord('event', event.id, {
      include: [
        // Event description
        'venue',

        // Zone Select / Zone Map
        'venue-layout.asset-attachment',
        'master-allocations.ticket-zone',

        // Seat Editor
        'master-allocations.ticket-allocations.event-ticket-prices'
      ].join(','),
      reload: true
    });

    return event;
  }

  /**
   * Select zone with highest priority first
   */
  @action
  didTransition() {
    const qP = this.paramsFor('listings.show.event.seats') as { zone?: string };
    const event = this.modelFor('listings.show.event.seats') as EventModel;

    if (isBlank(qP.zone) && !isBlank(event.firstAvailableZone)) {
      // @ts-ignore - insufficient types for replaceWith
      this.router.replaceWith({
        queryParams: { zone: event.firstAvailableZone }
      });
    }
  }
}
