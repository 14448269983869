/* import __COLOCATED_TEMPLATE__ from './cart-summary.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

interface SidebarCardsCartSummarySignature {
  Args: {};
}

export default class SidebarCardsCartSummaryComponent extends Component<SidebarCardsCartSummarySignature> {
  @tracked displayDetails = false;

  @action
  toggleDetails() {
    this.displayDetails = !this.displayDetails;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sidebar::Cards::CartSummary': typeof SidebarCardsCartSummaryComponent;
    'sidebar/cards/cart-summary': typeof SidebarCardsCartSummaryComponent;
  }
}
