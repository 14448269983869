import { isNone } from '@ember/utils';
import DS from 'ember-data';

export default class JsonTransform extends DS.Transform {
  deserialize(serialized: object | null): object {
    return isNone(serialized) ? {} : serialized;
  }
  serialize(deserialized: object | null): object {
    return isNone(deserialized) ? {} : deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    json: object;
  }
}
