import Model, { attr, belongsTo } from '@ember-data/model';

import type SeatAssignmentModel from './seat-assignment';
import type TicketAllocationModel from './ticket-allocation';

/**
 * Join table between seat assignments and ticket allocations
 */
export default class AssignmentAllocationModel extends Model {
  @attr('boolean') exclusive!: boolean;
  @attr('boolean') effective!: boolean;

  @belongsTo('seat-assignment', { async: false, inverse: null })
  seatAssignment!: SeatAssignmentModel;

  /**
   * Ticket allocations are loaded via the master allocations, so we only need
   * to know the seat <-> ticket allocation mapping.
   *
   * There might be ticket allocations linked that are not public, so we're
   * not able to load them. But we still need to know that they exist and are
   * potentially `exclusive` (see attribute above).
   *
   * Since we don't want to access a sync relationship that is potentially not
   * loaded, we use the `belongsTo` method in the getter below, to access the
   * already loaded value.
   */
  @belongsTo('ticket-allocation', { async: false, inverse: null })
  private ticketAllocation!: TicketAllocationModel;

  get publicTicketAllocation(): TicketAllocationModel | null {
    if (!this.effective) {
      return null;
    }

    // @ts-ignore - insufficient ember-data types
    const ticketAllocationRel = this.belongsTo('ticketAllocation');

    return ticketAllocationRel.value() as TicketAllocationModel | null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'assignment-allocation': AssignmentAllocationModel;
  }
}
