import type { Store } from '@ember-data/store';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';

export default class extends Route {
  @service private router!: RouterService;
  @service store!: Store;

  queryParams = {
    tag: {
      refreshModel: true
    }
  };

  async model({ tag }: { tag: string | undefined }) {
    // detect if we have a query param passed if not => transition away
    const event = this.modelFor('listings.show.event') as EventModel;

    if (tag) {
      // fetch all products by specified tag
      return { event };
    } else {
      return this.router.replaceWith('listings.show.event.index', event.id);
    }
  }
}
