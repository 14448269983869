import type { Store } from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type MembershipService from 'ticketbooth/services/membership';
import { INCLUDE_ALL } from 'ticketbooth/utils/order-api';

export default class extends Route {
  @service membership!: MembershipService;
  @service store!: Store;
  @service private router!: RouterService;

  beforeModel(transition: Transition) {
    const {
      to: {
        params: { order_id }
      }
    } = transition;

    if (!this.membership.isLoggedIn) {
      this.router.transitionTo('login', {
        queryParams: { path: 'members.order', identifier: order_id }
      });
    }
  }

  model({ order_id }: { order_id: string }) {
    return this.store.findRecord('order', order_id, {
      include: INCLUDE_ALL,
      reload: true
    });
  }
}
