/* import __COLOCATED_TEMPLATE__ from './month-select.hbs'; */
import Component from '@glimmer/component';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';
import range from 'lodash-es/range';

import { action } from '@ember/object';

interface CalendarMonthSelectSignature {
  Args: {
    minDate: Date;
    maxDate: Date;
    selectedDate: Date;
    selectDate(date: Date): Promise<void>;
  };
}

export default class CalendarMonthSelectComponent extends Component<CalendarMonthSelectSignature> {
  get months(): { date: Date; serialized: string }[] {
    const { minDate, maxDate, selectedDate } = this.args;
    const interval = { start: startOfMonth(minDate), end: endOfMonth(maxDate) };
    const start = startOfYear(selectedDate);

    return range(12)
      .map(num => {
        const date = addMonths(start, num);
        return { date, serialized: format(date, 'M') };
      })
      .filter(({ date }) => isWithinInterval(date, interval));
  }

  get selectedSerialized(): string {
    return format(this.args.selectedDate, 'M');
  }

  @action
  nativeSelect(event: Event & { target: HTMLSelectElement }) {
    this.args.selectDate(
      this.months.find(month => month.serialized === event.target.value)!.date
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calendar::MonthSelect': typeof CalendarMonthSelectComponent;
    'calendar/month-select': typeof CalendarMonthSelectComponent;
  }
}
