/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ButtonTbCardSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const ButtonTbCardComponent = templateOnlyComponent<ButtonTbCardSignature>();

export default ButtonTbCardComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Button::Tb::Card': typeof ButtonTbCardComponent;
    'button/tb/card': typeof ButtonTbCardComponent;
  }
}
