/* import __COLOCATED_TEMPLATE__ from './regular-product.hbs'; */
import Component from '@glimmer/component';

import type EventModel from 'ticketbooth/models/event';
import type ProductModel from 'ticketbooth/models/product';

interface ProductsListRegularProductSignature {
  Args: {
    product: ProductModel;
    quantity: number;
    onChangeQuantity: (quantity: number) => Promise<void>;
    event?: EventModel;
    list?: string;
    idx?: number;
  };
}

export default class ProductsListRegularProductComponent extends Component<ProductsListRegularProductSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProductsList::RegularProduct': typeof ProductsListRegularProductComponent;
    'products-list/regular-product': typeof ProductsListRegularProductComponent;
  }
}
