import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import { INCLUDE_ALL } from 'ticketbooth/utils/order-api';

export default class extends Route {
  @service private cartProvider!: CartProviderService;
  @service store!: Store;

  queryParams = {
    uid: { refreshModel: true }
  };

  model(params: { uid: string }) {
    return this.store.findRecord('order', params.uid, {
      include: INCLUDE_ALL,
      reload: true
    });
  }

  @action
  willTransition(transition: Transition) {
    if (!transition.data.hasCreatedCart) {
      this.createNewCart(transition);
    }
  }

  /**
   * Get new cart before navigating away from page
   */
  private async createNewCart(transition: Transition) {
    transition.abort();
    await this.cartProvider.createNewCart();
    transition.data.hasCreatedCart = true;
    transition.retry();
  }
}
