/* import __COLOCATED_TEMPLATE__ from './close-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ButtonCloseModalSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

const ButtonCloseModalComponent =
  templateOnlyComponent<ButtonCloseModalSignature>();

export default ButtonCloseModalComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Button::CloseModal': typeof ButtonCloseModalComponent;
    'button/close-modal': typeof ButtonCloseModalComponent;
  }
}
