/* import __COLOCATED_TEMPLATE__ from './terms-conditions-checkbox-wrapper.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

interface CheckoutFormTermsConditionsCheckboxWrapperSignature {
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class CheckoutFormTermsConditionsCheckboxWrapperComponent extends Component<CheckoutFormTermsConditionsCheckboxWrapperSignature> {
  @tracked displaySnippet = false;

  @action
  toggleSnippet() {
    this.displaySnippet = !this.displaySnippet;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutForm::TermsConditionsCheckboxWrapper': typeof CheckoutFormTermsConditionsCheckboxWrapperComponent;
    'checkout-form/terms-conditions-checkbox-wrapper': typeof CheckoutFormTermsConditionsCheckboxWrapperComponent;
  }
}
