import { tracked } from '@glimmer/tracking';

import type { QueryParamConfig } from '@ember/controller';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type LocaleService from 'ticketbooth/services/locale';

const DEFAULT_SOLDOUT_MESSAGE = `Soldout

We're sorry but there are no tickets available for today.

Try selecting another date or click below to go to the next available date.`;

export default class extends Controller {
  @service private router!: RouterService;
  @service private locale!: LocaleService;

  queryParams: Array<Record<string, QueryParamConfig>> = [
    {
      layout: {
        type: 'string',
        as: 'l'
      }
    }
  ];

  @tracked layout: string | null = null;

  model!: { event: EventModel; events?: EventModel[] };

  get isIndividualLayout() {
    return this.layout && this.layout.toLocaleLowerCase() === 'i';
  }

  get someEventHasLowAvailability() {
    return this.model.events?.some(event => event.lowAvailability);
  }

  get eventsDate() {
    return `Events for ${this.model.event.day.toLocaleDateString()}`;
  }

  get todaySoldoutSnippet(): string {
    return (
      this.locale.translate('public_website_no_tickets_today_message_html') ||
      DEFAULT_SOLDOUT_MESSAGE
    );
  }

  @action
  transitionToEvent(event: EventModel) {
    return this.router
      .transitionTo('listings.show.event.index', event)
      .method('replace');
  }

  @action
  scrollToPriceSelection() {
    const container = document.querySelector(
      '#content-scroll-container'
    ) as HTMLElement | null;
    const target = document.querySelector(
      '#price-selection-scroll-target'
    ) as HTMLElement | null;
    if (container && target) {
      const offset =
        target.getBoundingClientRect().top +
        container.scrollTop -
        document.body.clientHeight / 2;
      container.scrollTo({ top: offset, behavior: 'smooth' });
    }
  }

  @action
  scrollToEvent(event: EventModel) {
    const container = document.querySelector(
      '#content-scroll-container'
    ) as HTMLElement | null;
    const target = document.querySelector(
      `[data-event-item-event="${event.id}"]`
    ) as HTMLElement | null;
    if (container && target) {
      const offset =
        target.getBoundingClientRect().top +
        container.scrollTop -
        document.body.clientHeight / 2;
      container.scrollTo({ top: offset, behavior: 'smooth' });
    }
  }
}
