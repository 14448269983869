/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import type { ComponentLike } from '@glint/template';

import templateOnlyComponent from '@ember/component/template-only';

import type ModalHeaderSubtitle from 'tangram/components/modal/header/subtitle';

interface ModalHeaderSignature {
  Args: {
    close?: Function;
    textSize?: string;
    closeable?: boolean;
    closeComponent?: ComponentLike<{
      Args: { onClick: Function };
      Element: any;
    }>;
  };
  Blocks: {
    default: [{ ui: { subtitle: typeof ModalHeaderSubtitle } }];
  };
  Element: HTMLElement;
}

const ModalHeaderComponent = templateOnlyComponent<ModalHeaderSignature>();

export default ModalHeaderComponent;
