import type { Resource } from 'ticketoffice-api';

import { isRunningSmokeTest } from 'tangram/utils/query-params-config';
import type CartModel from 'ticketbooth/models/cart';

import ApplicationSerializer from './application';

export default class CartSerializer extends ApplicationSerializer {
  normalize(
    modelClass: CartModel,
    resourceHash: Resource & { attributes: { 'hpp-url': string } }
  ) {
    if (isRunningSmokeTest()) {
      // Note: Workaround proxy issues.
      // See testem.js for more details.
      resourceHash.attributes['hpp-url'] =
        `/ticketbooth${resourceHash.attributes['hpp-url']}`;
    }

    return super.normalize(modelClass, resourceHash);
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    cart: CartSerializer;
  }
}
