import Model, { attr } from '@ember-data/model';

export default class TicketZoneModel extends Model {
  @attr('boolean') seated!: boolean;

  @attr() hotspot!: string[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-zone': TicketZoneModel;
  }
}
