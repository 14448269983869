/* import __COLOCATED_TEMPLATE__ from './app.hbs'; */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

import type AppService from 'ticketbooth/services/app';

export default class AppProvider extends Component {
  @service app!: AppService;
}
