import { getOwner, setOwner } from '@ember/application';
import type ApplicationInstance from '@ember/application/instance';

export class Entity {
  constructor(context: ApplicationInstance) {
    setOwner(this, getOwner(context));
  }
}

export function createDomainExport<T extends Entity>(
  Entity: new (c: ApplicationInstance) => T
) {
  return {
    create(context: ApplicationInstance) {
      return new Entity(context);
    }
  };
}
