import type { Resource } from 'ticketoffice-api';

import type { Locale } from 'tangram/helpers/humanize-locale';
import type { SnippetType } from 'ticketbooth/lib/locales';
import type PermissionModel from 'ticketbooth/models/permission';

import ApplicationSerializer from './application';

export default class PermissionSerializer extends ApplicationSerializer {
  normalize(
    modelClass: PermissionModel,
    resourceHash: Resource & {
      attributes: { descriptions: { locale: Locale; description: string }[] };
    }
  ) {
    // TODO: Migrate TO + NTB API to use `SnippetType` `{ locale, value }[]`
    this.mapDescriptionsToSnippets(resourceHash);

    return super.normalize(modelClass, resourceHash);
  }

  mapDescriptionsToSnippets(
    hash: Resource & {
      attributes: { descriptions: { locale: Locale; description: string }[] };
    }
  ) {
    const snippets: SnippetType[] = hash.attributes.descriptions.map(
      ({ locale, description }) => ({ locale, value: description })
    );
    // @ts-ignore
    hash.attributes.descriptions = snippets;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    permission: PermissionSerializer;
  }
}
