import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';
import { action } from '@ember/object';

import type EventModel from 'ticketbooth/models/event';
import type OrderModel from 'ticketbooth/models/order';

export default class EventHubController extends Controller {
  queryParams = ['orderId', 'eventId'];

  @tracked orderId: string | null = null;
  @tracked eventId: string | null = null;
  @tracked displayWalletsModal: boolean = false;

  get order(): OrderModel {
    return this.model.order;
  }

  get event(): EventModel {
    return this.model.event;
  }

  @action
  showWalletsModal() {
    this.displayWalletsModal = true;
  }

  @action
  closeWalletsModal() {
    this.displayWalletsModal = false;
  }
}
