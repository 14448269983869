import type { BrandingOptions } from 'tangram/modifiers/base-branding';
import { MODIFIER_OPTIONS } from 'tangram/utils/look-and-feel';

import BrandingModifier from './branding';

interface Args {
  positional: [];

  named: {
    isActive?: boolean;
  };
}

export default class extends BrandingModifier<Args> {
  protected getOptions(): BrandingOptions {
    return MODIFIER_OPTIONS.CARD_BUTTON_BORDER_COLOR(this.args.named.isActive);
  }
}
