/* import __COLOCATED_TEMPLATE__ from './confirm-selection.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SeatEditorConfirmSelectionSignature {
  Args: {};
  Element: HTMLDivElement;
}

const SeatEditorConfirmSelectionComponent =
  templateOnlyComponent<SeatEditorConfirmSelectionSignature>();

export default SeatEditorConfirmSelectionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::ConfirmSelection': typeof SeatEditorConfirmSelectionComponent;
    'seat-editor/confirm-selection': typeof SeatEditorConfirmSelectionComponent;
  }
}
