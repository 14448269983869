import { array, hash, fn } from '@ember/helper';

import eq from 'ember-truth-helpers/helpers/eq';

import type { GiftAidPreference } from 'ticketbooth/models/customer';

import translate from '../helpers/translate';
import GiftAidPromptOption from './gift-aid-prompt-option';

interface Signature {
  Args: {
    selected: GiftAidPreference | null;
    onChange: (value: GiftAidPreference) => void;
  };
}

const GiftAidPromptOptions: TemplateOnlyComponent<Signature> = <template>
  <div class='flex flex-col lg:flex-row gap-5 lg:gap-1 pb-6'>
    <GiftAidPromptOption
      @title={{translate 'gift_aid_prompt.all_donations'}}
      @text={{translate 'gift_aid_prompt.treat_as_gift_aid_donations_made'}}
      @options={{array
        (hash
          title=(translate 'gift_aid_prompt.option_all.title')
          text=(translate 'gift_aid_prompt.option_all.text')
        )
        (hash
          title=(translate 'gift_aid_prompt.option_onwards.title')
          text=(translate 'gift_aid_prompt.option_onwards.text')
        )
        (hash
          title=(translate 'gift_aid_prompt.option_cart.title')
          text=(translate 'gift_aid_prompt.option_cart.text')
        )
      }}
      @suggested={{true}}
      @selected={{eq @selected 'all'}}
      @onClick={{fn @onChange 'all'}}
    />
    <GiftAidPromptOption
      @title={{translate 'gift_aid_prompt.today_onwards'}}
      @text={{translate 'gift_aid_prompt.treat_as_gift_aid_donations_made'}}
      @options={{array
        (hash
          title=(translate 'gift_aid_prompt.option_onwards.title')
          text=(translate 'gift_aid_prompt.option_onwards.text')
        )
        (hash
          title=(translate 'gift_aid_prompt.option_cart.title')
          text=(translate 'gift_aid_prompt.option_cart.text')
        )
      }}
      @suggested={{false}}
      @selected={{eq @selected 'onwards'}}
      @onClick={{fn @onChange 'onwards'}}
    />
    <GiftAidPromptOption
      @title={{translate 'gift_aid_prompt.today_only'}}
      @text={{translate
        'gift_aid_prompt.only_treat_as_gift_aid_the_donations_in_your_current_cart'
      }}
      @options={{array}}
      @suggested={{false}}
      @selected={{eq @selected 'cart'}}
      @onClick={{fn @onChange 'cart'}}
    />
  </div>
</template>;

export default GiftAidPromptOptions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GiftAidPromptOptions: typeof GiftAidPromptOptions;
  }
}
