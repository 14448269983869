/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type ShowModel from 'ticketbooth/models/show';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';
import type PreloadService from 'ticketbooth/services/preload';

interface ShowsCardBaseSignature {
  Args: {
    show: ShowModel;
    context: string;
  };
  Element: HTMLElement;
}

export default class ShowsCardBaseComponent extends Component<ShowsCardBaseSignature> {
  @service preload!: PreloadService;
  @service googleAnalytics!: GoogleAnalyticsService;

  get displayInfo() {
    return (
      this.displayCompany ||
      this.displayCategory ||
      this.displayVenues ||
      this.displayDates
    );
  }

  get displayCompany() {
    const { show } = this.args;
    return (
      this.preload.getValue('look_and_feel.show_company') &&
      show.showProductionCompany
    );
  }
  get displayCategory() {
    const { show } = this.args;
    return (
      this.preload.getValue('look_and_feel.show_category') &&
      show.showEventCategory
    );
  }
  get displayVenues() {
    const { show } = this.args;
    return (
      this.preload.getValue('look_and_feel.show_venues') &&
      show.venues.slice().length > 0
    );
  }
  get displayDates() {
    return this.preload.getValue('look_and_feel.show_dates');
  }

  @action
  async trackShowClick() {
    this.googleAnalytics.selectItem(
      this.args.show.toAnalytics({
        item_list_id: 'shows',
        items_list_id: 'shows'
      })
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shows::Card::Base': typeof ShowsCardBaseComponent;
    'shows/card/base': typeof ShowsCardBaseComponent;
  }
}
