import ButtonText from 'tangram/components/button/text';
import branding from 'ticketbooth/modifiers/branding';

interface HeaderMenuItemInterface {
  Args: {
    onClick: Function;
    onSuccess?: Function;
    isActive?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const HeaderMenuItemComponent: TemplateOnlyComponent<HeaderMenuItemInterface> =
  <template>
    {{! @glint-nocheck: not typesafe yet }}
    <div class="w-full border-b" {{branding "borderColor" "navbar_fg_color" alpha=25}}>
      <ButtonText @classes="focus:outline-none py-3 px-4 w-full {{if @isActive "font-semibold"}}"
        @onClick={{@onClick}}
        @onSuccess={{@onSuccess}}
        ...attributes
      >
        {{yield}}
      </ButtonText>
    </div>
  </template>;

export default HeaderMenuItemComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HeaderMenuItem: typeof HeaderMenuItemComponent;
    'header/menu/item': typeof HeaderMenuItemComponent;
  }
}
