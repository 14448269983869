/* import __COLOCATED_TEMPLATE__ from './promotion-code-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ValidationError, object, string } from 'yup';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import FormObject from 'tangram/utils/form-object';
import type CartModel from 'ticketbooth/models/cart';
import type LocaleService from 'ticketbooth/services/locale';

class PromoCodeForm extends FormObject<{ promotionCode: string | null }> {
  @tracked promotionCode!: string | null;
}

interface PromotionCodeFormSignature {
  Args: {
    cart: CartModel;
    onChange: (promotionCode: string | null) => Promise<void>;
  };
}

export default class PromotionCodeFormComponent extends Component<PromotionCodeFormSignature> {
  @service locale!: LocaleService;

  @tracked formObject!: PromoCodeForm;
  @tracked schema = object().shape({
    promotionCode: string()
      .nullable()
      .required(this.locale.translate('errors.promotion_code.required'))
  });

  constructor(owner: unknown, args: PromotionCodeFormSignature['Args']) {
    super(owner, args);
    this.createFormObject();
  }

  private createFormObject() {
    this.formObject = new PromoCodeForm({
      promotionCode: this.args.cart.promotionCode
    });
  }

  @action
  async removePromotionCode() {
    await this.args.onChange(null);
  }

  @action
  async updatePromotionCode({ promotionCode }: PromoCodeForm) {
    if (isBlank(promotionCode)) {
      throw [
        new ValidationError(
          this.locale.translate('errors.promotion_code.required'),
          promotionCode,
          'promotionCode',
          'required'
        )
      ];
    }
    return this.args.onChange(promotionCode);
  }

  @action
  onPromotionCodeUpdated() {
    this.createFormObject();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PromotionCodeForm: typeof PromotionCodeFormComponent;
    'promotion-code-form': typeof PromotionCodeFormComponent;
  }
}
