/* import __COLOCATED_TEMPLATE__ from './product.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RecommendationsListCardsProductSignature {
  Args: {};
}

const RecommendationsListCardsProductComponent =
  templateOnlyComponent<RecommendationsListCardsProductSignature>();

export default RecommendationsListCardsProductComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RecommendationsList::Cards::Product': typeof RecommendationsListCardsProductComponent;
    'recommendations-list/cards/product': typeof RecommendationsListCardsProductComponent;
  }
}
