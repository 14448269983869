import type { BrandingOptions } from 'tangram/modifiers/base-branding';
import { MODIFIER_OPTIONS } from 'tangram/utils/look-and-feel';

import BrandingModifier from './branding';

interface Signature {
  positional: [];
  named: {};
}

export default class extends BrandingModifier<Signature> {
  protected getOptions(): BrandingOptions {
    return MODIFIER_OPTIONS.CARD_SECONDARY_TEXT_COLOR();
  }
}
