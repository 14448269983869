import without from 'lodash-es/without';

import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type { PostMessage } from 'ticketbooth/utils/iframe-handling-post-messages';
import { isSetupMessage } from 'ticketbooth/utils/iframe-handling-post-messages';
import type {
  CookieStatusResponse,
  ParentInformationResponse,
  ResizeResponse,
  ScrollResponse,
  ReceiveMessage
} from 'ticketbooth/utils/iframe-handling-receive-messages';

import type TicketboothErrorsService from './errors';
import type IframeService from './iframe';
import type MessagingService from './messaging';

type Callback = {
  parentInformation: (message: ParentInformationResponse) => void;
  parentReady: () => void;
  didResize: (message: ResizeResponse) => void;
  didScroll: (message: ScrollResponse) => void;
  cookieStatus: (message: CookieStatusResponse) => void;
  logout: () => void;
};
type CallbackType = keyof Callback;

/**
 * Ticketbooth <-> Iframe parent communication via messaging
 */
export default class IframeMessengerService extends Service {
  @service private errors!: TicketboothErrorsService;
  @service private messaging!: MessagingService;
  @service private iframe!: IframeService;

  private targetOrigin?: string;
  private callbacks: { [T in CallbackType]: Callback[T][] } = {
    parentInformation: [this.onParentInformationReceived],
    parentReady: [],
    didResize: [],
    didScroll: [],
    cookieStatus: [],
    logout: []
  };
  private get isParentReady() {
    return !isBlank(this.targetOrigin);
  }

  on<T extends CallbackType>(type: T, callback: Callback[T]) {
    // @ts-ignore
    this.callbacks[type] = [...this.callbacks[type], callback];
  }
  off<T extends CallbackType>(type: T, callback: Callback[T]) {
    // @ts-ignore
    this.callbacks[type] = without(this.callbacks[type], callback);
  }

  addMessageHandler() {
    this.messaging.onMessage(this.receiveMessage);
  }

  removeMessageHandler() {
    this.messaging.offMessage(this.receiveMessage);
  }

  @action
  pingIframeHandling() {
    this.postMessage({ event: 'ticketboothInit' });
  }

  /**
   * Ticketbooth -> Iframe Handling
   */
  postMessage(message: PostMessage) {
    // IframeHandling v3.0 uses `eval(message)` to extract a `MessageEvent` data,
    // so we wrap it inside `Object.create`.
    const jsonAsObject = `Object.create(${JSON.stringify(message)})`;

    if (isSetupMessage(message)) {
      // The setup event is sending to '*' origin as the target origin is
      // unknown at this point.
      window.parent.postMessage(jsonAsObject, '*');
    } else if (this.isParentReady) {
      window.parent.postMessage(jsonAsObject, this.targetOrigin!);
    } else {
      this.on('parentReady', () =>
        window.parent.postMessage(jsonAsObject, this.targetOrigin!)
      );
    }
  }

  /**
   * Iframe Handling -> Ticketbooth
   */
  @action
  private receiveMessage(message: ReceiveMessage) {
    if ('parentInformation' in message) {
      this.callbacks.parentInformation.forEach(cb => cb(message));
    } else if ('cookieStatus' in message) {
      this.callbacks.cookieStatus.forEach(cb => cb(message));
    } else if ('event' in message && message.event === 'logout') {
      this.callbacks.logout.forEach(cb => cb());
    } else if ('didResize' in message) {
      this.callbacks.didResize.forEach(cb => cb(message));
    } else if ('didScroll' in message) {
      this.callbacks.didScroll.forEach(cb => cb(message));
    }
  }

  @action
  private onParentInformationReceived(message: ParentInformationResponse) {
    const {
      parentInformation: { location, version }
    } = message;

    // Add trailling slash
    const origin = new URL(location).origin.replace(/([^/])$/, '$1/');

    this.errors.onIframeSetup(origin, location, version);

    this.setTargetOrigin(origin);
    this.checkIframeHandlingVersion(version);

    this.callbacks.parentReady.forEach(cb => cb());
  }

  private setTargetOrigin(targetOrigin: string) {
    const { allowedTargetOrigins, homeUrl } = this.iframe;

    if (
      allowedTargetOrigins.some(
        allowed => allowed === targetOrigin || `${allowed}/` === targetOrigin
      )
    ) {
      this.targetOrigin = targetOrigin;
    } else {
      this.targetOrigin = homeUrl;
      this.errors.log(
        new Error(
          `Origin '${targetOrigin}' not in allowed origins list [${allowedTargetOrigins}]. Falling back to home_url (${homeUrl})`
        )
      );
    }
  }

  private checkIframeHandlingVersion(version: string) {
    if (version !== '3.0') {
      this.errors.log(
        new Error(
          `IframeHandling version is not the latest (latest: 3.0, current: ${version})`
        )
      );
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'iframe-messenger': IframeMessengerService;
  }
}
