import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type { TicketGroup } from 'ticketbooth/models/line-item';
import type { AllocatedTicketSelection } from 'ticketbooth/models/seat-assignment';
import type TicketLineItemModel from 'ticketbooth/models/ticket-line-item';

import type CartProviderService from './cart-provider';

export default class SeatEditorService extends Service {
  @service private router!: RouterService;
  @service private cartProvider!: CartProviderService;

  private repickTickets: AllocatedTicketSelection[] = [];

  get hasRepickSeats() {
    return this.repickSeats.length > 0;
  }

  @action
  async repickSeats(ticketGroup: TicketGroup) {
    const { event, masterAllocationName, lineItems } = ticketGroup;

    this.repickTickets = lineItems.map(
      (ticketLineItem: TicketLineItemModel) => ({
        seatAssignment: ticketLineItem.seatAssignment!,
        eventTicketPrice: ticketLineItem.eventTicketPrice
      })
    );

    await this.cartProvider.cart.removeLineItems(lineItems);

    await this.goToSeatEditor(event, masterAllocationName!);
  }

  @action
  popRepickSeats(): AllocatedTicketSelection[] {
    const { repickTickets } = this;

    this.repickTickets = [];

    return repickTickets;
  }

  private async goToSeatEditor(event: EventModel, masterAllocation: string) {
    await this.router.transitionTo(
      'listings.show.event.seats',
      event.show.id,
      event.id,
      {
        queryParams: {
          zone: masterAllocation
        }
      }
    );
  }
}
