import type Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service store!: Store;

  async model() {
    const { order, event } = this.modelFor('event-hub');
    const contexts = await this.store.query('context', {
      ref: `[${event.id}:${order.id}]`,
      include: 'context-categories.products.default-attachment'
    });
    return { order, event, contexts: contexts.slice() };
  }
}
