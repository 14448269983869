/* import __COLOCATED_TEMPLATE__ from './donation-product-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AnnoucerService from 'a11y-announcer';
import { number, object } from 'yup';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type Form from 'tangram/components/form';
import type CurrencyService from 'tangram/services/currency';
import FormObject from 'tangram/utils/form-object';
import type DonationProductModel from 'ticketbooth/models/donation-product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import type LocaleService from 'ticketbooth/services/locale';

class DonationFormObject extends FormObject<{
  price: number | null;
  priceOverride: number | null;
  agreed: boolean | null;
}> {
  @tracked price!: number | null;
  @tracked priceOverride!: number | null;
  @tracked agreed!: boolean | null;
}

interface DonationProductFormSignature {
  Args: {
    product: DonationProductModel;
    lineItems: [ProductLineItemModel] | [];
    onChange(price: number, agreed: boolean | null): Promise<void>;
  };
}

export default class DonationProductFormComponent extends Component<DonationProductFormSignature> {
  @service announcer!: AnnoucerService;
  @service currency!: CurrencyService;
  @service locale!: LocaleService;

  @tracked formObject!: DonationFormObject;

  schema = object().shape({
    priceOverride: number()
      .nullable()
      .moreThan(0)
      .label(this.locale.translate('checkout.donation_price_override'))
  });

  constructor(owner: unknown, args: DonationProductFormSignature['Args']) {
    super(owner, args);

    this.createFormObject();
  }

  get lineItem() {
    return this.args.lineItems[0];
  }

  get initPrice() {
    return this.lineItem?.price ?? this.args.product.donationAmounts[0];
  }

  @action
  createFormObject() {
    const { lineItem, initPrice } = this;
    const { donationAmounts } = this.args.product;
    const agreed = lineItem?.agreed ?? null;

    if (donationAmounts.includes(initPrice)) {
      this.formObject = new DonationFormObject({
        price: this.initPrice,
        priceOverride: null,
        agreed
      });
    } else {
      this.formObject = new DonationFormObject({
        price: null,
        priceOverride: this.initPrice,
        agreed
      });
    }
  }

  @action
  setPrice(change: Form['change'], price: number) {
    change('price', price);
    change('priceOverride', null);
    this.announcer.announce(
      `Price changed to ${this.currency.symbol}${price}`,
      'assertive'
    );
  }

  @action
  setPriceOverride(change: Form['change'], priceOverride: number) {
    if (
      isBlank(priceOverride) &&
      this.args.product.donationAmounts.includes(this.initPrice)
    ) {
      this.setPrice(change, this.initPrice);
    } else {
      change('price', null);
      change('priceOverride', priceOverride);
    }
  }

  @action
  async onSubmit({ price, priceOverride, agreed }: DonationFormObject) {
    await this.args.onChange(Math.abs(price ?? priceOverride!), agreed);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DonationProductForm: typeof DonationProductFormComponent;
    'donation-product-form': typeof DonationProductFormComponent;
  }
}
