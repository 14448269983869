import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { object, boolean } from 'yup';

import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import Form from 'tangram/components/form';
import type { FormContext } from 'tangram/components/form/-control-base';
import FormObject from 'tangram/utils/form-object';
import type DonationProductModel from 'ticketbooth/models/donation-product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import type CartProviderService from 'ticketbooth/services/cart-provider';

export class DonationsPromptForm extends FormObject<{
  donations: DonationForm[];
}> {
  @tracked donations!: DonationForm[];

  get hasMadeDecision() {
    return this.donations.every(donation => donation.hasMadeDecision);
  }
}

class DonationForm extends FormObject<{
  product: DonationProductModel;
  lineItem: ProductLineItemModel | undefined;
  optOut: boolean;
  agreed: boolean;
  price: number | null;
  priceOverride: number | null;
}> {
  @tracked product!: DonationProductModel;
  @tracked lineItem?: ProductLineItemModel;

  @tracked optOut!: boolean;
  @tracked price!: number | null;
  @tracked priceOverride!: number | null;

  /**
   * @deprecated - will be soon moved to a separate checkout step
   */
  @tracked agreed!: boolean;

  schema = object().shape({
    hasMadeDecision: boolean().test(
      'has-made-decision',
      'Select a donation amount or check box to continue',
      function isRequiredWhenNoPrice() {
        const form = this.parent as DonationForm;
        return form.hasMadeDecision;
      }
    )
  });

  private lastInput?: Pick<DonationForm, 'price' | 'priceOverride' | 'agreed'>;

  get optIn() {
    return !this.optOut;
  }
  get hasMadeDecision() {
    return this.optOut || this.willDonate;
  }
  get selectedPrice() {
    return this.price || this.priceOverride;
  }
  get isInCart() {
    return !!this.lineItem;
  }
  private get hasSelectedPrice() {
    return this.selectedPrice !== null && this.selectedPrice > 0;
  }
  private get willDonate() {
    return this.isInCart || this.hasSelectedPrice;
  }

  changePrice(change: Form['change'], price: number) {
    change('price', price);
    change('priceOverride', null);
    if (this.optOut) {
      change('optOut', false);
    }
  }
  changePriceOverride(change: Form['change'], price: string) {
    change('price', null);
    change('priceOverride', price);
    if (this.optOut) {
      change('optOut', false);
    }
  }
  changeOptOut(change: Form['change'], optOut: boolean) {
    change('optOut', optOut);

    if (optOut) {
      this.clearPriceAndRemember(change);
    } else {
      this.setRememberedPrice(change);
    }
  }
  private clearPriceAndRemember(change: Form['change']) {
    this.lastInput = {
      price: this.price,
      priceOverride: this.priceOverride,
      agreed: this.agreed
    };
    change('price', null);
    change('priceOverride', null);
    change('agreed', false);
  }
  private setRememberedPrice(change: Form['change']) {
    const { lastInput } = this;
    if (lastInput) {
      const { price, priceOverride, agreed } = lastInput;
      change('price', price);
      change('priceOverride', priceOverride);
      change('agreed', agreed);
    }
  }
}

interface Signature {
  Args: {
    onSubmit: (form: DonationsPromptForm) => Promise<void>;
    onSuccess?: () => void;
  };
  Blocks: {
    default: [
      {
        state: {
          f: FormContext;
          form: DonationsPromptForm;
          isSubmitBusy: boolean;
          isSubmitDisabled: boolean;
        };
        actions: {
          changePrice: DonationsPrompt['changePrice'];
          changePriceOverride: DonationsPrompt['changePriceOverride'];
          changeOptOut: DonationsPrompt['changeOptOut'];
          submit: () => void;
        };
      }
    ];
  };
}

export default class DonationsPrompt extends Component<Signature> {
  @service private cartProvider!: CartProviderService;

  @tracked formObject: DonationsPromptForm;

  get products(): DonationProductModel[] {
    const { cart } = this.cartProvider;
    return [
      ...cart.promptableDonationProducts.slice(),
      ...cart.donationProducts
    ].uniq();
  }
  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    const { donationsPrompt: preferences } = this.cartProvider.uiSessionData;
    this.formObject = new DonationsPromptForm({
      donations: this.products.map(product =>
        this.createDonationForm(product, preferences)
      )
    });
  }

  private createDonationForm(
    product: DonationProductModel,
    preferences: { productId: string; optOut: boolean }[] = []
  ) {
    const { cart } = this.cartProvider;
    const lineItem = cart.lineItemsForProduct(product)[0];
    const optOut = preferences.find(p => p.productId === product.id)?.optOut;

    const data = {
      product,
      lineItem,
      agreed: lineItem?.agreed ?? false,
      optOut: optOut ?? false,
      price: null as number | null,
      priceOverride: null as number | null
    };

    if (lineItem) {
      if (product.donationAmounts.includes(lineItem.price)) {
        data.price = lineItem.price;
      } else {
        data.priceOverride = lineItem.price;
      }
    }

    return new DonationForm(data);
  }

  @action
  onSubmit(form: DonationsPromptForm) {
    this.cartProvider.uiSessionData = {
      donationsPrompt: form.donations
        .filter(({ optOut }) => optOut)
        .map(donation => {
          const { product, optOut } = donation;
          return { productId: product.id, optOut };
        })
    };
    return this.args.onSubmit(form);
  }

  @action
  changePrice(change: Form['change'], donation: DonationForm, price: number) {
    donation.changePrice(change, price);
  }
  @action
  changePriceOverride(
    change: Form['change'],
    donation: DonationForm,
    price: string
  ) {
    donation.changePriceOverride(change, price);
  }
  @action
  changeOptOut(
    change: Form['change'],
    donation: DonationForm,
    optOut: boolean
  ) {
    donation.changeOptOut(change, optOut);
  }

  <template>
    <Form
      @formObject={{this.formObject}}
      @onSubmit={{this.onSubmit}}
      @onSuccess={{@onSuccess}}
      @isSubmittableUnchanged={{this.formObject.hasMadeDecision}}
      as |f|
    >
      {{yield
        (hash
          state=(hash
            f=f
            form=f.state.formObject
            isSubmitBusy=f.state.isBusy
            isSubmitDisabled=f.state.isInvalid
          )
          actions=(hash
            changePrice=this.changePrice
            changePriceOverride=this.changePriceOverride
            changeOptOut=this.changeOptOut
            submit=f.actions.markSubmittedAndSubmit
          )
        )
      }}
    </Form>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DonationsPromptForm: typeof DonationsPrompt;
  }
}
