import without from 'lodash-es/without';

import { NotFoundError } from '@ember-data/adapter/error';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { run } from '@ember/runloop';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

import type EnvironmentService from 'tangram/services/environment';
import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import type MemberModel from 'ticketbooth/models/member';
import type LocaleService from 'ticketbooth/services/locale';
import { INCLUDE_ALL, INCLUDE_ALL_FIELDS } from 'ticketbooth/utils/member-api';

import type TicketboothErrorsService from './errors';
import type GoogleAnalyticsService from './google-analytics';
import type PreloadService from './preload';

type SignupCallback = (member: MemberModel) => Promise<void>;

export default class MembershipService extends Service {
  @service private preload!: PreloadService;
  @service private store!: Store;
  @service private notifications!: NotificationsService;
  @service private environment!: EnvironmentService;
  @service private errors!: TicketboothErrorsService;
  @service private locale!: LocaleService;
  @service private googleAnalytics!: GoogleAnalyticsService;

  private loginCallbacks: Function[] = [];
  private logoutCallbacks: Function[] = [];
  private memberChangedCallbacks: Function[] = [this.errors.onMemberReload];
  private signupCallbacks: SignupCallback[] = [];

  onLogin(callback: Function) {
    this.loginCallbacks = [...this.loginCallbacks, callback];
  }
  offLogin(callback: Function) {
    this.loginCallbacks = without(this.loginCallbacks, callback);
  }
  onLogout(callback: Function) {
    this.logoutCallbacks = [...this.logoutCallbacks, callback];
  }
  onSignup(callback: SignupCallback) {
    this.signupCallbacks = [...this.signupCallbacks, callback];
  }
  offSignup(callback: SignupCallback) {
    this.signupCallbacks = without(this.signupCallbacks, callback);
  }
  onMemberChange(callback: Function) {
    this.memberChangedCallbacks = [...this.memberChangedCallbacks, callback];
  }
  offMemberChange(callback: Function) {
    this.memberChangedCallbacks = without(
      this.memberChangedCallbacks,
      callback
    );
  }

  /**
   * Note: Backend is using a cookie to remember the member id (`_ticketsolve_session`)
   *
   * Additionally if the `Keep me logged in` is checked upon login, a separate
   * cookie is used to keep the user logged for 14 days. So a cart session might
   * expire, but the user stays logged in (`auth_token`).
   */
  get member(): MemberModel | null {
    return this.store.peekAll('member').slice()[0];
  }

  private get membershipSetting(): 'yes' | 'no' | 'optional' {
    return this.preload.getValue('membership');
  }

  get isEnabled(): boolean {
    return ['yes', 'optional'].includes(this.membershipSetting);
  }
  get isDisabled(): boolean {
    return !this.isEnabled;
  }
  get isOptional(): boolean {
    return this.membershipSetting === 'optional';
  }

  get isLoggedIn(): boolean {
    return !!this.member;
  }

  get isLoggedOut(): boolean {
    return !this.isLoggedIn;
  }

  get requiresLogin(): boolean {
    return this.membershipSetting === 'yes' && !this.isLoggedIn;
  }

  get hasGiftAidPreference(): boolean {
    return this.isLoggedIn && this.member!.customer.hasGiftAidPreference;
  }

  async reload(): Promise<void> {
    const member = (await this.store.queryRecord('member', {
      include: INCLUDE_ALL,
      fields: INCLUDE_ALL_FIELDS
    })) as MemberModel | null;

    await this.onMemberChanged(member);
  }

  private clearStaleRecords(current: MemberModel | null) {
    if (current) {
      this.store
        .peekAll('member')
        .filter(member => member !== current)
        .forEach(member => member.unloadRecord());
    } else {
      // https://github.com/emberjs/data/issues/5447#issuecomment-845672812
      run(() => this.store.unloadAll('member'));
    }
  }

  async logout(): Promise<void> {
    await this.member?.logout({});
    await this.reload();

    await this.logoutSuccess();
  }

  async login(
    email: string,
    password: string,
    rememberMe: boolean = false
  ): Promise<void> {
    try {
      await this.store
        .adapterFor('member')
        .login(this.store, email, password, rememberMe);
    } catch (error) {
      this.loginFailed(error);
      throw error;
    }
    await this.loginSuccess();
  }

  private async loginSuccess() {
    this.notifications.success(
      this.locale.translate('member.successfully_logged_in')
    );
    await Promise.all(this.loginCallbacks.map(callback => callback()));
    this.googleAnalytics.login();
  }

  private async logoutSuccess() {
    if (!this.environment.isTest) {
      await Promise.all(this.logoutCallbacks.map(callback => callback()));
      window.location.reload();
    }
  }

  private loginFailed(error: any) {
    const errors = getAllUserMessagesOrDefault(error, {
      defaultMsg: 'Unsuccessful login - try again '
    });
    this.notifications.error(errors.join('. '));
  }

  async signup(
    email: string,
    password: string,
    emailConfirmation?: string,
    passwordConfirmation?: string,
    customer?: { firstName: string; lastName: string; phone: string } | null,
    token?: string | null
  ): Promise<void> {
    let member: MemberModel;
    try {
      member = await this.store
        .adapterFor('member')
        .signup(
          this.store,
          email,
          password,
          emailConfirmation,
          passwordConfirmation,
          customer,
          token
        );
    } catch (error) {
      this.signupFailed(error);
      throw error;
    }
    await this.signupSuccess(member);
  }

  private async signupSuccess(member: MemberModel) {
    this.notifications.success('Successfully registered');
    await Promise.all(this.signupCallbacks.map(callback => callback(member)));
    await Promise.all(this.loginCallbacks.map(callback => callback()));
    this.googleAnalytics.signup();
  }

  private signupFailed(error: any) {
    const errors = getAllUserMessagesOrDefault(error, {
      defaultMsg: 'Unsuccessful register - try again '
    });
    this.notifications.error(errors.join('. '));
  }
  async validate(email: string): Promise<boolean> {
    return await this.store.adapterFor('member').validate(email);
  }

  async forgotPassword(email: string, checkout: boolean): Promise<void> {
    try {
      await this.store.adapterFor('member').forgotPassword(email, checkout);
    } catch (error) {
      this.forgotPasswordFailed(error);
      throw error;
    }
  }
  private forgotPasswordFailed(error: any) {
    let errors = [];
    if (error instanceof NotFoundError) {
      errors = [this.locale.translate('member.email_not_found')];
    } else {
      errors = getAllUserMessagesOrDefault(error, {
        defaultMsg: 'Unsuccessful password reset - try again '
      });
    }
    this.notifications.error(errors.join('. '));
  }
  async resetPassword(
    email: string,
    hash: string,
    password: string,
    passwordConfirmation: string
  ): Promise<void> {
    try {
      await this.store
        .adapterFor('member')
        .resetPassword(this.store, email, hash, password, passwordConfirmation);
    } catch (error) {
      this.resetPasswordFailed(error);
      throw error;
    }
    await this.resetPasswordSuccess();
  }

  private async resetPasswordSuccess() {
    this.notifications.success(
      this.locale.translate('member.password_updated')
    );
    await Promise.all(this.loginCallbacks.map(callback => callback()));
  }
  private resetPasswordFailed(error: any) {
    const errors = getAllUserMessagesOrDefault(error, {
      defaultMsg: 'Unsuccessful password reset - try again '
    });
    this.notifications.error(errors.join('. '));
  }

  @action
  async onMemberChanged(member: MemberModel | null) {
    this.clearStaleRecords(member);

    await Promise.all(this.memberChangedCallbacks.map(callback => callback()));
  }
}
