import { attr } from '@ember-data/model';
import Model from '@ember-data/model';

export default class EventCategoryModel extends Model {
  @attr('string') name!: string;
  @attr('boolean') isNullObject!: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'event-category': EventCategoryModel;
  }
}
