/* import __COLOCATED_TEMPLATE__ from './secondary.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ButtonTbSecondarySignature {
  Args: {
    onClick: Function;
    onSuccess?: Function;
    onError?: Function;

    text?: string;
    disabled?: boolean;
    testSelector?: string;
    type?: string;
    narrowStyle?: boolean;
    classes?: string;
    contentClasses?: string;

    borderColorSetting?: string;
    colorSetting?: string;
    bgColor?: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

const ButtonTbSecondaryComponent =
  templateOnlyComponent<ButtonTbSecondarySignature>();

export default ButtonTbSecondaryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Button::Tb::Secondary': typeof ButtonTbSecondaryComponent;
    'button/tb/secondary': typeof ButtonTbSecondaryComponent;
  }
}
