import type { SingleResourceDocument } from 'ticketoffice-api';

import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

import { memberAction } from 'ember-api-actions';

import { serializeAndPush } from 'tangram/utils/serialize-and-push';
import type MembershipService from 'ticketbooth/services/membership';
import {
  INCLUDE_ALL_FIELDS_STRING,
  INCLUDE_ALL_STRING
} from 'ticketbooth/utils/member-api';

import type BenefitModel from './benefit';
import type CustomerModel from './customer';
import type RewardRedemptionModel from './reward-redemption';
import type WaitingListEntryModel from './waiting-list-entry';

export default class MemberModel extends Model {
  @service private membership!: MembershipService;

  @attr('string') name!: string;
  @attr('string') email!: string;

  @belongsTo('customer', { async: false, inverse: null })
  customer!: CustomerModel;

  /**
   * Member has bought a benefit product
   */
  @belongsTo('benefit', { async: false, inverse: null })
  activeBenefit!: BenefitModel | null;

  @belongsTo('reward-redemption', { async: false, inverse: null })
  rewardRedemption!: RewardRedemptionModel | null;

  /**
   * Member has joined a waiting list
   */
  @hasMany('waiting-list-entry', { async: false, inverse: 'member' })
  waitingListEntries!: SyncHasMany<WaitingListEntryModel>;

  get hasDiscount() {
    return this.activeBenefit?.discountId;
  }

  get nameWithBenefit() {
    if (this.activeBenefit) {
      return `${this.name}, ${this.activeBenefit.name}`;
    }
    return this.name;
  }

  get nameWithObscuredEmail() {
    const EMAIL_PARTS_REGEX = /([\w-.]+@)([\w-.]+)(\.[\w-.]+)/;
    return this.name.replace(EMAIL_PARTS_REGEX, '$1****$3');
  }

  get hasRemainingRewards(): boolean {
    if (!this.rewardRedemption) {
      return false;
    }
    return this.rewardRedemption.remaining > 0;
  }

  logout = memberAction({
    type: 'post',
    path: 'logout'
  });

  updateProfile = memberAction<{
    member: {
      password: string;
      passwordConfirmation: string;
      currentPassword: string;
    };
    customer: CustomerModel;
  }>({
    type: 'patch',
    path: `profile?${INCLUDE_ALL_STRING}&${INCLUDE_ALL_FIELDS_STRING}`,
    before(this: MemberModel, { member, customer }) {
      return {
        data: {
          member: {
            attributes: {
              password: member.password,
              'password-confirmation': member.passwordConfirmation,
              'current-password': member.currentPassword
            }
          },
          customer: (
            customer.serialize({ includeId: true }) as SingleResourceDocument
          ).data
        }
      };
    },
    async after(this: MemberModel, payload) {
      const member = serializeAndPush.bind(this)(payload);
      await this.membership.onMemberChanged(member);
    }
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    member: MemberModel;
  }
}
