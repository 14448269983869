import { Renderer } from 'tangram/components/canvas';
import type { Transform } from 'tangram/utils/canvas-drawing';
import {
  drawSingleColoredCircle,
  drawCross
} from 'tangram/utils/canvas-drawing';
import { circleCoordinatesForSeat } from 'tangram/utils/seat-editor';
import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';

export default class SeatsRenderer extends Renderer {
  render(
    transform: Transform,
    width: number,
    height: number,
    seats: SeatAssignmentModel[],
    selectedSeats: SeatAssignmentModel[]
  ) {
    const { context } = this;

    if (context) {
      context.save();
      context.clearRect(0, 0, width, height);
      context.translate(transform.x, transform.y);
      context.scale(transform.k, transform.k);

      seats.forEach(seat => {
        const isSelected = selectedSeats.includes(seat);
        if (isSelected) {
          this.drawSelectedSeat(context, seat);
        } else {
          this.drawSeat(context, seat);
        }
      });

      context.restore();
    }
  }

  drawSelectedSeat(
    context: CanvasRenderingContext2D,
    seat: SeatAssignmentModel
  ) {
    const [cx, cy] = circleCoordinatesForSeat(seat);

    return drawSingleColoredCircle(
      context,
      cx,
      cy,
      seat.selectedColor,
      seat.selectedStrokeColor
    );
  }

  drawSeat(context: CanvasRenderingContext2D, seat: SeatAssignmentModel) {
    const [cx, cy] = circleCoordinatesForSeat(seat);

    drawSingleColoredCircle(context, cx, cy, seat.color, seat.strokeColor);

    if (seat.displayBlockedIndicator && seat.isBlocked) {
      this.addBlockedIndicator(context, cx, cy);
    }
  }

  private addBlockedIndicator(
    context: CanvasRenderingContext2D,
    cx: number,
    cy: number
  ) {
    context.strokeStyle = 'black';
    drawCross(context, cx, cy);
  }
}
