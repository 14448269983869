/* import __COLOCATED_TEMPLATE__ from './show-card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CoverShowCardSignature {
  Args: {};
}

const CoverShowCardComponent = templateOnlyComponent<CoverShowCardSignature>();

export default CoverShowCardComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::ShowCard': typeof CoverShowCardComponent;
    'cover/show-card': typeof CoverShowCardComponent;
  }
}
