/* import __COLOCATED_TEMPLATE__ from './member-signup-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AnnoucerService from 'a11y-announcer';
import omit from 'lodash-es/omit';
import trim from 'lodash-es/trim';
import type { ObjectSchema } from 'yup';
import { object, string, ref } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import { task, timeout } from 'ember-concurrency';

import type EnvironmentService from 'tangram/services/environment';
import FormObject from 'tangram/utils/form-object';
import type LocaleService from 'ticketbooth/services/locale';
import type MembershipService from 'ticketbooth/services/membership';

class SignupForm extends FormObject<SignupForm> {
  @tracked email!: string;
  @tracked password!: string;
}
class SignupConfirmationForm extends FormObject<SignupConfirmationForm> {
  @tracked email!: string;
  @tracked emailConfirmation!: string;
  @tracked password!: string;
  @tracked passwordConfirmation!: string;
}

interface MemberSignupFormSignature {
  Args: {
    submitLabel: string;
    onSubmit: (
      email: string,
      password: string,
      emailConfirmation?: string,
      passwordConfirmation?: string
    ) => Promise<void>;
    includeConfirmation?: boolean;
  };
}

export default class MemberSignupFormComponent extends Component<MemberSignupFormSignature> {
  @service private locale!: LocaleService;
  @service private environment!: EnvironmentService;
  @service private membership!: MembershipService;
  @service private announcer!: AnnoucerService;

  @tracked formObject: SignupForm | SignupConfirmationForm;
  @tracked schema: ObjectSchema<ObjectShape>;

  constructor(owner: unknown, args: MemberSignupFormSignature['Args']) {
    super(owner, args);

    this.formObject = this.createFormObject();
    this.schema = this.createSchema();
  }

  private createFormObject() {
    return this.args.includeConfirmation
      ? new SignupConfirmationForm({
          email: '',
          password: '',
          emailConfirmation: '',
          passwordConfirmation: ''
        })
      : new SignupForm({ email: '', password: '' });
  }

  private createSchema() {
    const t = (msg: string) => this.locale.translate(msg);
    const cantBeBlank = t('activerecord.errors.messages.blank');
    const doesNotMatchEmail = t(
      'activerecord.errors.messages.doesnt_match_email'
    );
    const doesNotMatchConfirmation = t(
      'activerecord.errors.messages.confirmation'
    );
    const emailAlreadyTaken = `${t('common.not_available')}. ${t(
      'checkout.login_instead'
    )}.`;
    const schema = {
      email: string()
        .required(cantBeBlank)
        .email(t('checkout.please_enter_valid_email'))
        .max(100)
        .test(
          'is-already-taken',
          emailAlreadyTaken,
          async () => await this.validateEmail.perform()
        ),
      emailConfirmation: string()
        .required(cantBeBlank)
        .oneOf([ref('email')], doesNotMatchEmail)
        .max(100),
      password: string().required(cantBeBlank).max(96),
      passwordConfirmation: string()
        .required(cantBeBlank)
        .oneOf([ref('password')], doesNotMatchConfirmation)
        .max(96)
    };

    return object().shape(
      this.args.includeConfirmation
        ? schema
        : omit(schema, ['emailConfirmation', 'passwordConfirmation'])
    );
  }

  @action
  async onSubmit(form: SignupForm | SignupConfirmationForm) {
    if (form instanceof SignupConfirmationForm) {
      await this.args.onSubmit(
        form.email,
        form.password,
        form.emailConfirmation,
        form.passwordConfirmation
      );
    } else {
      await this.args.onSubmit(form.email, form.password);
    }
  }

  @action
  onSuccess() {
    this.announcer.announce(
      'Registration complete! Please fill in your member details.',
      'assertive'
    );
  }

  validateEmail = task({ restartable: true }, async () => {
    await timeout(this.environment.isTest ? 0 : 1000);

    const email = trim(this.formObject.email);

    if (isBlank(email)) {
      return false;
    }

    return this.membership.validate(email);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MemberSignupForm: typeof MemberSignupFormComponent;
    'member-signup-form': typeof MemberSignupFormComponent;
  }
}
