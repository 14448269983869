import { tracked } from '@glimmer/tracking';
import type AnnoucerService from 'a11y-announcer';
import get from 'lodash-es/get';

import { assert } from '@ember/debug';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

import type { Locale } from 'tangram/helpers/humanize-locale';
import type BaseSettingsService from 'tangram/services/settings-base';
import escapeRegExp from 'tangram/utils/escape-regex';
import config from 'ticketbooth/config/environment';
import type {
  SnippetType,
  CountableLocaleObject,
  LocaleValue,
  LocaleDefault,
  LocaleObject
} from 'ticketbooth/lib/locales';
import LOCALES from 'ticketbooth/lib/locales';
import type ErrorsService from 'ticketbooth/services/errors';

export type LocaleParams = {
  [k: string]: any;
  count?: number;
};

const COOKIE_NAME = config.environment === 'test' ? 'test_locale' : 'locale';

const LOCALE_CHANGED_MESSAGES: { [x: string]: string } = {
  'en-GB': 'Language set to english',
  'cy-CY': "Iaith wedi newid i'r Gymraeg",
  'ie-IE': 'Athrú go Gaeilge'
};

function isLocaleSnippet(obj: SnippetType): obj is LocaleValue {
  return 'locale' in obj;
}
function isDefaultLocaleSnippet(obj: SnippetType): obj is LocaleDefault {
  return 'default' in obj;
}
function isCountableLocaleObject(
  obj: SnippetType[] | CountableLocaleObject | LocaleObject
): obj is CountableLocaleObject {
  return 'other' in obj;
}

export default class LocaleService extends Service {
  @service announcer!: AnnoucerService;
  @service private moment!: { setLocale(locale: string): Function };
  @service private settings!: BaseSettingsService<{ value: any }>;
  @service private errors!: ErrorsService;

  locales = LOCALES;
  localeFromUrl = new URLSearchParams(location.search)?.get(
    'locale'
  ) as Locale | null;

  get preloadLocaleObject(): LocaleObject {
    return this.settings.getSetting('preloadLocaleObject');
  }
  get localesAvailable() {
    return this.settings.locales as Locale[];
  }

  @tracked private _persistedLocale!: Locale;
  private get persistedLocale() {
    if (this._persistedLocale) {
      return this._persistedLocale;
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#Example_2_Get_a_sample_cookie_named_test2
    const cookieName = COOKIE_NAME;
    const pattern = `(?:(?:^|.*;\\s*)${cookieName}\\s*=\\s*([^;]*).*$)|^.*$`;
    const regexp = new RegExp(pattern);
    const locale = document.cookie.replace(regexp, '$1') as Locale;
    if (locale && this.localesAvailable.includes(locale)) {
      return locale;
    }
    return null;
  }
  private set persistedLocale(locale: Locale | null) {
    if (locale && this.localesAvailable.includes(locale)) {
      const cookieName = COOKIE_NAME;
      const domain = window.location.hostname; // foo.ticketsolve.com
      const oneYear = 60 * 60 * 24 * 365;
      document.cookie = `${cookieName}=${locale}; domain=${domain}; max-age=${oneYear}; path=/`;
      this._persistedLocale = locale;
    }
  }

  get currentLocale(): Locale {
    const { localeFromUrl, persistedLocale, localesAvailable } = this;

    if (localeFromUrl && localesAvailable.includes(localeFromUrl)) {
      return localeFromUrl;
    }
    if (persistedLocale && localesAvailable.includes(persistedLocale)) {
      return persistedLocale;
    }

    return localesAvailable[0];
  }

  get currentShortLocale() {
    return this.currentLocale.slice(0, 2);
  }

  get localeObjects() {
    return { ...this.locales, ...this.preloadLocaleObject };
  }

  setCurrentLocale(locale: Locale) {
    if (this.localesAvailable.includes(locale)) {
      this.persistedLocale = locale;
      this.localeFromUrl = null;
      this.setHtmlLang(locale);
      this.announceChange(locale);
      this.moment.setLocale(this.currentLocale.split('-')[0]);
    }

    this.errors.onLocaleChanged();
  }

  announceChange(locale: Locale) {
    this.announcer.announce(LOCALE_CHANGED_MESSAGES[locale], 'assertive');
  }

  setHtmlLang(locale: Locale) {
    const htmlLocale = locale.split('-')[0];
    (document.querySelector('html') as HTMLElement).lang = htmlLocale;
  }

  translate(locale?: string | SnippetType[], options?: LocaleParams) {
    if (locale) {
      if (typeof locale === 'string') {
        let localeObject = get(this.localeObjects, locale);

        const count = options?.count;
        if (
          localeObject &&
          isCountableLocaleObject(localeObject) &&
          count !== undefined
        ) {
          switch (count) {
            case 0:
              localeObject = localeObject.zero ?? localeObject.other;
              break;
            case 1:
              localeObject = localeObject.one ?? localeObject.other;
              break;
            default:
              localeObject = localeObject.other;
              break;
          }
        }
        assert(
          `${locale} doesn't exist in our hardcoded translations. Add it to ticketbooth/app/lib/locales.ts`,
          !!localeObject
        );
        return this.translateLocaleObject(
          localeObject as SnippetType[],
          options
        );
      } else {
        return this.translateLocaleObject(locale, options);
      }
    }
    return '';
  }

  private translateLocaleObject(
    localeObject?: SnippetType[],
    params?: LocaleParams
  ) {
    let translation;
    if (localeObject) {
      const locale = localeObject.find(
        value => isLocaleSnippet(value) && value.locale === this.currentLocale
      ) as LocaleValue | undefined;
      translation = locale?.value;
      if (!translation) {
        const locale = localeObject.find(
          value => isDefaultLocaleSnippet(value) && value.default
        ) as LocaleDefault | undefined;
        translation = locale?.default;
      }
    }
    return this.replaceParamsInTranslation(translation || '', params);
  }

  private replaceParamsInTranslation(
    translation: string,
    params?: LocaleParams
  ) {
    // replace all %{key} in the value
    return Object.entries({ ...params }).reduce((acc, [key, value]) => {
      const regex = new RegExp(escapeRegExp(`%{${key}}`), 'gim');
      return acc.replace(regex, value);
    }, translation);
  }
}
