import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

export default class extends DS.Transform {
  deserialize(serialized: any[] | null) {
    return isEmpty(serialized) ? [] : serialized;
  }
  serialize(deserialized: any[] | null) {
    return isEmpty(deserialized) ? [] : deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'plain-array': any[];
  }
}
