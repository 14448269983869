import without from 'lodash-es/without';

import { action } from '@ember/object';
import Service from '@ember/service';

export default class IframePositionService extends Service {
  private windowHeight = 0;
  private iframeTop = 0;
  private scrollTop = 0;
  private offsetTop = 0;
  private offsetBottom = 0;

  private get top() {
    // Viewport top
    let top = this.scrollTop - this.iframeTop;

    // Add offset config
    top += this.offsetTop;

    // Constrain to iframe top
    top = Math.max(0, top);

    return top;
  }

  private get bottom() {
    // Viewport bottom
    let bottom = this.windowHeight + this.scrollTop - this.iframeTop;

    // Subtract offset config
    bottom -= this.offsetBottom;

    // Constrain to iframe bottom
    bottom = Math.min(document.documentElement.clientHeight, bottom);

    return bottom;
  }

  /**
   * Parent website viewport excluding header and footer overlays
   */
  get parentWindowViewport() {
    return this.windowHeight - this.offsetTop - this.offsetBottom;
  }

  private scrollCaptureStartCallbacks: (() => void)[] = [];
  private scrollCaptureEndCallbacks: (() => void)[] = [];
  private changePositionCallbacks: ((top: number, bottom: number) => void)[] =
    [];

  private positionChanged() {
    this.changePositionCallbacks.forEach(cb => cb(this.top, this.bottom));
  }
  private startScrollCapture() {
    this.scrollCaptureStartCallbacks.forEach(cb => cb());
  }
  private endScrollCapture() {
    this.scrollCaptureEndCallbacks.forEach(cb => cb());
  }

  onScrollCaptureStart(cb: () => void) {
    const { scrollCaptureStartCallbacks } = this;
    this.scrollCaptureStartCallbacks = [...scrollCaptureStartCallbacks, cb];
  }
  onScrollCaptureEnd(cb: () => void) {
    const { scrollCaptureEndCallbacks } = this;
    this.scrollCaptureEndCallbacks = without(scrollCaptureEndCallbacks, cb);
  }
  onPositionChange(cb: (top: number, bottom: number) => void) {
    this.changePositionCallbacks = [...this.changePositionCallbacks, cb];
    if (this.changePositionCallbacks.length === 1) {
      this.startScrollCapture();
    }
  }
  offPositionChange(cb: (top: number, bottom: number) => void) {
    this.changePositionCallbacks = without(this.changePositionCallbacks, cb);
    if (this.changePositionCallbacks.length === 0) {
      this.endScrollCapture();
    }
  }

  @action
  change({
    windowHeight,
    iframeTop,
    offsetTop,
    offsetBottom,
    scrollTop
  }: {
    windowHeight?: number;
    iframeTop?: number;
    offsetTop?: number;
    offsetBottom?: number;
    scrollTop?: number;
  }) {
    if (typeof windowHeight === 'number') {
      this.windowHeight = windowHeight;
    }
    if (typeof iframeTop === 'number') {
      this.iframeTop = iframeTop;
    }
    if (typeof offsetTop === 'number') {
      this.offsetTop = offsetTop;
    }
    if (typeof offsetBottom === 'number') {
      this.offsetBottom = offsetBottom;
    }
    if (typeof scrollTop === 'number') {
      this.scrollTop = scrollTop;
    }

    this.positionChanged();
  }
}

declare module '@ember/service' {
  interface Registry {
    'iframe-position': IframePositionService;
  }
}
