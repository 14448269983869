/**
 * UI Cart Session
 *
 * Note: Use only for ui data tied to a specific cart
 */
export type UISessionData = {
  cartId: string;

  /**
   * Donations Prompt
   *
   * Remember if the user opted out of a donation
   */
  donationsPrompt?: { productId: string; optOut: boolean }[];

  /**
   * Gift Aid Prompt
   *
   * Remember if the user opted out of gift aid
   */
  giftAidPreference?: { optOut: boolean };
};

const STORAGE_KEY = 'cart-ui-session';

export function fetchCartSession(cartId: string): UISessionData {
  const serialized = sessionStorage.getItem(STORAGE_KEY);

  if (!serialized) {
    return { cartId };
  }

  try {
    const data = JSON.parse(serialized);
    if (data.cartId === cartId) {
      return data;
    }
  } catch (error) {
    console.error('Failed to parse cart session data', error);
  }

  return { cartId };
}

export function saveCartSession(
  cartId: string,
  data: Omit<UISessionData, 'cartId'>
) {
  try {
    sessionStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        ...fetchCartSession(cartId),
        ...data
      })
    );
  } catch (error) {
    console.error('Failed to save cart session data', error);
  }
}
