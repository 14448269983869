/* import __COLOCATED_TEMPLATE__ from './seat-editor.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Machine, assign } from 'xstate';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { useMachine } from 'ember-statecharts';

import type SeatManagement from 'tangram/components/seat-management';
import type EnvironmentService from 'tangram/services/environment';
import type { Coordinates2D } from 'tangram/utils/canvas-drawing';
import { findSeatByCoordinates } from 'tangram/utils/seat-editor';
import { matchesState } from 'tangram/utils/statecharts';
import type MasterAllocationModel from 'ticketbooth/models/master-allocation';
import type { AllocatedTicketSelection } from 'ticketbooth/models/seat-assignment';
import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';

import type SelectableTickets from './seat-editor/selectable-tickets';

interface StateContext {
  scrollDelta: number;
}

interface StateSchema {
  states: {
    idle: {};
    oneFingerIdle: {};
    oneFingerScroll: {};
  };
}

type StateEvent = CanvasTouchMove | { type: 'TOUCH_END' };

type CanvasTouchMove = { type: 'TOUCH_MOVE'; event: TouchEvent };

interface SeatEditorSignature {
  Args: {
    masterAllocation: MasterAllocationModel;
    scrollContainer: string;
    bookingFeeNote?: string;
    onScrollBy?: (x: number, y: number) => void;
  };
}

export default class SeatEditorComponent extends Component<SeatEditorSignature> {
  @service private environment!: EnvironmentService;

  /**
   * Only for mobile - bigger screens always display the seat editor
   */
  @tracked isEditorVisible = true;

  @action
  displayEditorTab(isEditorVisible: boolean) {
    this.isEditorVisible = isEditorVisible;
  }

  /**
   * Filter blocked seats, seats in cart, unassignable seats.
   */
  @action
  filterSelection(selectedSeats: SeatAssignmentModel[]) {
    return selectedSeats.filter(seat => seat.isSelectable);
  }

  /**
   * Always zoom/pan the canvas, unless we are selecting a seat, e.g.
   *
   *   - Click-and-drag to pan
   *   - Wheel to zoom
   *   - Touch to zoom/pan
   *
   * See also https://github.com/d3/d3-zoom/blob/main/README.md#api-reference
   */
  @action
  filterZoomPan(
    seats: SeatAssignmentModel[],
    event: Event,
    coordinates: Coordinates2D
  ) {
    let result = true;
    const isSelectingTouch = event.type.startsWith('touch');
    const isSelectingMouse = event.type === 'mousedown';
    if (isSelectingTouch && (event as TouchEvent).touches.length !== 2) {
      result = false;
    } else if (isSelectingTouch || isSelectingMouse) {
      const seat = findSeatByCoordinates<SeatAssignmentModel>(
        coordinates,
        seats
      );

      result = !(seat && seat.isSelectable);
    }
    if (result) {
      // coming from 1 finger to 2, we need to handle it
      this.machine.send('TOUCH_END');
    }
    return result;
  }

  @action
  selectSeatsAndTickets(
    selectSeats: SeatManagement['onSeatsSelected'],
    setTickets: SelectableTickets['setTickets'],
    tickets: AllocatedTicketSelection[]
  ) {
    const seatAssignments = tickets.map(sel => sel.seatAssignment);
    selectSeats(seatAssignments);
    setTickets(tickets);
  }

  @action
  scrollToConfirm() {
    if (!this.environment.isTest) {
      const content = document.getElementById(this.args.scrollContainer);
      const footer = document.getElementById('seat-editor-confirm-scroll');
      const sessionFooter = document.getElementById('session-footer');

      if (content && footer) {
        const top =
          footer.getBoundingClientRect().top +
          footer.offsetHeight +
          (sessionFooter ? sessionFooter?.clientHeight : 0) -
          content.clientHeight;
        if (top > 0) {
          content.scrollBy({ top, behavior: 'smooth' });
        }
      }
    }
  }

  get machine() {
    return this.statechart;
  }

  statechart = useMachine<StateContext, StateSchema, StateEvent, any, {}>(
    this,
    () => {
      return {
        machine: Machine<StateContext, StateSchema, StateEvent>({
          initial: 'idle',
          on: {
            TOUCH_MOVE: [
              { target: 'oneFingerScroll', cond: 'isTouchScrolling' },
              { target: 'oneFingerIdle' }
            ],
            TOUCH_END: {
              target: 'idle',
              actions: assign({ scrollDelta: -1 })
            }
          },
          states: {
            idle: {},
            oneFingerIdle: {},
            oneFingerScroll: { entry: 'handleTouchScroll' }
          }
        })
          .withContext({
            scrollDelta: -1
          })
          .withConfig({
            guards: {
              isTouchScrolling: (
                { scrollDelta },
                { event: { touches } }: CanvasTouchMove
              ) =>
                touches.length > 0 &&
                Math.abs(scrollDelta - touches[0].screenY) > 1
            },
            actions: {
              handleTouchScroll: (
                context,
                {
                  event: { touches }
                }: Extract<StateEvent, { type: 'TOUCH_MOVE' }>
              ) => {
                if (context.scrollDelta > 0) {
                  const scrollY = context.scrollDelta - touches[0].screenY;
                  this.args.onScrollBy?.(0, scrollY);
                }

                context.scrollDelta = touches[0].screenY;
              }
            }
          })
      };
    }
  );

  @matchesState('oneFingerIdle')
  isOneFingerIdle!: boolean;
  @matchesState('oneFingerScroll')
  isOneFingerScroll!: boolean;

  get displayTouchScrollMessage() {
    return this.isOneFingerIdle || this.isOneFingerScroll;
  }

  @action
  handleTouchMove(event: TouchEvent) {
    this.machine.send('TOUCH_MOVE', { event });
  }

  @action
  handleTouchEnd() {
    this.machine.send('TOUCH_END');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SeatEditor: typeof SeatEditorComponent;
    'seat-editor': typeof SeatEditorComponent;
  }
}
