import { attr } from '@ember-data/model';

import ProductModel from './product';

export default class FulfillmentProductModel extends ProductModel {
  canRemoveFromCart = false;

  @attr('number') priority!: number;

  get googleAnalyticsName(): string {
    return this.name.replace(/"/g, ' ').replace(/\n/g, '');
  }

  get googleAnalyticsCategory(): string {
    return 'fulfillment';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'fulfillment-product': FulfillmentProductModel;
  }
}
