function setViewport(content: string) {
  const meta = document.querySelector(
    'meta[name="viewport"]'
  ) as HTMLMetaElement | null;
  if (meta) {
    meta.content = content;
  }
}

function isZoomTarget(target: EventTarget | null): boolean {
  return (
    target instanceof Element &&
    ['INPUT', 'SELECT', 'TEXTAREA'].includes(target.nodeName)
  );
}

/**
 * Control max zoom for mobile users when focusing input fields
 *
 * Note: This effectively also prevents the credit card payment iframe from
 * automatically zooming in too much.
 */
export function restrictMobileZoom(): void {
  document.addEventListener(
    'focus',
    function ({ target }) {
      if (isZoomTarget(target)) {
        setViewport('width=device-width,initial-scale=1,maximum-scale=10');
      }
    },
    true
  );
  document.addEventListener(
    'blur',
    function ({ target }) {
      if (isZoomTarget(target)) {
        setViewport('width=device-width,initial-scale=1,maximum-scale=10');
      }
    },
    true
  );
}

export default { initialize: restrictMobileZoom };
