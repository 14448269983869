import { captureException } from '@sentry/ember';

const REVISION_QP_LEGACY = 'ticketbooth_version';
const REVISION_QP = 'revision';

export default {
  name: 'redirect-legacy-revision-preview',

  initialize() {
    try {
      const params = new URLSearchParams(location.search);

      const revisionKeyLegacy = params && params.get(REVISION_QP_LEGACY);

      if (!revisionKeyLegacy) {
        return;
      }

      params.set(REVISION_QP, revisionKeyLegacy);
      params.delete(REVISION_QP_LEGACY);

      window.location.search = params.toString();
    } catch (e) {
      captureException(e);
    }
  }
};
