/* import __COLOCATED_TEMPLATE__ from './ticket-price-selection.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SeatEditorTicketPriceSelectionSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
}

const SeatEditorTicketPriceSelectionComponent =
  templateOnlyComponent<SeatEditorTicketPriceSelectionSignature>();

export default SeatEditorTicketPriceSelectionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::TicketPriceSelection': typeof SeatEditorTicketPriceSelectionComponent;
    'seat-editor/ticket-price-selection': typeof SeatEditorTicketPriceSelectionComponent;
  }
}
