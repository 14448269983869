import Component from '@glimmer/component';

import { concat } from '@ember/helper';
import { inject as service } from '@ember/service';

// @ts-ignore
import inc from 'ember-composable-helpers/helpers/inc';
import { or } from 'ember-truth-helpers';

import type CurrencyService from 'tangram/services/currency';
import type TicketLineItemModel from 'ticketbooth/models/ticket-line-item';
import branding from 'ticketbooth/modifiers/branding';
import type CartProviderService from 'ticketbooth/services/cart-provider';

interface EventHubQrCodesTicketsSignature {
  Args: {
    ticketLineItems: TicketLineItemModel[];
  };
}

export default class EventHubQrCodesTickets extends Component<EventHubQrCodesTicketsSignature> {
  @service cart!: CartProviderService;
  @service currency!: CurrencyService;

  <template>
    {{! @glint-nocheck: not typesafe yet }}
    {{#each @ticketLineItems as |ticketLineItem index|}}
      <div class="rounded p-5 bg-gray-200 w-full mb-4"
        {{branding "backgroundColor" "navbar_bg_color"}}
        {{branding "color" "navbar_fg_color"}}
        data-test-ticket-line-item="{{index}}"
      >
        <div class="flex justify-between font-semibold text-lg mb-4 leading-tight">
          <div class="flex flex-grow truncate" data-test-seat-assignment-details="{{index}}">
            {{ticketLineItem.seatAssignment.details}}
          </div>
          <div data-test-ticket-line-item-quantity="{{index}}">
            {{concat (inc index) "/" @ticketLineItems.length}}
          </div>
        </div>

        <div class="flex justify-between mb-3 text-sm leading-tight">
          <div data-test-ticket-line-item-checkin-code="{{index}}">{{ticketLineItem.checkinCode}}</div>
        </div>

        <div class="flex justify-between text-sm leading-tight">
          <div class="flex flex-grow truncate" data-test-ticket-line-item-event-ticket-price-name="{{index}}">
            {{ticketLineItem.eventTicketPrice.name}}
          </div>
          <div data-test-ticket-line-item-event-ticket-price-price="{{index}}">
            {{concat this.currency.symbol ticketLineItem.eventTicketPrice.price}}
          </div>
        </div>

        {{#if (or ticketLineItem.seatAssignment.comment ticketLineItem.comment)}}
          <div class="border border-red-400 bg-red-100 mt-5 text-red-700 p-3 text-sm rounded"
            data-test-ticket-line-item-comment="{{index}}">
            {{or ticketLineItem.seatAssignment.comment ticketLineItem.comment}}
          </div>
        {{/if}}

        <div class="flex border my-5" {{branding "borderColor" "navbar_fg_color"}}></div>

        <div class="flex justify-center" data-test-ticket-line-item-qr-code="{{index}}">
          <img alt="ticket QR code" src={{ticketLineItem.qrCodeImageLink}} class="rounded shadow" />
        </div>
      </div>
    {{/each}}
  </template>
}
