import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';
import type MembershipService from 'ticketbooth/services/membership';

export default class extends Route {
  @service private checkoutFlow!: CheckoutFlowService;
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;

  get hasGiftAidPreference() {
    return this.membership.hasGiftAidPreference;
  }

  async beforeModel(transition: Transition) {
    if (!this.checkoutFlow.giftaidStep.available) {
      this.checkoutFlow.goToNextStep('gift_aid_prompt');
    }
    if (this.membership.hasGiftAidPreference) {
      this.checkoutFlow.skipStep('gift_aid_prompt', transition.from?.name);
    }
  }

  activate() {
    this.membership.onLogin(this.checkIfHasGiftAidPreference);
  }

  deactivate() {
    this.membership.offLogin(this.checkIfHasGiftAidPreference);
  }

  @action
  private async checkIfHasGiftAidPreference() {
    await this.cartProvider.reload();

    if (this.membership.hasGiftAidPreference) {
      this.checkoutFlow.goToNextStep('gift_aid_prompt');
    }
  }
}
