import { attr } from '@ember-data/model';
import Model from '@ember-data/model';

export default class VenueModel extends Model {
  @attr('string') name!: string;

  // Map
  @attr('number') latitude!: number;
  @attr('number') longitude!: number;
  @attr('number') zoom!: number;

  get hasMap(): boolean {
    const { latitude, longitude } = this;
    return typeof latitude === 'number' && typeof longitude === 'number';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    venue: VenueModel;
  }
}
