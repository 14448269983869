/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
import Component from '@glimmer/component';

import type { ModalContentBaseSignature } from 'tangram/components/modal/content/base';

interface ModalContentSignature {
  Args: {
    close?: Function;
    testSelector?: string;
    isSmall?: boolean;
  };
  Blocks: {
    default: [ModalContentBaseSignature['Blocks']['default'][0]];
  };
  Element: ModalContentBaseSignature['Element'];
}
import ModalHeader from './header';

export default class TicketboothModalContent extends Component<ModalContentSignature> {
  ModalHeader = ModalHeader;
}
