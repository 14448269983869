/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import type { CheckoutStep } from 'ticketbooth/services/checkout-flow';

interface CheckoutFlowHeaderSignature {
  Args: {
    items: CheckoutStep[];
    activeItem: CheckoutStep | null;
  };
  Element: HTMLDivElement;
}

export default class CheckoutFlowHeader extends Component<CheckoutFlowHeaderSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'checkout-flow/header': typeof CheckoutFlowHeader;
    CheckoutFlowHeader: typeof CheckoutFlowHeader;
  }
}
