/* import __COLOCATED_TEMPLATE__ from './events-list.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ShowEventsListSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
}

const ShowEventsListComponent =
  templateOnlyComponent<ShowEventsListSignature>();

export default ShowEventsListComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList': typeof ShowEventsListComponent;
    'show/events-list': typeof ShowEventsListComponent;
  }
}
