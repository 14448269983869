import type ApplicationInstance from '@ember/application/instance';
import type RouterService from '@ember/routing/router-service';

import type DataLayersService from 'ticketbooth/services/data-layers';
import type MembershipService from 'ticketbooth/services/membership';
import type PreloadService from 'ticketbooth/services/preload';

export function initialize(appInstance: ApplicationInstance): void {
  const dataLayersService = appInstance.lookup(
    'service:data-layers'
  ) as DataLayersService;

  const preloadService = appInstance.lookup(
    'service:preload'
  ) as PreloadService;

  const membershipService = appInstance.lookup(
    'service:membership'
  ) as MembershipService;

  const routerService = appInstance.lookup('service:router') as RouterService;

  const _window: any = window;

  if (_window.dataLayer) {
    dataLayersService.dataLayers['account'] = _window.dataLayer;
  }

  if (_window.tsDataLayer) {
    dataLayersService.dataLayers['ts'] = _window.tsDataLayer;
  }

  // Provide details about client type to TS analytics:
  // account name, membership type, customer type
  _setDimensions(dataLayersService, preloadService);

  // Group routes into pre-checkout / checkout groups for reports
  routerService.on('routeWillChange', transition => {
    if (transition.to) {
      const contentGroup = _contentGroupFor(transition.to.name);
      _setContentGroup(dataLayersService, contentGroup);
    }
  });

  // Report when users login or logout
  membershipService.onLogin(() => {
    dataLayersService.setDimension('Login', 'true', ['ts']);
  });

  membershipService.onLogout(() => {
    dataLayersService.setDimension('Login', 'false', ['ts']);
  });
}

function _setDimensions(dls: DataLayersService, preload: PreloadService) {
  const MEMBERSHIP_TYPES: { [key: string]: string } = {
    yes: 'Required',
    optional: 'Optional',
    no: 'Disabled'
  };

  const membershipSetting: string = preload.getValue('membership');
  const membershipValue = MEMBERSHIP_TYPES[membershipSetting];

  dls.setDimension('App Version', 'NTB');
  dls.setDimension('Membership', membershipValue, ['ts']);
  dls.setDimension('Account', preload.getValue('account_name'), ['ts']);
  dls.setDimension('Customer Type', preload.getValue('customer_type'), ['ts']);
}

function _contentGroupFor(routeName: string) {
  const CHECKOUT_CONTENT_GROUP_ROUTES = [
    'cart',
    'checkout',
    'donations-prompt',
    'confirm-order',
    'extras-prompt',
    'order-completed',
    'products-prompt',
    'login-wall'
  ];

  if (CHECKOUT_CONTENT_GROUP_ROUTES.includes(routeName.split('.')[0])) {
    return { name: 'checkout', index: 2 };
  }
  return { name: 'preCheckout', index: 1 };
}

function _setContentGroup(
  dls: DataLayersService,
  contentGroup: { name: string; index: number }
) {
  dls.setVariable('contentGroup.name', contentGroup.name, ['ts']);
  dls.setVariable('contentGroup.index', contentGroup.index.toString(), ['ts']);
}

export default {
  name: 'data-layers',
  initialize
};
