/* import __COLOCATED_TEMPLATE__ from './days.hbs'; */
import Component from '@glimmer/component';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import endOfMonth from 'date-fns/endOfMonth';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import range from 'lodash-es/range';

import { inject as service } from '@ember/service';

import type MediaService from 'ember-responsive/services/media';

import type LocaleService from 'ticketbooth/services/locale';

interface CalendarDaysSignature {
  Args: {
    selectedDate: Date;
    selectDate(date: Date): Promise<void>;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class CalendarDaysComponent extends Component<CalendarDaysSignature> {
  @service locale!: LocaleService;
  @service media!: MediaService;

  get daysOfPrevMonthInWeek(): Date[] {
    const monthStart = startOfMonth(this.args.selectedDate);
    const weekStart = startOfWeek(monthStart, { weekStartsOn: 1 });
    const count = differenceInDays(monthStart, weekStart);
    return range(count).map(num => addDays(weekStart, num));
  }

  get daysInMonth(): Date[] {
    const start = startOfMonth(this.args.selectedDate);
    const end = endOfMonth(this.args.selectedDate);
    const count = getDaysInMonth(end);
    return range(count).map(num => addDays(start, num));
  }

  get weekDays() {
    const weekDays = [
      this.locale.translate('common.week_days_short.monday'),
      this.locale.translate('common.week_days_short.tuesday'),
      this.locale.translate('common.week_days_short.wednesday'),
      this.locale.translate('common.week_days_short.thursday'),
      this.locale.translate('common.week_days_short.friday'),
      this.locale.translate('common.week_days_short.saturday'),
      this.locale.translate('common.week_days_short.sunday')
    ];

    if (this.media.isSm) {
      // Pick only first letter
      return weekDays.map(weekDay => weekDay[0]);
    }

    return weekDays;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calendar::Days': typeof CalendarDaysComponent;
    'calendar/days': typeof CalendarDaysComponent;
  }
}
