import type Store from '@ember-data/store';
import type ModelRegistry from 'ember-data/types/registries/model';

import type OrderModel from 'ticketbooth/models/order';
import { INCLUDE_ALL } from 'ticketbooth/utils/order-api';

import ApplicationAdapter from './application';

export default class OrderAdapter extends ApplicationAdapter {
  urlForConfirm() {
    return this.buildURL('order');
  }

  async confirm(
    store: Store,
    uuid: string,
    connectedOrderId: string | null = null
  ): Promise<OrderModel> {
    const model = store.modelFor('order');
    const serializer = store.serializerFor('application');
    const url = `${this.urlForConfirm()}`;

    const response = await this.ajax(`${url}?include=${INCLUDE_ALL}`, 'POST', {
      data: {
        data: {
          type: 'orders',
          attributes: {
            uuid,
            ...this.attachConntectedOrderId(connectedOrderId)
          }
        }
      }
    });

    const id = response?.data?.id ?? '';

    return store.push(
      serializer.normalizeSingleResponse(
        store,
        model,
        response,
        id,
        'findRecord'
      )
    ) as OrderModel;
  }

  attachConntectedOrderId(id: string | null) {
    return id ? { 'connected-order-id': id } : {};
  }

  urlForFindRecord<K extends keyof ModelRegistry>(
    id: string,
    modelName: K,
    snapshot: any
  ) {
    const url = super.urlForFindRecord(id, modelName, snapshot);
    const options = snapshot.adapterOptions;

    if (options?.filter) {
      const attrs = Object.keys(options.filter)
        .map(key => `filter[${key}]=${options.filter[key]}`)
        .join('&');

      return `${url}?${attrs}`;
    }

    return url;
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    order: OrderAdapter;
  }
}
