import merge from 'lodash-es/merge';

type A11yRunOptions = {
  rules?: object;
};

export const defaultA11yOptions = {
  rules: {
    'color-contrast': {
      enabled: false
    }
  }
};

export function getA11yRunOptions(opts: A11yRunOptions = {}) {
  return opts.rules ? merge({}, defaultA11yOptions, opts) : defaultA11yOptions;
}
