import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';

import type TicketboothErrorsService from 'ticketbooth/services/errors';

/**
 * string = route name
 * string[] = transition params (route, model1, model2, etc.)
 */
type RedirectTarget = string | ((parms: string[]) => string[]);

type TransitionParams = string[] | null;

export default class extends Route {
  @service private errors!: TicketboothErrorsService;
  @service private router!: RouterService;

  /**
   * Route Redirects
   *
   *  key   = url path (params are matched via regex)
   *  value = redirect route OR function that returns transition params
   */
  private REDIRECT_ROUTES: { [key: string]: RedirectTarget } = {
    '/show': 'listings.shows',
    '/shows.': 'listings.shows',
    '/events': 'listings.shows',
    '/signup': 'sign-up',
    '/registration': 'sign-up',
    '/signin': 'login',
    '/login-wall/signup': 'login-wall',
    '/donations': 'listings.products.donation',
    '/benefits': 'listings.products.benefit',
    '/event-categories/{category_id}/shows': 'listings.event-category',
    '/event-categories/{category_id}/shows/upcoming': 'listings.event-category',
    '/venues/{venue_id}/shows': 'listings.venue',
    '/shows/{show_id}/events': 'listings.show',
    '/mobile/shows/{show_id}': 'listings.show',
    '/mobile/shows/{show_id}/events': 'listings.show',
    '/members': 'members.profile',
    '/members/new': 'sign-up',
    '/members/reset-password': 'reset-password',
    '/orders/{uuid}': 'order-completed',
    '/orders/show/{uuid}': 'order-completed',
    '/orders/show/{order_number}/{uuid}': (params: string[]) => [
      'order-completed',
      params[1]
    ],
    '/orders/payments/{uuid}': 'order-completed.payment',
    '/orders/payments/{order_number}/{uuid}': (params: string[]) => [
      'order-completed.payment',
      params[1]
    ]
  } as const;

  /**
   * Convert `REDIRECT_ROUTES` to use RegExp
   */
  redirectMatchers = Object.keys(this.REDIRECT_ROUTES).reduce(
    (matchers: { regexp: RegExp; route: RedirectTarget }[], template) => {
      // Replace {...} with capture groups (cannot contain /, ? or #)
      let regexp = template.replace(/\{[^/]+\}/g, '([^//?#]+)');

      // Ensure start and end of path, with optional trailing slash & query params
      regexp = `^${regexp}\\/?(?:\\?.+)?$`;

      matchers.push({
        regexp: new RegExp(regexp),
        route: this.REDIRECT_ROUTES[template]
      });

      // Duplicate routes with dashes as underscores
      if (regexp.match(/-/)) {
        matchers.push({
          regexp: new RegExp(regexp.replace(/-/g, '_')),
          route: this.REDIRECT_ROUTES[template]
        });
      }

      return matchers;
    },
    []
  );

  beforeModel(transition: Transition & { intent: { url?: string } }) {
    const url = transition.intent.url;
    let isRedirected = false;

    try {
      isRedirected = this.redirectToAlias(transition);
    } catch (error) {
      this.errors.log(error, { extra: { url } });
    } finally {
      if (!isRedirected) {
        this.errors.log(new Error(`Unknown Route: ${url}`), { extra: { url } });
      }
    }
  }

  redirectToAlias(
    transition: Transition & { intent: { url?: string } }
  ): boolean {
    let {
      intent: { url }
    } = transition;

    if (typeof url !== 'string') {
      return false;
    }

    url = new URL(url, `${location.protocol}//${location.host}`).pathname;

    // Find first matching route
    const redirect = this.redirectMatchers.reduce(
      (result: TransitionParams, matcher) => {
        if (!result) {
          result = url!.match(matcher.regexp);
          if (result) {
            result.shift();
            if (typeof matcher.route === 'function') {
              result = matcher.route(result);
            } else {
              result = [matcher.route].concat(result);
            }
          }
        }
        return result;
      },
      null
    );

    if (!redirect) {
      return false;
    }

    let [route, ...models] = redirect;
    let queryParams = Object.fromEntries(new URLSearchParams(location.search));

    if (['order-completed', 'order-completed.payment'].includes(route)) {
      queryParams = { ...queryParams, uid: models[0] };
      models = [];
    }

    // @ts-ignore
    this.router.replaceWith(route, ...models, { queryParams });

    return true;
  }
}
