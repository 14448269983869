/* import __COLOCATED_TEMPLATE__ from './primary.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ButtonTbPrimarySignature {
  Args: {
    onClick: Function;
    onSuccess?: Function;
    onError?: Function;
    disabled?: boolean;
    isBusy?: boolean;
    testSelector?: string;
    narrowStyle?: boolean;
    small?: boolean;
    type?: string;
    contentClasses?: string;
    classes?: string;
    bgColor?: string;
    text?: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

const ButtonTbPrimaryComponent =
  templateOnlyComponent<ButtonTbPrimarySignature>();

export default ButtonTbPrimaryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Button::Tb::Primary': typeof ButtonTbPrimaryComponent;
    'button/tb/primary': typeof ButtonTbPrimaryComponent;
  }
}
