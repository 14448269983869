/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

import type HeaderMenuItemComponent from './item';

interface Signature {
  Args: {};
  Blocks: {
    default: [
      {
        ui: { menuItem: typeof HeaderMenuItemComponent };
      }
    ];
    end: [
      {
        ui: { menuItem: typeof HeaderMenuItemComponent };
      }
    ];
  };
}

const HeadMenuTrigger = templateOnlyComponent<Signature>();

export default HeadMenuTrigger;
