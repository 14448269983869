/* import __COLOCATED_TEMPLATE__ from './checkout-flow.hbs'; */
import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';

import { inject as service } from '@ember/service';

import type { Link } from 'ember-link';

import type { CheckoutStepName } from 'ticketbooth/services/checkout-flow';
import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';

import type Footer from './checkout-flow/footer';
import type Header from './checkout-flow/header';

interface CheckoutFlowComponentSignature {
  Args: {
    currentStep: CheckoutStepName;
  };
  Blocks: {
    default: [
      {
        ui: {
          header: WithBoundArgs<typeof Header, 'items' | 'activeItem'>;
          footer: WithBoundArgs<typeof Footer, 'previousItem' | 'nextItem'>;
        };
        state: {
          requiresLogin: boolean;
          namesOfProductsRequiringLogin: string;
        };
        actions: {
          previousStep: Link['transitionTo'] | undefined;
          nextStep: Link['transitionTo'] | undefined;
        };
      }
    ];
  };
}

export default class CheckoutFlowComponentComponent extends Component<CheckoutFlowComponentSignature> {
  @service private checkoutFlow!: CheckoutFlowService;

  constructor(
    owner: CheckoutFlowComponentComponent,
    args: CheckoutFlowComponentSignature['Args']
  ) {
    super(owner, args);
    this.checkoutFlow.setReferrer();
  }

  get items() {
    return this.checkoutFlow.availableSteps;
  }
  get activeItem() {
    return this.items.find(item => item.name === this.args.currentStep) ?? null;
  }
  get previousItem() {
    return this.checkoutFlow.prevAvailableStepFor(this.args.currentStep);
  }
  get nextItem() {
    return this.checkoutFlow.nextAvailableStepFor(this.args.currentStep);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CheckoutFlow: typeof CheckoutFlowComponentComponent;
    'checkout-flow': typeof CheckoutFlowComponentComponent;
  }
}
