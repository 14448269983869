import type OrderModel from 'ticketbooth/models/order';
import BaseHppCallbackRoute from 'ticketbooth/routes/hpp-callback';

export default class HppCallbackRoute extends BaseHppCallbackRoute {
  async model(args: { uuid: string }) {
    const opts = await super.model(args);

    if (!opts) return;

    const {
      order: { id: orderId },
      event: { id: eventId }
    } = this.modelFor('event-hub');
    return { ...opts, queryParams: { eventId, orderId } };
  }

  async afterModel(result: Unwrap<HppCallbackRoute['model']>) {
    if (result === undefined) return;
    this.trackPurchase(result);
    this.continue(result);
  }

  confirmCart({ uuid }: { uuid: string }) {
    const { order } = this.modelFor('event-hub');
    return this.cartProvider.cart.confirm(uuid, order.uuid);
  }

  continue({ order }: { order: OrderModel }) {
    const {
      order: { id: orderId },
      event: { id: eventId }
    } = this.modelFor('event-hub');
    this.router.replaceWith('event-hub.order-completed', {
      queryParams: { uid: order.id, orderId, eventId }
    });
  }
}
