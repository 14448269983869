import { hash } from '@ember/helper';

import Button from 'tangram/components/button';
import type { ButtonDefaultBlock } from 'tangram/components/button';
import isDark from 'tangram/helpers/is-dark';

import branding from '../../modifiers/branding';

interface ButtonLinkSignature {
  Args: {
    onClick?: Function;
    disabled?: boolean;
    onSuccess?: Function;
    onError?: Function;
    testSelector?: string;
    isBusy?: string;

    bgColor?: string;
  };
  Blocks: {
    default: [ButtonDefaultBlock];
  };
  Element: HTMLButtonElement;
}

const ButtonLinkComponent: TemplateOnlyComponent<ButtonLinkSignature> = <template>
  {{! @glint-nocheck: not typesafe yet }}
  <Button
    class="disabled:cursor-not-allowed disabled:opacity-30 focus:outline-none underline"
    @onClick={{@onClick}}
    @onSuccess={{@onSuccess}}
    @onError={{@onError}}
    @disabled={{@disabled}}
    @isBusy={{@isBusy}}
    @testSelector={{@testSelector}}
    {{branding "color" "links_color" onMouseHover=(hash luminance=(if (isDark @bgColor) 10 -10))}}
    ...attributes
    as |c|
  >
    {{#if (has-block-params)}}
      {{yield c}}
    {{else}}
      {{!-- @glint-ignore --}}
      <c.ui.content class="truncate">{{yield}}</c.ui.content>
    {{/if}}
  </Button>

</template>

export default ButtonLinkComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Button::Link': typeof ButtonLinkComponent;
    'button/link': typeof ButtonLinkComponent;
  }
}
