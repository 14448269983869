import Model, { belongsTo } from '@ember-data/model';

import type AssetAttachmentModel from './asset-attachment';

export default class VenueLayoutModel extends Model {
  @belongsTo('asset-attachment', { async: false, inverse: null })
  assetAttachment!: AssetAttachmentModel | null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'venue-layout': VenueLayoutModel;
  }
}
