import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type PreloadService from 'ticketbooth/services/preload';

export default class PreloadValueHelper extends Helper {
  @service preload!: PreloadService;

  compute([key]: [string]) {
    return this.preload.getValue(key);
  }
}
