import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type PreloadService from 'ticketbooth/services/preload';

interface Signature {
  Args: {
    Positional: [string];
  };
  Return: string;
}

export default class TranslateHelper extends Helper<Signature> {
  @service() preload!: PreloadService;

  compute([key]: [string]) {
    return this.preload.getValue(`look_and_feel.${key}`);
  }
}
