import { inject as service } from '@ember/service';

import type { SubOrderEntity } from 'ticketbooth/domains/event-hub/sub-order';
import { Entity, createDomainExport } from 'ticketbooth/domains/utils/entity';
import type PreloadService from 'ticketbooth/services/preload';
import { inject as domain } from 'ticketbooth/utils/domains';

export class EventHubDomain extends Entity {
  @domain('event-hub/sub-order') SubOrder!: SubOrderEntity;

  @service preload!: PreloadService;

  preordersEnabled() {
    return this.SubOrder.hasValidContext();
  }

  walletsEnabled() {
    return this.preload.getValue('mobile_wallets_enabled');
  }
}

export default createDomainExport(EventHubDomain);
