/* import __COLOCATED_TEMPLATE__ from './date-description.hbs'; */
import Component from '@glimmer/component';
import isSameDay from 'date-fns/isSameDay';

import type ShowModel from 'ticketbooth/models/show';

interface ShowDateDescriptionSignature {
  Args: {
    show: ShowModel;
  };
  Element: HTMLDivElement;
}

export default class ShowDateDescriptionComponent extends Component<ShowDateDescriptionSignature> {
  get dates() {
    const [from, to] = this.args.show.dates;
    if (isSameDay(from, to)) {
      return [from];
    }
    return [from, to];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::DateDescription': typeof ShowDateDescriptionComponent;
    'show/date-description': typeof ShowDateDescriptionComponent;
  }
}
