/* import __COLOCATED_TEMPLATE__ from './ticket-price-dropdown.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';

import type EventTicketPriceModel from 'ticketbooth/models/event-ticket-price';
import type { AllocatedTicketSelection } from 'ticketbooth/models/seat-assignment';
import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';

import type SelectableTickets from './selectable-tickets';

interface SeatEditorTicketPriceDropdownSignature {
  Args: {
    seatAssignment: SeatAssignmentModel;
    ticket: AllocatedTicketSelection;
    onChange: SelectableTickets['changeTicket'];
  };
  Blocks: {
    default: [unknown, unknown, unknown];
  };
}

export default class SeatEditorTicketPriceDropdownComponent extends Component<SeatEditorTicketPriceDropdownSignature> {
  @action
  changeTicket(
    closeDropdown: Function,
    eventTicketPrice: EventTicketPriceModel
  ) {
    this.args.onChange({
      ...this.args.ticket,
      eventTicketPrice
    });
    closeDropdown();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::TicketPriceDropdown': typeof SeatEditorTicketPriceDropdownComponent;
    'seat-editor/ticket-price-dropdown': typeof SeatEditorTicketPriceDropdownComponent;
  }
}
