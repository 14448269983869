import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type UserAgentService from 'ticketbooth/services/user-agent';

interface Signature {
  Args: {
    Positional: [];
  };
  Return: boolean;
}

export default class IsAppleDeviceHelper extends Helper<Signature> {
  @service private userAgent!: UserAgentService;

  compute() {
    return this.userAgent.isApple;
  }
}
