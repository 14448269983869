import ApplicationSerializer from './application';

export default class CustomerSerializer extends ApplicationSerializer {
  attrs = {
    addressLine1: 'address-line-1',
    addressLine2: 'address-line-2',
    optIn3rdParty: 'opt-in-3rd-party',
    isNullObject: { serialize: false },
    permissionOptions: { serialize: false }
  };
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    customer: CustomerSerializer;
  }
}
