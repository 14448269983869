/* import __COLOCATED_TEMPLATE__ from './voucher-code.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { object, string } from 'yup';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import FormObject from 'tangram/utils/form-object';
import type CartProviderService from 'ticketbooth/services/cart-provider';

interface ConfirmOrderVoucherCodeSignature {
  Args: {};
}

class VoucherPaymentForm extends FormObject<VoucherPaymentForm> {
  @tracked code!: string;
}

export default class ConfirmOrderVoucherCodeComponent extends Component<ConfirmOrderVoucherCodeSignature> {
  @service private cartProvider!: CartProviderService;

  formObject: VoucherPaymentForm;

  schema = object().shape({
    code: string().max(96).required()
  });

  constructor(owner: unknown, args: ConfirmOrderVoucherCodeSignature['Args']) {
    super(owner, args);
    this.formObject = this.createFormObject();
  }

  createFormObject() {
    return new VoucherPaymentForm({ code: '' });
  }

  @action
  onSubmit({ code }: VoucherPaymentForm) {
    return this.cartProvider.cart.addVoucherCode(code);
  }

  @action
  onSuccess() {
    this.formObject = this.createFormObject();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ConfirmOrder::VoucherCode': typeof ConfirmOrderVoucherCodeComponent;
    'confirm-order/voucher-code': typeof ConfirmOrderVoucherCodeComponent;
  }
}
