import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type CartModel from 'ticketbooth/models/cart';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type { Comparable } from 'ticketbooth/utils/cart-api';

export default class GetCartQuantity extends Helper {
  @service() cartProvider!: CartProviderService;

  get cart(): CartModel {
    return this.cartProvider.cart;
  }

  compute([comparable]: [Comparable]): number {
    return this.cart
      .getLineItemGroups(comparable)
      .reduce((acc, { quantity }) => acc + quantity, 0);
  }
}
