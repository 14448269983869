import Model, { attr } from '@ember-data/model';

import { htmlSafeSanitized } from 'tangram/helpers/html-safe-sanitized';

export default class AssetAttachmentModel extends Model {
  @attr('string') mediumUrl!: string;
  @attr('string') largeUrl!: string;
  @attr('string') originalUrl!: string | null;
  @attr('string') showEventsPageUrl!: string;
  @attr('string') private showEventsPage_2x_Url!: string;
  @attr('string') listingPageGridUrl!: string;
  @attr('string') private listingPageGrid_2x_Url!: string;
  @attr('string') altText!: string;

  /**
   * Examples: `'#fff|#000'`, `'#ccc'`, `null`
   */
  @attr('string') dominantColors!: string | null;

  get cssBackgroundStyle() {
    const dominantColors = this.dominantColors ?? '#333';
    const [color1, color2] = dominantColors.split('|');

    return htmlSafeSanitized([
      color2 ? `linear-gradient(${color1}, ${color2})` : `${color1}`
    ]);
  }

  get showEventsPageSrcSet(): string | null {
    return this.showEventsPage_2x_Url
      ? `${this.showEventsPage_2x_Url} 2x`
      : null;
  }
  get listingPageGridSrcSet(): string | null {
    return this.listingPageGrid_2x_Url
      ? `${this.listingPageGrid_2x_Url} 2x`
      : null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'asset-attachment': AssetAttachmentModel;
  }
}
