import type { Store } from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type VenueModel from 'ticketbooth/models/venue';
import { extractIdFromParam } from 'ticketbooth/utils/query-param';

type RouteParams = { venue_id: string };

export default class VenueRoute extends Route<VenueModel> {
  @service store!: Store;

  async model({ venue_id }: RouteParams): Promise<VenueModel> {
    return await this.store.loadRecord('venue', extractIdFromParam(venue_id)!, {
      reload: true
    });
  }
}
