/* import __COLOCATED_TEMPLATE__ from './comment-form.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CheckoutFormCommentFormSignature {
  Args: {};
}

const CheckoutFormCommentFormComponent =
  templateOnlyComponent<CheckoutFormCommentFormSignature>();

export default CheckoutFormCommentFormComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutForm::CommentForm': typeof CheckoutFormCommentFormComponent;
    'checkout-form/comment-form': typeof CheckoutFormCommentFormComponent;
  }
}
