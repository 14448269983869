import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';

/**
 * Seat editor uses ResizeObserver (seat-map.ts)
 *
 * Lazily load via dynamic import + separate bundle if unsupported
 */
async function polyfillResizeObserver() {
  if (!('ResizeObserver' in window)) {
    const { ResizeObserver } = await import('@juggle/resize-observer');
    // @ts-ignore - TODO: missing type in lib.dom.d.ts - remove when upgraded to ts v4
    window.ResizeObserver = ResizeObserver;
  }
}

export function initialize(): void {
  polyfillResizeObserver();

  polyfillCountryFlagEmojis(
    'CountryFlagEmoji',
    'https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill/dist/TwemojiCountryFlags.woff2'
  );
}

export default { initialize };
