import isEqual from 'date-fns/isEqual';
import type { Resource, SingleResourceDocument } from 'ticketoffice-api';

import { InvalidError } from '@ember-data/adapter/error';
import type Model from '@ember-data/model';
import JSONAPISerializer from '@ember-data/serializer/json-api';
import type { Store } from '@ember-data/store';

export default class ApplicationSerializer extends JSONAPISerializer {
  normalize(modelClass: Model, resourceHash: Resource) {
    const normalized = super.normalize(
      modelClass,
      resourceHash
    ) as SingleResourceDocument;

    const { id } = normalized.data;

    // @ts-ignore - types are wrong
    const record = this.store.peekRecord(modelClass.modelName, id);

    if (record && normalized.data.attributes) {
      // @ts-ignore - types are wrong
      modelClass.eachTransformedAttribute(
        (attribute: string, transform: 'date' | 'day') => {
          const currentValue = record[attribute] as Date | null;
          const newValue = normalized.data?.attributes?.[
            attribute
          ] as Date | null;
          if (!['date', 'day'].includes(transform)) {
            return;
          }
          if (!currentValue || !newValue) {
            return;
          }

          if (!isEqual(currentValue, newValue)) {
            return;
          }

          delete normalized.data?.attributes?.[attribute];
        }
      );
    }

    return normalized;
  }

  extractErrors(store: Store, typeClass: Model, payload: any, id: string) {
    if (payload && payload instanceof InvalidError) {
      // @ts-ignore
      payload.originalErrors = payload.errors;
    }
    return super.extractErrors(store, typeClass, payload, id);
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    application: ApplicationSerializer;
  }
}
