import { helper } from '@ember/component/helper';

import BenefitProductModel from 'ticketbooth/models/benefit-product';
import DonationProductModel from 'ticketbooth/models/donation-product';
import FulfillmentProductModel from 'ticketbooth/models/fulfillment-product';
import type { LineItemGroup } from 'ticketbooth/models/line-item';
import { isProductGroup } from 'ticketbooth/models/line-item';

export function isProductGroupType([lineItemGroup, productType]: [
  LineItemGroup,
  undefined | 'donation' | 'benefit' | 'fulfillment'
]) {
  if (!isProductGroup(lineItemGroup)) {
    return false;
  }
  switch (productType) {
    case 'donation':
      return lineItemGroup.product instanceof DonationProductModel;
    case 'benefit':
      return lineItemGroup.product instanceof BenefitProductModel;
    case 'fulfillment':
      return lineItemGroup.product instanceof FulfillmentProductModel;
  }
  return true;
}

export default helper(isProductGroupType);
