/* import __COLOCATED_TEMPLATE__ from './extras.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ExtrasFormExtrasSignature {
  Args: {};
}

const ExtrasFormExtrasComponent =
  templateOnlyComponent<ExtrasFormExtrasSignature>();

export default ExtrasFormExtrasComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExtrasForm::Extras': typeof ExtrasFormExtrasComponent;
    'extras-form/extras': typeof ExtrasFormExtrasComponent;
  }
}
