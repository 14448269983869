/* import __COLOCATED_TEMPLATE__ from './single-event.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ShowEventsListSingleEventSignature {
  Args: {};
  Blocks: {
    default: [];
  };
}

const ShowEventsListSingleEventComponent =
  templateOnlyComponent<ShowEventsListSingleEventSignature>();

export default ShowEventsListSingleEventComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::SingleEvent': typeof ShowEventsListSingleEventComponent;
    'show/events-list/single-event': typeof ShowEventsListSingleEventComponent;
  }
}
