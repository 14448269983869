/* import __COLOCATED_TEMPLATE__ from './zone-select.hbs'; */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type LocaleService from 'ticketbooth/services/locale';

export const ZONE_MAP_OPTION = 'See all zones';

interface SeatedEventZoneSelectSignature {
  Args: {
    event: EventModel;
    selectedMasterAllocationName: string | null;
    onSelect: (masterAllocationName: string) => void;
  };
}

export default class SeatedEventZoneSelectComponent extends Component<SeatedEventZoneSelectSignature> {
  @service private locale!: LocaleService;

  get options() {
    const { sortedMasterAllocations } = this.args.event;

    const soldout = this.locale.translate('common.not_available');

    return [
      ...(this.args.event.hasZoneMap
        ? [{ label: ZONE_MAP_OPTION, value: null }]
        : []),
      ...sortedMasterAllocations.map(masterAllocation => {
        const { name, soldout: disabled } = masterAllocation;
        return {
          label: disabled ? `${name} (${soldout})` : name,
          value: masterAllocation.name,
          disabled
        };
      })
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatedEvent::ZoneSelect': typeof SeatedEventZoneSelectComponent;
    'seated-event/zone-select': typeof SeatedEventZoneSelectComponent;
  }
}
