import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Config } from 'dompurify';

import { assert } from '@ember/debug';
import { on } from '@ember/modifier';
import { action } from '@ember/object';

import { DEFAULT_CONFIG } from 'tangram/helpers/html-safe-sanitized';
import htmlSafeSanitized from 'tangram/helpers/html-safe-sanitized';
import noOp from 'tangram/helpers/no-op';
import isOverflownHelper from 'tangram/modifiers/is-overflown';

import translate from '../helpers/translate';
import ButtonLink from './button/link';

interface ToggleMoreBoxSignature {
  Args: {
    content: string;
    maxHeight?: string;
    textIsClickable?: boolean;
  };
  Element: HTMLDivElement;
}

export default class ToggleMoreBoxComponent extends Component<ToggleMoreBoxSignature> {
  @tracked isOverflown?: boolean;
  @tracked isExpanded: boolean = false;

  @tracked maxHeight: string;

  constructor(owner: unknown, args: ToggleMoreBoxSignature['Args']) {
    super(owner, args);
    this.maxHeight = this.args.maxHeight ?? 'max-h-36';
    assert(
      `@maxHeight must contain a max-height class to correctly calculate the content height`,
      this.maxHeight.includes('max-h-')
    );
  }

  /**
   * Keep `target` attribute in links.
   *
   * See also initializer `configure-dom-purify`
   */
  sanitizeConfig: Config = {
    ...DEFAULT_CONFIG,
    ADD_ATTR: ['target']
  };

  get textIsClickable(): boolean {
    return this.args.textIsClickable ?? true;
  }

  @action
  toggle({ target }: Event & { target?: HTMLElement }) {
    if (target instanceof HTMLAnchorElement) {
      // Do not toggle when opening a link
      return;
    }

    this.isExpanded = !this.isExpanded;
  }

  @action
  handleOverflow(
    contents: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) {
    observer.disconnect();
    this.isOverflown = !contents.every(content => content.isIntersecting);
  }

  <template>
    <div
      {{isOverflownHelper this.handleOverflow}}
      class="overflow-hidden {{unless this.isExpanded this.maxHeight}} {{if this.isOverflown "cursor-pointer" "cursor-default"}}"
      role={{if this.textIsClickable "button" ""}}
      aria-expanded={{this.isExpanded}}
      {{!-- template-lint-disable --}}
      {{on "click" (if this.textIsClickable this.toggle (noOp))}}
      ...attributes
    >
      <div>{{htmlSafeSanitized @content this.sanitizeConfig}}</div>
    </div>

    {{#if this.isOverflown}}
      <ButtonLink class="mt-2" @onClick={{this.toggle}} tabindex="-1" aria-hidden="true">
        {{#if this.isExpanded}}
          {{translate "product.collapse_description"}}
        {{else}}
          {{translate "product.expand_description"}}
        {{/if}}
      </ButtonLink>
    {{/if}}
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ToggleMoreBox: typeof ToggleMoreBoxComponent;
    'toggle-more-box': typeof ToggleMoreBoxComponent;
  }
}
