/* import __COLOCATED_TEMPLATE__ from './order-payment.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

import type OrderModel from 'ticketbooth/models/order';

interface OrderPaymentSignature {
  Args: {
    order: OrderModel;
  };
}

export default class OrderPaymentComponent extends Component<OrderPaymentSignature> {
  @tracked isPaymentFormVisible = false;
  @tracked paymentAmount = 0;

  @action
  displayPaymentForm(amount: number) {
    this.paymentAmount = amount;
    this.isPaymentFormVisible = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrderPayment: typeof OrderPaymentComponent;
    'order-payment': typeof OrderPaymentComponent;
  }
}
