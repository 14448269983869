/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CoverImageSignature {
  Args: {};
  Element: HTMLImageElement;
}

const CoverImageComponent = templateOnlyComponent<CoverImageSignature>();

export default CoverImageComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::Image': typeof CoverImageComponent;
    'cover/image': typeof CoverImageComponent;
  }
}
