import { attr, belongsTo } from '@ember-data/model';

import type BenefitProductModel from './benefit-product';
import PromotionCodeModel from './promotion-code';

export default class BenefitModel extends PromotionCodeModel {
  @attr('string') name!: string;
  @attr('date') expiresOn!: Date | null;
  @attr('number') discountId!: number | null;

  @belongsTo('benefit-product', { async: false, inverse: null })
  benefitProduct!: BenefitProductModel | null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    benefit: BenefitModel;
  }
}
