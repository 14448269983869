/* import __COLOCATED_TEMPLATE__ from './payment-summary.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarCardsPaymentSummarySignature {
  Args: {};
}

const SidebarCardsPaymentSummaryComponent =
  templateOnlyComponent<SidebarCardsPaymentSummarySignature>();

export default SidebarCardsPaymentSummaryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sidebar::Cards::PaymentSummary': typeof SidebarCardsPaymentSummaryComponent;
    'sidebar/cards/payment-summary': typeof SidebarCardsPaymentSummaryComponent;
  }
}
