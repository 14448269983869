import compact from 'lodash-es/compact';

import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type MembershipService from 'ticketbooth/services/membership';

import type EventTicketPriceModel from './event-ticket-price';

export default class TicketAllocationModel extends Model {
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;

  @attr('string') name!: string;
  @attr('number') size!: number;
  @attr('string') hexColor!: string;
  @attr('number', { defaultValue: 0 }) priority!: number;

  @attr('boolean') assignedAllocation!: boolean;

  @hasMany('event-ticket-price', { async: false, inverse: null })
  eventTicketPrices!: SyncHasMany<EventTicketPriceModel>;

  get visibleTicketPrices(): EventTicketPriceModel[] {
    const appliedDiscountIds = compact([
      this.cartProvider.cart.discountId,
      this.membership.member?.activeBenefit?.discountId
    ]);

    return this.eventTicketPrices
      .slice()
      .filter(eventTicketPrice =>
        eventTicketPrice.isVisibleFor(appliedDiscountIds)
      );
  }

  get hasVisibleTicketPrices() {
    return this.visibleTicketPrices.length > 0;
  }

  /**
   * Use `hasMany().ids()` instead of accessing sync relationship as it might
   * have not been loaded in certain cases.
   *
   * Example: On the cart page we don't load all ticket prices for an allocation
   * but only the one of the ticket-line-item).
   */
  hasEventTicketPrice(eventTicketPrice: EventTicketPriceModel) {
    // @ts-ignore - insufficient ember-data types
    return this.hasMany('eventTicketPrices')
      .ids()
      .includes(eventTicketPrice.id);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-allocation': TicketAllocationModel;
  }
}
