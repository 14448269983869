/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CardButtonSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

const CardButtonComponent = templateOnlyComponent<CardButtonSignature>();

export default CardButtonComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Button': typeof CardButtonComponent;
    'card/button': typeof CardButtonComponent;
  }
}
