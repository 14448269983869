import Model, { attr } from '@ember-data/model';

export default class PassModel extends Model {
  @attr('string') applePassUrl!: string;
  @attr('string') googlePassUrl!: string;
  @attr('string') walletName!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    pass: PassModel;
  }
}
