/* import __COLOCATED_TEMPLATE__ from './event-selection.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Interpreter } from 'xstate';
import { Machine, sendParent } from 'xstate';

import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type PreloadService from 'ticketbooth/services/preload';

interface ShowEventsListEventSelectionSignature {
  Args: {
    event: EventModel;
    registerActor: Function;
  };
}

type Schema = {
  states: {
    open: {};
    closed: {};
  };
};

type Events = { type: 'OPEN' } | { type: 'CLOSE' } | { type: 'CLOSED' };

type Context = {};

export type EventButtonMachine = Interpreter<Context, Schema, Events>;

export default class ShowEventsListEventSelectionComponent extends Component<ShowEventsListEventSelectionSignature> {
  @service private preload!: PreloadService;

  private machine!: Interpreter<Context, Schema, Events>;
  @tracked private state: keyof Schema['states'] = 'closed';

  @tracked displayTooltip = false;

  get isOpen() {
    return this.state === 'open';
  }

  constructor(
    owner: ShowEventsListEventSelectionComponent,
    args: ShowEventsListEventSelectionSignature['Args']
  ) {
    super(owner, args);

    const { event } = this.args;

    next(() => {
      this.machine = this.args.registerActor({
        machine: Machine<Context, Schema, Events>({
          initial: 'closed' as const,
          context: {},
          on: {
            CLOSED: 'closed'
          },
          states: {
            open: {
              on: {
                CLOSE: {
                  target: 'closed',
                  actions: [
                    sendParent(() => ({
                      type: 'CLOSE_EVENT',
                      event
                    }))
                  ]
                }
              }
            },
            closed: {
              on: {
                OPEN: {
                  target: 'open',
                  actions: [
                    sendParent(() => ({
                      type: 'OPEN_EVENT',
                      event
                    }))
                  ]
                }
              }
            }
          }
        }),
        event
      }).actor;

      // Keep track of current state
      this.machine.onTransition(({ value }) => {
        const state = value.toString() as keyof Schema['states'];
        if (this.state !== state) {
          this.state = state;
        }
      });
    });
  }

  @action
  toggleEvent() {
    if (this.isOpen) {
      this.machine.send('CLOSE');
    } else {
      this.machine.send('OPEN');
    }
  }

  @action
  showTooltip() {
    if (this.args.event.soldout || this.args.event.salesDisabled) {
      this.displayTooltip = true;
    }
  }

  @action
  hideTooltip() {
    this.displayTooltip = false;
  }

  get displayCrossSellHint() {
    return (
      this.args.event.soldout &&
      this.preload.getValue('event_cross_sell_enabled') === true
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::EventSelection': typeof ShowEventsListEventSelectionComponent;
    'show/events-list/event-selection': typeof ShowEventsListEventSelectionComponent;
  }
}
