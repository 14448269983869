import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type OrderModel from 'ticketbooth/models/order';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type ErrorsService from 'ticketbooth/services/errors';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';

export default class HppCallbackRoute extends Route {
  @service cartProvider!: CartProviderService;
  @service googleAnalytics!: GoogleAnalyticsService;
  @service errors!: ErrorsService;
  @service router!: RouterService;

  async model({ uuid }: { uuid: string }) {
    try {
      const order = await this.confirmCart({ uuid });
      return { order, uuid };
    } catch (error) {
      this.errors.log(error);
    }
  }

  async afterModel(result: Unwrap<HppCallbackRoute['model']>) {
    if (result === undefined) return;
    this.trackPurchase(result);
    this.continue(result);
  }

  continue({ order }: { order: OrderModel }) {
    this.router.replaceWith('order-completed', {
      queryParams: { uid: order.id }
    });
  }

  trackPurchase({ order }: { order: OrderModel }) {
    if (!order) return;
    try {
      this.googleAnalytics.purchase(order);
    } catch (error) {
      // Prevent analytics error from proceeding to order page
      this.errors.log(error);
    }
  }

  confirmCart({ uuid }: { uuid: string }) {
    return this.cartProvider.cart.confirm(uuid);
  }
}
