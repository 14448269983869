/* import __COLOCATED_TEMPLATE__ from './quantity-input.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

interface QuantityInputSignature {
  Args: {
    quantity: number;
    maxQuantity: number;
    displayConfirmButton: boolean;
    onSubmit: (quantity: number) => void;
    onCancel: () => void;
  };
}

export default class QuantityInputComponent extends Component<QuantityInputSignature> {
  @tracked quantity: number = this.args.quantity;

  get isInvalid() {
    return this.quantity > this.args.maxQuantity;
  }

  get isChangedUncomitted() {
    return this.args.quantity !== this.quantity;
  }

  get displayConfirmButton() {
    return this.args.displayConfirmButton || this.isChangedUncomitted;
  }

  @action
  setQuantity({ target }: Event & { target: HTMLInputElement }) {
    this.quantity = Number(target.value);
  }

  @action
  onSumbit() {
    if (!this.isInvalid) {
      this.args.onSubmit(this.quantity);
    }
  }

  @action
  onKeyUp({ key }: KeyboardEvent) {
    if (key === 'Escape') {
      this.args.onCancel();
    } else if (key === 'Enter') {
      this.onSumbit();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QuantityInput: typeof QuantityInputComponent;
    'quantity-input': typeof QuantityInputComponent;
  }
}
