import Modifier from 'ember-modifier';
import { Element } from 'ember-modifier/-private/class/modifier';

interface Args {
  positional: [];
  named: {
    selector?: string;
  };
}

export default class extends Modifier<Args> {
  // @ts-ignore
  get element() {
    // @ts-ignore
    return this[Element];
  }

  didInstall() {
    this.addHighlightStyle();
  }

  didReceiveArguments() {
    this.addHighlightStyle();
  }

  private addHighlightStyle() {
    const selector = this.args.named.selector ?? '*';
    [...this.element.querySelectorAll(selector)].forEach(
      (element: HTMLElement) => {
        element.classList.add('p-1');
        element.style.backgroundColor = '#ecf58e'; // Try yellow (the default used for discounts)
        element.style.color = '#444';
      }
    );
  }
}
