import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';

export default class LoginController extends Controller {
  queryParams = ['path', 'identifier'];

  @tracked path: string | null = null;
  @tracked identifier: string | null = null;
}
