import { format } from 'date-fns';

import { identity } from 'tangram/utils/json-api';
import { updateOperation, createOperation } from 'tangram/utils/operations-api';
import type { ResourceOperations } from 'tangram/utils/operations-api';
import type CartModel from 'ticketbooth/models/cart';
import type MoneyVoucherProductModel from 'ticketbooth/models/money-voucher';
import {
  type ProductPurchaseOptions,
  isProductPurchaseOptions
} from 'ticketbooth/models/product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';

import type { GiftAttributes } from '../../cart';

export interface RequestManager {
  request(req: Record<string, unknown>): Promise<any>;
}

class CartRequestManager implements RequestManager {
  async request(req: { cart: CartModel } & ResourceOperations) {
    const { cart, ...ops } = req;

    const value = await cart.updateLineItems(ops);

    return value;
  }
}

export default class CartRepository {
  requestManager: RequestManager;

  constructor({ requestManager }: { requestManager?: RequestManager } = {}) {
    this.requestManager = requestManager || new CartRequestManager();
  }

  transformDateOrDateString(date: string | Date | null) {
    if (date === null || date === '') {
      return null;
    }

    if (typeof date === 'string') {
      return date;
    }

    return format(date, 'yyyy-MM-dd');
  }

  // will reate a line-item?!
  createGift({
    // this should be resource identifier
    cart,
    // this should be a resource identifier
    gift,
    amount,
    recipientName,
    recipientEmail,
    message,
    date
  }: {
    cart: CartModel;
    gift: MoneyVoucherProductModel | ProductPurchaseOptions;
  } & GiftAttributes) {
    let op;

    if (isProductPurchaseOptions(gift)) {
      const { product, event } = gift;

      op = createOperation({
        type: 'product-line-items',
        attributes: {
          quantity: 1,
          price: amount,
          'event-id': identity(event).id,
          'gift-recipient-name': recipientName,
          'gift-email': recipientEmail,
          'gift-message': message,
          'gift-date': this.transformDateOrDateString(date)
        },
        relationships: {
          product: {
            data: identity(product)
          },
          cart: {
            data: identity(cart)
          }
        }
      });
    } else {
      op = createOperation({
        type: 'product-line-items',
        attributes: {
          quantity: 1,
          price: amount,
          'gift-recipient-name': recipientName,
          'gift-email': recipientEmail,
          'gift-message': message,
          'gift-date': this.transformDateOrDateString(date)
        },
        relationships: {
          product: {
            data: identity(gift)
          },
          cart: {
            data: identity(cart)
          }
        }
      });
    }

    return this.requestManager.request({
      cart,
      ops: [op]
    });
  }

  editGift({
    cart,
    lineItem,
    amount,
    recipientName,
    recipientEmail,
    message,
    date
  }: { cart: CartModel; lineItem: ProductLineItemModel } & GiftAttributes) {
    const op = updateOperation({
      ...identity(lineItem),
      attributes: {
        quantity: 1,
        price: amount,
        'gift-recipient-name': recipientName,
        'gift-email': recipientEmail,
        'gift-message': message,
        'gift-date': this.transformDateOrDateString(date)
      },
      relationships: {
        product: {
          data: identity(lineItem.product)
        },
        cart: {
          data: identity(cart)
        }
      }
    });

    return this.requestManager.request({
      cart,
      ops: [op]
    });
  }
}
