/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type MediaService from 'ember-responsive/services/media';

import type { Locale } from 'tangram/helpers/humanize-locale';
import type LocaleService from 'ticketbooth/services/locale';
import type PreloadService from 'ticketbooth/services/preload';

interface HeaderComponentSignature {
  Args: {};
}

export default class HeaderComponentComponent extends Component<HeaderComponentSignature> {
  @service() locale!: LocaleService;
  @service() media!: MediaService;
  @service() preload!: PreloadService;

  get hasBackgroundImage(): boolean {
    return !!this.preload.getValue('look_and_feel.header_background_image');
  }

  get subdomain(): string {
    return this.preload.getValue('account_name');
  }

  get homeUrl(): string {
    return this.preload.getValue('home_url');
  }

  get displayLocales() {
    return this.media.isLg && this.locale.localesAvailable.length > 1;
  }

  @action
  setLocale(locale: Locale) {
    this.locale.setCurrentLocale(locale);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Header: typeof HeaderComponentComponent;
    header: typeof HeaderComponentComponent;
  }
}
