import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type RouterService from '@ember/routing/router-service';
import { schedule } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

import type TicketboothErrorsService from './errors';
import type IframeService from './iframe';

export default class IframeRouterService extends Service {
  @service private router!: RouterService;
  @service private iframe!: IframeService;
  @service private errors!: TicketboothErrorsService;

  /**
   * During page load we might need to pause the routing. To continue later
   * we need to remember the transition.
   */
  private pausedTransition: Transition | null = null;

  /**
   * Don't intercept route transitions to 'hpp-callback' when true.
   *
   * This prevents the parent from redirecting to the HPP callback page, even
   * if there is a config in the `iframe_paths` setting for `hpp-callback`.
   */
  private hppRedirectDisabled = false;

  private initTransitionDone?: Function;

  pauseRouting() {
    this.router.on('routeWillChange', this.abortTransition);
  }
  continueRouting(): Transition | null {
    // @ts-ignore - insufficient type
    this.router.off('routeWillChange', this.abortTransition);

    return this.pausedTransition;
  }

  @action
  private abortTransition(transition: Transition) {
    if (!transition.isAborted) {
      transition.abort();
    }
    if (transition.targetName) {
      this.pausedTransition = transition;
    }
  }

  isParentPathValid(transition: Transition, parentLocation: string) {
    if (this.hppRedirectDisabled && transition.to.name === 'hpp-callback') {
      return { isValid: true, path: null };
    }

    const hasNoParent = window.top === window;

    const path = this.configuredParentPathFor(transition);
    const hasNoConfiguredPath = path === null;
    const isSamePath = path !== null && this.isSamePath(path, parentLocation);

    return {
      isValid: hasNoParent || hasNoConfiguredPath || isSamePath,
      path
    };
  }

  private configuredParentPathFor(transition: Transition): string | null {
    const { iframePaths } = this.iframe;

    const routeName = transition.targetName;

    const iframePath = iframePaths[routeName];

    if (typeof iframePath === 'string') {
      return iframePath;
    }

    if (typeof iframePath === 'object' && 'dynamic_segment' in iframePath) {
      // TODO Check if such iframe_paths are still used because the mapping
      // exists only for products, which don't use a dynamic segment in LTB/NTB
      this.errors.log(
        new Error(`iframe path with dynamic segment encountered`),
        {
          extra: {
            iframePath,
            iframePaths,
            routeName
          }
        }
      );
      return iframePath.path;
    }

    return null;
  }

  /**
   * Compare only path name without domain etc.
   * Use regex to remove trailing slash
   */
  private isSamePath(iframePath: string, parentLocation: string) {
    return (
      iframePath.replace(/^\/|\/$/g, '') ===
      new URL(parentLocation).pathname.replace(/^\/|\/$/g, '')
    );
  }

  captureInitTransitionDone() {
    const initTransition = new Promise(resolve => {
      this.initTransitionDone = resolve;
    });

    this.router.on('routeDidChange', this.captureInitTransitionDoneHandler);

    return initTransition;
  }

  @action
  private captureInitTransitionDoneHandler(transition: Transition) {
    if (transition.isAborted) {
      // Note: Init transition might be aborted due to redirect, we are instead
      // waiting for the first successful transition to complete
      return;
    }

    // @ts-ignore - insufficient type
    this.router.off('routeDidChange', this.captureInitTransitionDoneHandler);

    schedule('afterRender', () => {
      this.initTransitionDone?.();
    });
  }

  disableParentHppRedirect() {
    this.hppRedirectDisabled = true;
  }
}

declare module '@ember/service' {
  interface Registry {
    'iframe-router': IframeRouterService;
  }
}
