/* import __COLOCATED_TEMPLATE__ from './reset-password-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { object, string } from 'yup';

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { later, cancel } from '@ember/runloop';
import { inject as service } from '@ember/service';

import type EnvironmentService from 'tangram/services/environment';
import FormObject from 'tangram/utils/form-object';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type LocaleService from 'ticketbooth/services/locale';
import type MembershipService from 'ticketbooth/services/membership';
import type PreloadService from 'ticketbooth/services/preload';

class ResetPasswordForm extends FormObject<ResetPasswordForm> {
  @tracked password!: string;
  @tracked passwordConfirmation!: string;
}

interface ResetPasswordFormSignature {
  Args: {
    email: string;
    hash: string;
    redirectToCheckout: boolean;
  };
}

export default class ResetPasswordFormComponent extends Component<ResetPasswordFormSignature> {
  @service private locale!: LocaleService;
  @service private membership!: MembershipService;
  @service private router!: RouterService;
  @service private preload!: PreloadService;
  @service private cartProvider!: CartProviderService;
  @service private environment!: EnvironmentService;

  @tracked passwordReset = false;
  autoRedirectTimer: ReturnType<typeof later> | null = null;

  formObject = new ResetPasswordForm({
    password: '',
    passwordConfirmation: ''
  });
  schema = object().shape({
    password: string().required(
      this.locale.translate('activerecord.errors.messages.blank')
    ),
    passwordConfirmation: string().required(
      this.locale.translate('activerecord.errors.messages.blank')
    )
  });

  get redirectTimeout() {
    return this.environment.isTest ? 1000 : 5000;
  }

  willDestroy() {
    this.cancelAutoRedirectTimer();

    super.willDestroy();
  }

  @action
  async onSubmit({ password, passwordConfirmation }: ResetPasswordForm) {
    await this.membership.resetPassword(
      this.args.email,
      this.args.hash,
      password,
      passwordConfirmation
    );
  }

  @action
  async onSuccess() {
    if (this.args.redirectToCheckout) {
      await this.cartProvider.reload();
    }
    this.passwordReset = true;

    this.autoRedirectTimer = later(this, this.redirect, this.redirectTimeout);
  }

  @action
  async redirect() {
    this.cancelAutoRedirectTimer();

    if (this.args.redirectToCheckout) {
      await this.router.transitionTo('checkout');
    } else {
      const homeUrl = this.preload.getValue('home_url') as string | null;
      if (homeUrl) {
        location.href = homeUrl;
      } else {
        this.router.transitionTo('listings.shows');
      }
    }
  }

  @action
  cancelAutoRedirectTimer() {
    if (this.autoRedirectTimer) {
      cancel(this.autoRedirectTimer);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ResetPasswordForm: typeof ResetPasswordFormComponent;
    'reset-password-form': typeof ResetPasswordFormComponent;
  }
}
