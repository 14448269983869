import LineItemModel, { BookingChargeGroup } from './line-item';

export default class BookingChargeLineItemModel extends LineItemModel {
  get googleAnalyticsSku(): string {
    return 'booking_charge-1';
  }

  get googleAnalyticsName(): string {
    return 'booking-charge';
  }

  get googleAnalyticsCategory(): string {
    return 'charges';
  }

  createGroup(lineItem: BookingChargeLineItemModel): BookingChargeGroup {
    return new BookingChargeGroup(lineItem);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-charge-line-item': BookingChargeLineItemModel;
  }
}
