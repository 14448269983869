/* import __COLOCATED_TEMPLATE__ from './interests-form.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CheckoutFormInterestsFormSignature {
  Args: {};
}

const CheckoutFormInterestsFormComponent =
  templateOnlyComponent<CheckoutFormInterestsFormSignature>();

export default CheckoutFormInterestsFormComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutForm::InterestsForm': typeof CheckoutFormInterestsFormComponent;
    'checkout-form/interests-form': typeof CheckoutFormInterestsFormComponent;
  }
}
