import Component from '@glimmer/component';

import type Store from '@ember-data/store';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type { LoadPaginatedResult } from 'tangram/components/async-pagination';
import type MembershipService from 'ticketbooth/services/membership';
import { INCLUDE_OVERVIEW } from 'ticketbooth/utils/order-api';

import type MemberModel from '../models/member';

interface LoadPaginatedOrders {
  loadOrdersPaginated: LoadPaginatedResult;
  loadRedemptionsPaginated: LoadPaginatedResult;
}

interface Signature {
  Args: {};
  Blocks: {
    default: [
      {
        state: {
          isEnabled: boolean;
          isOptional: boolean;
          isLoggedOut: boolean;
          isLoggedIn: boolean;
          member: MemberModel | null;
        };
        actions: {
          login: MemberProvider['login'];
          logout: MemberProvider['logout'];
          signup: MemberProvider['signup'];
          signupUsingToken: MemberProvider['signupUsingToken'];
          validate: MemberProvider['validate'];
          loadOrdersPaginated: MemberProvider['loadOrdersPaginated'];
          loadRedemptionsPaginated: MemberProvider['loadRedemptionsPaginated'];
        };
      }
    ];
  };
}

export default class MemberProvider
  extends Component<Signature>
  implements LoadPaginatedOrders
{
  @service private membership!: MembershipService;
  @service private store!: Store;

  @action
  async reload() {
    return await this.membership.reload();
  }

  @action
  async login(email: string, password: string, rememberMe: boolean) {
    return await this.membership.login(email, password, rememberMe);
  }

  @action
  async logout() {
    return await this.membership.logout();
  }

  @action
  async signup(
    email: string,
    password: string,
    emailConfirmation?: string,
    passwordConfirmation?: string
  ) {
    return await this.membership.signup(
      email,
      password,
      emailConfirmation,
      passwordConfirmation
    );
  }

  @action
  async signupUsingToken(
    token: string | null,
    email: string,
    password: string,
    emailConfirmation?: string,
    passwordConfirmation?: string
  ) {
    return await this.membership.signup(
      email,
      password,
      emailConfirmation,
      passwordConfirmation,
      null,
      token
    );
  }

  @action
  async validate(email: string) {
    return this.membership.validate(email);
  }

  @action
  async loadOrdersPaginated(offset: number, limit: number) {
    const result = await this.store.query('order', {
      include: INCLUDE_OVERVIEW,
      page: { offset, limit },
      sort: '-created-at'
    });

    return {
      result: result.slice(),
      total: result.meta['records-count']
    };
  }
  @action
  async loadRedemptionsPaginated(offset: number, limit: number) {
    const result = await this.store.query('order', {
      include: INCLUDE_OVERVIEW,
      filter: { redemptions: true },
      page: { offset, limit },
      sort: '-created-at'
    });

    return {
      result: result.slice(),
      total: result.meta['records-count']
    };
  }

  <template>
    {{yield (hash
      state=(hash
        isEnabled=this.membership.isEnabled
        isOptional=this.membership.isOptional
        isLoggedOut=this.membership.isLoggedOut
        isLoggedIn=this.membership.isLoggedIn
        member=this.membership.member
      )
      actions=(hash
        login=this.login
        logout=this.logout
        signup=this.signup
        signupUsingToken=this.signupUsingToken
        validate=this.validate
        loadOrdersPaginated=this.loadOrdersPaginated
        loadRedemptionsPaginated=this.loadRedemptionsPaginated
      )
    )}}
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MembershipProvider: typeof MemberProvider;
    'membership-provider': typeof MemberProvider;
  }
}
