import CartRepository from '../../_shared/repositories/cart';
import NoopView from '../../_shared/views/noop';
import type CartModel from '../../../models/cart';
import type ProductLineItemModel from '../../../models/product-line-item';
import type { GiftAttributes } from '../../cart';

export default class EditGiftUseCase {
  private repository: CartRepository;
  private view: NoopView<CartModel>;

  constructor() {
    this.repository = new CartRepository();
    this.view = new NoopView<CartModel>();
  }

  async execute({
    cart,
    lineItem,
    amount,
    recipientName,
    recipientEmail,
    message,
    date
  }: {
    cart: CartModel;
    lineItem: ProductLineItemModel;
  } & GiftAttributes): Promise<CartModel> {
    const newCart = await this.repository.editGift({
      cart,
      lineItem,
      amount,
      recipientName,
      recipientEmail,
      message,
      date
    });

    return this.view.fromDomain(newCart);
  }
}
