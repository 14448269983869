import Model, { attr, belongsTo } from '@ember-data/model';

import type RecommendableModel from 'ticketbooth/models/recommendable';

export default class RecommendationModel extends Model {
  @attr('number') priority!: number;
  @attr('string') summary!: string;

  @belongsTo('recommendable', {
    polymorphic: true,
    async: false,
    inverse: null
  })
  recommendee!: RecommendableModel;

  @belongsTo('recommendable', {
    polymorphic: true,
    async: false,
    inverse: 'recommendations'
  })
  recommendable!: RecommendableModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    recommendation: RecommendationModel;
  }
}
