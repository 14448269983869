import jsonrepair from 'jsonrepair';
import without from 'lodash-es/without';

import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';

import type ErrorsService from 'ticketbooth/services/errors';

import type IframeService from './iframe';

export default class MessagingService extends Service {
  @service private errors!: ErrorsService;
  @service private iframe!: IframeService;

  private callbacks: Function[] = [];

  private get validOrigins(): string[] {
    return [
      'https://hpp.sandbox.realexpayments.com',
      'https://hpp.realexpayments.com',
      `${window.location.protocol}//${window.location.host}`,
      ...this.iframe.allowedTargetOrigins
    ];
  }

  private isValidOrigin(origin: MessageEvent['origin']) {
    const { validOrigins } = this;

    return validOrigins.some(valid => valid.includes(origin));
  }

  // TODO: After TS 4.0 Upgrade - use MessageEvent generic to pass data type
  @action
  private handleMessage({
    data,
    origin
  }: Omit<MessageEvent, 'data'> & {
    data: string | object | null | undefined;
  }) {
    if (this.isValidOrigin(origin)) {
      const normalized = this.parseMessageData(data);

      if (normalized) {
        this.callbacks.forEach(cb => cb(normalized));
      }
    } else {
      // Skip sentry report as it's triggered many times by browser extensions,
      // ad trackers, 3ds providers, ...
      console.error(`Unknown origin sending message: ${origin}`, data);
    }
  }

  /**
   * Parse different types of event messages
   *
   * window.parent.postMessage({"event":"hppError","message":"Referral by bank","http_code":"500"});
   * window.parent.postMessage('{"event":"hppError","message":"Referral by bank","http_code":"500"}');
   * window.parent.postMessage("Object.create({event:'hppError',message:'Referral by bank',http_code:'500'})");
   */
  private parseMessageData(data: string | object | null | undefined) {
    if (data === null || data === undefined || typeof data === 'object') {
      return data ?? null;
    }
    if (data === 'undefined') {
      return null;
    }

    data = data.trim();

    if (data.startsWith('[iFrameSizer]') || data === '') {
      // Ignore messages from `iframe-resizer` addon; They are not json compliant
      // and would log lots of sentry reports due to parse errors
      return null;
    }

    const isEvalPostMessage =
      data.startsWith('Object.create({') &&
      (data.endsWith('})') || data.endsWith('});'));
    if (isEvalPostMessage) {
      // Convert pojo inside `Object.create({...})` to JSON so we don't have to
      // use unsafe `eval`
      try {
        data = data.endsWith('})')
          ? data.slice('Object.create('.length, ')'.length * -1)
          : data.slice('Object.create('.length, ');'.length * -1);
        return JSON.parse(jsonrepair(data));
      } catch (error) {
        const details = JSON.stringify({ origin, data });
        this.errors.log(new Error(`Could not parse legacy msg: ${details}`), {
          extra: {
            error,
            json: data,
            json_repaired: jsonrepair(data)
          }
        });
      }
    } else {
      try {
        return JSON.parse(data);
      } catch (error) {
        const details = JSON.stringify(data);
        this.errors.log(new Error(`JSON.parse: ${details}`), {
          extra: { origin, data, error }
        });
      }
    }

    return null;
  }

  onMessage(callback: Function) {
    this.callbacks = [...this.callbacks, callback];
  }
  offMessage(callback: Function) {
    this.callbacks = without(this.callbacks, callback);
  }

  constructor() {
    super(...arguments);

    window.addEventListener('message', this.handleMessage);
  }

  willDestroy() {
    window.removeEventListener('message', this.handleMessage);
  }
}
