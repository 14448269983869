import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { debounce, schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';

import type { AsyncFirstPage } from 'tangram/components/async-infinite-pagination';
import type ShowModel from 'ticketbooth/models/show';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';

export default class extends Controller {
  @service private googleAnalytics!: GoogleAnalyticsService;

  // Query Params
  queryParams = ['tags', { scrollIndex: 'i' }];
  @tracked tags = null;
  @tracked scrollIndex = 0;

  // Avoid `ember-route-scroll` to interfere with `i`/`scrollToShow` behavior
  preserveScrollPosition = true;
  PAGE_SIZE = 12;
  get scrollPage() {
    return Math.ceil((this.scrollIndex + 1) / this.PAGE_SIZE);
  }

  private showsInViewportIndexes: Set<number> = new Set();
  private showsInViewportObserver = new IntersectionObserver(
    (entries, _opts) => {
      const { showsInViewportIndexes } = this;
      entries.forEach(entry => {
        const index = Number(entry.target.getAttribute('data-show-card-index'));
        if (entry.isIntersecting) {
          showsInViewportIndexes.add(index);
        } else {
          showsInViewportIndexes.delete(index);
        }
      });
      debounce(this, this.updateScrollIndex, 300);
    },
    {
      root: null,
      threshold: 1
    }
  );

  @action
  onShowRendered(index: number, show: ShowModel, element: HTMLElement) {
    this.trackInViewport(element);
    this.trackShowImpressions(show, index);
  }

  @action
  onShowUnrendered(element: HTMLElement) {
    this.untrackInViewport(element);
  }

  @action
  async loadFirstPage(loadShows: AsyncFirstPage) {
    const { scrollIndex, scrollPage, PAGE_SIZE } = this;

    if (scrollPage > 1) {
      await loadShows(0, scrollPage * PAGE_SIZE);
    } else {
      await loadShows();
    }
    if (scrollIndex > 0) {
      schedule('afterRender', () => this.scrollToShow(scrollIndex));
    }
  }

  private updateScrollIndex() {
    const { showsInViewportIndexes } = this;
    this.scrollIndex =
      showsInViewportIndexes.size > 0 ? Math.min(...showsInViewportIndexes) : 0;
  }
  private scrollToShow(index: number) {
    const selector = `[data-show-card-index="${index}"]`;
    const showCard = document.querySelector(selector) as HTMLElement;
    if (showCard) {
      document
        .getElementById('content-scroll-container')!
        .scrollTo(0, showCard.offsetTop);
    }
  }
  private trackInViewport(element: HTMLElement) {
    this.showsInViewportObserver.observe(element);
  }
  private untrackInViewport(element: HTMLElement) {
    this.showsInViewportObserver.unobserve(element);
  }
  private trackShowImpressions(show: ShowModel, index: number) {
    this.googleAnalytics.viewItem(
      show.toAnalytics({ index, item_list_name: 'Show listings' })
    );
  }
}
