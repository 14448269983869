import type ApplicationInstance from '@ember/application/instance';

import ENV from 'ticketbooth/config/environment';
import type CookieConsentService from 'ticketbooth/services/cookie-consent';
import type PreloadService from 'ticketbooth/services/preload';

export default {
  name: 'cookie-consent',
  after: 'data-layers',

  initialize(appInstance: ApplicationInstance) {
    // We only load CookieControl if it's not in tests
    if (window['CookieControl'] && ENV.environment === 'production') {
      // We only load CookieControl if there is no custom GTM or
      // if the custom GTM is in the last version with cookies consent
      const preload = appInstance.lookup('service:preload') as PreloadService;
      const cookieConsent = appInstance.lookup(
        'service:cookie-consent'
      ) as CookieConsentService;
      if (!preload.getValue('gtm_container_id')) {
        cookieConsent.load();
      } else {
        // wait for the new configuration in the customer GTM container
        setTimeout(() => {
          if (window['ts-show-cookie-consent']) {
            cookieConsent.load();
          }
        }, 5000);
      }
    }
  }
};
