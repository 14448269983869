import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';

export default class extends Controller {
  queryParams = ['tags'];

  @tracked
  tags: string | null = null;
}
