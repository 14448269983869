import Model, { attr } from '@ember-data/model';

import type { ExtrasDefinition } from 'ticketbooth/utils/extras';
import { extractExtras } from 'ticketbooth/utils/extras';

/**
 * Ticket Prices per Event
 *
 * Takes hidden/visible booking charges into account for:
 *   - price
 *   - booking-fee
 */
export default class EventTicketPriceModel extends Model {
  @attr('string') name!: string;
  /**
   * Group Tickets (e.g. 1 Family ticket for 5)
   */
  @attr('number', { defaultValue: 1 }) groupSize!: number;
  @attr('number') minimumTickets!: number;
  @attr('number') price!: number;
  @attr('number') bookingFeeOnline!: number;
  @attr('string') description!: string;
  @attr('boolean') restricted!: boolean;
  @attr() discountIds!: number[];
  @attr() extras!: ExtrasDefinition | {} | null;

  get bookingFee() {
    return this.bookingFeeOnline;
  }

  /*
   * The event-ticket-prices id is made up of the event and ticket-pric id in
   * the format: [event_id]-[ticket_price_id]
   */
  get ticketPriceId(): string {
    const [, ticketPriceId] = this.id.split('-');
    return ticketPriceId;
  }

  get eventId(): string {
    const [eventId] = this.id.split('-');
    return eventId;
  }

  get extrasDefinition() {
    return extractExtras(this.extras);
  }

  get hasExtras() {
    return this.extrasDefinition !== null;
  }

  get hasMinimumTicketsRequirement() {
    return this.minimumTickets > 0;
  }

  /**
   * Some ticket prices are only visible via a discount
   */
  isVisibleFor(appliedDiscountIds: number[]) {
    const { discountIds } = this;
    if (!this.restricted) {
      return true;
    }
    return appliedDiscountIds.some(appliedDiscountId =>
      discountIds.includes(appliedDiscountId)
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'event-ticket-price': EventTicketPriceModel;
  }
}
