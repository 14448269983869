/* import __COLOCATED_TEMPLATE__ from './checkout-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import omit from 'lodash-es/omit';
import type { ObjectSchema, ValidationError } from 'yup';
import { object, string, boolean, ref } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type CurrencyService from 'tangram/services/currency';
import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import FormObject from 'tangram/utils/form-object';
import { applyFormAndSubmit } from 'tangram/utils/form/ember-data';
import type CustomerModel from 'ticketbooth/models/customer';
import type PermissionModel from 'ticketbooth/models/permission';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type ErrorsService from 'ticketbooth/services/errors';
import type LocaleService from 'ticketbooth/services/locale';
import type MembershipService from 'ticketbooth/services/membership';
import type { McInterestModel } from 'ticketbooth/services/preload';
import type PreloadService from 'ticketbooth/services/preload';
import {
  checkPhoneNumber,
  getNormalizedPhoneInfo,
  getPhoneNormalizer,
  getPhoneTemplate
} from 'ticketbooth/utils/phone-normalization';
import type { CallingCodeOnly } from 'ticketbooth/utils/phone-normalization';

interface CheckoutFields {
  firstName: string;
  lastName: string;

  email: string;
  emailConfirmation: string | null;
  phone: string;
  callingCode: string;
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  cityTown: string;
  region: string | null;
  country: string | null;
  countrySearchTerm: string | null;
  agreeTermsConditions: boolean;

  optInSMS: boolean;
  optInEmail: boolean;
  optInMail: boolean;
  optIn3rdParty: boolean;

  cartComment: string;

  permissions: PermissionModel[];
}

class CheckoutForm extends FormObject<
  CheckoutFields & { customer: CustomerModel | null }
> {
  @tracked firstName!: string;
  @tracked lastName!: string;

  @tracked email!: string;
  @tracked emailConfirmation!: string | null;
  @tracked phone!: string;
  @tracked callingCode!: string;
  get callingCodeOnly(): CallingCodeOnly {
    return this.callingCode.split('|')[1] as CallingCodeOnly;
  }
  get phoneTemplate() {
    return getPhoneTemplate(this.callingCodeOnly);
  }
  @tracked addressLine1!: string;
  @tracked addressLine2!: string;
  @tracked postCode!: string;
  @tracked cityTown!: string;
  @tracked region!: string | null;
  @tracked country!: string | null;
  @tracked countrySearchTerm!: string | null;
  @tracked agreeTermsConditions!: boolean;

  @tracked optInSMS!: boolean;
  @tracked optInEmail!: boolean;
  @tracked optInMail!: boolean;
  @tracked optIn3rdParty!: boolean;

  @tracked cartComment!: string;

  @tracked private customer!: CustomerModel | null;

  isSameCustomer(customer: CustomerModel | null) {
    return this.customer === customer;
  }
}

export class PermissionForm extends FormObject<{
  checked: boolean;
  permission: PermissionModel;
}> {
  @tracked checked!: boolean;
  @tracked permission!: PermissionModel;

  get required() {
    return this.permission.required;
  }
  get descriptions() {
    return this.permission.descriptions;
  }
}

export class InterestForm extends FormObject<{
  checked: boolean;
  interest: McInterestModel;
}> {
  @tracked checked!: boolean;
  @tracked interest!: McInterestModel;

  get name() {
    return this.interest.name;
  }
}

interface CheckoutFormSignature {
  Args: {
    onSuccess?: () => void;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class CheckoutFormComponent extends Component<CheckoutFormSignature> {
  @service private store!: Store;
  @service private membership!: MembershipService;
  @service private locale!: LocaleService;
  @service private notifications!: NotificationsService;
  @service private cartProvider!: CartProviderService;
  @service private errors!: ErrorsService;
  @service private preload!: PreloadService;
  @service private currency!: CurrencyService;

  @tracked checkoutForm!: CheckoutForm;
  @tracked checkoutFormSchema!: ObjectSchema<ObjectShape>;
  @tracked permissionForms!: PermissionForm[];
  @tracked permissionFormsSchema!: ObjectSchema<ObjectShape>;
  @tracked interestForms!: InterestForm[];
  @tracked interestFormsSchema!: ObjectSchema<ObjectShape>;

  get customer() {
    return this.cartProvider.cart.cartCustomer;
  }

  get includeEmailFields() {
    return !this.membership.isLoggedIn;
  }

  constructor(owner: unknown, args: CheckoutFormSignature['Args']) {
    super(owner, args);
    this.cartProvider.onCartChange(this.refreshForm);
    this.setupForms();
  }

  willDestroy() {
    super.willDestroy();
    this.cartProvider.offCartChange(this.refreshForm);
  }

  @action
  refreshForm() {
    if (!this.checkoutForm.isSameCustomer(this.customer)) {
      this.setupForms();
    }
  }

  private setupForms() {
    const { customer } = this;
    const { checkoutForm, permissionForms, interestForms } =
      this.createFormObjects(customer);
    const { checkoutFormSchema, permissionFormsSchema, interestFormsSchema } =
      this.createSchemas();
    this.checkoutForm = checkoutForm;
    this.checkoutFormSchema = checkoutFormSchema;
    this.permissionForms = permissionForms;
    this.permissionFormsSchema = permissionFormsSchema;
    this.interestForms = interestForms;
    this.interestFormsSchema = interestFormsSchema;
  }

  private createSchemas() {
    const t = (msg: string) => this.locale.translate(msg);
    const cantBeBlank = t('activerecord.errors.messages.blank');
    const mustBeAccepted = t('activerecord.errors.messages.accepted');
    const doesNotMatchEmail = t(
      'activerecord.errors.messages.doesnt_match_email'
    );

    const schema = {
      firstName: string().max(96),
      lastName: string().max(96),
      email: string().email().max(100),
      emailConfirmation: string()
        .oneOf([ref('email')], doesNotMatchEmail)
        .max(100),
      phone: string()
        .max(96)
        .warn(
          () => t('checkout.invalid_phone_number'),
          function (value) {
            return checkPhoneNumber(value, this.parent.callingCodeOnly);
          }
        ),
      addressLine1: string().max(96),
      addressLine2: string().max(96),
      postCode: string().max(96),
      cityTown: string().max(96),
      region: string().max(96).nullable(),
      country: string()
        .max(96)
        .nullable()
        .test(
          'no-country-selected',
          'Country must be selected from the dropdown',
          country =>
            !isBlank(country) || isBlank(this.checkoutForm.countrySearchTerm)
        ),
      agreeTermsConditions: boolean()
        .required(mustBeAccepted)
        .oneOf([true], mustBeAccepted),
      optInSMS: boolean(),
      optInEmail: boolean(),
      optInMail: boolean(),
      optIn3rdParty: boolean(),
      cartComment: string()
    };

    const { customerFormFieldsForOnline } = this.preload;

    if (customerFormFieldsForOnline['full_name'].required) {
      schema.firstName = schema.firstName.required(cantBeBlank);
      schema.lastName = schema.lastName.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['email'].required) {
      schema.email = schema.email.required(cantBeBlank);
      schema.emailConfirmation = schema.emailConfirmation.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['address_line_1'].required) {
      schema.addressLine1 = schema.addressLine1.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['address_line_2'].required) {
      schema.addressLine2 = schema.addressLine2.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['post_code'].required) {
      schema.postCode = schema.postCode.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['city_town'].required) {
      schema.cityTown = schema.cityTown.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['region'].required) {
      schema.region = schema.region.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['country'].required) {
      schema.country = schema.country.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['phone'].required) {
      schema.phone = schema.phone.required(cantBeBlank);
    }

    return {
      checkoutFormSchema: object().shape(
        this.includeEmailFields
          ? schema
          : omit(schema, ['email', 'emailConfirmation'])
      ),
      permissionFormsSchema: object().shape({
        checked: boolean().test(
          'is-required-check',
          mustBeAccepted,
          function isRequiredPermission(checked) {
            const form = this.parent as PermissionForm;
            return !form.required || checked === true;
          }
        )
      }),
      interestFormsSchema: object().shape({
        checked: boolean()
      })
    };
  }

  private createFormObjects(customer: CustomerModel | null) {
    const { phone, callingCode } = getNormalizedPhoneInfo({
      phone: customer?.phone,
      country: customer?.country,
      currency: this.currency.symbol
    });
    return {
      checkoutForm: new CheckoutForm({
        firstName: customer?.firstName ?? '',
        lastName: customer?.lastName ?? '',

        email: customer?.email ?? '',
        emailConfirmation: customer?.email ?? '',
        phone,
        callingCode,
        addressLine1: customer?.addressLine1 ?? '',
        addressLine2: customer?.addressLine2 ?? '',
        postCode: customer?.postCode ?? '',
        cityTown: customer?.cityTown ?? '',
        region: customer?.region ?? null,
        country: customer?.country ?? null,
        countrySearchTerm: null,
        agreeTermsConditions: customer?.agreeTermsConditions ?? false,

        optInSMS: customer?.optInSMS ?? false,
        optInEmail:
          customer?.optInEmail ??
          this.preload.getValue('customer_opt_auto_select') ??
          false,
        optInMail: customer?.optInMail ?? false,
        optIn3rdParty: customer?.optIn3rdParty ?? false,

        customer: customer,

        cartComment: this.cartProvider.cart.comment ?? '',

        // Keep initial state for `permissions` has-many for rollback
        permissions: customer?.permissions.slice() ?? []
      }),
      permissionForms: this.cartProvider.cart.permissionOptions.map(
        permission => {
          const checked = customer?.permissions.includes(permission) ?? false;
          return new PermissionForm({ checked, permission });
        }
      ),
      interestForms: this.preload.mcInterests.map(
        interest => new InterestForm({ checked: false, interest })
      )
    };
  }

  private applyFormObjects(
    form: CheckoutForm,
    permissionForms: PermissionForm[]
  ): CustomerModel {
    let customer = this.customer;
    const data = Object.assign<Partial<CustomerModel>, Partial<CustomerModel>>(
      {},
      {
        firstName: form.firstName,
        lastName: form.lastName,

        email: form.email,
        emailConfirmation: this.includeEmailFields
          ? form.emailConfirmation
          : form.email,
        phone:
          getPhoneNormalizer(form.callingCodeOnly, form.phone)?.number ?? '',
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        postCode: form.postCode,
        cityTown: form.cityTown,
        region: form.region ?? '',
        country: form.country ?? '',
        agreeTermsConditions: form.agreeTermsConditions,

        optInSMS: form.optInSMS,
        optInEmail: form.optInEmail,
        optInMail: form.optInMail,
        optIn3rdParty: form.optIn3rdParty
      }
    );

    if (customer) {
      Object.assign<CustomerModel, typeof data>(customer, data);
    } else {
      customer = this.store.createRecord('customer', data);
    }

    const permissions = permissionForms
      .filter(form => form.checked)
      .map(form => form.permission);

    customer.permissions.splice(0, customer.permissions.length);
    customer.permissions.push(...permissions);

    return customer;
  }

  @applyFormAndSubmit(function (
    this: CheckoutFormComponent,
    form: CheckoutForm,
    childForms: (PermissionForm | InterestForm)[]
  ) {
    const permissionForms = childForms.filter(
      form => form instanceof PermissionForm
    ) as PermissionForm[];
    return this.applyFormObjects(form, permissionForms);
  })
  async onSubmit(customer: CustomerModel) {
    // Prevent reloading form with new customer at this point
    this.cartProvider.offCartChange(this.refreshForm);

    const { cart } = this.cartProvider;

    return await cart.confirmCustomer({
      customer,
      comment: this.checkoutForm.cartComment,
      interests: this.interestForms
        .filter(form => form.checked)
        .map(form => form.interest)
    });
  }

  @action
  async onSuccess() {
    // Member `name` could be updated
    if (this.membership.isLoggedIn) {
      await this.membership.reload();
    }

    this.setupForms();
    this.args.onSuccess?.();
  }

  @action
  async onError(error: any) {
    const list = getAllUserMessagesOrDefault(error);

    this.errors.log(error);

    return this.notifications.error(list.join('. '));
  }

  @action
  onSubmitInvalid(errors: ValidationError[]) {
    const element = errors.reduce<HTMLElement | null>(
      (element, { path }) =>
        element ?? document.querySelector(`[name="${path}"]`),
      null
    );

    if (element) {
      // Scroll to invalid form field
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      // Scroll to top
      document
        .querySelector('#content-scroll-container')!
        .scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CheckoutForm: typeof CheckoutFormComponent;
    'checkout-form': typeof CheckoutFormComponent;
  }
}
