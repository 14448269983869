/* import __COLOCATED_TEMPLATE__ from './member-profile-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { ObjectSchema } from 'yup';
import { object, string, boolean, ref } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type CurrencyService from 'tangram/services/currency';
import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import FormObject from 'tangram/utils/form-object';
import { applyFormAndSubmit } from 'tangram/utils/form/ember-data';
import type CustomerModel from 'ticketbooth/models/customer';
import type PermissionModel from 'ticketbooth/models/permission';
import type ErrorsService from 'ticketbooth/services/errors';
import type LocaleService from 'ticketbooth/services/locale';
import type MembershipService from 'ticketbooth/services/membership';
import type PreloadService from 'ticketbooth/services/preload';
import {
  checkPhoneNumber,
  getPhoneNormalizer,
  getPhoneTemplate,
  getNormalizedPhoneInfo
} from 'ticketbooth/utils/phone-normalization';
import type { CallingCodeOnly } from 'ticketbooth/utils/phone-normalization';

interface MemberProfileFields {
  // Member details
  currentPassword: string;
  password: string;
  passwordConfirmation: string;

  // Customer details
  firstName: string;
  lastName: string;
  phone: string;
  callingCode: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  cityTown: string;
  region: string | null;
  country: string | null;
  countrySearchTerm: string | null;
  agreeTermsConditions: boolean;

  optInSMS: boolean;
  optInEmail: boolean;
  optInMail: boolean;
  optIn3rdParty: boolean;

  permissions: PermissionModel[];
}

class MemberProfileForm extends FormObject<MemberProfileFields> {
  @tracked currentPassword!: string;
  @tracked password!: string;
  @tracked passwordConfirmation!: string;

  @tracked firstName!: string;
  @tracked lastName!: string;

  @tracked email!: string;
  @tracked phone!: string;
  @tracked callingCode!: string;
  get callingCodeOnly(): CallingCodeOnly {
    return this.callingCode.split('|')[1] as CallingCodeOnly;
  }
  get phoneTemplate() {
    return getPhoneTemplate(this.callingCodeOnly);
  }
  @tracked addressLine1!: string;
  @tracked addressLine2!: string;
  @tracked postCode!: string;
  @tracked cityTown!: string;
  @tracked region!: string | null;
  @tracked country!: string | null;
  @tracked countrySearchTerm!: string | null;
  @tracked agreeTermsConditions!: boolean;

  @tracked optInSMS!: boolean;
  @tracked optInEmail!: boolean;
  @tracked optInMail!: boolean;
  @tracked optIn3rdParty!: boolean;
}

interface PermissionFields {
  checked: boolean;
  permission: PermissionModel;
}

export class PermissionForm extends FormObject<PermissionFields> {
  @tracked checked!: boolean;

  @tracked permission!: PermissionModel;

  get required() {
    return this.permission.required;
  }
  get descriptions() {
    return this.permission.descriptions;
  }
}

interface MemberProfileFormSignature {
  Args: {};
}

export default class MemberProfileFormComponent extends Component<MemberProfileFormSignature> {
  @service private membership!: MembershipService;
  @service private locale!: LocaleService;
  @service private notifications!: NotificationsService;
  @service private errors!: ErrorsService;
  @service private preload!: PreloadService;
  @service private router!: RouterService;
  @service private currency!: CurrencyService;

  @tracked profileForm: MemberProfileForm;
  @tracked profileFormSchema: ObjectSchema<ObjectShape>;
  @tracked permissionForms: PermissionForm[];
  @tracked permissionFormsSchema: ObjectSchema<ObjectShape>;
  @tracked forgotPasswordEmailSent = false;

  get member() {
    return this.membership.member!;
  }
  get customer() {
    return this.member.customer;
  }

  constructor(owner: unknown, args: MemberProfileFormSignature['Args']) {
    super(owner, args);

    const { profileForm, permissionForms } = this.createFormObjects(
      this.customer
    );
    const { profileFormSchema, permissionFormsSchema } = this.createSchemas();
    this.profileForm = profileForm;
    this.profileFormSchema = profileFormSchema;
    this.permissionForms = permissionForms;
    this.permissionFormsSchema = permissionFormsSchema;
  }

  private createSchemas() {
    const t = (msg: string) => this.locale.translate(msg);
    const cantBeBlank = t('activerecord.errors.messages.blank');
    const mustBeAccepted = t('activerecord.errors.messages.accepted');
    const doesNotMatchConfirmation = t(
      'activerecord.errors.messages.confirmation'
    );

    const schema = {
      // Password can be changed optionally
      currentPassword: string().max(96).required(cantBeBlank),
      password: string().max(96),
      passwordConfirmation: string()
        .oneOf([ref('password')], doesNotMatchConfirmation)
        .max(96),

      firstName: string().max(96),
      lastName: string().max(96),
      email: string().email().required(cantBeBlank).max(100),
      phone: string()
        .max(96)
        .warn(
          () => t('checkout.invalid_phone_number'),
          function (value) {
            return checkPhoneNumber(value, this.parent.callingCodeOnly);
          }
        ),
      addressLine1: string().max(96),
      addressLine2: string().max(96),
      postCode: string().max(96),
      cityTown: string().max(96),
      region: string().max(96).nullable(),
      country: string()
        .max(96)
        .nullable()
        .test(
          'no-country-selected',
          'Country must be selected from the dropdown',
          country =>
            !isBlank(country) || isBlank(this.profileForm.countrySearchTerm)
        ),
      agreeTermsConditions: boolean()
        .required(mustBeAccepted)
        .oneOf([true], mustBeAccepted),
      optInSMS: boolean(),
      optInEmail: boolean(),
      optInMail: boolean(),
      optIn3rdParty: boolean()
    };

    const { customerFormFieldsForOnline } = this.preload;
    if (customerFormFieldsForOnline['full_name'].required) {
      schema.firstName = schema.firstName.required(cantBeBlank);
      schema.lastName = schema.lastName.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['address_line_1'].required) {
      schema.addressLine1 = schema.addressLine1.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['address_line_2'].required) {
      schema.addressLine2 = schema.addressLine2.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['post_code'].required) {
      schema.postCode = schema.postCode.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['city_town'].required) {
      schema.cityTown = schema.cityTown.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['region'].required) {
      schema.region = schema.region.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['country'].required) {
      schema.country = schema.country.required(cantBeBlank);
    }
    if (customerFormFieldsForOnline['phone'].required) {
      schema.phone = schema.phone.required(cantBeBlank);
    }

    return {
      profileFormSchema: object().shape(schema),
      permissionFormsSchema: object().shape({
        // Required permissions can be optional in profile page
        checked: boolean()
      })
    };
  }

  private createFormObjects(customer: CustomerModel) {
    const { phone, callingCode } = getNormalizedPhoneInfo({
      phone: customer?.phone,
      country: customer?.country,
      currency: this.currency.symbol
    });
    return {
      profileForm: new MemberProfileForm({
        currentPassword: '',
        password: '',
        passwordConfirmation: '',

        firstName: customer.firstName,
        lastName: customer.lastName,

        email: customer.email,
        phone,
        callingCode,
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        postCode: customer.postCode,
        cityTown: customer.cityTown,
        region: customer.region,
        country: customer.country,
        countrySearchTerm: null,
        agreeTermsConditions: customer.agreeTermsConditions,

        optInSMS: customer.optInSMS,
        optInEmail: customer.optInEmail,
        optInMail: customer.optInMail,
        optIn3rdParty: customer.optIn3rdParty,

        // Keep initial state for `permissions` has-many for rollback
        permissions: customer.permissions.slice()
      }),
      permissionForms: customer.permissionOptions.map(permission => {
        const checked = customer?.permissions.includes(permission) ?? false;
        return new PermissionForm({ checked, permission });
      })
    };
  }

  private applyFormObjects(
    profileForm: MemberProfileForm,
    permissionForms: PermissionForm[]
  ): CustomerModel {
    const { customer } = this;

    Object.assign<CustomerModel, Partial<CustomerModel>>(customer, {
      firstName: profileForm.firstName,
      lastName: profileForm.lastName,

      email: profileForm.email,
      phone:
        getPhoneNormalizer(profileForm.callingCodeOnly, profileForm.phone)
          ?.number ?? '',
      addressLine1: profileForm.addressLine1,
      addressLine2: profileForm.addressLine2,
      postCode: profileForm.postCode,
      cityTown: profileForm.cityTown,
      region: profileForm.region ?? '',
      country: profileForm.country ?? '',
      agreeTermsConditions: profileForm.agreeTermsConditions,

      optInSMS: profileForm.optInSMS,
      optInEmail: profileForm.optInEmail,
      optInMail: profileForm.optInMail,
      optIn3rdParty: profileForm.optIn3rdParty
    });

    const permissions = permissionForms
      .filter(form => form.checked)
      .map(form => form.permission);

    customer.permissions.splice(0, customer.permissions.length);
    customer.permissions.push(...permissions);

    return customer;
  }

  @applyFormAndSubmit(function (
    this: MemberProfileFormComponent,
    form: MemberProfileForm,
    childForms: PermissionForm[]
  ) {
    return this.applyFormObjects(form, childForms);
  })
  async onSubmit(customer: CustomerModel) {
    return await this.member.updateProfile({
      member: {
        currentPassword: this.profileForm.currentPassword,
        password: this.profileForm.password,
        passwordConfirmation: this.profileForm.passwordConfirmation
      },

      customer
    });
  }

  @action
  async onSuccess() {
    this.notifications.success(
      this.locale.translate('member.updated_successfully')
    );
    this.router.transitionTo('listings.shows');
  }

  @action
  async onError(error: any) {
    const list = getAllUserMessagesOrDefault(error);

    this.errors.log(error);

    return this.notifications.error(list.join('. '));
  }

  @action
  async forgotPassword() {
    await this.membership.forgotPassword(this.customer.email, false);
    this.forgotPasswordEmailSent = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MemberProfileForm: typeof MemberProfileFormComponent;
    'member-profile-form': typeof MemberProfileFormComponent;
  }
}
