/* import __COLOCATED_TEMPLATE__ from './ticket-price-overview.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SeatedEventTicketPriceOverviewSignature {
  Args: {};
}

const SeatedEventTicketPriceOverviewComponent =
  templateOnlyComponent<SeatedEventTicketPriceOverviewSignature>();

export default SeatedEventTicketPriceOverviewComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatedEvent::TicketPriceOverview': typeof SeatedEventTicketPriceOverviewComponent;
    'seated-event/ticket-price-overview': typeof SeatedEventTicketPriceOverviewComponent;
  }
}
