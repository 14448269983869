import Countries from 'i18n-iso-countries';
import type { CountryCode, PhoneNumber } from 'libphonenumber-js';
import {
  getExampleNumber,
  AsYouType,
  parsePhoneNumber
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

export type CallingCode = `${CountryCode}|${CallingCodeOnly}`;
export type CallingCodeOnly = `+${number}`;

const IE_CALLING_CODE: CallingCode = 'IE|+353';
const GB_CALLING_CODE: CallingCode = 'GB|+44';

export function getNormalizedPhoneInfo({
  phone,
  country,
  currency
}: {
  phone?: string | null;
  country?: string;
  currency: string;
}) {
  let phoneInput = phone ?? '';
  let callingCode = currency === '€' ? IE_CALLING_CODE : GB_CALLING_CODE;
  if (phoneInput) {
    const phoneNumber = getPhoneNormalizer(
      callingCode.split('|')[1] as CallingCodeOnly,
      phoneInput,
      country
    );
    phoneInput = phoneNumber?.nationalNumber ?? '';
    const plausibleCountry = getMorePlausibleCountry(phoneNumber);
    callingCode = phoneNumber?.countryCallingCode
      ? `${plausibleCountry!}|+${phoneNumber.countryCallingCode as unknown as number}`
      : callingCode;
  }
  return { phone: phoneInput, callingCode };
}

export function checkPhoneNumber(
  phone: string | null | undefined,
  prefix?: string
) {
  if (phone) {
    try {
      const phoneNumber = parsePhoneNumber(`${prefix}${phone}`);
      return !!phoneNumber && phoneNumber?.isPossible();
    } catch (e) {
      return false;
    }
  }
  return true;
}

export function getMorePlausibleCountry(phoneNumber?: PhoneNumber) {
  return (
    phoneNumber &&
    (phoneNumber.country ??
      phoneNumber.getPossibleCountries()?.[0] ??
      // @ts-ignore
      phoneNumber.getMetadata().country_calling_codes[
        phoneNumber.countryCallingCode
      ]?.[0])
  );
}

export function getPhoneTemplate(callingCode: CallingCodeOnly | undefined) {
  const phone = getPhoneNormalizer(callingCode, '12345678');
  const country = getMorePlausibleCountry(phone);
  if (country) {
    return getExampleNumber(country, examples)?.formatNational();
  }
  return '1234 567890';
}

export function getCountryCodeFromCountry(country?: string) {
  let countryCode;
  if (country) {
    countryCode = Countries.getAlpha2Code(country, 'en') as CountryCode;
  }
  return countryCode;
}
export function getPhoneNormalizer(
  callingCode: CallingCodeOnly | undefined,
  phone: string,
  defaultCountry?: string
) {
  try {
    if (callingCode) {
      const phoneFormatter = new AsYouType({
        defaultCallingCode: callingCode.replace('+', ''),
        defaultCountry: getCountryCodeFromCountry(defaultCountry)
      });
      phoneFormatter.input(phone.replace(/^00/, '+'));
      return phoneFormatter.getNumber();
    }
  } catch (e) {
    console.error(e);
  }
  return undefined;
}
