/* import __COLOCATED_TEMPLATE__ from './preloader.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CoverPreloaderSignature {
  Args: {};
}

const CoverPreloaderComponent =
  templateOnlyComponent<CoverPreloaderSignature>();

export default CoverPreloaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::Preloader': typeof CoverPreloaderComponent;
    'cover/preloader': typeof CoverPreloaderComponent;
  }
}
