/* import __COLOCATED_TEMPLATE__ from './cta.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface HeaderCartButtonCtaSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const HeaderCartButtonCtaComponent =
  templateOnlyComponent<HeaderCartButtonCtaSignature>();

export default HeaderCartButtonCtaComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Header::Cart::Button::Cta': typeof HeaderCartButtonCtaComponent;
    'header/cart/button/cta': typeof HeaderCartButtonCtaComponent;
  }
}
