import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

import type DataLayersService from './data-layers';

const CUSTOM_COOKIES = ['consent_settings'];
const API_KEY = '0de354718dfe978572f6a8ba8fa7162e591d384d';
const PRODUCT = 'PRO_MULTISITE';
const GEO_INFO_URL = `https://apikeys.civiccomputing.com/c/v?d=${encodeURIComponent(document.location.hostname)}&p=CookieControl%20Multi-Site&v=9&k=${API_KEY}&format=json`;

export default class CookieConsentService extends Service {
  @service private dataLayers!: DataLayersService;
  @tracked hasConsent = false;

  get config() {
    const dataLayers = this.dataLayers;
    return {
      apiKey: API_KEY,
      product: PRODUCT,
      necessaryCookies: [
        '_ticketsolve_session',
        '_RequestVerificationToken',
        'ASP.NET_SessionId',
        'cookie_notice_website_accepted',
        'session-id',
        'locale',
        ...CUSTOM_COOKIES
      ],
      text: {
        thirdPartyTitle: 'Warning: Some cookies require your attention',
        thirdPartyDescription:
          'Consent for some third party cookies can not be automatically revoked. Please follow the link below if you want to opt out of them.'
      },
      optionalCookies: [
        {
          name: 'analytics',
          label: 'Analytics Cookies',
          description:
            'Analytical cookies help us to improve our website by collecting and reporting information on its usage.',
          cookies: [
            '_ga',
            '_ga*',
            '_gid',
            '_gat',
            '__utma',
            '__utmt',
            '__utmb',
            '__utmc',
            '__utmz',
            '__utmv'
          ],
          onAccept() {
            window['TS_ANALYTICS_consent_opt_in'] = true;
            dataLayers.event('ts.consent_update.analytics');
            if (window['FS']) {
              window['FS'].consent(true);
            }
          },
          onRevoke() {
            window['TS_ANALYTICS_consent_opt_in'] = false;
            dataLayers.event('ts.consent_update.analytics');
            if (window['FS']) {
              window['FS'].consent(false);
            }
          }
        },
        {
          name: 'marketing',
          label: 'Marketing Cookies',
          description:
            'We use marketing cookies to help us improve the relevancy of advertising campaigns you receive.',
          cookies: ['trackers', 'fs_uid'],
          onAccept() {
            window['TS_MARKETING_consent_opt_in'] = true;
            dataLayers.event('ts.consent_update.marketing');
          },
          onRevoke() {
            window['TS_MARKETING_consent_opt_in'] = false;
            dataLayers.event('ts.consent_update.marketing');
          }
        }
      ],

      position: 'LEFT',
      theme: 'LIGHT',
      rejectButton: true,
      branding: {
        fontColor: '#333',
        backgroundColor: '#f4f4f4',
        fontSizeTitle: '1.2em',
        fontSizeIntro: '1em',
        fontSizeHeaders: '1em',
        fontSize: '1em',
        toggleText: '#fff',
        toggleColor: '#222',
        toggleBackground: '#444',
        buttonIcon: null,
        buttonIconWidth: '64px',
        buttonIconHeight: '64px',
        removeIcon: true,
        removeAbout: false,
        acceptBackground: '#222',
        acceptText: '#f4f4f4'
      },
      excludedCountries: ['all'],
      initialState: 'notify',
      consentCookieExpiry: 365,
      closeOnGlobalChange: false,
      removeAbout: true
    };
  }
  @action
  load() {
    window['CookieControl'].load(this.config);
    fetch(GEO_INFO_URL)
      .then((response: any) => response.json())
      .then((response: any) => {
        try {
          this.hasConsent = response.geo.gdpr_applies;
        } catch (e) {
          this.hasConsent = false;
        }
      });
  }

  @action
  open() {
    if (this.hasConsent) {
      window['CookieControl'].open();
    }
  }
}
