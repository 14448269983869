/* import __COLOCATED_TEMPLATE__ from './zone-map.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

import type EventModel from 'ticketbooth/models/event';
import type MasterAllocationModel from 'ticketbooth/models/master-allocation';

interface SeatedEventZoneMapSignature {
  Args: {
    event: EventModel;
    onSelect: (masterAllocation: MasterAllocationModel) => void;
  };
}

/**
 * Display venue layout image + render clickable hotspots on top of it
 *
 * Note: The image is likely not rendered in full width/height on mobile,
 * so we use svg `viewBox` and use the original hotspot coords.
 */
export default class SeatedEventZoneMapComponent extends Component<SeatedEventZoneMapSignature> {
  @tracked imageLoaded = false;
  @tracked imageWidth?: number;
  @tracked imageHeight?: number;

  @action
  onImageLoad({ target: image }: { target: HTMLImageElement }) {
    this.imageWidth = image.naturalWidth;
    this.imageHeight = image.naturalHeight;
    this.imageLoaded = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatedEvent::ZoneMap': typeof SeatedEventZoneMapComponent;
    'seated-event/zone-map': typeof SeatedEventZoneMapComponent;
  }
}
