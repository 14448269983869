import { tracked } from '@glimmer/tracking';

import type { Store } from '@ember-data/store';
import { inject as service } from '@ember/service';

import { Entity, createDomainExport } from 'ticketbooth/domains/utils/entity';
import type ContextModel from 'ticketbooth/models/context';
import type EventModel from 'ticketbooth/models/event';
import type OrderModel from 'ticketbooth/models/order';
import { INCLUDE_EVENT_HUB } from 'ticketbooth/utils/order-api';

export class SubOrderEntity extends Entity {
  @service store!: Store;

  @tracked order?: OrderModel;
  @tracked event?: EventModel;
  @tracked contexts?: ContextModel[];

  async load(
    orderId: string,
    eventId: string,
    opts = {
      includeContext: true
    }
  ) {
    const [order, event, contexts] = await Promise.all([
      this.loadOrder(orderId),
      this.loadEvent(eventId),
      opts.includeContext ? this.loadContext(orderId, eventId) : undefined
    ]);

    this.order = order;
    this.event = event;
    this.contexts = contexts;

    return { order, event, contexts };
  }

  async loadContext(orderId: string, eventId: string) {
    const contexts = await this.store.query('context', {
      ref: `[${eventId}:${orderId}]`
    });
    return contexts.slice();
  }

  loadOrder(orderId: string) {
    return this.store.findRecord('order', orderId, {
      adapterOptions: {
        filter: {
          'effective-product-line-items.qr-code-compatible': true
        }
      },
      include: INCLUDE_EVENT_HUB
    });
  }

  loadEvent(eventId: string) {
    return this.store.findRecord('event', eventId, {
      include: 'venue,show.default-attachment'
    });
  }

  hasValidContext(event = this.event, contexts = this.contexts) {
    if (!event || !contexts) return false;
    return contexts.length > 0;
  }

  isValid(order = this.order, event = this.event) {
    if (!order || !event) return false;
    return !!order.effectiveTicketLineItems.find(
      tli => tli.event.id === event.id
    );
  }
}

export default createDomainExport(SubOrderEntity);
