/* import __COLOCATED_TEMPLATE__ from './extras-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import isEqual from 'lodash-es/isEqual';
import type { ObjectSchema, StringSchema } from 'yup';
import { object, string } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import FormObject from 'tangram/utils/form-object';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import TicketLineItemModel from 'ticketbooth/models/ticket-line-item';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type TicketboothErrorsService from 'ticketbooth/services/errors';
import type { ExtrasDefinition } from 'ticketbooth/utils/extras';

import type CartProviderComponent from './cart-provider';

interface ExtrasGroup {
  extras: ExtrasDefinition;
  lineItems: (TicketLineItemModel | ProductLineItemModel)[];
}

class ExtrasFormObject extends FormObject<ExtrasFormObject> {
  @tracked extrasGroups!: {
    header: string;

    schema: ObjectSchema<ObjectShape>;
    forms: ExtrasPerLineItemFormObject[];
  }[];
}

class ExtrasPerLineItemFormObject
  extends FormObject<FormProperties>
  implements FormProperties
{
  @tracked $extras!: ExtrasDefinition;
  @tracked $lineItem!: TicketLineItemModel | ProductLineItemModel;

  // Tracked form properties for extras fields are added at runtime
  // See `FormProperties` and `FormPropertyKey`
}

type FormPropertyKey = ExtrasDefinition['fields'][number]['name'];
interface FormProperties {
  [k: string]: any;
}

interface ExtrasFormSignature {
  Args: {
    onSubmit: CartProviderComponent['changeExtras'];
    onSuccess?: () => void;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class ExtrasFormComponent extends Component<ExtrasFormSignature> {
  @service private cartProvider!: CartProviderService;
  @service private errors!: TicketboothErrorsService;

  @tracked formObject: ExtrasFormObject;

  get extrasLineItems() {
    return [
      ...this.cartProvider.cart.ticketLineItems
        .slice()
        .filter(({ eventTicketPrice }) => !!eventTicketPrice.extrasDefinition),
      ...this.cartProvider.cart.productLineItems
        .slice()
        .filter(({ product }) => !!product.extrasDefinition)
    ];
  }

  /**
   * Group by extras definition
   */
  get extrasGroups(): ExtrasGroup[] {
    return this.extrasLineItems.reduce((groups: ExtrasGroup[], lineItem) => {
      const extras =
        lineItem instanceof TicketLineItemModel
          ? lineItem.eventTicketPrice.extrasDefinition
          : lineItem.product.extrasDefinition;
      if (extras) {
        const group = groups.find(group => isEqual(group.extras, extras));
        if (group) {
          group.lineItems.push(lineItem);
        } else {
          groups.push({ extras, lineItems: [lineItem] });
        }
      }
      return groups;
    }, []);
  }

  constructor(owner: unknown, args: ExtrasFormSignature['Args']) {
    super(owner, args);

    this.formObject = this.createFormObject();
  }

  private createFormObject(): ExtrasFormObject {
    return new ExtrasFormObject({
      extrasGroups: this.createExtrasFormGroups()
    });
  }

  private createExtrasFormGroups() {
    return this.extrasGroups.map(({ extras, lineItems }) => {
      return {
        header: extras.metadata.information,
        schema: this.createChildFormSchema(extras),
        forms: lineItems.map(lineItem =>
          this.createChildFormObject(extras, lineItem)
        )
      };
    });
  }

  private createChildFormSchema(extras: ExtrasDefinition) {
    const allRequired =
      !extras.metadata.optional || extras.metadata.optional === 'no';

    return object().shape(
      extras.fields.reduce(
        (schema: { [k in FormPropertyKey]: StringSchema }, field) => {
          schema[field.name] = allRequired ? string().required() : string();
          return schema;
        },
        {}
      )
    );
  }

  private createChildFormObject(
    extras: ExtrasDefinition,
    lineItem: TicketLineItemModel | ProductLineItemModel
  ): ExtrasPerLineItemFormObject {
    // Take init values from TicketLineItemModel#extras
    const formProperties = extras.fields.reduce(
      (props: FormProperties, field) => ({
        ...props,
        [field.name]:
          lineItem.extras?.find(l => l.name === field.name)?.value ?? ''
      }),
      {}
    );

    if ('$lineItem' in formProperties || '$extras' in formProperties) {
      this.errors.log(
        new Error('Extras found with `$lineItem` or `$extras property'),
        {
          extra: {
            extras_definition: JSON.stringify(extras)
          }
        }
      );
    }

    const formObject = new ExtrasPerLineItemFormObject({
      $lineItem: lineItem,
      $extras: extras,
      ...formProperties
    });

    extras.fields.forEach(field => {
      // Add @tracked getter/setter
      Object.defineProperty(
        formObject,
        field.name,
        // @ts-ignore - invalid types
        tracked(formObject, field.name, {
          configurable: true,
          enumerable: true,
          writable: true,
          initializer: () => formProperties[field.name]
        })
      );
    });

    return formObject;
  }

  @action
  onSubmit(_form: ExtrasFormObject, extraForms: ExtrasPerLineItemFormObject[]) {
    return this.args.onSubmit(
      extraForms.map(form => ({
        lineItem: form.$lineItem,
        extras: form.$extras.fields.map(field => {
          return {
            name: field.name as FormPropertyKey,
            value: (form as FormProperties)[field.name]
          };
        })
      }))
    );
  }

  @action
  async onSuccess() {
    this.formObject = this.createFormObject();
    this.args.onSuccess?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ExtrasForm: typeof ExtrasFormComponent;
    'extras-form': typeof ExtrasFormComponent;
  }
}
