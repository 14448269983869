/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ShowHeaderDetailsSignature {
  Args: {};
  Element: HTMLDivElement;
}

const ShowHeaderDetailsComponent =
  templateOnlyComponent<ShowHeaderDetailsSignature>();

export default ShowHeaderDetailsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ShowHeader::Details': typeof ShowHeaderDetailsComponent;
    'show-header/details': typeof ShowHeaderDetailsComponent;
  }
}
