import { captureException } from '@sentry/ember';

import { TRACKERS_PARAMS } from './trackers-params';

export const QUEUEIT_TOKENS = [
  'TSVq',
  'TSLVq',
  'TSLVp',
  'TSLVts',
  'TSLVc',
  'TSLVrt',
  'TSLVh',
  'TSLVe'
];

export default {
  name: 'clean-query-string-params',
  after: 'set-trackers-query-string-params',

  initialize() {
    try {
      const params = new URLSearchParams(location.search);

      [...QUEUEIT_TOKENS, ...TRACKERS_PARAMS].forEach(param => {
        if (params.has(param)) {
          params.delete(param);
        }
      });

      const search = [...params].length === 0 ? '' : `?${params}`;

      if (location.search !== search) {
        history.replaceState(null, '', `${location.pathname}${search}`);
      }
    } catch (e) {
      captureException(e);
    }
  }
};
