/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import Component from '@glimmer/component';

import type Model from '@ember-data/model';
import { inject as service } from '@ember/service';

import type ProductModel from 'ticketbooth/models/product';
import type RecommendationModel from 'ticketbooth/models/recommendation';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';

interface RecommendationsListCardsBaseSignature {
  Args: {
    recommendation: RecommendationModel;
    showCoverPhoto?: boolean;
    showSummary?: boolean;
  };
  Blocks: {
    'date-description': [];
    footer: [];
  };
}

export default class RecommendationsListCardsBaseComponent extends Component<RecommendationsListCardsBaseSignature> {
  @service googleAnalytics!: GoogleAnalyticsService;

  get showCoverPhoto() {
    const showCoverPhoto = this.args.showCoverPhoto ?? true;
    const product = this.args.recommendation.recommendee as ProductModel;
    return showCoverPhoto && product.defaultAttachment;
  }

  get route() {
    const modelType = (
      this.args.recommendation.recommendee.constructor as typeof Model
    ).modelName;
    switch (modelType) {
      case 'show':
        return 'listings.show';
      case 'donation-product':
        return 'listings.products.donation';
      case 'benefit-product':
        return 'listings.products.benefit';
      default:
        return 'listings.products.regular';
    }
  }

  get model() {
    if (this.route === 'listings.show') {
      return this.args.recommendation.recommendee.id;
    }
    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RecommendationsList::Cards::Base': typeof RecommendationsListCardsBaseComponent;
    'recommendations-list/cards/base': typeof RecommendationsListCardsBaseComponent;
  }
}
