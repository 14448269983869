import DOMPurify from 'dompurify';

export default {
  name: 'configure-dom-purify',

  initialize() {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if (node instanceof HTMLAnchorElement && 'target' in node) {
        // https://web.dev/external-anchors-use-rel-noopener/
        node.setAttribute('rel', 'noopener');
      }
    });
  }
};
