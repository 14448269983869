/* import __COLOCATED_TEMPLATE__ from './ga-event.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type AnalyticsModel from 'ticketbooth/models/analytics-model';
import type CartModel from 'ticketbooth/models/cart';
import type ProductModel from 'ticketbooth/models/product';
import type { GAItem } from 'ticketbooth/services/google-analytics';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';

type Event =
  | 'view_cart'
  | 'add_shipping_info'
  | 'begin_checkout'
  | 'view_item'
  | 'view_item_list';

type GaArgs = CartModel | GAItem | GAItem[] | ProductModel[] | AnalyticsModel;

type EventMap = {
  [key in Event]: (_: GaArgs) => void;
};

interface GaEventSignature {
  Args: {
    event: Event;
    data?: GaArgs;
    extraData?: object;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class GaEventComponent extends Component<GaEventSignature> {
  @service googleAnalytics!: GoogleAnalyticsService;

  EVENTS: EventMap = {
    view_cart: data => this.googleAnalytics.viewCart(data as CartModel),
    view_item: (data: AnalyticsModel) =>
      this.googleAnalytics.viewItem(data.toAnalytics()),
    view_item_list: (data: ProductModel[]) => {
      const items = data.map((d, index) =>
        d.toAnalytics({ index, price: d.price })
      );
      this.googleAnalytics.viewItemList(items, this.args.extraData);
    },
    begin_checkout: data =>
      this.googleAnalytics.beginCheckout(data as CartModel),
    add_shipping_info: data =>
      this.googleAnalytics.addShippingInfo(data as CartModel)
  };

  @action
  logEvent(data?: GaArgs | Element) {
    if (data && !(data instanceof Element)) {
      return this.EVENTS[this.args.event](data);
    }

    if (this.args.data) {
      return this.EVENTS[this.args.event](this.args.data);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GaEvent: typeof GaEventComponent;
    'ga-event': typeof GaEventComponent;
  }
}
