import { hash } from '@ember/helper';

import NeutralButton from '../components/button/tb/neutral';
import CardButton from '../components/card/button';
import lookAndFeel from '../helpers/look-and-feel';
import branding from '../modifiers/branding';

interface CardSignature {
  Args: {};
  Blocks: {
    default: [
      {
        ui: {
          button: typeof CardButton;
          cta: typeof NeutralButton;
        };
      }
    ];
  };
  Element: HTMLElement;
}

const CardComponent: TemplateOnlyComponent<CardSignature> = <template>
  {{! @glint-nocheck: not typesafe yet }}
  <article
    class="p-2 my-2 rounded-sm"
    {{branding "backgroundColor" "card_bg_color"}}
    {{branding "color" "card_fg_color"}}
    ...attributes
  >
    {{yield (hash
      ui=(hash
        button=(component CardButton bgColor=(lookAndFeel "card_bg_color"))
        cta=(component NeutralButton bgColor=(lookAndFeel "card_bg_color"))
      )
    )}}
  </article>
</template>

export default CardComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Card: typeof CardComponent;
    card: typeof CardComponent;
  }
}
