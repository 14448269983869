/* import __COLOCATED_TEMPLATE__ from './show-header.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

import type AssetAttachmentModel from 'ticketbooth/models/asset-attachment';

interface CoverShowHeaderSignature {
  Args: {
    cover?: boolean;
    attachment: AssetAttachmentModel;
  };
}

const CoverShowHeaderComponent =
  templateOnlyComponent<CoverShowHeaderSignature>();

export default CoverShowHeaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::ShowHeader': typeof CoverShowHeaderComponent;
    'cover/show-header': typeof CoverShowHeaderComponent;
  }
}
