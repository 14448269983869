/* import __COLOCATED_TEMPLATE__ from './donation-product.hbs'; */
import Component from '@glimmer/component';

import type DonationProductModel from 'ticketbooth/models/donation-product';

interface Signature {
  Args: {
    product: DonationProductModel;
    list?: string;
    idx?: number;
  };
  Blocks: {
    form: [];
  };
  Element: HTMLDivElement;
}

export default class DonationProductsList extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProductsList::DonationProduct': typeof DonationProductsList;
  }
}
