import Model, { attr } from '@ember-data/model';

import type { SnippetType } from 'ticketbooth/lib/locales';

export default class PermissionModel extends Model {
  @attr('string') name!: string;
  @attr('boolean') required!: boolean;
  @attr('plain-array') descriptions!: SnippetType[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    permission: PermissionModel;
  }
}
