import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import Modifier from 'ember-modifier';
import { Element } from 'ember-modifier/-private/class/modifier';

import type IframeService from 'ticketbooth/services/iframe';
import type IframePositionService from 'ticketbooth/services/iframe-position';

interface Args {
  positional: [];
  named: {};
}

export default class extends Modifier<Args> {
  @service private iframe!: IframeService;
  @service private iframePosition!: IframePositionService;

  // @ts-ignore
  get element() {
    // @ts-ignore
    return this[Element];
  }

  didInstall() {
    if (!this.iframe.isActive) {
      return;
    }
    this.updateElementHeight();
    this.iframePosition.onPositionChange(this.updateElementHeight);
  }

  willDestroy(): void {
    if (!this.iframe.isActive) {
      return;
    }
    this.iframePosition.offPositionChange(this.updateElementHeight);
  }

  @action
  private updateElementHeight() {
    const { parentWindowViewport } = this.iframePosition;
    const height = `${Math.round(parentWindowViewport * 0.66)}px`;

    if (this.element.style.height !== height) {
      this.element.style.height = height;
    }
  }
}
