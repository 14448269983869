import { attr } from '@ember-data/model';

import ProductModel from './product';

export default class MoneyVoucherProductModel extends ProductModel {
  @attr('boolean') allowPriceOverride!: boolean;

  override get isFutureGiftable() {
    return this.giftable;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'money-voucher': MoneyVoucherProductModel;
  }
}
