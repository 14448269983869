/* import __COLOCATED_TEMPLATE__ from './year-select.hbs'; */
import Component from '@glimmer/component';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import startOfYear from 'date-fns/startOfYear';
import range from 'lodash-es/range';

import { action } from '@ember/object';

interface CalendarYearSelectSignature {
  Args: {
    minDate: Date;
    maxDate: Date;
    selectedDate: Date;
    selectDate(date: Date): Promise<void>;
  };
}

export default class CalendarYearSelectComponent extends Component<CalendarYearSelectSignature> {
  get years(): { date: Date; serialized: string }[] {
    const { minDate, maxDate } = this.args;
    const differenceInYears = maxDate.getFullYear() - minDate.getFullYear();
    const start = startOfYear(minDate);

    return range(differenceInYears + 1).map(num => {
      let date = addYears(start, num);

      if (isBefore(date, minDate)) {
        date = minDate;
      } else if (isAfter(date, maxDate)) {
        date = maxDate;
      }

      return { date, serialized: format(date, 'yyyy') };
    });
  }

  get selectedSerialized(): string {
    return format(this.args.selectedDate, 'yyyy');
  }

  @action
  nativeSelect(event: Event & { target: HTMLSelectElement }) {
    this.args.selectDate(
      this.years.find(year => year.serialized === event.target.value)!.date
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calendar::YearSelect': typeof CalendarYearSelectComponent;
    'calendar/year-select': typeof CalendarYearSelectComponent;
  }
}
