// Ticketbooth -> IframeHandling
export type SetupMessage = { event: 'ticketboothInit' };

export type CookieStatusMessage = { cookieStatus: '' };

export type ScrollMessage =
  | { event: 'scrollToIframe' }
  | { event: 'scrollBy'; x: number; y: number }
  | { event: 'startScrollCapture' }
  | { event: 'endScrollCapture' };

export type RedirectMessage = {
  event: 'newDestinationUrl';
  destinationUrl: string;
};

export type UpdateMemberMessage = {
  event: 'memberStatus';
  loggedIn: string;
  name: string;
};

export type UpdateCartMessage = {
  event: 'updateCart';
  itemCount: string;
  result: string;
};

export type PostMessage =
  | SetupMessage
  | ScrollMessage
  | RedirectMessage
  | UpdateMemberMessage
  | UpdateCartMessage
  | CookieStatusMessage;

export function isSetupMessage(message: PostMessage): message is SetupMessage {
  return 'event' in message && message.event === 'ticketboothInit';
}
