/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';
import type { SafeString } from '@ember/template/-private/handlebars';

interface CheckoutHeaderSignature {
  Args: {
    title: SafeString;
  };
  Element: HTMLSpanElement;
}

const CheckoutHeaderComponent =
  templateOnlyComponent<CheckoutHeaderSignature>();

export default CheckoutHeaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checkout::Header': typeof CheckoutHeaderComponent;
    'checkout/header': typeof CheckoutHeaderComponent;
  }
}
