/* import __COLOCATED_TEMPLATE__ from './forgot-password-form.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { object, string } from 'yup';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import FormObject from 'tangram/utils/form-object';
import type LocaleService from 'ticketbooth/services/locale';
import type MembershipService from 'ticketbooth/services/membership';

class ForgotPasswordForm extends FormObject<ForgotPasswordForm> {
  @tracked email!: string;
}

interface ForgotPasswordFormSignature {
  Args: {
    goBackLink: { transitionTo: Function };
    isCheckoutPage: boolean;
  };
}

export default class ForgotPasswordFormComponent extends Component<ForgotPasswordFormSignature> {
  @service private locale!: LocaleService;
  @service private membership!: MembershipService;

  @tracked emailSent = false;

  formObject = new ForgotPasswordForm({ email: '' });
  schema = object().shape({
    email: string().required(
      this.locale.translate('activerecord.errors.messages.blank')
    )
  });

  @action
  async onSubmit({ email }: ForgotPasswordForm) {
    await this.membership.forgotPassword(email, this.args.isCheckoutPage);
  }

  @action
  async onSuccess() {
    this.emailSent = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ForgotPasswordForm: typeof ForgotPasswordFormComponent;
    'forgot-password-form': typeof ForgotPasswordFormComponent;
  }
}
