/* import __COLOCATED_TEMPLATE__ from './create-gift.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format, formatISO } from 'date-fns';
import type { ObjectSchema } from 'yup';
import { number, object, string } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import FormObject from 'tangram/utils/form-object';
import type MoneyVoucherProductModel from 'ticketbooth/models/money-voucher';
import type ProductModel from 'ticketbooth/models/product';
import {
  isProductPurchaseOptions,
  type ProductPurchaseOptions
} from 'ticketbooth/models/product';
import ProductLineItemModel from 'ticketbooth/models/product-line-item';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type ErrorsService from 'ticketbooth/services/errors';
import type LocaleService from 'ticketbooth/services/locale';

import type { CartDomain } from '../../domains/cart';
import type CartModel from '../../models/cart';
import { inject as domain } from '../../utils/domains';

class CreateGiftForm extends FormObject<CreateGiftForm> {
  @tracked recipientName!: string;
  @tracked recipientEmail!: string;
  @tracked amount!: number;
  @tracked message!: string;
  @tracked date!: string | null;
}

interface FormComponent<F, R> {
  formObject: F;

  schema: ObjectSchema<ObjectShape>;

  createFormObject(): F;

  onSubmit(formObject: F): Promise<R>;
}

interface Args {
  gift:
    | MoneyVoucherProductModel
    | ProductLineItemModel
    | ProductPurchaseOptions;
  isEditing?: boolean;
}

export default class ModalsCreateGift
  extends Component<Args>
  implements FormComponent<CreateGiftForm, CartModel>
{
  @service private cartProvider!: CartProviderService;
  @service private locale!: LocaleService;
  @service private notifications!: NotificationsService;
  @service private errors!: ErrorsService;

  @tracked formObject: CreateGiftForm;
  @tracked contactsSupported: boolean = false;

  schema: ObjectSchema<ObjectShape>;

  get isEditing() {
    const { gift } = this.args;

    return gift instanceof ProductLineItemModel;
  }

  // we need to calculate this so that we cannot select a date in the past
  get minDate() {
    return formatISO(new Date(), { representation: 'date' });
  }

  @domain('cart') cartDomain!: CartDomain;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.formObject = this.createFormObject();
    this.schema = this.createSchema();
    this.contactsSupported = 'contacts' in navigator;
  }

  createFormObject() {
    const { gift } = this.args;

    if (this.isEditing) {
      const {
        giftRecipientName: recipientName,
        giftEmail,
        price,
        giftMessage,
        giftDate
      } = gift as ProductLineItemModel;

      return new CreateGiftForm({
        recipientName: recipientName || '',
        recipientEmail: giftEmail || '',
        amount: price,
        message: giftMessage || '',
        date: giftDate ? format(giftDate, 'yyyy-MM-dd') : null
      });
    } else {
      const _gift = gift as ProductModel | ProductPurchaseOptions;

      let price;

      if (isProductPurchaseOptions(_gift)) {
        price = _gift.product.price;
      } else {
        price = _gift.price;
      }

      return new CreateGiftForm({
        recipientName: '',
        recipientEmail: '',
        amount: price,
        message: '',
        date: null
      });
    }
  }

  createSchema() {
    const t = (msg: string) => this.locale.translate(msg);

    return object().shape({
      recipientName: string().required(
        t('gifting.form.validations.name.required')
      ),
      recipientEmail: string()
        .email(t('checkout.please_enter_valid_email'))
        .required(t('gifting.form.validations.email.required')),
      amount: number()
        .required(t('gifting.form.validations.amount.required'))
        .moreThan(0),
      date: string()
        .transform(function (value) {
          // cast `null` to a string so we can validate required
          if (value === null) {
            return '';
          }

          return value;
        })
        .required(t('gifting.form.validations.date.required')),
      message: string().nullable()
    });
  }

  @action onSubmit(formObject: CreateGiftForm) {
    // on submit needs to be different, when we edit
    const { gift } = this.args;
    const { cartProvider, isEditing } = this;
    const { cart } = cartProvider;
    const { recipientName, recipientEmail, amount, message, date } = formObject;

    if (isEditing) {
      return this.cartDomain.editGift({
        cart,
        gift: gift as ProductLineItemModel,
        recipientName,
        recipientEmail,
        amount,
        message,
        date
      });
    } else {
      return this.cartDomain.createGift({
        cart,
        gift: gift as MoneyVoucherProductModel | ProductPurchaseOptions,
        recipientName,
        recipientEmail,
        amount,
        message,
        date
      });
    }
  }

  @action onError(errors: any) {
    const list = getAllUserMessagesOrDefault(errors[0], {
      defaultMsg: this.locale.translate('errors.default')
    });

    this.notifications.error(list.join('. '));

    this.errors.log(errors);
  }

  @action async selectContact(changeFn: Function) {
    const properties: string[] = [];
    //@ts-ignore
    const supportedProperties = await navigator.contacts.getProperties();
    if (supportedProperties.includes('name')) {
      properties.push('name');
    }
    if (supportedProperties.includes('email')) {
      properties.push('email');
    }
    try {
      //@ts-ignore
      const contacts = await navigator.contacts.select(properties);
      if (contacts.length) {
        const contact = contacts[0];
        if (supportedProperties.includes('name')) {
          changeFn('recipientName', contact.name);
        }
        if (supportedProperties.includes('email')) {
          changeFn('recipientEmail', contact.email[0]);
        }
      }
    } catch (e: any) {
      this.notifications.error(e);
    }
  }
}
