import differenceInMinutes from 'date-fns/differenceInMinutes';
import endOfMonth from 'date-fns/endOfMonth';
import formatISO from 'date-fns/formatISO';
import startOfMonth from 'date-fns/startOfMonth';

import type { Store } from '@ember-data/store';
import Service, { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type ShowModel from 'ticketbooth/models/show';

/**
 * Manage calendar events data
 *
 * Currently only supports loading events per month.
 *
 * Cache data for short period to allow faster calendar navigation
 */
export default class EventCalendarService extends Service {
  @service private store!: Store;

  private cache: {
    [showAndMonth: string]: { date: Date; events: EventModel[] };
  } = {};

  private cacheKey(show: ShowModel, from: Date, to: Date) {
    return `${show.id}_${formatISO(from, {
      representation: 'date'
    })}_${formatISO(to, { representation: 'date' })}`;
  }

  private getCache(key: string) {
    const cache = this.cache[key];

    if (cache && differenceInMinutes(new Date(), cache.date) > 5) {
      // Clear after 5 mins
      return null;
    }

    return cache;
  }

  private setCache(key: string, events: EventModel[]) {
    this.cache[key] = {
      date: new Date(),
      events
    };
  }

  private async loadEventsFor(show: ShowModel, from: Date, to: Date) {
    const cache = this.getCache(this.cacheKey(show, from, to));

    if (cache) {
      return cache.events;
    }

    const events = (
      await this.store.query('event', {
        filter: {
          show: show.id,
          q: {
            from_date: formatISO(from, { representation: 'date' }),
            to_date: formatISO(to, { representation: 'date' })
          }
        },
        include: 'show',
        fields: {
          events:
            'show,day,time-of-day,soldout,waiting-list-enabled,online-booking-cutoff',
          shows: 'id'
        },
        page: {
          limit: 10000,
          offset: 0
        }
      })
    ).slice();

    this.setCache(this.cacheKey(show, from, to), events);

    return events;
  }

  async loadEventsOfMonth(show: ShowModel, month: Date) {
    const from = startOfMonth(month);
    const to = endOfMonth(month);
    return await this.loadEventsFor(show, from, to);
  }
}
