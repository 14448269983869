/* import __COLOCATED_TEMPLATE__ from './tooltip.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { cancel, later } from '@ember/runloop';

import { htmlSafeSanitized } from 'tangram/helpers/html-safe-sanitized';
import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';

interface SeatEditorTooltipSignature {
  Args: {
    seatAssignment?: SeatAssignmentModel;
  };
}

/**
 * Tooltip
 *
 * Keep tooltip visible when moving over the seats until a 100ms timeout
 */
export default class SeatEditorTooltipComponent extends Component<SeatEditorTooltipSignature> {
  private clearHoverTimer: ReturnType<typeof later> | null = null;

  @tracked seatAssignment: SeatAssignmentModel | null = null;

  get colorStyle() {
    const { seatAssignment } = this;
    if (!seatAssignment) {
      return null;
    }
    return htmlSafeSanitized([`background-color: ${seatAssignment.color}`]);
  }

  willDestroy(): void {
    this.cancelTimer();
    super.willDestroy();
  }

  @action
  onSeatAssignmentChanged() {
    const { seatAssignment } = this.args;

    this.cancelTimer();

    if (seatAssignment) {
      this.seatAssignment = seatAssignment;
    } else {
      this.clearHoverTimer = later(this, this.clear, 100);
    }
  }

  private clear() {
    this.seatAssignment = null;
  }

  private cancelTimer() {
    if (this.clearHoverTimer) {
      cancel(this.clearHoverTimer);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::Tooltip': typeof SeatEditorTooltipComponent;
    'seat-editor/tooltip': typeof SeatEditorTooltipComponent;
  }
}
