/* import __COLOCATED_TEMPLATE__ from './selectable-tickets.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import uniq from 'lodash-es/uniq';
import without from 'lodash-es/without';

import { action } from '@ember/object';

import type EventTicketPriceModel from 'ticketbooth/models/event-ticket-price';
import type { AllocatedTicketSelection } from 'ticketbooth/models/seat-assignment';
import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';

interface SeatEditorSelectableTicketsSignature {
  Args: {
    preselect?: AllocatedTicketSelection[];
  };
  Blocks: {
    default: [unknown];
  };
}

export default class SeatEditorSelectableTicketsComponent extends Component<SeatEditorSelectableTicketsSignature> {
  @tracked tickets: AllocatedTicketSelection[] = this.args.preselect ?? [];

  get isConfirmed() {
    return this.tickets.length > 0;
  }

  get hasInsufficientGroupTickets() {
    const { tickets } = this;
    const groupTicketPrices = uniq(
      tickets
        .filter(({ eventTicketPrice: { groupSize } }) => groupSize > 1)
        .map(({ eventTicketPrice }) => eventTicketPrice)
    );
    return groupTicketPrices.some(eventTicketPrice => {
      const selected = tickets.filter(
        ticket => ticket.eventTicketPrice === eventTicketPrice
      );
      return selected.length < eventTicketPrice.groupSize;
    });
  }

  @action
  setTickets(tickets: AllocatedTicketSelection[]) {
    this.tickets = tickets;
  }

  @action
  confirmTickets(selectedSeats: SeatAssignmentModel[]) {
    this.tickets = selectedSeats.map(
      seatAssignment => seatAssignment.defaultTicketSelection
    );
  }

  /**
   * Add `defaultTicketSelection` for new seats only
   *
   * Do not update when selection is not confirmed yset
   */
  @action
  updateTickets(selectedSeats: SeatAssignmentModel[]) {
    if (!this.isConfirmed || selectedSeats.length === 0) {
      return;
    }

    const { tickets } = this;

    this.tickets = selectedSeats.map(seatAssignment => {
      const selected = tickets.find(
        selected => selected.seatAssignment === seatAssignment
      );
      return selected ?? seatAssignment.defaultTicketSelection;
    });
  }

  @action
  clearTickets() {
    if (this.tickets.length > 0) {
      this.tickets = [];
    }
  }

  @action
  changeTicket(ticket: AllocatedTicketSelection) {
    const current = this.findTicket(ticket.seatAssignment);
    const { tickets } = this;
    const { groupSize } = ticket.eventTicketPrice;
    if (!current) {
      return;
    }

    const autoSelectGroup = groupSize > 1 && tickets.length === groupSize;

    if (autoSelectGroup) {
      this.tickets = tickets.map(({ seatAssignment }) => ({
        seatAssignment,
        eventTicketPrice: ticket.eventTicketPrice
      }));
    } else {
      this.tickets = [...without(tickets, current), ticket];
    }
  }

  @action
  findTicket(seatAssignment: SeatAssignmentModel) {
    return this.tickets.find(
      ticket => ticket.seatAssignment === seatAssignment
    );
  }

  @action
  isTicketPriceSelectable(ticketPrice: EventTicketPriceModel) {
    const notEnoughTickets =
      ticketPrice.groupSize > 1 && this.tickets.length < ticketPrice.groupSize;

    return !notEnoughTickets;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::SelectableTickets': typeof SeatEditorSelectableTicketsComponent;
    'seat-editor/selectable-tickets': typeof SeatEditorSelectableTicketsComponent;
  }
}
