/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CheckoutFlowItemSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

const CheckoutFlowItemComponent =
  templateOnlyComponent<CheckoutFlowItemSignature>();

export default CheckoutFlowItemComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutFlow::Item': typeof CheckoutFlowItemComponent;
    'checkout-flow/item': typeof CheckoutFlowItemComponent;
  }
}
