import { attr } from '@ember-data/model';
import Model from '@ember-data/model';

export default class PaymentModel extends Model {
  @attr('number') amount!: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    payment: PaymentModel;
  }
}
