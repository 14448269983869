/* import __COLOCATED_TEMPLATE__ from './shows-provider.hbs'; */
import Component from '@glimmer/component';

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type { LoadPaginatedResult } from 'tangram/components/async-pagination';
import type EventCategoryModel from 'ticketbooth/models/event-category';
import type VenueModel from 'ticketbooth/models/venue';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type MembershipService from 'ticketbooth/services/membership';
import type PreloadService from 'ticketbooth/services/preload';
import { includeForShows } from 'ticketbooth/utils/show-api';

interface LoadPaginatedShows {
  loadByTags: LoadPaginatedResult;
  loadByVenuePaginated: LoadPaginatedResult;
  loadByEventCategoryPaginated: LoadPaginatedResult;
}

interface Args {
  tags?: string;
  venue?: VenueModel;
  eventCategory?: EventCategoryModel;
}

/**
 * https://stackoverflow.com/a/8831937/4576905
 */
function hash(str: string): number {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}

export default class ShowsProviderComponent
  extends Component<Args>
  implements LoadPaginatedShows
{
  @service private store!: Store;
  @service private preload!: PreloadService;
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;

  private get queryOptions() {
    return includeForShows({
      includeCategory: this.preload.lookAndFeel('show_category'),
      includeCompany: this.preload.lookAndFeel('show_company'),
      includeVenues: this.preload.lookAndFeel('show_venues')
    });
  }

  private get cacheKey() {
    const cartDiscountId = this.cartProvider.cart.discountId ?? '';
    const memberDiscountId =
      this.membership.member?.activeBenefit?.discountId ?? '';
    return hash(`${cartDiscountId}-${memberDiscountId}`);
  }

  @action
  async loadByTags(offset: number, limit: number) {
    const { tags } = this.args;
    const { include, fields } = this.queryOptions;

    const result = await this.store.query('show', {
      include,
      fields,
      page: { offset, limit },
      meta: { cache: this.cacheKey },
      ...(tags ? { filter: { tags } } : {})
    });

    return {
      result: result.slice(),
      total: result.meta['records-count']
    };
  }

  @action
  async loadByVenuePaginated(offset: number, limit: number) {
    const { include, fields } = this.queryOptions;

    const result = await this.store.query('show', {
      include,
      fields,
      page: { offset, limit },
      meta: { cache: this.cacheKey },
      filter: {
        venue: this.args.venue!.id
      }
    });

    return {
      result: result.slice(),
      total: result.meta['records-count']
    };
  }

  @action
  async loadByEventCategoryPaginated(offset: number, limit: number) {
    const { include, fields } = this.queryOptions;

    const result = await this.store.query('show', {
      include,
      fields,
      page: { offset, limit },
      meta: { cache: this.cacheKey },
      filter: {
        'event-category': this.args.eventCategory!.id
      }
    });

    return {
      result: result.slice(),
      total: result.meta['records-count']
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ShowsProvider: typeof ShowsProviderComponent;
    'shows-provider': typeof ShowsProviderComponent;
  }
}
