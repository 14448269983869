/* import __COLOCATED_TEMPLATE__ from './recommendation-card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CoverRecommendationCardSignature {
  Args: {};
}

const CoverRecommendationCardComponent =
  templateOnlyComponent<CoverRecommendationCardSignature>();

export default CoverRecommendationCardComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::RecommendationCard': typeof CoverRecommendationCardComponent;
    'cover/recommendation-card': typeof CoverRecommendationCardComponent;
  }
}
