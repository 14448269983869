import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';
import type MembershipService from 'ticketbooth/services/membership';

export default class extends Route {
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;
  @service private checkoutFlow!: CheckoutFlowService;
  @service private router!: RouterService;

  async beforeModel(transition: Transition) {
    if (this.cartProvider.isEmpty) {
      this.router.replaceWith('cart');
    } else if (!this.checkoutFlow.loginWallStep.available) {
      this.checkoutFlow.goToNextStep('login_wall');
    } else if (this.membership.isLoggedIn) {
      this.checkoutFlow.skipStep('login_wall', transition.from?.name);
    } else if (this.membership.isDisabled) {
      this.checkoutFlow.skipStep('login_wall', transition.from?.name);
    }
  }

  activate() {
    this.membership.onLogin(this.reloadCartAndGoToNextStep);
  }

  deactivate() {
    this.membership.offLogin(this.reloadCartAndGoToNextStep);
  }

  @action
  private async reloadCartAndGoToNextStep() {
    await this.cartProvider.reload();

    this.checkoutFlow.goToNextStep('login_wall');
  }
}
