/* import __COLOCATED_TEMPLATE__ from './grid-management.hbs'; */
import Component from '@glimmer/component';
import { Machine, assign, send } from 'xstate';

import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { useMachine } from 'ember-statecharts';

import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import { matchesState } from 'tangram/utils/statecharts';
import type EventModel from 'ticketbooth/models/event';
import type Grid from 'ticketbooth/models/grid';
import type GridModel from 'ticketbooth/models/grid';
import type MasterAllocation from 'ticketbooth/models/master-allocation';
import type { AllocatedTicketSelection } from 'ticketbooth/models/seat-assignment';
import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type ErrorsService from 'ticketbooth/services/errors';
import type PreloadService from 'ticketbooth/services/preload';
import type SeatEditorService from 'ticketbooth/services/seat-editor';
import { hasSingleSeat } from 'ticketbooth/utils/single-seat-validation';

interface StateSchema {
  states: {
    loadingCachedGridData: {};
    loadingCachedGridSuccess: {
      states: {
        operations: {
          states: {
            idle: {};
            persisting: {};
            success: {};
            error: {};
          };
        };
        data: {
          states: {
            loadingDiffData: {};
            loadingDiffSuccess: {};
            loadingDiffError: {};
          };
        };
      };
    };
    loadingCachedGridError: {};
  };
}

type PersistEvent = {
  type: 'PERSIST';
  event: EventModel;
  tickets: AllocatedTicketSelection[];
};

type StatechartEvents =
  | InvokedEvent
  | {
      type: 'SWITCH_ALLOCATION';
      masterAllocation: MasterAllocation;
    }
  | {
      type: 'CART_EXPIRED';
    }
  | PersistEvent;

function isPersistEvent(event?: StatechartEvents): event is PersistEvent {
  if (!event) {
    return false;
  }
  return typeof event.type === 'string' && event.type === 'PERSIST';
}

function isSeatHasBecomeUnvailableError(errors: any): boolean {
  const messages = getAllUserMessagesOrDefault(errors);
  // https://github.com/ticketsolve/ticketsolve/blob/97fd850ca293a4fd0994d585d974597832a91992/config/locales/en-GB.rb#L1059
  return messages.some(error => error === 'seat has become unavailable');
}

interface MContext {
  masterAllocation: MasterAllocation;
  grid: Grid | null;
  unavailableSeats: SeatAssignmentModel[];
}

const machine = Machine<MContext, StateSchema, StatechartEvents>({
  initial: 'loadingCachedGridData',
  on: {
    SWITCH_ALLOCATION: 'loadingCachedGridData'
  },
  states: {
    loadingCachedGridData: {
      invoke: {
        src: 'loadCachedGrid',
        onDone: {
          target: 'loadingCachedGridSuccess',
          actions: assign({
            grid: (_c, e) => e.data
          })
        },
        onError: 'loadingCachedGridError'
      }
    },

    loadingCachedGridSuccess: {
      onExit: ['clearSelection'],
      type: 'parallel',
      states: {
        operations: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                PERSIST: 'persisting'
              }
            },
            persisting: {
              invoke: {
                src: 'sendOperations',
                onDone: 'success',
                onError: 'error'
              }
            },
            success: {
              onEntry: ['clearSelection', send('RELOAD_GRID_DIFF')],
              invoke: {
                src: 'reloadMasterAllocation',
                onDone: 'idle',
                onError: 'error'
              }
            },
            error: {
              onEntry: [
                'logError',
                'notifySaveError',
                'trackUnavailableSeat',
                send('RELOAD_GRID_DIFF')
              ],
              invoke: {
                src: 'reloadMasterAllocation',
                onDone: 'idle'
              },
              on: {
                PERSIST: 'persisting'
              }
            }
          }
        },
        data: {
          initial: 'loadingDiffData',
          states: {
            loadingDiffData: {
              invoke: {
                src: 'loadDiffData',
                onDone: 'loadingDiffSuccess',
                onError: 'loadingDiffError'
              }
            },
            loadingDiffSuccess: {
              entry: ['notifyGridLoaded'],
              on: {
                RELOAD_GRID_DIFF: 'loadingDiffData',
                CART_EXPIRED: 'loadingDiffData'
              }
            },
            loadingDiffError: {
              on: {
                RELOAD_GRID_DIFF: 'loadingDiffData'
              }
            }
          }
        }
      }
    },
    loadingCachedGridError: {
      entry: ['logError']
    }
  }
});

interface GridManagementSignature {
  Args: {
    masterAllocation: MasterAllocation;
    selectedSeats: SeatAssignmentModel[];
    selectedTickets: AllocatedTicketSelection[];
    clearSelection: Function;
    selectSeatsAndTickets: (selection: AllocatedTicketSelection[]) => void;
    confirmTickets: (selectedSeats: SeatAssignmentModel[]) => void;
  };
  Blocks: {
    default: [unknown];
  };
}
export default class GridManagementComponent extends Component<GridManagementSignature> {
  @service private notifications!: NotificationsService;
  @service private errors!: ErrorsService;
  @service private cartProvider!: CartProviderService;
  @service private seatEditor!: SeatEditorService;
  @service private store!: Store;
  @service private preload!: PreloadService;

  statechart = useMachine<MContext, StateSchema, StatechartEvents, any, {}>(
    this,
    () => ({
      machine: machine
        .withContext({
          masterAllocation: this.args.masterAllocation,
          grid: null,
          unavailableSeats: []
        })
        .withConfig({
          actions: {
            logError: (_c, e: InvokedEvent) => this.errors.log(e.data),
            clearSelection: () => this.clearSelection(),
            notifySaveError: (_c, e: InvokedEvent) => {
              const error = e.data;
              const messages = getAllUserMessagesOrDefault(error, {
                defaultMsg: 'Could not add tickets. Please try again'
              });
              this.notifications.error(messages.join('. '));
            },
            trackUnavailableSeat: (context, e: InvokedEvent, { state }) => {
              const lastEvent = state.history?.event;
              if (
                isPersistEvent(lastEvent) &&
                isSeatHasBecomeUnvailableError(e.data)
              ) {
                context.unavailableSeats = lastEvent.tickets.map(
                  ({ seatAssignment }) => seatAssignment
                );
              }
            },
            notifyGridLoaded: context => {
              if (this.seatEditor.hasRepickSeats) {
                this.repickSeats();
              }
              if (context.unavailableSeats.length > 0) {
                this.checkIfSeatStatusInvalid(context.unavailableSeats);
                context.unavailableSeats = [];
              }
            }
          },
          services: {
            loadCachedGrid: (
              context,
              event: Extract<StatechartEvents, { type: 'SWITCH_ALLOCATION' }>
            ) => {
              const id = (event.masterAllocation ?? context.masterAllocation)
                .id;
              return this.store.findRecord('grid', id, { reload: true });
            },
            sendOperations: (
              _context,
              { event, tickets }: Extract<StatechartEvents, { type: 'PERSIST' }>
            ) => this.cartProvider.cart.addSeatedTickets(event, tickets),
            loadDiffData: ({ grid }) => grid!.loadDeltaForCurrentVersion({}),
            reloadMasterAllocation: (_context, _event) =>
              this.store.findRecord(
                'master-allocation',
                this.args.masterAllocation.id,
                {
                  include: 'ticket-allocations',
                  reload: true
                }
              )
          }
        })
    })
  );

  constructor(owner: unknown, args: GridManagementSignature['Args']) {
    super(owner, args);
    this.cartProvider.onExpiration(this.cartExpired);
  }
  willDestroy(): void {
    this.cartProvider.offExpiration(this.cartExpired);
    super.willDestroy();
  }

  @matchesState({ loadingCachedGridSuccess: { data: 'loadingDiffSuccess' } })
  gridIsFullyLoaded!: boolean;

  @matchesState('loadingCachedGridSuccess')
  canDisplayGrid!: boolean;

  @matchesState({ loadingCachedGridSuccess: { operations: 'persisting' } })
  isPersisting!: boolean;

  get isBusySeatSelection(): boolean {
    return this.isPersisting;
  }

  get grid(): GridModel | null {
    return this.statechart.state!.context.grid;
  }

  get seatAssignments(): SeatAssignmentModel[] {
    return this.grid?.seatAssignments.slice() ?? [];
  }

  get hasSingleSeat() {
    if (!this.args.masterAllocation.event.checkForSingleSeats) {
      return false;
    }
    return hasSingleSeat(
      this.seatAssignments,
      this.args.selectedSeats,
      this.preload.getValue('allow_single_seat_at_row_end')
    );
  }

  get hasSelection() {
    return this.args.selectedSeats.length > 0;
  }

  get canAddTickets() {
    return (
      !this.isBusySeatSelection && !this.hasSingleSeat && this.hasSelection
    );
  }

  /**
   * Report potential caching issues
   *
   * Once we detect a `Seat has become unavailable` error, we reload the cache
   * and at least one seat should not be in the 'available' state anymore.
   */
  private checkIfSeatStatusInvalid(seatAssignments: SeatAssignmentModel[]) {
    const seatsStillAvailable = seatAssignments.every(
      seat => seat.status === 'available'
    );

    if (seatsStillAvailable) {
      this.errors.log(
        new Error(
          'Seats are `available` after `Seat has become unavailable` error'
        ),
        {
          extra: {
            seatAssignments: seatAssignments.map(
              ({ id, row, number, status }) => ({ id, row, number, status })
            )
          }
        }
      );
    }
  }

  @action
  clearSelection() {
    if (this.args.selectedTickets.length > 0) {
      this.args.clearSelection();
    }
  }

  @action
  masterAllocationChanged() {
    const { masterAllocation } = this.args;
    if (masterAllocation) {
      this.statechart.send('SWITCH_ALLOCATION', { masterAllocation });
    }
  }

  @action
  addSeatedTickets() {
    const { masterAllocation, selectedTickets } = this.args;
    if (selectedTickets.length > 0) {
      this.statechart.send('PERSIST', {
        event: masterAllocation.event,
        tickets: selectedTickets
      });
    }
  }

  @action
  repickSeats() {
    const selection = this.seatEditor.popRepickSeats();

    this.args.selectSeatsAndTickets(selection);
  }

  @action
  cartExpired() {
    this.statechart.send('CART_EXPIRED');
  }

  @action
  confirmSelection() {
    const { selectedSeats } = this.args;
    if (selectedSeats.length === 0) {
      return;
    }
    if (selectedSeats.every(({ ticketPrices }) => ticketPrices.length === 1)) {
      const tickets: AllocatedTicketSelection[] = selectedSeats.map(
        seatAssignment => ({
          seatAssignment,
          eventTicketPrice: seatAssignment.ticketPrices[0]
        })
      );
      this.statechart.send('PERSIST', {
        event: this.args.masterAllocation.event,
        tickets
      });
    } else {
      this.args.confirmTickets(this.args.selectedSeats);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GridManagement: typeof GridManagementComponent;
    'grid-management': typeof GridManagementComponent;
  }
}
