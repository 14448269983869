import { helper } from '@ember/component/helper';

import type { LineItemGroup } from 'ticketbooth/models/line-item';
import { isBookingChargeGroup } from 'ticketbooth/models/line-item';

export function isBookingChargeGroupType([lineItemGroup]: [LineItemGroup]) {
  return isBookingChargeGroup(lineItemGroup);
}

export default helper(isBookingChargeGroupType);
