import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';

export default class extends Route {
  @service private cartProvider!: CartProviderService;
  @service private checkoutFlow!: CheckoutFlowService;
  @service private router!: RouterService;

  async beforeModel() {
    if (!this.checkoutFlow.extrasPromptStep.available) {
      this.checkoutFlow.goToNextStep('extras_prompt');
    }
  }

  activate() {
    this.cartProvider.onExpiration(this.redirectToCartPage);
  }

  deactivate() {
    this.cartProvider.offExpiration(this.redirectToCartPage);
  }

  @action
  private redirectToCartPage() {
    this.router.transitionTo('cart');
  }
}
