import without from 'lodash-es/without';

import { getOwner } from '@ember/application';
import type ApplicationInstance from '@ember/application/instance';
import type Controller from '@ember/controller';
import { action } from '@ember/object';
import { addListener, removeListener } from '@ember/object/events';
import type Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';

import EmberRouterScrollService from 'ember-router-scroll/services/router-scroll';

export default class RouterScrollService extends EmberRouterScrollService {
  declare router: RouterService;

  private scrollTopCallbacks: (() => void)[] = [];
  private scrollByCallbacks: ((x: number, y: number) => void)[] = [];
  private scrollContainerId = 'content-scroll-container';

  // Picked up by EmberRouterScrollService as fallback to the controller's `preserveScrollPosition`
  preserveScrollPosition = false;

  constructor() {
    super(...arguments);

    addListener(this, 'didScroll', this.onDidScroll);
  }

  willDestroy() {
    removeListener(this, 'didScroll', this.onDidScroll);
  }

  onScrollReset(cb: () => void) {
    this.scrollTopCallbacks = [...this.scrollTopCallbacks, cb];
  }
  offScrollReset(cb: () => void) {
    this.scrollTopCallbacks = without(this.scrollTopCallbacks, cb);
  }

  onScrollBy(cb: (x: number, y: number) => void) {
    this.scrollByCallbacks = [...this.scrollByCallbacks, cb];
  }

  @action
  scrollBy(x: number, y: number) {
    document.getElementById(this.scrollContainerId)!.scrollBy(x, y);

    this.scrollByCallbacks.forEach(cb => cb(x, y));
  }

  /**
   * We only scroll to the top using this service.
   *
   * This is especially useful for the checkout process to always scroll to the
   * top when clicking the continue/back buttons.
   */
  @action
  onDidScroll() {
    if (this.preventScrollForCurrentRoute) {
      return;
    }

    this.scrollTopCallbacks.forEach(cb => cb());
  }

  get preventScrollForCurrentRoute(): boolean {
    const owner: ApplicationInstance = getOwner(this);
    const route: Route = owner.lookup('route:' + this.router.currentRouteName);
    const controller: Controller & { preserveScrollPosition?: boolean } =
      route?.controller;

    return controller && !!controller.preserveScrollPosition;
  }
}
