import Service from '@ember/service';

const COOKIE_RESTRICTION = {
  AllowCookieCrossOrigin: 'SameSite=None; Secure',
  AllowCookieCrossOriginOnlyFromTopLevel: 'SameSite=Lax'
} as const;
type CookieRestriction = keyof typeof COOKIE_RESTRICTION;

export default class DocumentService extends Service {
  async testCookieSupport(): Promise<boolean> {
    return (await this.testStorageAccess()) || this.testCookieReadWrite();
  }

  async testStorageAccess(): Promise<boolean> {
    // @ts-ignore - needs ts upgrade to v4
    if (!document.hasStorageAccess) {
      return false;
    }

    // Safari & Firefox
    // @ts-ignore - needs ts upgrade to v4
    return await document.hasStorageAccess();
  }

  async testCookieReadWrite(): Promise<boolean> {
    const cookie = 'cookie_test=1';

    this.setCookie(cookie, 'AllowCookieCrossOrigin');

    if (!this.cookieExists(cookie)) {
      // Note: Safari doesn't accept SameSite=None on all version yet, so we try
      // to write the cookie again without that flag if we can't find the cookie.
      this.setCookie(cookie, 'AllowCookieCrossOriginOnlyFromTopLevel');
    }

    return this.cookieExists(cookie);
  }

  setCookie(cookie: string, restriction: CookieRestriction) {
    document.cookie = `${cookie}; ${COOKIE_RESTRICTION[restriction]}`;
  }

  cookieExists(keyValue: string) {
    return document.cookie.includes(keyValue);
  }
}
