import { InitSentryForEmber } from '@sentry/ember';
import type { Event, EventHint } from '@sentry/types';

import AdapterError from '@ember-data/adapter/error';
import Application from '@ember/application';

import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import config from 'ticketbooth/config/environment';

import {
  shouldLogEmberDataError,
  shouldLogErrorByStack
} from './services/errors';

import './deprecation-workflow';

/**
 * Init sentry via runtime config
 *
 * Recommended by docs
 * (https://github.com/getsentry/sentry-javascript/tree/master/packages/ember#installation)
 *
 * Note: RegEx are not supported in environment config
 * (https://github.com/getsentry/sentry-javascript/issues/3820)
 */
InitSentryForEmber({
  environment: config.environment,
  enabled:
    config.environment === 'production' &&
    !location.host.endsWith('ticketsolvedev.com:3000'),
  tracesSampleRate: 0.01,
  dsn: 'https://ab2a9fb1e40b4ba3b978bcc90f2d7572@o33077.ingest.sentry.io/5735656',
  ignoreErrors: [
    // Ember-data
    /TransitionAborted/,

    // Ignore failed or cancelled network requests
    /ChunkLoadError/,
    /NetworkError when attempting to fetch resource/,
    /Load failed$/,
    /Failed to fetch$/,
    /^cancelled$/,
    /^\[handled\]: cancelled$/,
    /^TypeError: \[handled\]: cancelled$/,

    // Microsoft
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
    /Object Not Found Matching Id/,

    // Bing
    // https://stackoverflow.com/a/69576781/4576905
    /instantSearchSDKJSBridgeClearHighlight/,

    // LastPass Extension
    /Not implemented on this platform/
  ],
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^(chrome|safari|safari-web)-extension:\/\//i
  ],
  release:
    document
      .querySelector("meta[name='sentry:revision']")
      ?.getAttribute('content') || undefined,
  beforeSend(event: Event, hint: EventHint) {
    const error = hint?.originalException;

    // @ts-ignore
    if (window.TB_UNSUPPORTED_BROWSER === true) {
      // Do not log app errors when unsupported browser
      // See lib/content-for-snippets/body.html
      return null;
    }

    if (error instanceof AdapterError && !shouldLogEmberDataError(error)) {
      return null;
    }

    if (error instanceof Error && !shouldLogErrorByStack(error.stack)) {
      return null;
    }

    return event;
  }
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
