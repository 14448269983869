/* import __COLOCATED_TEMPLATE__ from './zoom-control.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SeatEditorZoomControlSignature {
  Args: {};
}

const SeatEditorZoomControlComponent =
  templateOnlyComponent<SeatEditorZoomControlSignature>();

export default SeatEditorZoomControlComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::ZoomControl': typeof SeatEditorZoomControlComponent;
    'seat-editor/zoom-control': typeof SeatEditorZoomControlComponent;
  }
}
