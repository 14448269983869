export interface ExtrasDefinition {
  metadata: {
    information: string;
    optional?: 'no' | 'yes';
  };
  fields: {
    /**
     * Unique Identifier
     */
    name: string;
    description: string;
    /**
     * Dropdown
     */
    options?: string[];
  }[];
}

/**
 * `{}` is the `NullExtrasDefinition`
 *
 * `metadata` key is required
 */
export function extractExtras(
  extras: ExtrasDefinition | {} | null
): ExtrasDefinition | null {
  if (extras && 'metadata' in extras) {
    return extras;
  }
  return null;
}

export type Extra = {
  name: string;
  value: string;
};
