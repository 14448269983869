import { tracked } from '@glimmer/tracking';
import { addSeconds, differenceInSeconds } from 'date-fns';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import isEqual from 'lodash-es/isEqual';
import orderBy from 'lodash-es/orderBy';
import sumBy from 'lodash-es/sumBy';
import { all } from 'rsvp';
import type { SingleResourceDocument } from 'ticketoffice-api';
import { cached } from 'tracked-toolbox';

import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
// eslint-disable-next-line ember/no-mixins
import LoadableModel from 'ember-data-storefront/mixins/loadable-model';

import { memberAction } from 'ember-api-actions';

import type {
  ResourceOperation,
  ResourceOperations
} from 'tangram/utils/operations-api';
import { deleteOperation } from 'tangram/utils/operations-api';
import { serializeAndPush } from 'tangram/utils/serialize-and-push';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type TicketboothErrorsService from 'ticketbooth/services/errors';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';
import type { McInterestModel } from 'ticketbooth/services/preload';
import type PreloadService from 'ticketbooth/services/preload';
import type { Comparable, AnyLineItemGroup } from 'ticketbooth/utils/cart-api';
import {
  INCLUDE_ALL,
  createTicketLineItemOperation,
  createProductLineItemOperation,
  createDonationProductLineItemOperation,
  createBenefitProductLineItemOperation,
  createSeatedTicketOperation
} from 'ticketbooth/utils/cart-api';
import diffLineItems, {
  type LineItemGroupDiff
} from 'ticketbooth/utils/diff-line-items';
import type { Extra } from 'ticketbooth/utils/extras';
import { hasSplitSeats } from 'ticketbooth/utils/split-seat-validation';

import type BenefitProductModel from './benefit-product';
import type BookingChargeLineItemModel from './booking-charge-line-item';
import type CreditCardPaymentModel from './credit-card-payment';
import type CustomerModel from './customer';
import DonationProductModel from './donation-product';
import type EventModel from './event';
import type EventTicketPriceModel from './event-ticket-price';
import FulfillmentProductModel from './fulfillment-product';
import type InventoryModel from './inventory';
import type { LineItemGroup, ProductGroup, TicketGroup } from './line-item';
import type LineItemModel from './line-item';
import {
  isBookingChargeGroup,
  isFulfillmentProductGroup,
  isTicketGroup
} from './line-item';
import MoneyVoucherProductModel from './money-voucher';
import type OrderModel from './order';
import type PermissionModel from './permission';
import ProductModel, { type ProductPurchaseOptions } from './product';
import type ProductLineItemModel from './product-line-item';
import { productPurchaseOptionsFromProductLineItemOrProduct } from './product-line-item';
import type RecommendationModel from './recommendation';
import type RedemptionModel from './redemption';
import type { AllocatedTicketSelection } from './seat-assignment';
import type TicketAllocationModel from './ticket-allocation';
import type TicketLineItemModel from './ticket-line-item';
import type VoucherPaymentModel from './voucher-payment';

export type GiftAidPreference = 'all' | 'onwards' | 'cart' | 'not-defined';

export default class CartModel extends Model.extend(LoadableModel) {
  @service private cartProvider!: CartProviderService;
  @service private preload!: PreloadService;
  @service('errors') private errorsService!: TicketboothErrorsService;
  @service private googleAnalytics!: GoogleAnalyticsService;

  /**
   * See `localExpiresAt`
   */
  @attr('date') private expiresAt!: Date | null;
  @attr('date') private currentTime!: Date | null;
  @attr('number') total!: number;
  @attr('number') outstanding!: number;
  @attr('boolean') hasPartialPayments!: boolean;
  @attr('string') hppUrl!: string;
  @attr('string') comment!: string;
  @attr('string') uuid!: string;

  /**
   * Either a line item in the cart has a discount OR discounts are enabled domain wide
   */
  @attr('boolean') discountsAvailable!: boolean;
  @attr('string') discountDescription!: string | null;
  @attr('string') promotionCode!: string | null;
  @attr('string') promotionCodeDescription!: string | null;

  @attr('string') giftAidPreference!: GiftAidPreference | null;

  /**
   * The discount id associated with the applied promo code
   *
   * Note: There might always be a "no discount"/"NullDiscount" discount active
   */
  @attr('number') discountId!: number;

  @hasMany('ticket-line-item', { async: false, inverse: null })
  ticketLineItems!: SyncHasMany<TicketLineItemModel>;

  @hasMany('product-line-item', { async: false, inverse: null })
  productLineItems!: SyncHasMany<ProductLineItemModel>;

  @hasMany('booking-charge-line-item', { async: false, inverse: null })
  bookingChargeLineItems!: SyncHasMany<BookingChargeLineItemModel>;

  @hasMany('fulfillment-product', { async: false, inverse: null })
  availableFulfillmentProducts!: SyncHasMany<FulfillmentProductModel>;

  @hasMany('donation-product', { async: false, inverse: null })
  promptableDonationProducts!: SyncHasMany<DonationProductModel>;

  @hasMany('product', { async: false, polymorphic: true, inverse: null })
  suggestedProducts!: SyncHasMany<ProductModel>;

  @hasMany('voucher-payment', { async: false, inverse: null })
  voucherPayments!: SyncHasMany<VoucherPaymentModel>;

  @hasMany('credit-card-payment', { async: false, inverse: null })
  creditCardPayments!: SyncHasMany<CreditCardPaymentModel>;

  @hasMany('permission', { async: false, inverse: null })
  permissionOptions!: SyncHasMany<PermissionModel>;

  @hasMany('recommendation', { async: false, inverse: null })
  recommendations!: SyncHasMany<RecommendationModel>;

  @belongsTo('customer', { async: false, inverse: null })
  private customer!: CustomerModel;

  @tracked isConfirmed = false;

  get isNotConfirmed() {
    return !this.isConfirmed;
  }

  get cartCustomer() {
    const { customer } = this;
    if (customer?.isNullCustomer) {
      return null;
    }
    return customer;
  }

  @cached
  get lineItems(): LineItemModel[] {
    return [
      ...this.ticketLineItems.slice(),
      ...this.productLineItems.slice(),
      ...this.bookingChargeLineItems.slice()
    ];
  }

  @cached
  get groupedLineItems(): AnyLineItemGroup[] {
    return this.lineItems.reduce(
      (groups: LineItemGroup[], lineItem) => lineItem.groupWith(groups),
      []
    );
  }

  get lineItemsWithExtras() {
    return [
      ...this.ticketLineItems.slice(),
      ...this.productLineItems.slice()
    ].filter(lineItem => lineItem.hasExtras);
  }

  get lineItemsWithGiftaid() {
    return [
      ...this.ticketLineItems.slice(),
      ...this.productLineItems.slice()
    ].filter(lineItem => lineItem.giftaidable);
  }

  get redemptionSummmary(): {
    redemption: RedemptionModel;
    remainingMinusCommitted: number;
  }[] {
    return this.cartVoucherPayments.map(({ redemption }) => ({
      redemption,
      remainingMinusCommitted: redemption.remainingMinusComittedToCart(this)
    }));
  }

  get itemCount(): number {
    return this.groupedLineItems.reduce((n, group) => n + group.quantity, 0);
  }
  get ticketsAndProductsCount(): number {
    const countAsTicketOrProduct = (group: AnyLineItemGroup) =>
      !isBookingChargeGroup(group) && !isFulfillmentProductGroup(group);
    return this.groupedLineItems
      .filter(countAsTicketOrProduct)
      .reduce((n, group) => n + group.quantity, 0);
  }

  get fulfillmentLineItem(): ProductLineItemModel | null {
    const { productLineItems } = this;
    return (
      productLineItems.find(
        lineItem => lineItem.product instanceof FulfillmentProductModel
      ) ?? null
    );
  }

  get sortedFulfillmentProducts(): FulfillmentProductModel[] {
    return orderBy(
      this.availableFulfillmentProducts.slice(),
      ['priority'],
      ['desc']
    );
  }

  get defaultFulfillmentProduct(): FulfillmentProductModel | null {
    return this.sortedFulfillmentProducts[0] ?? null;
  }

  get donationProducts(): DonationProductModel[] {
    return this.productLineItems
      .slice()
      .reduce((acc: DonationProductModel[], lineItem) => {
        if (lineItem.product instanceof DonationProductModel) {
          acc.push(lineItem.product);
        }
        return acc;
      }, []);
  }

  @cached
  get localExpiresAt(): Date | null {
    const { expiresAt, currentTime } = this;
    if (!expiresAt || !currentTime) {
      return null;
    }
    return addSeconds(new Date(), differenceInSeconds(expiresAt, currentTime));
  }

  get hasItems(): boolean {
    return this.itemCount > 0;
  }

  get hasNoItems(): boolean {
    return !this.hasItems;
  }

  get hasExpirationDate() {
    return this.localExpiresAt !== null;
  }

  get cartVoucherPayments(): VoucherPaymentModel[] {
    return this.voucherPayments.filter(payment => !payment.isRewardPayment);
  }

  get cartRewardPayment(): VoucherPaymentModel | undefined {
    return this.voucherPayments.find(payment => payment.isRewardPayment);
  }

  get redeemedReward(): RedemptionModel | undefined {
    return this.cartRewardPayment?.redemption;
  }

  get hasRedeemedRewards(): boolean {
    return !!this.redeemedReward;
  }

  get hasPayments(): boolean {
    return (
      this.voucherPayments.slice().length > 0 ||
      this.creditCardPayments.slice().length > 0
    );
  }

  get hasFulfillmentProducts() {
    return this.availableFulfillmentProducts.slice().length > 0;
  }

  get hasRecommendations(): boolean {
    return this.recommendations.slice().length > 0;
  }

  get hasPromptableDonations() {
    return (
      this.promptableDonationProducts.slice().length > 0 ||
      this.productLineItems
        .slice()
        .some(lineItem => lineItem.requiresAgreement!)
    );
  }

  get hasGiftAidDonation() {
    return this.lineItemsWithGiftaid.length > 0;
  }

  get hasProductSuggestions() {
    return this.suggestedProducts.slice().length > 0;
  }

  get hasExtras() {
    return this.lineItemsWithExtras.length > 0;
  }
  get hasExtrasDefinitions() {
    const hasTicketExtrasDefinition = this.ticketLineItems
      .slice()
      .some(lineItem => lineItem.eventTicketPrice.hasExtras);
    const hasProductExtrasDefinition = this.productLineItems
      .slice()
      .some(lineItem => lineItem.product.hasExtras);
    return hasTicketExtrasDefinition || hasProductExtrasDefinition;
  }

  get hasSplitSeats() {
    return (
      this.preload.getValue('check_for_split_seats') === true &&
      hasSplitSeats(this.ticketLineItems.slice())
    );
  }

  lineItemsForProduct(product: ProductModel) {
    const lineItems = filter(this.productLineItems.slice(), { product });

    if (product instanceof DonationProductModel && lineItems.length > 1) {
      const lineItemsJson = lineItems.map(lineItem => lineItem.serialize());
      this.errorsService.log(
        new Error('Multiple line items for donation-product found'),
        { extra: { lineItems: lineItemsJson } }
      );
    }

    return lineItems;
  }

  quantityForProduct(product: ProductModel) {
    if (product instanceof MoneyVoucherProductModel) {
      // handle money-vouchers differently as they can be gifted
      return sumBy(
        this.lineItemsForProduct(product).filter(
          (lineItem: ProductLineItemModel) => !lineItem.giftEmail
        ),
        'quantity'
      );
    }

    return sumBy(this.lineItemsForProduct(product), 'quantity');
  }

  quantityForGift(product: MoneyVoucherProductModel) {
    return sumBy(
      this.lineItemsForProduct(product).filter(
        (lineItem: ProductLineItemModel) => lineItem.giftEmail
      ),
      'quantity'
    );
  }

  getLineItemGroup(comparators: Comparable): AnyLineItemGroup | undefined {
    return find<AnyLineItemGroup>(this.groupedLineItems, comparators);
  }

  getLineItemGroups(comparators: Comparable): AnyLineItemGroup[] {
    return filter<AnyLineItemGroup>(this.groupedLineItems, comparators);
  }

  reloadWithIncludes(): Promise<any> {
    return this.sideload(INCLUDE_ALL, { reload: true });
  }

  private async serializeAndPush(payload: any) {
    const cart = serializeAndPush.bind(this)(payload);
    await this.cartProvider.onCartChanged(cart);
    return cart;
  }

  async reportAddedProducts(added: LineItemGroupDiff[]) {
    if (added.length == 0) return;

    const addedProductsData = await all(
      added.map(async addedItem =>
        addedItem.lineItem.toAnalytics({
          quantity: addedItem.quantity,
          price: addedItem.lineItem.price
        })
      )
    );

    return this.googleAnalytics.addToCart(addedProductsData);
  }

  async reportRemovedProducts(removed: LineItemGroupDiff[]) {
    if (removed.length == 0) return;

    const removedProductsData = await all(
      removed.map(async removedItem =>
        removedItem.lineItem.toAnalytics({
          quantity: removedItem.quantity,
          price: removedItem.lineItem.price
        })
      )
    );

    return this.googleAnalytics.removeFromCart(removedProductsData);
  }

  async reportFulfillmentProducts(
    beforeFulfillment: ProductLineItemModel | null,
    afterFulfillment: ProductLineItemModel | null
  ) {
    // These line items are handled separately as they are not
    // something the customer themselves purchase
    if (beforeFulfillment?.id !== afterFulfillment?.id) {
      if (beforeFulfillment?.id) {
        this.googleAnalytics.removeFromCart([beforeFulfillment?.toAnalytics()]);
      }

      if (afterFulfillment?.id) {
        this.googleAnalytics.addToCart([afterFulfillment?.toAnalytics()]);
      }
    }
  }

  async handleGAOperations({
    added,
    removed,
    beforeFulfillment,
    afterFulfillment
  }: {
    added: LineItemGroupDiff[];
    removed: LineItemGroupDiff[];
    beforeFulfillment: ProductLineItemModel | null;
    afterFulfillment: ProductLineItemModel | null;
  }) {
    await this.reportAddedProducts(added);
    await this.reportRemovedProducts(removed);
    await this.reportFulfillmentProducts(beforeFulfillment, afterFulfillment);
  }

  /**
   * Transactional request to send list of operations (create, update, delete).
   *
   * Receives new cart resource if successful and updates the store.
   */
  updateLineItems = memberAction<ResourceOperations, Promise<CartModel>>({
    type: 'patch',
    path: `operations?include=${INCLUDE_ALL}`,
    async after(this: CartModel, payload) {
      const before = this.groupedLineItems;
      const beforeFulfillment = this.fulfillmentLineItem;

      const cart = await this.serializeAndPush(payload);

      const after = this.groupedLineItems;
      const afterFulfillment = this.fulfillmentLineItem;

      const { added, removed } = diffLineItems(before, after);

      await this.handleGAOperations({
        added,
        removed,
        beforeFulfillment,
        afterFulfillment
      });

      await this.cartProvider.onCartItemsChanged();

      return cart;
    }
  });

  emptyCart = memberAction<void, Promise<CartModel>>({
    type: 'post',
    path: `empty?include=${INCLUDE_ALL}`,
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  addVoucherCode = memberAction<string, Promise<CartModel>>({
    type: 'post',
    path: `add_voucher_code?include=${INCLUDE_ALL}`,
    before(this: CartModel, voucher_code) {
      return { data: { voucher_code } };
    },
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  addRewardPayment = memberAction<void, Promise<CartModel>>({
    type: 'post',
    path: `add_reward_payment?include=${INCLUDE_ALL}`,
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  changePromotionCode = memberAction<string | null, Promise<CartModel>>({
    type: 'patch',
    path: `?include=${INCLUDE_ALL}`,
    before(this: CartModel, promotionCode) {
      return {
        data: {
          id: this.id,
          type: 'carts',
          attributes: { 'promotion-code': promotionCode }
        }
      };
    },
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  changeExtras = memberAction<
    {
      lineItem: TicketLineItemModel | ProductLineItemModel;
      extras: Extra[] | null;
    }[],
    Promise<CartModel>
  >({
    type: 'patch',
    path: `operations?include=${INCLUDE_ALL}`,
    before(this: CartModel, extrasPerLineItems) {
      const ops: ResourceOperations = {
        ops: extrasPerLineItems
          .filter(({ lineItem, extras }) => {
            const savingEmptyExtras =
              isBlank(lineItem.extras) &&
              extras?.every(({ value }) => isBlank(value));
            return !savingEmptyExtras;
          })
          .filter(({ lineItem, extras }) => {
            const hasChanges = !isEqual(lineItem.extras, extras);
            return hasChanges;
          })
          .map(({ lineItem, extras }) => {
            const { data } = lineItem.serialize({
              includeId: true
            }) as SingleResourceDocument;
            data.attributes!.extras = extras;
            return { action: 'update', data };
          })
      };
      return ops;
    },
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  /**
   * Transactional request to remove a group of tickets with the minimum
   * ticket validation
   *
   * Receives new cart resource if successful and updates the store.
   *
   * Note: This custom API is a workaround to the /cart/operations API and might
   * be obsolete in future. See `removeTicketGroup`
   */
  removeMinTicketGroup = memberAction<TicketGroup, Promise<CartModel>>({
    type: 'delete',
    path: `ticket_line_items_group?include=${INCLUDE_ALL}`,
    before(this: CartModel, { ticketPrice, ticketAllocation }) {
      return {
        data: {
          'ticket-price-id': ticketPrice.ticketPriceId,
          'ticket-allocation-id': ticketAllocation.id
        }
      };
    },
    async after(this: CartModel, payload) {
      const cart = await this.serializeAndPush(payload);
      await this.cartProvider.onCartItemsChanged();
      return cart;
    }
  });

  /**
   * Removes expired ticket line items from cart
   */
  reapCart = memberAction<void>({
    type: 'post',
    path: `reap?include=${INCLUDE_ALL}`,
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  async removeLineItems(lineItems: LineItemModel[], numToRemove?: number) {
    if (numToRemove === undefined) {
      numToRemove = lineItems.length;
    }
    return this.updateLineItems({
      ops: lineItems
        .slice(0, numToRemove)
        .map(lineItem => deleteOperation(lineItem))
    });
  }

  async removeTicketGroup(group: TicketGroup) {
    if (group.ticketPrice.hasMinimumTicketsRequirement) {
      await this.removeMinTicketGroup(group);
    } else {
      await this.cartProvider.cart.removeLineItems(group.lineItems);
    }
  }

  async addTicketLineItems(
    event: EventModel,
    eventTicketPrice: EventTicketPriceModel,
    ticketAllocations: TicketAllocationModel[],
    length: number,
    hint = ''
  ) {
    const CreateOperation = createTicketLineItemOperation(
      event,
      eventTicketPrice,
      ticketAllocations,
      hint
    );
    return this.updateLineItems({
      ops: Array.from({ length }, () => CreateOperation)
    });
  }

  async addProductLineItems(
    product: ProductModel | ProductPurchaseOptions,
    inventory: InventoryModel | null,
    length: number,
    hint = ''
  ) {
    const CreateOperation = createProductLineItemOperation(
      product,
      inventory,
      hint
    );
    return this.updateLineItems({
      ops: Array.from({ length }, () => CreateOperation)
    });
  }

  async changeGroupQuantity(
    lineItemGroup: TicketGroup | ProductGroup,
    newQuantity: number
  ) {
    const diff = newQuantity - lineItemGroup.quantity;
    if (diff > 0) {
      const hint = lineItemGroup.lineItems[0].id;
      if (isTicketGroup(lineItemGroup)) {
        const event = lineItemGroup.event;
        const ticketP = lineItemGroup.ticketPrice;
        const ticketA = lineItemGroup.ticketAllocation;
        await this.addTicketLineItems(event, ticketP, [ticketA], diff, hint);
      } else {
        const [productLineItem] =
          lineItemGroup.lineItems as ProductLineItemModel[];
        const productOrProductPurchaseOptions =
          productPurchaseOptionsFromProductLineItemOrProduct(productLineItem);

        await this.addProductLineItems(
          productOrProductPurchaseOptions,
          lineItemGroup.inventory,
          diff,
          hint
        );
      }
    } else if (diff <= 0) {
      if (isTicketGroup(lineItemGroup)) {
        await this.removeLineItems(lineItemGroup.uniqLineItems, Math.abs(diff));
      } else {
        await this.removeLineItems(lineItemGroup.lineItems, Math.abs(diff));
      }
    }
  }

  async changeProductQuantity(
    product: ProductModel | ProductPurchaseOptions,
    inventory: InventoryModel | null,
    newQuantity: number
  ) {
    const _product =
      product instanceof ProductModel ? product : product.product;

    // only non-gifted items count towards product quantity as gifts are counted
    // separately
    const lineItems = filter(this.lineItemsForProduct(_product), {
      isFutureGifted: false
    });

    const diff = newQuantity - this.quantityForProduct(_product);

    if (diff > 0) {
      const productOrProductPurchaseOptions =
        productPurchaseOptionsFromProductLineItemOrProduct(
          lineItems[0] || product
        );

      await this.addProductLineItems(
        productOrProductPurchaseOptions,
        inventory,
        diff,
        lineItems[0]?.id
      );
    } else if (diff <= 0) {
      await this.removeLineItems(lineItems, Math.abs(diff));
    }
  }

  async addSeatedTickets(
    event: EventModel,
    tickets: AllocatedTicketSelection[]
  ) {
    const update = tickets.filter(
      ({ eventTicketPrice, ticketLineItem }) =>
        ticketLineItem && ticketLineItem.eventTicketPrice !== eventTicketPrice
    );
    const create = tickets.filter(({ ticketLineItem }) => !ticketLineItem);
    return this.updateLineItems({
      ops: [
        ...create.map(ticket => createSeatedTicketOperation(event, ticket)),
        ...update.map(ticket => createSeatedTicketOperation(event, ticket)),
        ...update.map(ticket => deleteOperation(ticket.ticketLineItem!))
      ]
    });
  }

  addBenefitProduct(
    product: BenefitProductModel,
    agreed: boolean,
    giftEmail: string | null
  ) {
    const operations: ResourceOperation[] = [
      createBenefitProductLineItemOperation(product, agreed, giftEmail)
    ];
    return this.updateLineItems({ ops: operations });
  }

  async changeDonationProduct(
    product: DonationProductModel,
    price: number,
    agreed: boolean | null
  ) {
    const ops = this.createChangeDonationProductOp(product, price, agreed);
    if (ops.length === 0) {
      return;
    }
    return this.updateLineItems({ ops });
  }

  createChangeDonationProductOp(
    product: DonationProductModel,
    price: number | null,
    agreed: boolean | null,
    optOut = false
  ) {
    const lineItem = this.lineItemsForProduct(product)[0];

    if (
      lineItem &&
      lineItem.price === price &&
      lineItem.agreed === agreed &&
      !optOut
    ) {
      // No operation needed
      return [];
    }

    const operations: ResourceOperation[] =
      !price || optOut
        ? []
        : [
            createDonationProductLineItemOperation(
              product,
              price,
              agreed ?? false
            )
          ];

    if (lineItem) {
      operations.push(deleteOperation(lineItem));
    }

    return operations;
  }

  async changeFulfillmentProduct(product: FulfillmentProductModel) {
    const operations: ResourceOperation[] = [
      createProductLineItemOperation(product, null)
    ];
    if (this.fulfillmentLineItem) {
      operations.push(deleteOperation(this.fulfillmentLineItem));
    }
    return this.updateLineItems({ ops: operations });
  }

  async selectDefaultFulfillmentProduct(): Promise<void> {
    if (!this.fulfillmentLineItem && this.defaultFulfillmentProduct) {
      await this.changeFulfillmentProduct(this.defaultFulfillmentProduct);
    }
  }

  /**
   * Confirm order by cart uuid
   */
  async confirm(uuid: string, connectedOrderId?: string): Promise<OrderModel> {
    const order = await this.store
      .adapterFor('order')
      .confirm(this.store, uuid, connectedOrderId);
    this.isConfirmed = true;
    return order;
  }

  /**
   * Checkout Page
   *
   *  - Create/Update customer
   *  - Update cart comment
   *  - Update mailchimp interests
   */
  confirmCustomer = memberAction<{
    customer: CustomerModel;
    comment: string;
    interests: McInterestModel[];
  }>({
    type: 'post',
    path: `confirm_customer?include=${INCLUDE_ALL}`,
    before(
      this: CartModel,
      { customer, comment, interests }
    ): {
      data: {
        customer: any;
        comment: string;
        'mc-interests': string[];
      };
    } {
      return {
        data: {
          // @ts-ignore
          customer: customer.serialize({ includeId: true }).data,
          comment,
          'mc-interests': interests.map(({ id }) => id)
        }
      };
    },
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  /**
   * Gift Aid Preferences Page
   */
  confirmGiftAidPreference = memberAction<{
    optOut: boolean;
    preference: GiftAidPreference | null;
  }>({
    type: 'patch',
    path: `gift_aid?include=${INCLUDE_ALL}`,
    before(
      this: CartModel,
      { optOut, preference }
    ): {
      data: {
        attributes: { preference: GiftAidPreference | null };
      };
    } {
      return {
        data: {
          attributes: { preference: optOut ? null : preference }
        }
      };
    },
    async after(this: CartModel, payload) {
      return await this.serializeAndPush(payload);
    }
  });

  async deleteVoucherPayment(voucherPayment: VoucherPaymentModel) {
    const adapter = this.store.adapterFor('voucher-payment');
    const payload = await adapter.deletePayment(voucherPayment);
    return await this.serializeAndPush(payload);
  }

  toAnalytics() {
    const products = (this.productLineItems ?? []).map(p =>
      p.toAnalytics({ quantity: p.quantity, price: p.price })
    );

    const tickets = (this.ticketLineItems ?? []).map(t =>
      t.toAnalytics({ quantity: t.quantity, price: t.price })
    );

    return [...products, ...tickets];
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    cart: CartModel;
  }
}
