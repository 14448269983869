/* import __COLOCATED_TEMPLATE__ from './cart-line-items-table.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

import type CartModel from 'ticketbooth/models/cart';

interface CartLineItemsTableSignature {
  Args: {
    cart: CartModel;
  };
}

const CartLineItemsTableComponent =
  templateOnlyComponent<CartLineItemsTableSignature>();

export default CartLineItemsTableComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CartLineItemsTable: typeof CartLineItemsTableComponent;
    'cart-line-items-table': typeof CartLineItemsTableComponent;
  }
}
