import Transform from '@ember-data/serializer/transform';
import { isNone } from '@ember/utils';

import { htmlSafeSanitized } from 'tangram/helpers/html-safe-sanitized';

export default class HtmlSafeTransform extends Transform {
  deserialize(serialized: string): string {
    if (serialized) {
      const div = document.createElement('div');
      div.innerHTML = htmlSafeSanitized([serialized]).toString();
      return div.textContent || '';
    }

    return serialized;
  }
  serialize(deserialized: string): string {
    return isNone(deserialized) ? '' : deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'html-safe': string;
  }
}
