/* import __COLOCATED_TEMPLATE__ from './order-preview.hbs'; */
import Component from '@glimmer/component';

import type OrderModel from 'ticketbooth/models/order';

interface OrderPreviewSignature {
  Args: {
    order: OrderModel;
  };
  Element: HTMLDivElement;
}

export default class OrderPreviewComponent extends Component<OrderPreviewSignature> {
  get descriptionItems() {
    return this.args.order.description?.split(',') ?? [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrderPreview: typeof OrderPreviewComponent;
    'order-preview': typeof OrderPreviewComponent;
  }
}
