/* import __COLOCATED_TEMPLATE__ from './ticket-price-dropdown-option.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SeatEditorTicketPriceDropdownOptionSignature {
  Args: {};
  Element: HTMLElement;
}

const SeatEditorTicketPriceDropdownOptionComponent =
  templateOnlyComponent<SeatEditorTicketPriceDropdownOptionSignature>();

export default SeatEditorTicketPriceDropdownOptionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::TicketPriceDropdownOption': typeof SeatEditorTicketPriceDropdownOptionComponent;
    'seat-editor/ticket-price-dropdown-option': typeof SeatEditorTicketPriceDropdownOptionComponent;
  }
}
