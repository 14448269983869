/* import __COLOCATED_TEMPLATE__ from './show.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RecommendationsListCardsShowSignature {
  Args: {};
}

const RecommendationsListCardsShowComponent =
  templateOnlyComponent<RecommendationsListCardsShowSignature>();

export default RecommendationsListCardsShowComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RecommendationsList::Cards::Show': typeof RecommendationsListCardsShowComponent;
    'recommendations-list/cards/show': typeof RecommendationsListCardsShowComponent;
  }
}
