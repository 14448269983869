import Component from '@glimmer/component';

import { concat } from '@ember/helper';
import { inject as service } from '@ember/service';

// @ts-ignore
import inc from 'ember-composable-helpers/helpers/inc';
// @ts-ignore
import sortBy from 'ember-composable-helpers/helpers/sort-by';

import type CurrencyService from 'tangram/services/currency';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import branding from 'ticketbooth/modifiers/branding';
import type CartProviderService from 'ticketbooth/services/cart-provider';

interface EventHubQrCodesProductsSignature {
  Args: {
    productLineItems: ProductLineItemModel[];
  };
}

export default class EventHubQrCodesProducts extends Component<EventHubQrCodesProductsSignature> {
  @service cart!: CartProviderService;
  @service currency!: CurrencyService;

  <template>
    {{! @glint-nocheck: not typesafe yet }}
    {{#each (sortBy "name:desc" @productLineItems) as |productLineItem index|}}
      <div class="rounded p-5 bg-gray-200 w-full mb-4"
        {{branding "backgroundColor" "navbar_bg_color"}}
        {{branding "color" "navbar_fg_color"}}
        data-test-product-line-item="{{index}}"
      >
        <div class="flex justify-between font-semibold text-lg mb-4 leading-tight">
          <div class="flex flex-grow truncate" data-test-product-line-item-name="{{index}}">
            {{productLineItem.name}}
          </div>
          <div data-test-product-line-item-quantity="{{index}}">
            {{concat (inc index) "/" @productLineItems.length}}
          </div>
        </div>

        <div class="flex justify-between text-sm leading-tight">
          <div class="flex flex-grow truncate" data-test-product-line-item-checkin-code="{{index}}">
            {{productLineItem.checkinCode}}
          </div>
          <div data-test-product-line-item-price="{{index}}">
            {{concat this.currency.symbol productLineItem.price}}
          </div>
        </div>

        {{#if productLineItem.comment}}
          <div class="border border-red-400 bg-red-100 mt-5 text-red-700 p-3 text-sm rounded"
            data-test-product-line-item-comment="{{index}}">
            {{productLineItem.comment}}
          </div>
        {{/if}}

        <div class="flex border my-5" {{branding "borderColor" "navbar_fg_color"}}></div>

        <div class="flex justify-center" data-test-product-line-item-qr-code="{{index}}">
          <img alt="product QR code" src={{productLineItem.qrCodeImageLink}} class="rounded shadow" />
        </div>
      </div>
    {{/each}}
  </template>
}
