import { parse } from 'date-fns';
import isBefore from 'date-fns/isBefore';

import type { SyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type { SnippetType } from 'ticketbooth/lib/locales';
import type LocaleService from 'ticketbooth/services/locale';

import type AssetAttachmentModel from './asset-attachment';
import type EventCategoryModel from './event-category';
import type ProductionCompanyModel from './production-company';
import RecommendableModel from './recommendable';
import type RecommendationModel from './recommendation';
import type ShowPropertyModel from './show-property';
import type TagModel from './tag';
import type VenueModel from './venue';

export default class ShowModel extends RecommendableModel {
  @service() locale!: LocaleService;

  @attr('html-safe') name!: string;
  /**
   * Discounts can also be enabled per show only
   */
  @attr('boolean') discountsAvailable!: boolean;
  /**
   * Checks settings `min_dates_for_calendar` and `min_times_for_calendar`
   * if a calendar view should be used for this show.
   */
  @attr('boolean') displayEventsCalendar!: boolean;
  /**
   * Tuple with 2 dates: [first event date, last event date]
   */
  @attr('plain-array') nextEventDates!: readonly [string, string];
  @attr('json') recommendationsTitle!: object;
  @attr('plain-array') showDetails!: SnippetType[];
  @attr('string') dateDescription!: string;

  @attr('boolean') soldout!: boolean;
  @attr('boolean') lowAvailability!: boolean;

  /**
   * All listable events have sales disabled
   */
  @attr('boolean') salesDisabled!: boolean;
  @attr('string') salesDisabledMessage!: string;

  @belongsTo('production-company', { async: false, inverse: null })
  productionCompany!: ProductionCompanyModel;

  @belongsTo('asset-attachment', { async: false, inverse: null })
  defaultAttachment!: AssetAttachmentModel;

  @belongsTo('event-category', { async: false, inverse: null })
  eventCategory!: EventCategoryModel;

  @hasMany('venue', { async: false, inverse: null })
  venues!: SyncHasMany<VenueModel>;

  @hasMany('tag', { async: false, inverse: null })
  tags!: SyncHasMany<TagModel>;

  // @ts-ignore
  @hasMany('recommendation', {
    inverse: 'recommendable',
    async: false,
    as: 'recommendable'
  })
  recommendations!: SyncHasMany<RecommendationModel>;

  @hasMany('show-property', { async: false, inverse: null })
  showProperties!: SyncHasMany<ShowPropertyModel>;

  get hasRecommendations() {
    return this.recommendations.slice().length > 0;
  }

  get recommendationsArray() {
    return this.recommendations.slice();
  }

  get googleAnalyticsName(): string {
    return this.name.replace(/"/g, ' ').replace(/\n/g, '');
  }

  get googleAnalyticsCategory(): string {
    return this.showEventCategory?.name || '';
  }

  get showEventCategory() {
    // @ts-ignore - belongsTo not typed
    if (this.belongsTo('eventCategory').value() === null) {
      return null;
    }
    const { eventCategory } = this;
    if (eventCategory?.isNullObject) {
      return null;
    }
    return eventCategory;
  }

  get showProductionCompany() {
    // @ts-ignore - belongsTo not typed
    if (this.belongsTo('productionCompany').value() === null) {
      return null;
    }
    const { productionCompany } = this;
    if (productionCompany?.isNullObject) {
      return null;
    }
    return productionCompany;
  }

  get dates(): [Date, Date] {
    const dateFormat = 'yyyy-MM-dd';
    const [date1, date2] = this.nextEventDates.map(date =>
      parse(date, dateFormat, 0)
    );

    if (isBefore(date1, date2)) {
      return [date1, date2];
    }
    return [date2, date1];
  }

  get firstEventDate(): Date {
    return this.dates[0];
  }
  get lastEventDate(): Date {
    return this.dates[1];
  }

  get hasEventCategoryAndProductionCompany() {
    return this.showEventCategory && this.showProductionCompany;
  }

  get hasLowAvailability() {
    return !this.soldout && this.lowAvailability;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    show: ShowModel;
  }
}
