import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import uniq from 'lodash-es/uniq';

import type SeatAssignmentModel from 'ticketbooth/models/seat-assignment';
import type TicketLineItemModel from 'ticketbooth/models/ticket-line-item';

/**
 * Account setting `check-for-split-seats` should warn the user if a seat
 * selection is split on either multiple master allocations, or if the seats
 * are not next to each other.
 *
 * Based on `Cart::has_split_tickets?`
 */
export function hasSplitSeats(ticketLineItems: TicketLineItemModel[]): boolean {
  const lineItemsByEvent = groupBy(ticketLineItems, ({ event }) => event.id);

  for (const event in lineItemsByEvent) {
    const seated = lineItemsByEvent[event].filter(({ isSeated }) => isSeated);

    if (seated.length === 0) {
      continue;
    }

    const masterAllocations = uniq(
      seated.map(({ masterAllocationName }) => masterAllocationName)
    );

    if (masterAllocations.length > 1) {
      return true;
    }

    if (seatsAreSplit(seated.map(({ seatAssignment }) => seatAssignment!))) {
      return true;
    }
  }

  return false;
}

function seatsAreSplit(seatAssignments: SeatAssignmentModel[]): boolean {
  const groupings = sortBy(
    uniq(seatAssignments.map(({ grouping }) => grouping))
  );

  if (groupings.length > 1 && groupings.length < seatAssignments.length) {
    // e.g. 2,2,3
    return true;
  }

  const diff = Math.abs(groupings[groupings.length - 1] - groupings[0]);
  if (diff !== groupings.length - 1) {
    return true;
  }

  return false;
}
