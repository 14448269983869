/* import __COLOCATED_TEMPLATE__ from './product.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CoverProductSignature {
  Args: {};
}

const CoverProductComponent = templateOnlyComponent<CoverProductSignature>();

export default CoverProductComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::Product': typeof CoverProductComponent;
    'cover/product': typeof CoverProductComponent;
  }
}
