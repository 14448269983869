import orderBy from 'lodash-es/orderBy';

import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import type EventModel from './event';
import type EventTicketPriceModel from './event-ticket-price';
import type TicketAllocationModel from './ticket-allocation';
import type TicketZoneModel from './ticket-zone';

export default class MasterAllocationModel extends Model {
  @attr('string') name!: string;
  @attr('boolean') soldout!: boolean;
  @attr('number') priority!: number;
  @attr('number') size!: number;

  @belongsTo('event', { async: false, inverse: 'masterAllocations' })
  event!: EventModel;

  @belongsTo('ticket-zone', { async: false, inverse: null })
  ticketZone!: TicketZoneModel;

  @hasMany('ticket-allocation', { async: false, inverse: null })
  ticketAllocations!: SyncHasMany<TicketAllocationModel>;

  get visibleTicketAllocations(): TicketAllocationModel[] {
    return orderBy(
      this.ticketAllocations.slice(),
      [({ priority }) => priority],
      ['asc']
    )
      .reverse()
      .filter(ticketAllocation => ticketAllocation.hasVisibleTicketPrices);
  }

  get unassignedTicketAllocations(): TicketAllocationModel[] {
    return this.visibleTicketAllocations.filter(
      ticketAllocation => !ticketAllocation.assignedAllocation
    );
  }

  get visibleTicketPrices(): EventTicketPriceModel[] {
    return this.visibleTicketAllocations
      .map(({ visibleTicketPrices }) => visibleTicketPrices.slice())
      .flat();
  }

  get hasPublicTicketAllocations() {
    return this.ticketAllocations.slice().length > 0;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'master-allocation': MasterAllocationModel;
  }
}
