import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type IframeService from 'ticketbooth/services/iframe';
import type MembershipService from 'ticketbooth/services/membership';
import type PreloadService from 'ticketbooth/services/preload';

function isDefaultShowsPath(browseHomePath: string) {
  return !browseHomePath || ['/', '/shows'].includes(browseHomePath);
}

export default class extends Route {
  @service private preload!: PreloadService;
  @service private iframe!: IframeService;
  @service private membership!: MembershipService;

  /**
   * Example: '/', '/shows', 'https://www.ticketsolve.com'
   *
   * Default: '/' or '/shows'
   *
   * See https://github.com/ticketsolve/ticketsolve/blob/b8af5070121ba8e0a00fc3b37f9c12f37b70865a/lib/context_helper.rb#L46
   */
  get browseHomePath() {
    return this.preload.getValue('browse_home_path') as string;
  }

  activate() {
    this.membership.onLogin(this.reloadShows);
  }

  deactivate() {
    this.membership.offLogin(this.reloadShows);
  }

  beforeModel(transition: Transition) {
    const browseHomePath = this.preload.getValue('browse_home_path');

    if (this.shouldRedirectToBrowseHomePath()) {
      transition.abort();
      window.location = browseHomePath;
    }
  }

  shouldRedirectToBrowseHomePath() {
    if (this.iframe.isActive) {
      // Iframe redirect of "shows" page is handled via `iframe_paths` in the
      // iframe service
      return false;
    }

    return !isDefaultShowsPath(this.browseHomePath);
  }

  @action
  private reloadShows() {
    if (this.membership.member?.hasDiscount) {
      location.reload();
    }
  }
}
