import compact from 'lodash-es/compact';
import orderBy from 'lodash-es/orderBy';
import uniq from 'lodash-es/uniq';

import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type { SeatBase } from 'tangram/utils/seat-editor';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type PreloadService from 'ticketbooth/services/preload';

import type AssignmentAllocationModel from './assignment-allocation';
import type EventTicketPriceModel from './event-ticket-price';
import type GridModel from './grid';
import type TicketAllocationModel from './ticket-allocation';
import type TicketLineItemModel from './ticket-line-item';

export type AllocatedTicketSelection = {
  seatAssignment: SeatAssignmentModel;
  eventTicketPrice: EventTicketPriceModel;
  ticketLineItem?: TicketLineItemModel;
};

export default class SeatAssignmentModel extends Model implements SeatBase {
  @service private cartProvider!: CartProviderService;
  @service private preload!: PreloadService;

  @attr('string') section!: string;
  @attr('string') row!: string;
  @attr('number') number!: number;
  @attr('number') x!: number;
  @attr('number') y!: number;
  @attr('number') grouping!: number;
  @attr('string') comment!: string;
  @attr('boolean') blocked!: boolean;
  @attr('string') status!:
    | 'available'
    | 'blocked'
    | 'reserved'
    | 'seat_assigned'
    | 'templocked';

  get assigned() {
    return this.status === 'seat_assigned' || this.status === 'reserved';
  }

  @belongsTo('grid', { inverse: 'seatAssignments', async: false })
  grid!: GridModel;

  @hasMany('assignment-allocation', { async: false, inverse: 'seatAssignment' })
  assignmentAllocations!: SyncHasMany<AssignmentAllocationModel>;

  /**
   * Effective assigned ticket allocations mapping for a seat assignment
   *
   * This includes
   *  - only assigned allocations
   *  - only effective allocations
   *  - only with public availability (filtered server-side)
   *  - all allocation types (public and non public)
   */
  private get effectiveAssignmentAllocations(): AssignmentAllocationModel[] {
    return this.assignmentAllocations
      .slice()
      .filter(({ effective }) => effective);
  }

  /**
   * Available assigned ticket allocations for a seat assignment
   *
   * This includes additionally to `effectiveAssignmentAllocations`
   *  - only public allocation type
   *  - only visible ticket prices (e.g. a ticket price might only be visible after applying cart discount)
   */
  private get assignedTicketAllocations(): TicketAllocationModel[] {
    const effectiveAssignedTicketAllocations = compact(
      this.effectiveAssignmentAllocations.map(
        ({ publicTicketAllocation }) => publicTicketAllocation
      )
    );
    return effectiveAssignedTicketAllocations.filter(
      ({ hasVisibleTicketPrices }) => hasVisibleTicketPrices
    );
  }

  /**
   * Available unassigned ticket allocations for a master allocation
   *
   * This includes
   *  - only unassigned allocations (filtered server-side)
   *  - only effective allocations (filtered server-side)
   *  - only with public availability (filtered server-side)
   *  - only public allocation type (filtered server-side)
   */
  private get unassignedTicketAllocations(): TicketAllocationModel[] {
    return this.grid.masterAllocation.unassignedTicketAllocations;
  }

  /**
   * Visible ticket allocations
   *
   * Similar to LTB, we choose either assigned allocations or unassigned - but
   * never a mix or unassigned as a fallback. This is different to TO, where
   * we combine them, unless the assigned allocation is exclusive.
   */
  get visibleTicketAllocations(): TicketAllocationModel[] {
    if (this.effectiveAssignmentAllocations.length > 0) {
      return orderBy(
        this.assignedTicketAllocations,
        [({ priority }) => priority, ({ size }) => size],
        ['desc', 'desc']
      );
    }

    return this.unassignedTicketAllocations;
  }

  get hasVisibleTicketAllocations(): boolean {
    return this.visibleTicketAllocations.length > 0;
  }

  get ticketPrices(): EventTicketPriceModel[] {
    return uniq(
      this.visibleTicketAllocations
        .map(({ visibleTicketPrices }) => visibleTicketPrices)
        .flat()
    );
  }

  get details(): string {
    const { section, row, number } = this;
    if (section) {
      return `${section} - ${row} ${number}`;
    }
    return `${row} ${number}`;
  }

  get isAssignable(): boolean {
    return this.status === 'available' && this.hasVisibleTicketAllocations;
  }

  get isBlocked(): boolean {
    return this.status === 'blocked';
  }

  get isExclusive(): boolean {
    const { assignmentAllocations } = this;
    return (
      assignmentAllocations.length === 1 &&
      assignmentAllocations.slice()[0].exclusive
    );
  }

  get isSelectable(): boolean {
    // 1. Must be available (not reserverd, blocked or in the cart)
    if (!this.isAssignable || this.isInCart) {
      return false;
    }

    const { unassignedTicketAllocations } = this;
    const assignmentAllocations = this.assignmentAllocations.slice();

    // 2. No unassigned allocations - make sure assigned allocation is available
    if (unassignedTicketAllocations.length === 0) {
      return assignmentAllocations.length > 0;
    }

    return true;
  }

  get isInCart(): boolean {
    return !!this.cartProvider.cart.ticketLineItems.find(
      ({ seatAssignment }) => seatAssignment === this
    );
  }

  get inCartColor(): string {
    return this.preload.lookAndFeel('seats_in_cart_fill_color');
  }

  get inCartStrokeColor(): string {
    return this.preload.lookAndFeel('seats_in_cart_stroke_color');
  }

  get unavailableColor(): string {
    return this.preload.lookAndFeel('seats_blocked_fill_color');
  }

  get unavailableStrokeColor(): string {
    return this.preload.lookAndFeel('seats_blocked_stroke_color');
  }

  get selectedColor(): string {
    return this.preload.lookAndFeel('seats_selected_fill_color');
  }

  get selectedStrokeColor(): string {
    return this.preload.lookAndFeel('seats_selected_stroke_color');
  }

  get displayBlockedIndicator(): boolean {
    return this.preload.getValue('display_blocked_seats');
  }

  get color(): string {
    if (this.isInCart) {
      return this.inCartColor;
    }
    if (this.isAssignable) {
      const { ticketAllocationForColor } = this;
      return ticketAllocationForColor?.hexColor ?? this.unavailableColor;
    }
    return this.unavailableColor;
  }

  get strokeColor(): string | null {
    if (this.isInCart) {
      return this.inCartStrokeColor;
    }
    if (this.isAssignable) {
      return null;
    }
    return this.unavailableStrokeColor;
  }

  /**
   * Choose by exclusive -> assigned -> unassigned ticket allocation
   *
   * If exclusive -> take first assigned allocation
   * If multiple -> take with largest size
   */
  private get ticketAllocationForColor(): TicketAllocationModel | null {
    const assignedTicketAllocations = orderBy(
      this.visibleTicketAllocations,
      ({ size }) => size,
      ['desc']
    );

    if (assignedTicketAllocations.length > 0) {
      return assignedTicketAllocations[0];
    }

    const unassignedTicketAllocations = orderBy(
      this.unassignedTicketAllocations,
      ({ size }) => size,
      ['desc']
    );

    return unassignedTicketAllocations[0];
  }

  /**
   * Select first non-group ticket as default
   */
  get defaultTicketSelection(): AllocatedTicketSelection {
    const { ticketPrices } = this;

    const minGroupSize = Math.min(
      ...ticketPrices.map(({ groupSize }) => groupSize)
    );
    const eventTicketPrice = ticketPrices.find(
      ticketPrice => ticketPrice.groupSize === minGroupSize
    )!;

    return {
      seatAssignment: this,
      eventTicketPrice
    };
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'seat-assignment': SeatAssignmentModel;
  }
}
