/* import __COLOCATED_TEMPLATE__ from './legend.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SeatEditorLegendSignature {
  Args: {};
}

const SeatEditorLegendComponent =
  templateOnlyComponent<SeatEditorLegendSignature>();

export default SeatEditorLegendComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatEditor::Legend': typeof SeatEditorLegendComponent;
    'seat-editor/legend': typeof SeatEditorLegendComponent;
  }
}
