import ApplicationAdapter from './application';

export default class CartAdapter extends ApplicationAdapter {
  pathForType() {
    return 'cart';
  }

  /**
   * Cart is a singleton resource, so we direct all queries to the /cart endpoint
   */
  buildURL() {
    return this.urlForQueryRecord({}, 'cart');
  }
}
