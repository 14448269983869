import { cached } from 'tracked-toolbox';

import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';

import type { SnippetType } from 'ticketbooth/lib/locales';

import type BookingChargeLineItemModel from './booking-charge-line-item';
import type ConntectedOrderModel from './connected-order';
import type CreditCardPaymentModel from './credit-card-payment';
import type CustomerModel from './customer';
import type EventModel from './event';
import type FulfillmentProductModel from './fulfillment-product';
import type { LineItemGroup } from './line-item';
import type ProductLineItemModel from './product-line-item';
import type TicketLineItemModel from './ticket-line-item';
import VoucherPaymentModel from './voucher-payment';

export default class OrderModel extends Model {
  @attr('string') uuid!: string;
  @attr('number') total!: number;
  @attr('number') paid!: number;
  @attr('number') remaining!: number;
  @attr('number') minimumPayment!: number;
  @attr('string') comment!: string;

  @attr('number') orderNumber!: number;
  @attr('string') connectedOrderId!: string;
  @attr('string') printAtHomeUrl!: boolean;
  @attr('string') description!: string | null;
  @attr('date') createdAt!: Date;
  @attr('date') reservedUntil!: Date | null;

  /**
   * Derived by the setting `allow_print_at_home`:
   *   - 'no'
   *   - 'always'
   *   - 'based_on_fulfillment_product'
   */
  @attr('boolean') allowPrintAtHome!: boolean;

  @attr() snippets!: { prompt_after_order_html: SnippetType[] };

  @belongsTo('customer', { async: false, inverse: null })
  customer!: CustomerModel;

  @hasMany('ticket-line-item', { async: false, inverse: null })
  effectiveTicketLineItems!: DS.ManyArray<TicketLineItemModel>;

  @hasMany('connected-order', { async: false, inverse: null })
  connectedOrders!: DS.ManyArray<ConntectedOrderModel>;

  @hasMany('product-line-item', { async: false, inverse: null })
  effectiveProductLineItems!: DS.ManyArray<ProductLineItemModel>;

  @hasMany('booking-charge-line-item', { async: false, inverse: null })
  effectiveBookingChargeLineItems!: DS.ManyArray<BookingChargeLineItemModel>;

  @hasMany('fulfillment-product', { async: false, inverse: null })
  availableFulfillmentProducts!: DS.ManyArray<FulfillmentProductModel>;

  @belongsTo('fulfillment-product', { async: false, inverse: null })
  fulfillmentProduct!: FulfillmentProductModel | null;

  @hasMany('voucher-payment', { async: false, inverse: null })
  voucherPayments!: DS.ManyArray<VoucherPaymentModel>;

  @hasMany('credit-card-payment', { async: false, inverse: null })
  creditCardPayments!: DS.ManyArray<CreditCardPaymentModel>;

  @cached
  get groupedLineItems(): LineItemGroup[] {
    return [
      ...this.effectiveTicketLineItems.slice(),
      ...this.effectiveProductLineItems.slice(),
      ...this.effectiveBookingChargeLineItems.slice()
    ].reduce(
      (groups: LineItemGroup[], lineItem) => lineItem.groupWith(groups),
      []
    );
  }

  get effectiveLineItemsWithExtras() {
    return [
      ...this.effectiveTicketLineItems.slice(),
      ...this.effectiveProductLineItems.slice()
    ].filter(lineItem => lineItem.hasExtras);
  }

  get payments(): (VoucherPaymentModel | CreditCardPaymentModel)[] {
    return [
      ...this.voucherPayments.slice(),
      ...this.creditCardPayments.slice()
    ];
  }

  get hasItems(): boolean {
    return this.groupedLineItems.length > 0;
  }

  get hasExtras() {
    return this.effectiveLineItemsWithExtras.length > 0;
  }

  get hasMoreThanOnePayment() {
    return this.payments.length > 1;
  }

  get hasSingleVoucherPayment() {
    return (
      this.payments.length === 1 &&
      this.payments[0] instanceof VoucherPaymentModel
    );
  }

  get firstVoucherPayment(): VoucherPaymentModel | undefined {
    return this.voucherPayments.slice()[0];
  }

  get orderRewardPayment(): VoucherPaymentModel | undefined {
    return this.voucherPayments.find(payment => payment.isRewardPayment);
  }

  get hasRewardPayment(): boolean {
    return !!this.orderRewardPayment;
  }

  get totalItemQuantity(): number {
    return this.totalTicketsQuantity + this.totalProductQuantity;
  }

  get totalTicketsQuantity(): number {
    return this.effectiveTicketLineItems
      .slice()
      .mapBy('quantity')
      .reduce((total, q) => total + q, 0);
  }

  get totalProductQuantity(): number {
    return this.effectiveProductLineItems
      .slice()
      .mapBy('quantity')
      .reduce((total, q) => total + q, 0);
  }

  get isMinimumPaymentDue() {
    return this.minimumPayment > 0;
  }

  get anythingToPay() {
    return this.remaining > 0 || this.isMinimumPaymentDue;
  }

  get canPayInstallment() {
    return this.isMinimumPaymentDue && this.minimumPayment <= this.remaining;
  }

  toAnalytics() {
    const products = (this.effectiveProductLineItems ?? []).map(p =>
      p.toAnalytics({ quantity: p.quantity, price: p.price })
    );

    const tickets = (this.effectiveTicketLineItems ?? []).map(t =>
      t.toAnalytics({ quantity: t.quantity, price: t.price })
    );

    return [...products, ...tickets];
  }

  hasTicketsForEvent(event: EventModel) {
    return !!this.effectiveTicketLineItems.find(tli => tli.event === event);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    order: OrderModel;
  }
}
