import get from 'lodash-es/get';

import type RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type { LookAndFeel } from 'tangram/utils/look-and-feel';
import {
  LOOK_AND_FEEL_FALLBACK,
  LOOK_AND_FEEL_DEFAULT
} from 'tangram/utils/look-and-feel';
import type ErrorsService from 'ticketbooth/services/errors';

/**
 ```ts
  {
    base: string;
    shows: string;
    cart: string;
    login: string;
    'members.orders': string;
    'members.profile': string;
    'reset-password': string;
    'sign-up': string;
    orders: string;
    products: {
      path: string;
      dynamic_segment: string;
    };
    'products.benefit': string;
    'products.regular': string;
    'hpp-callback': string;
  }
  ```
 */
export type IframePathMapping = {
  [key in string]: string | { path: string; dynamic_segment: string };
};

/**
 * Currently only pojo in preload data
 *
 * Might be migrated to full ember-data model later
 */
export type McInterestModel = { id: string; name: string };

const IFRAME_PATH_LTB_TO_NTB = {
  shows: 'listings.shows',
  products: 'listings.products',
  'products.regular': 'listings.products.regular',
  'products.benefit': 'listings.products.benefit',
  'products.donation': 'listings.products.donation',
  venue: 'listings.venue',
  'venue.shows': 'listings.venue',
  show: 'listings.show',
  'show.event': 'listings.show.event',
  'show.event.waiting-list': 'listings.show.event.waiting-list',
  'event-seats': 'listings.show.event.seats',
  'event-category': 'listings.event-category',
  'login-wall.signup': 'sign-up',
  'suggested-products': 'products-prompt',
  extras: 'extras-prompt',
  'confirm-donations': 'donations-prompt',
  order: 'order-completed',
  'order.payment': 'order-completed.payment',

  // Deprecated: Probably left over from changes in LTB router
  orders: 'order-completed',
  benefits: 'listings.products.benefit',

  // Deprecated: Exists on some customer sites, but have no target in iframe_paths
  'products.donations': 'listings.products.donation'
} as const;

type VisibilitySetting = 'required' | 'display' | 'hidden';

export type CustomerFieldSetting = {
  configurable: boolean;
  visibility: VisibilitySetting;
  field_type: 'string';
  default: VisibilitySetting;
};

export type CustomerFieldsSetting = {
  address_line_1: CustomerFieldSetting;
  address_line_2: CustomerFieldSetting;
  city_town: CustomerFieldSetting;
  country: CustomerFieldSetting;
  email: CustomerFieldSetting;
  full_name: CustomerFieldSetting;
  mobile: CustomerFieldSetting;
  phone: CustomerFieldSetting;
  post_code: CustomerFieldSetting;
  region: CustomerFieldSetting;
};

export type CustomerFormFieldConfig = {
  required: boolean;
  display: boolean;
  hidden: boolean;
};

export type CustomerFormFieldsConfig = Record<
  keyof CustomerFieldsSetting,
  CustomerFormFieldConfig
>;

export default class PreloadService extends Service {
  @service private errors!: ErrorsService;
  @service private router!: RouterService;
  private preloadData!: any;

  setPreloadData(preloadData: any) {
    this.preloadData = preloadData;
  }

  getValue(key: string) {
    return get(this.preloadData, key);
  }

  lookAndFeel<T extends keyof LookAndFeel>(key: T): LookAndFeel[T] {
    let value = this.getValue(`look_and_feel.${key}`);
    if (isBlank(value) && LOOK_AND_FEEL_FALLBACK[key]) {
      value = this.getValue(`look_and_feel.${LOOK_AND_FEEL_FALLBACK[key]}`);
    }
    if (isBlank(value) && LOOK_AND_FEEL_DEFAULT[key]) {
      value = LOOK_AND_FEEL_DEFAULT[key];
    }
    return value;
  }

  get iframePaths(): IframePathMapping {
    const paths = this.getValue('iframe_paths') as IframePathMapping | null;

    if (!paths) {
      return {};
    }

    const unknown: string[] = [];

    Object.keys(paths).forEach(route => {
      if (typeof route === 'string') {
        const isLtbRoute = route in IFRAME_PATH_LTB_TO_NTB;

        if (isLtbRoute) {
          // @ts-ignore
          paths[IFRAME_PATH_LTB_TO_NTB[route]] = paths[route];
          delete paths[route];
        }
      }
    });
    Object.keys(paths).forEach(route => {
      // @ts-ignore
      if (!this.router._router.hasRoute(route) && route !== 'base') {
        unknown.push(route);
      }
    });

    if (unknown.length > 0) {
      this.errors.log(
        new Error(
          `Unknown routes in 'iframe_paths' setting: ${unknown.join(',')}`
        )
      );
    }

    return paths;
  }

  get customerFieldsForOnline() {
    return this.getValue('customer_fields_for_online');
  }

  get customerFormFieldsForOnline() {
    const { customerFieldsForOnline } = this;

    const config = customerFieldsForOnline;

    return Object.entries(
      config as Record<keyof CustomerFieldsSetting, CustomerFieldSetting>
    ).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: {
          required: value.visibility === 'required',
          display: value.visibility !== 'hidden',
          hide: value.visibility === 'hidden'
        }
      };
    }, {} as CustomerFormFieldsConfig);
  }

  get mcInterests(): McInterestModel[] {
    return this.getValue('mc_interests') ?? [];
  }
}
