/* import __COLOCATED_TEMPLATE__ from './line-item-group.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface HeaderCartLineItemGroupSignature {
  Args: {};
}

const HeaderCartLineItemGroupComponent =
  templateOnlyComponent<HeaderCartLineItemGroupSignature>();

export default HeaderCartLineItemGroupComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Header::Cart::LineItemGroup': typeof HeaderCartLineItemGroupComponent;
    'header/cart/line-item-group': typeof HeaderCartLineItemGroupComponent;
  }
}
