import Component from '@glimmer/component';

import { fn } from '@ember/helper';
import { inject as service } from '@ember/service';

import type DonationProductModel from 'ticketbooth/models/donation-product';
import type RecommendationModel from 'ticketbooth/models/recommendation';
import type CartProviderService from 'ticketbooth/services/cart-provider';

import translate from '../../../helpers/translate';
import ButtonTbPrimary from '../../button/tb/primary';
import CartProvider from '../../cart-provider';
import RecommendationsListCardsBase from './base';

interface Signature {
  Args: {
    recommendation: RecommendationModel;
    showSummary?: boolean;
  };
}

export default class DonationRecommendation extends Component<Signature> {
  @service private cartProvider!: CartProviderService;

  get product() {
    return this.args.recommendation.recommendee as DonationProductModel;
  }

  get isAlreadyInCart() {
    // Same donation can be added only once, the "Add to cart" button wouldn't work
    return this.cartProvider.cart.lineItemsForProduct(this.product).length > 0;
  }

  get price() {
    const { product } = this;
    // Price might be 0.00
    return product.price || product.sortedDonationAmounts[0];
  }

  <template>
    {{#unless this.isAlreadyInCart}}
      <RecommendationsListCardsBase
        @recommendation={{@recommendation}}
        @showSummary={{@showSummary}}
      >
        <:footer>
          <CartProvider as |c|>
            <ButtonTbPrimary
              @narrowStyle={{true}}
              class='w-full md:px-[1px]'
              @onClick={{fn
                c.actions.changeDonationProduct
                this.product
                this.price
                false
              }}
            >
              <div class='text-sm truncate'>
                {{translate 'cart.add_to_cart'}}
              </div>
            </ButtonTbPrimary>
          </CartProvider>
        </:footer>
      </RecommendationsListCardsBase>
    {{/unless}}
  </template>
}
