import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import { ZONE_MAP_OPTION } from 'ticketbooth/components/seated-event/zone-select';
import type EventModel from 'ticketbooth/models/event';
import type MasterAllocationModel from 'ticketbooth/models/master-allocation';
import type RouterScrollService from 'ticketbooth/services/-router-scroll';
import type TicketboothErrorsService from 'ticketbooth/services/errors';

export default class SeatsController extends Controller {
  @service private errors!: TicketboothErrorsService;
  @service private routerScroll!: RouterScrollService;

  queryParams = ['zone'];

  @tracked zone?: string | null;

  model!: EventModel;

  get selectedMasterAllocation(): MasterAllocationModel | null {
    const { model: event, zone } = this;

    if (isNone(zone)) {
      return null;
    }

    const masterAllocation =
      event.masterAllocations.find(({ name }) => name === zone) ??
      event.masterAllocations.find(({ name }) =>
        name.match(new RegExp(zone.trim(), 'i'))
      ) ??
      event.masterAllocations.find(({ name }) =>
        zone.match(new RegExp(name.trim(), 'i'))
      ) ??
      null;

    if (zone && !masterAllocation && zone !== ZONE_MAP_OPTION) {
      const zones = event.masterAllocations.map(({ name }) => name).join(',');
      this.errors.log(new Error(`Unknown zone: "${zone}"`), {
        extra: { zones }
      });
    }

    return masterAllocation;
  }

  @action
  changeMasterAllocation(masterAllocation: MasterAllocationModel | null) {
    this.zone = masterAllocation?.name;
  }

  @action
  changeMasterAllocationName(name: string | null) {
    this.zone = name;
  }

  @action
  scrollBy(x: number, y: number) {
    this.routerScroll.scrollBy(x, y);
  }
}
