export default {
  sm: '(max-width: 600px)',
  md: '(min-width: 601px) and (max-width: 1024px)',
  lg: '(min-width: 1025px)',
  hover: '(hover: hover)',
  /**
   * At least one of the pointer inputs is coarse, best to make "touch targets" bigger
   *
   * https://css-tricks.com/interaction-media-features-and-their-potential-for-incorrect-assumptions/
   */
  touch: '(any-pointer: coarse)'
};
