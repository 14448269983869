/**
 * Examples:
 *   - 1173625729%E2%AD%90%EF%B8%8F -> 1173625729
 *   - 1173623435https -> 1173623435
 *   - 1173627362&cache=&_operator_refresh=true -> 1173627362
 *
 * See also https://sentry.io/organizations/ticketsolve/issues/3444092144/events/
 */
export function extractIdFromParam(param: string): string | null {
  const matches = param.match(/[0-9]+/);
  if (matches) {
    return matches[0];
  }
  return null;
}
