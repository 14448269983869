/* import __COLOCATED_TEMPLATE__ from './product-small.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CoverProductSmallSignature {
  Args: {};
}

const CoverProductSmallComponent =
  templateOnlyComponent<CoverProductSmallSignature>();

export default CoverProductSmallComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::ProductSmall': typeof CoverProductSmallComponent;
    'cover/product-small': typeof CoverProductSmallComponent;
  }
}
