import Model from '@ember-data/model';

abstract class AnalyticsModel extends Model {
  abstract get googleAnalyticsSku(): string;
  abstract get googleAnalyticsName(): string;
  abstract get googleAnalyticsBrand(): string;
  abstract get googleAnalyticsCategory(): string;
  abstract get googleAnalyticsVariant(): string;

  toAnalytics(data: object = {}) {
    return {
      id: this.googleAnalyticsSku,
      name: this.googleAnalyticsName,
      item_id: this.googleAnalyticsSku,
      item_name: this.googleAnalyticsName,
      item_brand: this.googleAnalyticsBrand,
      item_category: this.googleAnalyticsCategory,
      item_variant: this.googleAnalyticsVariant,
      ...data
    };
  }
}

export default AnalyticsModel;
