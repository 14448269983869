/* import __COLOCATED_TEMPLATE__ from './share-widget.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type MediaService from 'ember-responsive/services/media';

import type ShowModel from 'ticketbooth/models/show';
import type LocaleService from 'ticketbooth/services/locale';
import type PreloadService from 'ticketbooth/services/preload';

interface ShowShareWidgetSignature {
  Args: {
    show: ShowModel;
  };
}

export default class ShowShareWidgetComponent extends Component<ShowShareWidgetSignature> {
  @service locale!: LocaleService;
  @service preload!: PreloadService;
  @service media!: MediaService;

  get canShare() {
    return navigator.share !== undefined;
  }

  get shareMessage() {
    return `${this.locale.translate('social.share_message')} ${this.shareUrl}`;
  }

  get subdomain(): string {
    return this.preload.getValue('account_name');
  }

  get shareUrl() {
    return new URL(`${window.location.origin}/share/${this.args.show.id}`).href;
  }

  @action
  async share() {
    try {
      const options: ShareData = {
        url: this.shareUrl
      };
      if (this.media.isSm) {
        // On desktop, if only share the url, it gives more options to the user
        options.title = `${this.subdomain} - ${this.args.show.name}`;
        options.text = `${this.subdomain} - ${this.args.show.name}.\n${this.shareMessage}`;
      }
      await navigator.share(options);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error sharing', error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::ShareWidget': typeof ShowShareWidgetComponent;
    'show/share-widget': typeof ShowShareWidgetComponent;
  }
}
