import Service from '@ember/service';
import { inject as service } from '@ember/service';

import CartModel from 'ticketbooth/models/cart';
import OrderModel from 'ticketbooth/models/order';
import type DataLayersService from 'ticketbooth/services/data-layers';
import type ErrorsService from 'ticketbooth/services/errors';
import type SettingsService from 'ticketbooth/services/settings';

export type GAItem = {
  item_id: string; // The sku or item id, required if name not given
  item_name: string; // The items name, required if id not given
  item_brand: string;
  item_category: string;
  item_variant: string;

  id: string;
  name: string;
  price?: number;
};

type GAEvent = {
  event?: string;
  method?: string;
  ecommerce?: {
    transaction_id?: string | number;
    currency: string;
    value?: number;
    items: GAItem[];
  };
};

type GAItems = GAItem[];

export type ShareMethod = 'twitter' | 'email' | 'facebook';

type ShareEvent = {
  method: ShareMethod;
  item_id: number | string;
  content_type: 'show';
};

export default class GoogleAnalyticsService extends Service {
  @service dataLayers!: DataLayersService;
  @service settings!: SettingsService;
  @service errors!: ErrorsService;

  get currency(): string {
    return this.settings.getSetting('currency') || 'EUR';
  }

  push(data: GAEvent, layers?: Array<string>) {
    try {
      return this.dataLayers.push(data, layers);
    } catch (error) {
      // Prevent analytics errors from surfacing to users
      this.errors.log(error);
    }
  }

  // If the item has a key called `item_id` we assume it is a GAItem
  isGAItem(gaItem: any): gaItem is GAItem {
    return typeof gaItem?.item_id === 'string';
  }

  isGAItems(gaItems: any): gaItems is GAItem[] {
    return Array.isArray(gaItems) && this.isGAItem(gaItems[0]);
  }

  getValue(data?: OrderModel | CartModel | GAItems | GAItem) {
    if (!data) return undefined;
    if (this.isGAItem(data)) return data.price;
    if (data instanceof CartModel) return data.total;
    if (data instanceof OrderModel) return data.total;
    if (this.isGAItems(data)) {
      return data.reduce((acc, { price }) => acc + (price ?? 0), 0);
    }
    return undefined;
  }

  login(method: string = 'email') {
    return this.push({ event: 'login', method });
  }

  signup(method: string = 'email') {
    return this.push({ event: 'sign_up', method });
  }

  viewCart(cart?: CartModel) {
    return this.addCartInfo('view_cart', cart);
  }

  beginCheckout(cart?: CartModel) {
    return this.addCartInfo('begin_checkout', cart);
  }

  addToCart(items: GAItems) {
    return this.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: this.currency,
        value: this.getValue(items),
        items
      }
    });
  }

  removeFromCart(items: GAItems) {
    return this.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: this.currency,
        value: this.getValue(items),
        items
      }
    });
  }

  viewItem(item: GAItem) {
    return this.push({
      event: 'view_item',
      ecommerce: {
        currency: this.currency,
        value: this.getValue(item),
        items: [item]
      }
    });
  }

  viewItemList(items: GAItems, extras: { listName?: string } = {}) {
    return this.push({
      event: 'view_item_list',
      ecommerce: {
        currency: this.currency,
        value: this.getValue(items),
        items
      },
      ...extras
    });
  }

  selectItem(item: GAItem) {
    return this.push({
      event: 'select_item',
      ecommerce: {
        currency: this.currency,
        value: this.getValue(item),
        items: [item]
      }
    });
  }

  addShippingInfo(cart?: CartModel) {
    return this.addCartInfo('add_shipping_info', cart);
  }

  addPaymentInfo(cart?: CartModel) {
    return this.addCartInfo('add_payment_info', cart);
  }

  purchase(order: OrderModel) {
    return this.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: `${order.id}`,
        currency: this.currency,
        value: this.getValue(order),
        items: order.toAnalytics()
      }
    });
  }

  share(data: ShareEvent) {
    this.push(data);
  }

  // This is only used to process cart information, it should not be
  // used externally as it does not represent an event.
  private addCartInfo(event: string, cart?: CartModel) {
    const data: any = {
      event,
      ecommerce: {
        currency: this.currency,
        value: this.getValue(cart),
        items: cart?.toAnalytics() || []
      }
    };

    if (cart?.fulfillmentLineItem) {
      data.ecommerce.shipping_tier = cart.fulfillmentLineItem.name;
    }

    if (cart?.promotionCode) {
      data.ecommerce.coupon = cart.promotionCode;
    }

    return this.push(data);
  }
}
