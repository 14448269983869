import type { BrandingOptions } from 'tangram/modifiers/base-branding';
import { MODIFIER_OPTIONS } from 'tangram/utils/look-and-feel';

import BrandingModifier from './branding';

export default class extends BrandingModifier<{}> {
  protected getOptions(): BrandingOptions {
    return MODIFIER_OPTIONS.CARD_BACKGROUND_COLOR();
  }
}
