import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import Modifier from 'ember-modifier';
import { Element } from 'ember-modifier/-private/class/modifier';

import type IframeService from 'ticketbooth/services/iframe';
import type IframePositionService from 'ticketbooth/services/iframe-position';

interface Args {
  positional: [];
  named: {
    pinBottom?: boolean;
  };
}

export default class extends Modifier<Args> {
  @service private iframe!: IframeService;
  @service private iframePosition!: IframePositionService;

  // @ts-ignore
  get element() {
    // @ts-ignore
    return this[Element];
  }

  private prevTop?: string;
  private prevBottom?: string;

  didInstall() {
    if (!this.iframe.isActive) {
      return;
    }

    if (this.args.named.pinBottom) {
      this.prevTop = this.element.style.top;
      this.prevBottom = this.element.style.bottom;
      this.element.style.top = '0';
      this.element.style.bottom = 'auto';
    }
    this.iframePosition.onPositionChange(this.updateElementPosition);
  }

  willDestroy(): void {
    if (!this.iframe.isActive) {
      return;
    }

    if (this.args.named.pinBottom) {
      this.element.style.top = this.prevTop!;
      this.element.style.bottom = this.prevBottom!;
    }
    this.iframePosition.offPositionChange(this.updateElementPosition);
  }

  @action
  private updateElementPosition(top: number, bottom: number) {
    if (this.args.named.pinBottom) {
      const offset = bottom - this.element.offsetHeight;
      this.element.style.transform = `translateY(${offset}px) translateZ(0)`;
    } else {
      const offset = top;
      this.element.style.transform = `translateY(${offset}px) translateZ(0)`;
    }
  }
}
