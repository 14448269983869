import type { Store } from '@ember-data/store';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import { extractIdFromParam } from 'ticketbooth/utils/query-param';

import type ShowRoute from '../show';

type RouteParams = { event_id: string };

export default class ShowEventRoute extends Route {
  @service private router!: RouterService;
  @service store!: Store;

  private get show(): Unwrap<ShowRoute['model']> {
    return this.modelFor('listings.show');
  }
  private get params(): Partial<RouteParams> {
    return this.paramsFor('listings.show.event');
  }

  beforeModel() {
    const { event_id } = this.params;

    if (event_id && extractIdFromParam(event_id) === null) {
      this.router.transitionTo('listings.show', this.show);
    }
  }

  model({ event_id }: RouteParams): Promise<EventModel> {
    return this.store.loadRecord('event', extractIdFromParam(event_id)!, {
      reload: true
    });
  }

  afterModel(event: Unwrap<ShowEventRoute['model']>) {
    if (this.show.salesDisabled) {
      this.router.transitionTo('listings.show.index');
    }
    if (this.isEventShowMismatch(event)) {
      this.router.transitionTo('listings.show', this.show);
    }
  }

  private isEventShowMismatch(event: EventModel) {
    // Compare ids as event's show might not be loaded
    return this.show.id !== event.belongsTo('show').id();
  }
}
