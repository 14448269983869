import type EventModel from 'ticketbooth/models/event';

/**
 * Pick next bookable event or fallback to first soldout
 */
export function findNextBookableEvent(events: EventModel[]): EventModel | null {
  return (
    events.find(event => event.isBookable) ??
    (events.length > 0 ? events[0] : null)
  );
}
