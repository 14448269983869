/* import __COLOCATED_TEMPLATE__ from './benefit-product.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RecommendationsListCardsBenefitProductSignature {
  Args: {};
}

const RecommendationsListCardsBenefitProductComponent =
  templateOnlyComponent<RecommendationsListCardsBenefitProductSignature>();

export default RecommendationsListCardsBenefitProductComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RecommendationsList::Cards::BenefitProduct': typeof RecommendationsListCardsBenefitProductComponent;
    'recommendations-list/cards/benefit-product': typeof RecommendationsListCardsBenefitProductComponent;
  }
}
