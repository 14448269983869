import { inject as service } from '@ember/service';

import Modifier from 'ember-modifier';
import { Element } from 'ember-modifier/-private/class/modifier';

import type PreloadService from 'ticketbooth/services/preload';

export default class extends Modifier {
  @service private preload!: PreloadService;

  // @ts-ignore
  get element() {
    // @ts-ignore
    return this[Element];
  }

  didInstall() {
    this.element.style.setProperty(
      '--placeholder-color',
      this.preload.getValue('look_and_feel.card_fg_color')
    );
  }
}
