import type ApplicationInstance from '@ember/application/instance';

import { isDark, isColor, transformColor } from 'tangram/utils/color';
import type PreloadService from 'ticketbooth/services/preload';

export function initialize(appInstance: ApplicationInstance): void {
  const preload = appInstance.lookup('service:preload') as PreloadService;
  const lookAndFeel = preload.getValue('look_and_feel');

  const accentColor = lookAndFeel['accent_color'];
  const cardBgColor = lookAndFeel['card_bg_color'];
  const fontUrl1 = lookAndFeel['font_url_1'];
  const headingFont = lookAndFeel['heading_font'];
  const fontUrl2 = lookAndFeel['font_url_2'];
  const linksColor = lookAndFeel['links_color'];

  // Form controls: main content context
  const focusBorderColor = _luminanceVariant(accentColor, 10);
  const ringColor = _luminanceVariant(cardBgColor, 30);

  // Form controls: card context
  const cardFocusBorderColor = _luminanceVariant(cardBgColor, -10);
  const cardActiveBorderColor = _luminanceVariant(cardBgColor, 10);
  const cardRingColor = _luminanceVariant(cardBgColor, 30);

  const colors: any = {
    'normal-border': accentColor,
    'focus-border': focusBorderColor,
    ring: ringColor,
    'card-normal-border': accentColor,
    'card-focus-border': cardFocusBorderColor,
    'card-active-border': cardActiveBorderColor,
    'card-ring': cardRingColor
  };

  const colorsSnippet = Object.entries(colors)
    .map(
      ([colorName, color]) => `--colors-form-controls-${colorName}: ${color};`
    )
    .join('\n');

  const styleSnippet = document.createElement('style');
  styleSnippet.id = 'branding-variables';
  styleSnippet.innerHTML = '';
  if (fontUrl1) {
    styleSnippet.innerHTML += '@import url("' + fontUrl1 + '");\n';
  }
  if (fontUrl2 && fontUrl2 !== fontUrl1) {
    styleSnippet.innerHTML += '@import url("' + fontUrl2 + '");\n';
  }
  if (!fontUrl1 && !fontUrl2) {
    styleSnippet.innerHTML +=
      '@import url("https://d235gwso45fsgz.cloudfront.net/ticketbooth/v2/ticketbooth/css/Graphik.css");\n';
  }
  styleSnippet.innerHTML += `
    :root {\n
      ${colorsSnippet}\n
  `;
  if (headingFont) {
    styleSnippet.innerHTML += `--heading-font: ${headingFont};\n`;
  }
  if (linksColor) {
    styleSnippet.innerHTML += `--links-color: ${linksColor};\n`;
  }
  styleSnippet.innerHTML += `}`;

  document.head.append(styleSnippet);
}

// Given a color, return the same color with a luminance offset depending
// if it's a dark or light color.
function _luminanceVariant(color: string, offset: number) {
  if (!isColor(color)) return color;
  return transformColor(color, {
    luminance: isDark(color) ? offset : offset * -1
  });
}

export default {
  after: 'settings',
  initialize
};
