/* import __COLOCATED_TEMPLATE__ from './extras.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarCardsExtrasSignature {
  Args: {};
}

const SidebarCardsExtrasComponent =
  templateOnlyComponent<SidebarCardsExtrasSignature>();

export default SidebarCardsExtrasComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sidebar::Cards::Extras': typeof SidebarCardsExtrasComponent;
    'sidebar/cards/extras': typeof SidebarCardsExtrasComponent;
  }
}
