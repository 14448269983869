import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { ObjectSchema } from 'yup';
import { object, string } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type { Link } from 'ember-link';

import Button from 'tangram/components/button';
import Form from 'tangram/components/form';
import FormCheckbox from 'tangram/components/form/checkbox';
import FormForm from 'tangram/components/form/form';
import FormInput from 'tangram/components/form/input';
import FormPassword from 'tangram/components/form/password';
import FormRow from 'tangram/components/form/row';
import type NotificationsService from 'tangram/services/notifications';
import FormObject from 'tangram/utils/form-object';
import type LocaleService from 'ticketbooth/services/locale';

import translate from '../helpers/translate';
import TbFormCta from './form/cta';
import FormNavbarCta from './form/navbar-cta';

class MemberLoginForm extends FormObject<MemberLoginForm> {
  @tracked email!: string;
  @tracked password!: string;
  @tracked rememberMe!: boolean;
}

interface MemberLoginFormSignature {
  Args: {
    isNavbar?: boolean;
    displayInline?: boolean;
    signupLink?: Link;
    forgotPasswordLink?: Link;

    onSubmit: (
      email: string,
      password: string,
      rememberMe: boolean
    ) => Promise<void>;
  };
}

export default class MemberLoginFormComponent extends Component<MemberLoginFormSignature> {
  @service locale!: LocaleService;
  @service notifications!: NotificationsService;

  @tracked formObject: MemberLoginForm;
  @tracked schema: ObjectSchema<ObjectShape>;

  constructor(owner: unknown, args: MemberLoginFormSignature['Args']) {
    super(owner, args);

    this.formObject = this.createFormObject();
    this.schema = this.createSchema();
  }

  private createFormObject() {
    return new MemberLoginForm({ email: '', password: '', rememberMe: false });
  }

  private createSchema() {
    const t = (msg: string) => this.locale.translate(msg);
    const cantBeBlank = t('activerecord.errors.messages.blank');
    return object().shape({
      email: string().required(cantBeBlank).max(100),
      password: string().required(cantBeBlank).max(96)
    });
  }

  @action
  async onSubmit(form: MemberLoginForm) {
    await this.args.onSubmit(form.email, form.password, form.rememberMe);
  }

  <template>
    <Form
      @formObject={{this.formObject}}
      @schema={{this.schema}}
      @onSubmit={{this.onSubmit}}
      as |f|
    >
      <FormForm @f={{f}}>
        <div class="px-2 py-4 text-sm">
          {{#if @displayInline}}
            <div class="mb-2">
              <FormInput @f={{f}}
                @wrapperClasses="w-full"
                @property="email"
                @inline={{true}}
                @placeholder={{translate "member.email_plain"}}
                @testSelector="member-email"
                aria-label={{translate "member.email"}}
              />
            </div>
            <div class="mb-2">
              <FormPassword @f={{f}}
                @wrapperClasses="w-full"
                @property="password"
                @inline={{true}}
                @placeholder={{translate "member.password"}}
                @testSelector="member-password"
                aria-label={{translate "member.password"}}
              />
            </div>
          {{else}}
            <FormRow>
              <FormInput @f={{f}} @property="email" @label={{translate "member.email"}} @testSelector="member-email" />
            </FormRow>
            <FormRow>
              <FormPassword @f={{f}} @property="password" @label={{translate "member.password"}} @testSelector="member-password" />
            </FormRow>
          {{/if}}

          {{#if @isNavbar}}
            <FormNavbarCta @f={{f}} data-test-member-login>{{translate "member.login"}}</FormNavbarCta>
          {{else}}
            <TbFormCta @f={{f}} data-test-member-login>{{translate "member.login"}}</TbFormCta>
          {{/if}}

          <FormRow @marginBottom="4">
            <FormCheckbox @f={{f}} @property="rememberMe" @label={{translate "member.remember_me"}} />
          </FormRow>

          {{#if @signupLink}}
            <Button
              @onClick={{@signupLink.transitionTo}}
              class="w-full p-2 mt-2 text-xs font-semibold text-center uppercase border"
              data-test-register-link
            >
              {{translate "member.register"}}
            </Button>
          {{/if}}

          {{#if @forgotPasswordLink}}
            <Button
              @onClick={{@forgotPasswordLink.transitionTo}}
              @testSelector="forgot-password"
              class="w-full p-2 mt-2 text-xs font-semibold text-center uppercase border"
            >
              {{translate "member.forgot_your_password"}}
            </Button>
          {{/if}}
        </div>
      </FormForm>
    </Form>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MemberLoginForm: typeof MemberLoginFormComponent;
    'member-login-form': typeof MemberLoginFormComponent;
  }
}
