import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';

export default class extends Controller {
  queryParams = ['token'];

  @tracked
  token: string | null = null;
}
