import { attr } from '@ember-data/model';

import ProductModel from './product';

export default class DonationProductModel extends ProductModel {
  isDonationProduct: boolean = true;

  @attr('boolean') allowPriceOverride!: boolean;
  /**
   * Unused when `giftaidable` is true.
   *
   * Some customers use the declaration for something else than gift aid,
   * but in the gift aid context the gift aid checkout step is used instead.
   */
  @attr('string') declaration!: string;
  @attr() donationAmounts!: number[];

  get sortedDonationAmounts() {
    return this.donationAmounts.sort((a, b) => a - b);
  }

  get googleAnalyticsVariant(): string {
    return 'product/donation';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'donation-product': DonationProductModel;
  }
}
