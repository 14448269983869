import { ForbiddenError } from '@ember-data/adapter/error';
import type Store from '@ember-data/store';

import type MemberModel from 'ticketbooth/models/member';
import {
  INCLUDE_ALL_FIELDS_STRING,
  INCLUDE_ALL_STRING
} from 'ticketbooth/utils/member-api';

import ApplicationAdapter from './application';
export default class MemberAdapter extends ApplicationAdapter {
  pathForType() {
    return 'member';
  }

  /**
   * Member is a singleton resource, so we direct all queries to the /member endpoint
   */
  buildURL() {
    return this.urlForQueryRecord({}, 'member');
  }

  urlForLogin() {
    return this.buildURL() + '/login';
  }

  urlForSignup() {
    return this.buildURL() + '/signup';
  }

  urlForValidate() {
    return this.buildURL() + '/check_email';
  }

  urlForForgotPassword() {
    return this.buildURL() + '/forgot_password';
  }

  urlForResetPassword() {
    return this.buildURL() + '/reset_password';
  }

  async login(
    store: Store,
    email: string,
    password: string,
    remember_me: boolean
  ): Promise<MemberModel> {
    const url = `${this.urlForLogin()}?${INCLUDE_ALL_STRING}&${INCLUDE_ALL_FIELDS_STRING}`;
    return this.serializeAndPush(
      store,
      'member',
      await this.fetch('POST', url, { data: { email, password, remember_me } })
    );
  }

  async signup(
    store: Store,
    email: string,
    password: string,
    emailConfirmation?: string,
    passwordConfirmation?: string,
    customer?: { firstName: string; lastName: string; phone: string } | null,
    token?: string | null
  ): Promise<MemberModel> {
    const url = `${this.urlForSignup()}?${INCLUDE_ALL_STRING}&${INCLUDE_ALL_FIELDS_STRING}`;
    return this.serializeAndPush(
      store,
      'member',
      await this.fetch('POST', url, {
        data: {
          benefit_token: token,
          email,
          password,
          email_confirmation: emailConfirmation,
          password_confirmation: passwordConfirmation,
          ...(customer
            ? {
                customer: {
                  data: {
                    type: 'customers',
                    attributes: {
                      'first-name': customer.firstName,
                      'last-name': customer.lastName,
                      phone: customer.phone
                    }
                  }
                }
              }
            : {})
        }
      })
    );
  }

  async validate(email: string): Promise<boolean> {
    const url = this.urlForValidate();
    try {
      // Returns 204 or 403 status code (`ForbiddenError`)
      await this.fetch('POST', url, { data: { email } });

      return true;
    } catch (error) {
      if (error instanceof ForbiddenError) {
        return false;
      }

      throw error;
    }
  }

  async forgotPassword(email: string, checkout: boolean): Promise<void> {
    const url = this.urlForForgotPassword();
    await this.fetch<void>('POST', url, { data: { email, checkout } });
  }
  async resetPassword(
    store: Store,
    email: string,
    hash: string,
    password: string,
    passwordConfirmation: string
  ): Promise<MemberModel> {
    const url = `${this.urlForResetPassword()}?${INCLUDE_ALL_STRING}&${INCLUDE_ALL_FIELDS_STRING}`;
    return this.serializeAndPush(
      store,
      'member',
      await this.fetch('POST', url, {
        data: {
          email,
          hash,
          password,
          password_confirmation: passwordConfirmation
        }
      })
    );
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    member: MemberAdapter;
  }
}
