import { helper } from '@ember/component/helper';

import type { LineItemGroup } from 'ticketbooth/models/line-item';
import { isTicketGroup } from 'ticketbooth/models/line-item';

export function isTicketGroupType([lineItemGroup]: [LineItemGroup]) {
  return isTicketGroup(lineItemGroup);
}

export default helper(isTicketGroupType);
