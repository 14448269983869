import { attr } from '@ember-data/model';
import Model from '@ember-data/model';

const YOUTUBE = /youtube.+v=([\w-]+)/;
const VIMEO = /vimeo.+\/(\d+)/;
const HTTP_LINK = /^(http|https):\/\//;
const WWW_LINK = /^www\./;

export default class ShowPropertyModel extends Model {
  @attr('string') name!: string;
  @attr('string') value!: string;

  get hasVideo(): boolean {
    return !!this.embedURL;
  }

  get hasLink(): boolean {
    return !!this.linkURL;
  }

  get embedURL(): string | null {
    return this.youtubeURL ?? this.vimeoURL;
  }

  get youtubeURL(): string | null {
    const matches = this.value.match(YOUTUBE);
    return matches
      ? `https://www.youtube.com/embed/${matches[1]}?rel=0&wmode=opaque;controls=0`
      : null;
  }

  get vimeoURL(): string | null {
    const matches = this.value.match(VIMEO);
    return matches ? `https://player.vimeo.com/video/${matches[1]}` : null;
  }

  get linkURL(): string | null {
    return this.wwwURL ?? this.httpURL ?? null;
  }

  get wwwURL(): string | null {
    return WWW_LINK.test(this.value) ? `http://${this.value}` : null;
  }

  get httpURL(): string | null {
    return HTTP_LINK.test(this.value) ? this.value : null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'show-property': ShowPropertyModel;
  }
}
