/* import __COLOCATED_TEMPLATE__ from './member-login.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface HeaderMemberLoginSignature {
  Args: {};
}

const HeaderMemberLoginComponent =
  templateOnlyComponent<HeaderMemberLoginSignature>();

export default HeaderMemberLoginComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Header::MemberLogin': typeof HeaderMemberLoginComponent;
    'header/member-login': typeof HeaderMemberLoginComponent;
  }
}
