import { attr } from '@ember-data/model';
import Model from '@ember-data/model';

import type CartModel from './cart';

export default class RedemptionModel extends Model {
  @attr('string') code!: string;
  @attr('number') amount!: number;
  @attr('number') redeemedAmount!: number;

  get remaining(): number {
    return this.amount - this.redeemedAmount;
  }

  remainingMinusComittedToCart(cart: CartModel): number {
    return (
      this.remaining -
      cart.voucherPayments
        .filter(({ redemption }) => redemption === this)
        .reduce((committed: number, { amount }) => committed + amount, 0)
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    redemption: RedemptionModel;
  }
}
