import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type NotificationsService from 'tangram/services/notifications';
import type IframeService from 'ticketbooth/services/iframe';
import type IframeRouterService from 'ticketbooth/services/iframe-router';
import type MessagingService from 'ticketbooth/services/messaging';

import BasePaymentPageRoute from '../-base-payment-page';
import type { HppErrorMessage, HppSuccessMessage } from '../confirm-order';
import { isHppErrorMessage, isHppSuccessMessage } from '../confirm-order';

export default class extends BasePaymentPageRoute {
  @service private messaging!: MessagingService;
  @service private notifications!: NotificationsService;
  @service private iframe!: IframeService;
  @service private iframeRouter!: IframeRouterService;
  @service router!: RouterService;

  override parentRouteName = 'order-completed.payment';

  activate() {
    super.addPaymentPageCSP();

    this.messaging.onMessage(this.displayCCPaymentNotification);
  }

  deactivate() {
    this.messaging.offMessage(this.displayCCPaymentNotification);
  }

  @action
  willTransition(transition: Transition) {
    super.removePaymentPageCSP(transition);
  }

  @action
  private displayCCPaymentNotification(
    data?: HppErrorMessage | HppSuccessMessage
  ) {
    if (isHppErrorMessage(data)) {
      this.notifications.error(data.message);
    } else if (isHppSuccessMessage(data)) {
      if (this.iframe.isActive) {
        this.iframeRouter.disableParentHppRedirect();
      }

      this.router.replaceWith('hpp-callback', data.uuid);
    }
  }
}
