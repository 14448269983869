import { attr } from '@ember-data/model';

import ProductModel from './product';

export default class BenefitProductModel extends ProductModel {
  /**
   * Unused when `giftaidable` is true.
   *
   * Some customers use the declaration for something else than gift aid,
   * but in the gift aid context the gift aid checkout step is used instead.
   */
  @attr('string') declaration!: string;

  get googleAnalyticsVariant(): string {
    return 'product/benefit';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'benefit-product': BenefitProductModel;
  }
}
