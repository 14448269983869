import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type MembershipService from 'ticketbooth/services/membership';

export enum CheckoutSteps {
  Checkout = 1,
  ConfirmOrder = 2
}

export default class extends Route {
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;
  @service private router!: RouterService;
  @service store!: Store;

  async beforeModel() {
    if (this.cartProvider.isEmpty) {
      this.router.replaceWith('cart');
    } else if (this.membership.requiresLogin) {
      this.router.replaceWith('login-wall');
    }
  }

  async model() {
    await this.cartProvider.cart.selectDefaultFulfillmentProduct();
  }

  async activate() {
    this.membership.onLogin(this.reloadCart);
  }

  deactivate() {
    this.membership.offLogin(this.reloadCart);
  }

  /**
   * The cart might have a different customer when a member is logged in, so we
   * make sure to reload the cart.
   */
  @action
  private async reloadCart() {
    await this.cartProvider.reload();
  }
}
