export function isRunningIframeTest() {
  return (
    new URLSearchParams(window.location.search).get('iframe-test') === 'true'
  );
}

type PreloadDocument = {
  data: object;
};

export function addIframeTestPreload(doc: PreloadDocument): PreloadDocument {
  doc.data = {
    ...doc.data,
    home_url: `${location.origin}//ticketbooth/iframe-tester.html`,
    ticketbooth_iframe: true,
    ticketbooth_iframe_routing: true,
    ticketbooth_iframe_home_url_redirect: false,
    iframe_paths: {
      cart: '/ticketbooth/iframe-tester-cart.html',
      'hpp-callback': '/ticketbooth/iframe-tester.html'
    }
  };

  return doc;
}
