import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type MembershipService from 'ticketbooth/services/membership';

export default class extends Route {
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;
  @service private router!: RouterService;

  async beforeModel() {
    if (!this.membership.isEnabled) {
      this.router.replaceWith('listings.shows');
    }
    if (this.membership.isLoggedIn) {
      this.goToMemberProfile();
    }
  }

  activate() {
    this.membership.onLogin(this.goToMemberProfile);
  }

  deactivate() {
    this.membership.offLogin(this.goToMemberProfile);
  }

  @action
  private async goToMemberProfile() {
    await this.cartProvider.reload();

    this.router.transitionTo('members.profile');
  }
}
