/* import __COLOCATED_TEMPLATE__ from './time-selection.hbs'; */
import Component from '@glimmer/component';

import type EventModel from 'ticketbooth/models/event';

interface ShowEventsListTimeSelectionSignature {
  Args: {
    day: string;
    events: EventModel[];
    registerActor: Function;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class ShowEventsListTimeSelectionComponent extends Component<ShowEventsListTimeSelectionSignature> {
  get events(): EventModel[] {
    return this.args.events.sortBy('dateTime');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::TimeSelection': typeof ShowEventsListTimeSelectionComponent;
    'show/events-list/time-selection': typeof ShowEventsListTimeSelectionComponent;
  }
}
