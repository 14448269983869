import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type { SnippetType } from 'ticketbooth/lib/locales';
import type { LocaleParams } from 'ticketbooth/services/locale';
import type LocaleService from 'ticketbooth/services/locale';

export interface TranslateSignature {
  Args: {
    Positional: [string | SnippetType[] | undefined];
    Named?: LocaleParams;
  };
  Return: string;
}

export default class TranslateHelper extends Helper<TranslateSignature> {
  @service() locale!: LocaleService;

  compute(
    [snippet]: [string | SnippetType[] | undefined],
    options?: LocaleParams
  ) {
    return this.locale.translate(snippet, options);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    translate: typeof TranslateHelper;
  }
}
