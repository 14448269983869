import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type NotificationsService from 'tangram/services/notifications';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type IframeService from 'ticketbooth/services/iframe';
import type IframeRouterService from 'ticketbooth/services/iframe-router';
import type LocaleService from 'ticketbooth/services/locale';
import type MembershipService from 'ticketbooth/services/membership';
import type MessagingService from 'ticketbooth/services/messaging';

import BasePaymentPageRoute from './-base-payment-page';

export type HppErrorMessage = {
  event: 'hppError';
  message: string;
  http_code: number;
  recaptcha?: 'true' | 'false';
};
export type HppSuccessMessage = {
  event: 'hppSuccess';
  uuid: string;
};

export function isHppErrorMessage(data: any): data is HppErrorMessage {
  return (
    typeof data === 'object' &&
    'event' in data &&
    'message' in data &&
    data.event === 'hppError'
  );
}

export function isHppSuccessMessage(data: any): data is HppSuccessMessage {
  return (
    typeof data === 'object' &&
    'event' in data &&
    'uuid' in data &&
    data.event === 'hppSuccess'
  );
}

export default class extends BasePaymentPageRoute {
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;
  @service private messaging!: MessagingService;
  @service private notifications!: NotificationsService;
  @service private locale!: LocaleService;
  @service private iframe!: IframeService;
  @service private iframeRouter!: IframeRouterService;
  @service router!: RouterService;

  override parentRouteName = 'confirm-order';

  async beforeModel() {
    if (this.cartProvider.isEmpty || this.cartProvider.isExpired) {
      this.router.replaceWith('cart');
    } else if (this.membership.requiresLogin) {
      this.router.replaceWith('login-wall');
    }
  }

  async model() {
    await this.cartProvider.cart.selectDefaultFulfillmentProduct();
  }

  activate() {
    super.addPaymentPageCSP();

    this.membership.onLogin(this.reloadCart);
    this.messaging.onMessage(this.displayCCPaymentNotification);
    this.cartProvider.onExpiration(this.redirectToCartPage);
  }

  deactivate() {
    this.membership.offLogin(this.reloadCart);
    this.messaging.offMessage(this.displayCCPaymentNotification);
    this.cartProvider.offExpiration(this.redirectToCartPage);
  }

  @action
  willTransition(transition: Transition) {
    super.removePaymentPageCSP(transition);
  }

  @action
  private async reloadCart() {
    await this.cartProvider.reload();
  }

  @action
  private displayCCPaymentNotification(
    data?: HppErrorMessage | HppSuccessMessage
  ) {
    if (isHppErrorMessage(data)) {
      this.notifications.error(data.message);
    } else if (isHppSuccessMessage(data)) {
      this.notifications.success(
        this.locale.translate('orders.successfully_placed')
      );

      if (this.iframe.isActive) {
        this.iframeRouter.disableParentHppRedirect();
      }

      this.router.replaceWith('hpp-callback', data.uuid);
    }
  }

  @action
  private redirectToCartPage() {
    this.router.transitionTo('cart');
  }
}
