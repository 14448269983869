import type ApplicationInstance from '@ember/application/instance';

import type IframeService from 'ticketbooth/services/iframe';

import { QUEUEIT_TOKENS } from './clean-query-string-params';

const RESTORE_SESSION_FLAG = 'ts-restore-session-cookie';

function hasQueueItToken() {
  const queryParamKeys = [...new URLSearchParams(location.search).keys()];
  return QUEUEIT_TOKENS.some(queueItKey => queryParamKeys.includes(queueItKey));
}

function hasStorageAccessApi() {
  // @ts-ignore - upgrade to ts v4 for storage access types
  return typeof document.hasStorageAccess !== 'undefined';
}

function hasRestoredSession() {
  return localStorage.getItem(RESTORE_SESSION_FLAG) === '1';
}

/**
 * Reloading the page escapes the cookie blocking as we're not anymore redirected by a 3rd party
 * domain.
 *
 * A session cookie can now be set in the response headers or an existing session cookie can be
 * sent to the server and answered with a matching csrf token.
 */
function restoreSession() {
  localStorage.setItem(RESTORE_SESSION_FLAG, '1');
  location.reload();
}

function cleanupSessionFlag() {
  localStorage.removeItem(RESTORE_SESSION_FLAG);
}

/**
 * See https://webkit.org/blog/10218/full-third-party-cookie-blocking-and-more/
 *
 * > ... once a request is blocked from using cookies, all redirects of that request are also
 * > blocked from using cookies.
 *
 * Try to detect this scenario by checking for
 *   - a 3rd party redirection (queue-it)
 *   - browser support for storage access api - as it can be used to grant cookie access by user interaction
 */
function isPossiblyInCookieBlockLatchMode(inIframe: boolean) {
  return inIframe && hasStorageAccessApi() && hasQueueItToken();
}

export function initialize(inIframe: boolean) {
  if (isPossiblyInCookieBlockLatchMode(inIframe)) {
    if (!hasRestoredSession()) {
      restoreSession();
    } else {
      cleanupSessionFlag();
    }
  }
}

/**
 * Workaround for the Cookie Blocking Latch Mode introduced by Safari 13.1
 *
 * Must be called before query params are cleared (e.g. instance-initializers/clean-query-string-params)
 */
export default {
  name: 'restore-blocked-session',
  after: 'settings',

  initialize(appInstance: ApplicationInstance): void {
    if (typeof URLSearchParams === 'undefined') {
      // Ignore older browsers as they don't need this workaround
      return;
    }
    const iframeService = appInstance.lookup('service:iframe') as IframeService;
    initialize(iframeService.isActive);
  }
};
