import Icon from 'tangram/components/icon';
import translate from 'ticketbooth/helpers/translate';

interface ShowAvailabilityBadgeSignature {
  Element: HTMLDivElement;
}

const ShowAvailabilityBadge: TemplateOnlyComponent<ShowAvailabilityBadgeSignature> =
  <template>
    {{! @glint-nocheck: not typesafe yet }}
    <div
      class='text-2xs px-2 flex items-center font-semibold gap-1'
      ...attributes
    >
      <Icon
        @name='hot'
        @classes='flex-shrink-0'
        @w='w-3'
        @h='h-3'
        aria-hidden='true'
      />
      <div
        class='whitespace-nowrap overflow-ellipsis overflow-hidden'
      >{{translate 'low_availability_label'}}</div>
      {{yield}}
    </div>
  </template>;

export default ShowAvailabilityBadge;
