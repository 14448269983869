import FulfillmentProductModel from 'ticketbooth/models/fulfillment-product';
import type {
  LineItemGroup,
  TicketGroup,
  ProductGroup
} from 'ticketbooth/models/line-item';
import type LineItemModel from 'ticketbooth/models/line-item';
import {
  isTicketGroup,
  isProductGroup,
  isBookingChargeGroup
} from 'ticketbooth/models/line-item';

import type { AnyLineItemGroup } from './cart-api';

export type LineItemGroupDiff = {
  lineItem: LineItemModel;
  quantity: number;
};

export default function diffLineItems(
  oldItems: LineItemGroup[],
  newItems: LineItemGroup[]
): { added: Array<LineItemGroupDiff>; removed: Array<LineItemGroupDiff> } {
  const added: Array<LineItemGroupDiff> = [];
  const removed: Array<LineItemGroupDiff> = [];

  getUniqueItems(oldItems, newItems).forEach((group: LineItemGroup) => {
    let newQuantity = 0;
    let oldQuantity = 0;

    if (isTicketGroup(group)) {
      const query = (item: TicketGroup) => {
        return group.name === item.name && group.price === item.price;
      };

      newQuantity = newItems.find(query)?.quantity || 0;
      oldQuantity = oldItems.find(query)?.quantity || 0;
    } else if (isProductGroup(group)) {
      const { id } = group.product;

      // Filter out fulfillment products, these are tracked independently
      const isNotFulfillment = (item: ProductGroup) =>
        !(item.product instanceof FulfillmentProductModel);

      newQuantity =
        newItems
          .filter(isNotFulfillment)
          .find(
            (item: AnyLineItemGroup) =>
              'product' in item && item.product.id === id
          )?.quantity || 0;

      oldQuantity =
        oldItems
          .filter(isNotFulfillment)
          .find(
            (item: AnyLineItemGroup) =>
              'product' in item && item.product.id === id
          )?.quantity || 0;
    } else if (isBookingChargeGroup(group)) {
      const { description } = group;
      newQuantity =
        newItems.find(item => item.description === description)?.quantity || 0;
      oldQuantity =
        oldItems.find(item => item.description === description)?.quantity || 0;
    }

    const quantityDiff = newQuantity - oldQuantity;

    if (quantityDiff > 0) {
      added.push({
        lineItem: group.lineItems[0],
        quantity: Math.abs(quantityDiff)
      });
    } else if (quantityDiff < 0) {
      removed.push({
        lineItem: group.lineItems[0],
        quantity: Math.abs(quantityDiff)
      });
    }
  });

  return { added, removed };
}

function getUniqueItems(
  oldItems: LineItemGroup[],
  newItems: LineItemGroup[]
): LineItemGroup[] {
  const uniqueItems = oldItems.slice();

  // If there are common elements in both collections, use the new ones
  newItems.forEach((newItem: LineItemGroup) => {
    const match = uniqueItems.find((oldItem: LineItemGroup) => {
      return (
        oldItem.name === newItem.name &&
        oldItem.price === newItem.price &&
        oldItem.discount === newItem.discount
      );
    });

    if (match) {
      uniqueItems.removeObject(match);
    }

    uniqueItems.addObject(newItem);
  });

  return uniqueItems;
}
