import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type PreloadService from 'ticketbooth/services/preload';

export default class extends Route {
  @service private preload!: PreloadService;
  @service private router!: RouterService;

  beforeModel() {
    if (!this.preload.getValue('has_regular_products')) {
      this.router.replaceWith('listings.shows');
    }
  }
}
