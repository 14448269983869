import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type IframeService from 'ticketbooth/services/iframe';
import type PreloadService from 'ticketbooth/services/preload';

export default class IsIframeActive extends Helper {
  @service private iframe!: IframeService;
  @service private preload!: PreloadService;

  compute() {
    if (this.iframe.isActive) {
      return this.preload.getValue('display_show_details');
    }
    return true;
  }
}
