/* import __COLOCATED_TEMPLATE__ from './selectable-events-list.hbs'; */
import Component from '@glimmer/component';
import type AnnoucerService from 'a11y-announcer';
import { Machine, spawn, assign } from 'xstate';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { useMachine } from 'ember-statecharts';

import type EventModel from 'ticketbooth/models/event';
import type LocaleService from 'ticketbooth/services/locale';

import { groupEventDayKey } from './events-list/day-selection';
import type { EventButtonMachine as ChildMachine } from './events-list/event-selection';

type Context = {
  children: { event: EventModel; actor: ChildMachine }[];
  selectedEvent: EventModel | null;
};

type Schema = { states: { idle: {} } };

type Events =
  | { type: 'REGISTER_CHILD'; machine: ChildMachine; event: EventModel }
  | { type: 'OPEN_EVENT'; event: EventModel }
  | { type: 'CLOSE_EVENT'; event: EventModel };

interface ShowSelectableEventsListSignature {
  Args: {
    selected?: EventModel;
    onSelect?: Function;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 * Statechart for managing the day/time/event/price selection use case
 */
export default class ShowSelectableEventsListComponent extends Component<ShowSelectableEventsListSignature> {
  @service locale!: LocaleService;
  @service announcer!: AnnoucerService;

  private supervisorMachine = useMachine(this, () => {
    return {
      machine: Machine<Context, Schema, Events>({
        initial: 'idle' as const,
        context: {
          children: [],
          selectedEvent: null
        },
        on: {
          REGISTER_CHILD: {
            actions: assign({
              children: (c, e) => [
                ...c.children,
                { event: e.event, actor: spawn(e.machine) as ChildMachine }
              ]
            })
          },
          OPEN_EVENT: {
            actions: [
              ({ children }, { event }) => {
                children.forEach(child => {
                  if (child.event !== event) {
                    child.actor.send('CLOSED');
                  }
                });
              },
              assign({
                selectedEvent: (_c, e) => e.event
              }),
              (_c, e) => this.args.onSelect?.(e.event),
              () =>
                this.announcer.announce(
                  this.locale.translate('a11y.show.events.event_details_shown'),
                  'polite'
                )
            ]
          },
          CLOSE_EVENT: {
            actions: [
              ({ children }, { event }) => {
                children.forEach(child => {
                  if (child.event === event) {
                    child.actor.send('CLOSE');
                  }
                });
              },
              assign({
                selectedEvent: (_c, _e) => null
              }),
              () =>
                this.announcer.announce(
                  this.locale.translate(
                    'a11y.show.events.event_details_hidden'
                  ),
                  'polite'
                )
            ]
          }
        },
        states: {
          idle: {}
        }
      })
    };
  });

  get supervisor() {
    return this.supervisorMachine;
  }

  get selectedEvent(): EventModel | null {
    return this.supervisor.state!.context.selectedEvent;
  }

  get selectedDay(): string | null {
    return this.selectedEvent ? groupEventDayKey(this.selectedEvent) : null;
  }

  @action
  registerActor(opts: { event: EventModel; machine: ChildMachine }) {
    this.supervisor.send('REGISTER_CHILD', opts);

    const machine = this.supervisor.state!.context.children.find(
      ({ event }) => event === opts.event
    )!;
    const { selected } = this.args;

    if (opts.event === selected) {
      machine.actor.send('OPEN');
    } else {
      machine.actor.send('CLOSE');
    }

    return machine;
  }

  @action
  closeEvent(event: EventModel) {
    this.supervisor.send('CLOSE_EVENT', { event });
  }

  @action
  onSelectedChange() {
    const { selected } = this.args;
    const { selectedEvent } = this;
    if (selectedEvent && selectedEvent !== selected) {
      this.closeEvent(selectedEvent);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::SelectableEventsList': typeof ShowSelectableEventsListComponent;
    'show/selectable-events-list': typeof ShowSelectableEventsListComponent;
  }
}
