import type { ProductPurchaseOptions } from 'ticketbooth/models/product';

import CartRepository from '../../_shared/repositories/cart';
import NoopView from '../../_shared/views/noop';
import type CartModel from '../../../models/cart';
import type MoneyVoucherProductModel from '../../../models/money-voucher';
import type { GiftAttributes } from '../../cart';

export default class CreateGiftUseCase {
  private repository: CartRepository;
  private view: NoopView<CartModel>;

  constructor() {
    this.view = new NoopView();
    this.repository = new CartRepository();
  }

  async execute({
    cart,
    gift,
    amount,
    recipientEmail,
    recipientName,
    message,
    date
  }: {
    cart: CartModel;
    gift: MoneyVoucherProductModel | ProductPurchaseOptions;
  } & GiftAttributes): Promise<CartModel> {
    const newCart = await this.repository.createGift({
      cart,
      gift,
      amount,
      recipientEmail,
      recipientName,
      message,
      date
    });

    return this.view.fromDomain(newCart);
  }
}
