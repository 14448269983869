import type { SingleResourceDocument } from 'ticketoffice-api';

import type VoucherPaymentModel from 'ticketbooth/models/voucher-payment';
import { INCLUDE_ALL } from 'ticketbooth/utils/cart-api';

import ApplicationAdapter from './application';

export default class VoucherPaymentAdapter extends ApplicationAdapter {
  /**
   * Delete payments via cart resource
   */
  urlForDeleteRecord(id: string) {
    return `${this.buildURL()}/cart/delete_voucher_payment/${id}`;
  }

  async deletePayment(
    model: VoucherPaymentModel
  ): Promise<SingleResourceDocument> {
    const url = `${this.urlForDeleteRecord(model.id)}?include=${INCLUDE_ALL}`;
    return await this.fetch('POST', url);
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'voucher-payment': VoucherPaymentAdapter;
  }
}
