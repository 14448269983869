/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';

interface SessionFooterFooterSignature {
  Args: {};
  Element: HTMLDivElement;
}

export default class SessionFooterFooterComponent extends Component<SessionFooterFooterSignature> {
  @service private checkoutFlow!: CheckoutFlowService;

  @action
  resetReferrer() {
    this.checkoutFlow.resetReferrer();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SessionFooter::Footer': typeof SessionFooterFooterComponent;
    'session-footer/footer': typeof SessionFooterFooterComponent;
  }
}
