/* import __COLOCATED_TEMPLATE__ from './neutral.hbs'; */
import Component from '@glimmer/component';

interface ButtonTbNeutralSignature {
  Args: {
    bgColor?: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}
export default class ButtonTbNeutralComponent extends Component<ButtonTbNeutralSignature> {
  protected baseBgColor = 'white';
  get bgColor() {
    return this.args.bgColor ?? this.baseBgColor;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Button::Tb::Neutral': typeof ButtonTbNeutralComponent;
    'button/tb/neutral': typeof ButtonTbNeutralComponent;
  }
}
