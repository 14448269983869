import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type CartProviderService from 'ticketbooth/services/cart-provider';
import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';

export default class extends Route {
  @service private cartProvider!: CartProviderService;
  @service private checkoutFlow!: CheckoutFlowService;

  async beforeModel() {
    if (!this.checkoutFlow.productsPromptStep.available) {
      this.checkoutFlow.goToNextStep('products_prompt');
    }
  }

  model() {
    return this.cartProvider.cart.suggestedProducts.slice();
  }
}
