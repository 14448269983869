import setupDeprecationWorkflow from 'ember-cli-deprecation-workflow';

setupDeprecationWorkflow({
  // prettier-ignore
  workflow: [
    { handler: 'silence', matchId: 'ember-cli-mirage-config-routes-only-export' },
    { handler: 'silence', matchId: 'ember-cli-mirage.miragejs.import' },

    // Ember-modifier
    { handler: 'silence', matchId: 'ember-modifier.no-args-property' },
    { handler: 'silence', matchId: 'ember-modifier.no-element-property' },
    { handler: 'silence', matchId: 'ember-modifier.use-destroyables' },
    { handler: 'silence', matchId: 'ember-modifier.use-modify' },

    // Ember-data 6
    { handler: 'silence', matchId: 'ember-data:deprecate-legacy-imports' },
    { handler: 'silence', matchId: 'ember-data:deprecate-non-strict-id' },
    { handler: 'silence', matchId: 'ember-data:deprecate-non-strict-types' },
    { handler: 'silence', matchId: 'ember-data:deprecate-non-unique-collection-payloads' },
    { handler: 'silence', matchId: 'ember-data:deprecate-relationship-remote-update-clearing-local-state' },

    // Ember-data 5
    { handler: 'throw', matchId: 'ember-data:model-save-promise' },
    { handler: 'throw', matchId: 'ember-data:deprecate-has-record-for-id' },
    { handler: 'throw', matchId: 'ember-data:deprecate-secret-adapter-fallback' },
    { handler: 'throw', matchId: 'ember-data:deprecate-snapshot-model-class-access' },
    { handler: 'throw', matchId: 'ember-data:deprecate-promise-many-array-behaviors' },
    { handler: 'throw', matchId: 'ember-data:deprecate-string-arg-schemas' },
    { handler: 'throw', matchId: 'ember-data:rsvp-unresolved-async' },
    { handler: 'throw', matchId: 'ember-data:deprecate-array-like' },
    { handler: 'throw', matchId: 'ember-data:deprecate-early-static' },
    { handler: 'throw', matchId: 'ember-data:deprecate-errors-hash-to-array-helper' },
    { handler: 'throw', matchId: 'ember-data:deprecate-model-reopen' },
    { handler: 'throw', matchId: 'ember-data:deprecate-model-reopenclass' },
    { handler: 'throw', matchId: 'ember-data:deprecate-non-strict-relationships' },
    { handler: 'throw', matchId: 'ember-data:deprecate-promise-proxies' },
    { handler: 'throw', matchId: 'ember-data:deprecate-v1-cache' },
    { handler: 'throw', matchId: 'ember-data:deprecate-v1cache-store-apis' },
    { handler: 'throw', matchId: 'ember-data:no-a-with-array-like' },
    { handler: 'throw', matchId: 'ember-data:non-explicit-relationships' },
    { handler: 'throw', matchId: 'ember-data:deprecate-instantiate-record-args' },

    // Ember 4
    { handler: 'throw', matchId: 'ember-polyfills.deprecate-assign' },
    { handler: 'throw', matchId: 'remove-owner-inject' },

    // Ember 3 and below
    { handler: 'throw', matchId: '3-0-browser-support-policy' },
    { handler: 'throw', matchId: 'action.mouseenter-leave-move' },
    { handler: 'throw', matchId: 'application-controller.router-properties' },
    { handler: 'throw', matchId: 'argument-less-helper-paren-less-invocation' },
    { handler: 'throw', matchId: 'array-observers' },
    { handler: 'throw', matchId: 'attrs-arg-access' },
    { handler: 'throw', matchId: 'autotracking.mutation-after-consumption' },
    { handler: 'throw', matchId: 'class-binding-and-class-name-bindings-in-templates' },
    { handler: 'throw', matchId: 'component-manager-string-lookup' },
    { handler: 'throw', matchId: 'component.mouseenter-leave-move' },
    { handler: 'throw', matchId: 'computed-property.override' },
    { handler: 'throw', matchId: 'computed-property.property' },
    { handler: 'throw', matchId: 'computed-property.volatile' },
    { handler: 'throw', matchId: 'deprecate-router-events' },
    { handler: 'throw', matchId: 'deprecated-run-loop-and-computed-dot-access' },
    { handler: 'throw', matchId: 'editions.classic' },
    { handler: 'throw', matchId: 'ember-component.is-visible' },
    { handler: 'throw', matchId: 'ember-component.send-action' },
    { handler: 'throw', matchId: 'ember-console.deprecate-logger' },
    { handler: 'throw', matchId: 'ember-env.old-extend-prototypes' },
    { handler: 'throw', matchId: 'ember-glimmer.link-to.positional-arguments' },
    { handler: 'throw', matchId: 'ember-glimmer.with-syntax' },
    { handler: 'throw', matchId: 'ember-global' },
    { handler: 'throw', matchId: 'ember-metal.get-with-default' },
    { handler: 'throw', matchId: 'ember-polyfills.deprecate-merge' },
    { handler: 'throw', matchId: 'ember-runtime.deprecate-copy-copyable' },
    { handler: 'throw', matchId: 'ember-source-deprecation-without-for' },
    { handler: 'throw', matchId: 'ember-source-deprecation-without-since' },
    { handler: 'throw', matchId: 'ember-string.htmlsafe-ishtmlsafe' },
    { handler: 'throw', matchId: 'ember-string.loc' },
    { handler: 'throw', matchId: 'ember-string.prototype-extensions' },
    { handler: 'throw', matchId: 'ember-utils.try-invoke' },
    { handler: 'throw', matchId: 'ember.built-in-components.import' },
    { handler: 'throw', matchId: 'ember.built-in-components.legacy-arguments' },
    { handler: 'throw', matchId: 'ember.built-in-components.legacy-attribute-arguments' },
    { handler: 'throw', matchId: 'ember.built-in-components.reopen' },
    { handler: 'throw', matchId: 'ember.component.reopen' },
    { handler: 'throw', matchId: 'ember.globals-resolver' },
    { handler: 'throw', matchId: 'ember.link-to.disabled-when' },
    { handler: 'throw', matchId: 'ember.partial' },
    { handler: 'throw', matchId: 'function-prototype-extensions.observes' },
    { handler: 'throw', matchId: 'function-prototype-extensions.on' },
    { handler: 'throw', matchId: 'function-prototype-extensions.property' },
    { handler: 'throw', matchId: 'has-block-and-has-block-params' },
    { handler: 'throw', matchId: 'implicit-injections' },
    { handler: 'throw', matchId: 'jquery-apis' },
    { handler: 'throw', matchId: 'jquery-event' },
    { handler: 'throw', matchId: 'manager-capabilities.components-3-4' },
    { handler: 'throw', matchId: 'manager-capabilities.modifiers-3-13' },
    { handler: 'throw', matchId: 'object.alias-method' },
    { handler: 'throw', matchId: 'old-deprecate-method-paths' },
    { handler: 'throw', matchId: 'optional-feature.application-template-wrapper' },
    { handler: 'throw', matchId: 'optional-feature.jquery-integration' },
    { handler: 'throw', matchId: 'optional-feature.template-only-glimmer-components' },
    { handler: 'throw', matchId: 'route-disconnect-outlet' },
    { handler: 'throw', matchId: 'route-render-template' },
    { handler: 'throw', matchId: 'template-compiler.registerPlugin' },
    { handler: 'throw', matchId: 'this-property-fallback' }
  ]
});
