import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import Countries from 'i18n-iso-countries';
// @ts-ignore
import CY from 'i18n-iso-countries/langs/cy.json';
// @ts-ignore
import EN from 'i18n-iso-countries/langs/en.json';
// @ts-ignore
import FR from 'i18n-iso-countries/langs/fr.json';
// @ts-ignore
import GA from 'i18n-iso-countries/langs/ga.json';
import { getCountryCallingCode, getCountries } from 'libphonenumber-js';
import { sortBy } from 'lodash-es';

import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import type { DropdownOption } from 'tangram/components/dropdown';
import type LocaleService from 'ticketbooth/services/locale';
import type PreloadService from 'ticketbooth/services/preload';
import type {
  CallingCode,
  CallingCodeOnly
} from 'ticketbooth/utils/phone-normalization';

Countries.registerLocale(EN);
Countries.registerLocale(CY);
Countries.registerLocale(FR);
Countries.registerLocale(GA);
const EN_COUNTRIES = Countries.getNames('en', { select: 'official' });

export default class CountryFlagsCollectionHelper extends Helper {
  @service() private locale!: LocaleService;
  @service() private preload!: PreloadService;

  get priorityCountries(): string[] {
    return this.preload.getValue('priority_countries') as string[];
  }

  compute() {
    const locale = this.locale.currentLocale.split('-')[0];
    const COUNTRIES = Countries.getNames(locale, { select: 'official' });
    const collection: (DropdownOption & {
      sortIndex: number;
      country?: string;
    })[] = getCountries()
      .filter(key => COUNTRIES[key])
      .map(key => {
        const country = COUNTRIES[key] ?? key;
        const value = `+${getCountryCallingCode(key)}` as CallingCodeOnly;
        const flag = getUnicodeFlagIcon(key);
        return {
          label: `${country} ${flag} ${value}`,
          value: `${key}|${value}` as CallingCode,
          country,
          sortIndex: this.priorityCountries.includes(EN_COUNTRIES[key]) ? 0 : 2
        };
      });
    if (this.priorityCountries.length > 0) {
      collection.push({ divider: true, sortIndex: 1, value: null, label: '' });
    }
    return sortBy(collection, ['sortIndex', 'country']);
  }
}
