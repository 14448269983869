import ApplicationAdapter from './application';

export default class CustomerAdapter extends ApplicationAdapter {
  pathForType() {
    return 'customer';
  }

  /**
   * Customer is a singleton resource, so we direct all queries to the /customer endpoint
   */
  buildURL() {
    return this.urlForQueryRecord({}, 'customer');
  }
}
