import type { SyncHasMany } from '@ember-data/model';
import { attr, hasMany } from '@ember-data/model';
import Model from '@ember-data/model';
import { isBlank } from '@ember/utils';

import type PermissionModel from './permission';

export type GiftAidPreference = 'all' | 'onwards' | 'cart' | 'not-defined';

export default class CustomerModel extends Model {
  @attr('boolean') isNullObject!: boolean;

  @attr('string') firstName!: string;
  @attr('string') lastName!: string;

  @attr('string') email!: string;
  /**
   * Property is not persisted
   */
  @attr('string') emailConfirmation!: string | null;
  @attr('string') phone!: string | null;
  @attr('string') mobile!: string | null;
  @attr('string') addressLine1!: string;
  @attr('string') addressLine2!: string;
  @attr('string') postCode!: string;
  @attr('string') cityTown!: string;
  @attr('string') region!: string;
  @attr('string') country!: string;
  @attr('boolean') agreeTermsConditions!: boolean;
  @attr('string') giftAidPreference!: GiftAidPreference | null;

  @attr('boolean') optInSMS!: boolean;
  @attr('boolean') optInEmail!: boolean;
  @attr('boolean') optInMail!: boolean;
  @attr('boolean') optIn3rdParty!: boolean;

  @hasMany('permission', { async: false, inverse: null })
  permissions!: SyncHasMany<PermissionModel>;

  @hasMany('permission', { async: false, inverse: null })
  permissionOptions!: SyncHasMany<PermissionModel>;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get contactSummary() {
    const { mobile, phone } = this;

    if (mobile === phone) {
      return mobile;
    }

    return [mobile, phone].filter(contact => !isBlank(contact)).join(' / ');
  }

  get locationSummary() {
    const { cityTown, region, country } = this;

    return [cityTown, region, country]
      .filter(location => !isBlank(location))
      .join(', ');
  }

  get addressSummary() {
    const { addressLine1, addressLine2, postCode } = this;

    return [addressLine1, addressLine2, postCode]
      .filter(location => !isBlank(location))
      .join(', ');
  }

  get isNullCustomer() {
    return this.isNullObject;
  }

  get hasGiftAidPreference() {
    return !!this.giftAidPreference && this.giftAidPreference !== 'not-defined';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    customer: CustomerModel;
  }
}
