/* import __COLOCATED_TEMPLATE__ from './prev-month-button.hbs'; */
import Component from '@glimmer/component';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import subMonths from 'date-fns/subMonths';

import { action } from '@ember/object';

interface CalendarPrevMonthButtonSignature {
  Args: {
    minDate: Date;
    selectedDate: Date;
    selectDate(date: Date): Promise<void>;
  };
}

export default class CalendarPrevMonthButtonComponent extends Component<CalendarPrevMonthButtonSignature> {
  get prevMonth(): Date {
    return subMonths(this.args.selectedDate, 1);
  }
  get hasPrevMonth(): boolean {
    return !isSameMonth(this.args.selectedDate, this.args.minDate);
  }
  get ariaLabel(): string {
    return `Previous month, ${format(this.prevMonth, 'MMMM yyyy')}`;
  }

  @action
  selectPrevMonth() {
    return this.args.selectDate(this.prevMonth);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calendar::PrevMonthButton': typeof CalendarPrevMonthButtonComponent;
    'calendar/prev-month-button': typeof CalendarPrevMonthButtonComponent;
  }
}
