import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type MembershipService from 'ticketbooth/services/membership';

export default class extends Route {
  @service private membership!: MembershipService;
  @service private router!: RouterService;

  beforeModel() {
    if (!this.membership.isLoggedIn) {
      this.router.transitionTo('login', {
        queryParams: { path: 'members.redemptions' }
      });
    }
  }
}
