import Service from '@ember/service';

export default class DataLayersService extends Service {
  dataLayers: any = {};

  push(data: {}, layers?: Array<string>) {
    (layers || ['account', 'ts']).forEach(layer => {
      this.dataLayers[layer]?.push(data);
    });
  }

  setVariable(key: string, value: string, layers?: Array<string>) {
    this.push({ [key]: value }, layers);
  }

  event(
    eventName: string,
    category?: string,
    action?: string,
    label?: string,
    value?: string
  ) {
    this.push({
      event: eventName,
      interactionCategory: category,
      interactionAction: action,
      interactionLabel: label,
      interactionValue: value
    });
  }

  interactionEvent(
    category: string,
    action?: string,
    label?: string,
    value?: string
  ) {
    this.event('interaction', category, action, label, value);
  }

  // handled in tagmanager pageView tags content groups
  setContentGroup(contentGroup: { name: string; index: any }) {
    this.setVariable('contentGroup.name', contentGroup.name);
    this.setVariable('contentGroup.index', contentGroup.index);
  }

  setDimension(dimension: string, value: string, layers?: Array<string>) {
    this.setVariable(dimension, value, layers);
  }
}
