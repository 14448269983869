/* import __COLOCATED_TEMPLATE__ from './checkbox-wrapper.hbs'; */
import Component from '@glimmer/component';

interface CheckoutFormCheckboxWrapperSignature {
  Args: {
    required?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class CheckoutFormCheckboxWrapperComponent extends Component<CheckoutFormCheckboxWrapperSignature> {
  get displayRequired() {
    return typeof this.args.required === 'boolean';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutForm::CheckboxWrapper': typeof CheckoutFormCheckboxWrapperComponent;
    'checkout-form/checkbox-wrapper': typeof CheckoutFormCheckboxWrapperComponent;
  }
}
