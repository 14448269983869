import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service private router!: RouterService;

  beforeModel() {
    this.router.transitionTo('listings.products.regular');
  }
}
