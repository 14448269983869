/* import __COLOCATED_TEMPLATE__ from './price-selection.hbs'; */
import Component from '@glimmer/component';

import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type RecommendationModel from 'ticketbooth/models/recommendation';
import type ShowModel from 'ticketbooth/models/show';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';
import type PreloadService from 'ticketbooth/services/preload';

interface ShowEventsListPriceSelectionSignature {
  Args: {
    show: ShowModel;
    event: EventModel;
    closeEvent?: Function;
    hideCloseButton?: boolean;
    list?: string;
  };
}

export default class ShowEventsListPriceSelectionComponent extends Component<ShowEventsListPriceSelectionSignature> {
  @service private googleAnalytics!: GoogleAnalyticsService;
  @service private store!: Store;
  @service private preload!: PreloadService;

  @action
  addProductDetails() {
    this.googleAnalytics.viewItem(
      this.args.event.toAnalytics({ item_list_name: this.args.list })
    );
  }

  get isCrossSellEnabled() {
    return this.preload.getValue('event_cross_sell_enabled') === true;
  }

  @action
  async loadCrossSellRecommendations(): Promise<RecommendationModel[]> {
    return (
      await this.store.query('recommendation', {
        filter: {
          'cross-sellable-event': this.args.event.id
        }
      })
    ).slice();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::PriceSelection': typeof ShowEventsListPriceSelectionComponent;
    'show/events-list/price-selection': typeof ShowEventsListPriceSelectionComponent;
  }
}
