import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import { Entity, createDomainExport } from 'ticketbooth/domains/utils/entity';
import type CartModel from 'ticketbooth/models/cart';
import type LineItemModel from 'ticketbooth/models/line-item';
import type MoneyVoucherProductModel from 'ticketbooth/models/money-voucher';
import type { ProductPurchaseOptions } from 'ticketbooth/models/product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type ErrorsService from 'ticketbooth/services/errors';
import { inject as domain } from 'ticketbooth/utils/domains';

import type { LineItemsEntity } from './cart/line-items';
import type { AddLineItemOpts, SomeProduct } from './cart/line-items';
import CreateGiftUseCase from './cart/use-cases/create-gift';
import EditGiftUseCase from './cart/use-cases/edit-gift';

export type GiftAttributes = {
  amount: number;
  recipientName: string;
  recipientEmail: string;
  message: string;
  date: Date | string | null;
};

export class CartDomain extends Entity {
  @domain('cart/line-items') LineItems!: LineItemsEntity;

  @service cartProvider!: CartProviderService;
  @service private notifications!: NotificationsService;
  @service private errors!: ErrorsService;

  private createGiftUseCase: CreateGiftUseCase = new CreateGiftUseCase();
  private editGiftUseCase: EditGiftUseCase = new EditGiftUseCase();

  get cart() {
    return this.cartProvider.cart;
  }

  get lineItems() {
    return this.cart.lineItems;
  }

  get productLineItems(): ProductLineItemModel[] {
    return this.cart.effectiveProductLineItems;
  }

  filterLineItems<T extends LineItemModel>(opts: Partial<T>): T[];
  filterLineItems<T extends LineItemModel>(opts: Partial<T>, col: T[]): T[];
  filterLineItems<T extends LineItemModel>(
    opts: Partial<T>,
    collection: T[] = this.lineItems as T[]
  ): T[] {
    const keys = Object.keys(opts) as Array<keyof T>;
    return collection.filter(item => keys.every(k => item[k] === opts[k]));
  }

  getQuantityInCart<T extends LineItemModel>(opts: Partial<T>): number;
  getQuantityInCart<T extends LineItemModel>(opts: Partial<T>, c: T[]): number;
  getQuantityInCart<T extends LineItemModel>(
    opts: Partial<T>,
    collection: T[] = this.lineItems as T[]
  ): number {
    return this.filterLineItems(opts, collection).reduce(
      (acc, item) => acc + item.quantity,
      0
    );
  }

  /**
   * This function operations on collections which have the same sub-properties.
   * i.e. they share the same event-id, context or contextCategory.
   * If the collections you're working with has multiple of these then other
   * functions should be used
   */
  async changeProductQuantity<T extends LineItemModel>(
    collection: T[],
    quantity: number,
    opts: AddLineItemOpts & { product: SomeProduct }
  ) {
    try {
      if (collection.length === quantity) return;

      if (collection.length === 0) {
        Array.from({ length: quantity }).map(() =>
          this.LineItems.addLineItem(opts.product, opts)
        );
      } else if (collection.length > quantity) {
        const itemsToRemove = collection.slice(0, collection.length - quantity);
        itemsToRemove.map(i => this.LineItems.removeLineItem(i));
      } else if (collection.length < quantity) {
        Array.from({ length: quantity - collection.length }).map(() =>
          this.LineItems.duplicateLineItem(
            collection[0] as unknown as ProductLineItemModel,
            opts
          )
        );
      }

      return await this.LineItems.execute();
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleError(error: any, defaultMsg?: string) {
    const messages = getAllUserMessagesOrDefault(error, { defaultMsg });
    this.errors.log(error);
    this.notifications.error(messages.join('. '));
  }

  @action createGift({
    cart,
    gift,
    amount,
    recipientName,
    recipientEmail,
    message,
    date
  }: {
    cart: CartModel;
    gift: MoneyVoucherProductModel | ProductPurchaseOptions;
  } & GiftAttributes) {
    return this.createGiftUseCase.execute({
      cart,
      gift,
      amount,
      recipientName,
      recipientEmail,
      message,
      date
    });
  }

  @action editGift({
    cart,
    gift,
    amount,
    recipientName,
    recipientEmail,
    message,
    date
  }: { cart: CartModel; gift: ProductLineItemModel } & GiftAttributes) {
    return this.editGiftUseCase.execute({
      cart,
      lineItem: gift,
      amount,
      recipientName,
      recipientEmail,
      message,
      date
    });
  }
}

export default createDomainExport(CartDomain);
