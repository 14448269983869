/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import Component from '@glimmer/component';
import type { EventObject } from 'xstate';
import { Machine } from 'xstate';

import { action } from '@ember/object';

import { useMachine } from 'ember-statecharts';

import { matchesState } from 'tangram/utils/statecharts';
import type AssetAttachmentModel from 'ticketbooth/models/asset-attachment';

interface StatechartContext {}
interface StateSchema {
  states: {
    idle: {};
    loading: {};
    loaded: {};
    finished: {};
  };
}

const machine = Machine<StatechartContext, StateSchema, EventObject>({
  initial: 'idle' as const,
  states: {
    idle: {
      on: {
        LOAD: 'loading'
      }
    },
    loading: {
      on: {
        LOADED: 'loaded',
        LOAD_ERROR: 'finished'
      }
    },
    loaded: {
      after: {
        400: 'finished'
      }
    },
    finished: {}
  }
});

interface CoverBaseSignature {
  Args: {
    attachment?: AssetAttachmentModel;
    src?: string;
    srcset?: string | null;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class CoverBaseComponent extends Component<CoverBaseSignature> {
  get machine() {
    return this.statechart;
  }

  statechart = useMachine<StatechartContext, StateSchema, EventObject, any, {}>(
    this,
    () => {
      return {
        machine
      };
    }
  );

  @matchesState('idle') isIdle!: boolean;
  get isShowingCover() {
    return !this.isIdle;
  }
  @matchesState('loading') isLoading!: boolean;
  @matchesState('loaded') isLoaded!: boolean;
  @matchesState('finished') isFinished!: boolean;

  get src() {
    const { src, attachment } = this.args;
    return src ?? (attachment ? attachment.mediumUrl : null);
  }
  get srcset(): string | null {
    const { srcset, attachment } = this.args;
    return (
      srcset ??
      (attachment && attachment.largeUrl ? `${attachment.largeUrl} 2x` : null)
    );
  }

  @action
  load() {
    this.machine.send('LOAD');
  }
  @action
  loaded() {
    this.machine.send('LOADED');
  }
  @action
  loadingError() {
    this.machine.send('LOAD_ERROR');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cover::Base': typeof CoverBaseComponent;
    'cover/base': typeof CoverBaseComponent;
  }
}
