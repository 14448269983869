import { addSeconds, format } from 'date-fns';

import { helper } from '@ember/component/helper';

export function formatSecondsLeft([seconds]: [number]) {
  if (seconds < 0) {
    return '00:00';
  }

  return format(addSeconds(new Date(0), seconds), 'mm:ss');
}

export default helper(formatSecondsLeft);
