/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ShowsListSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
}

const ShowsListComponent = templateOnlyComponent<ShowsListSignature>();

export default ShowsListComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shows::List': typeof ShowsListComponent;
    'shows/list': typeof ShowsListComponent;
  }
}
