import branding from 'ticketbooth/modifiers/branding';

interface ShowAvailabilityIndicatorSignature {
  Args: {
    isSmall?: boolean;
  };
  Element: HTMLDivElement;
}

const ShowAvailabilityIndicator: TemplateOnlyComponent<ShowAvailabilityIndicatorSignature> =
  <template>
    {{! @glint-nocheck: not typesafe yet }}
    <div class='flex items-center' ...attributes>
      <div
        class='absolute
          {{if @isSmall "h-2 w-2" "h-3 w-3"}}
          rounded-full animate-ping'
        {{branding 'backgroundColor' 'card_warning_bg_color'}}
      ></div>
      <div
        class='absolute {{if @isSmall "h-2 w-2" "h-3 w-3"}} rounded-full'
        {{branding 'backgroundColor' 'card_warning_bg_color'}}
      ></div>
      <div></div>
    </div>
  </template>;

export default ShowAvailabilityIndicator;
