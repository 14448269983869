import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';

import type ProductModel from './product';

export default class ContextCategoryModel extends Model {
  @attr('string') name!: string;

  @hasMany('products', { async: false, inverse: null })
  products!: SyncHasMany<ProductModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'context-category': ContextCategoryModel;
  }
}
