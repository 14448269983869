import compact from 'lodash-es/compact';
import orderBy from 'lodash-es/orderBy';
import uniqBy from 'lodash-es/uniqBy';

import { attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';

import type { AnyLineItemGroup } from './../utils/cart-api';
import AnalyticsModel from './analytics-model';
import type BookingChargeLineItemModel from './booking-charge-line-item';
import type EventModel from './event';
import type EventTicketPriceModel from './event-ticket-price';
import FulfillmentProductModel from './fulfillment-product';
import MoneyVoucherProductModel from './money-voucher';
import type ProductModel from './product';
import type ProductLineItemModel from './product-line-item';
import type TicketAllocationModel from './ticket-allocation';
import type TicketLineItemModel from './ticket-line-item';

export class LineItemGroup<T extends LineItemModel = LineItemModel> {
  lineItems: T[] = [];

  constructor(lineItem: T) {
    this.lineItems.push(lineItem);
  }

  get name() {
    return this.lineItems[0].name;
  }
  get price() {
    return this.lineItems[0].price;
  }
  get discount() {
    return this.lineItems.reduce((sum, lineItem) => sum + lineItem.discount, 0);
  }
  get comment() {
    return this.lineItems[0].comment ?? '';
  }
  get description() {
    return this.lineItems[0].description;
  }
  get quantity() {
    return this.lineItems.reduce((sum, lineItem) => sum + lineItem.quantity, 0);
  }
  get total() {
    return this.lineItems.reduce((sum, lineItem) => sum + lineItem.total, 0);
  }
  get payNow() {
    return this.lineItems.reduce((sum, lineItem) => sum + lineItem.payNow, 0);
  }

  isGroupableWith(_lineItem: T): boolean {
    return false;
  }
}

export class ProductGroup extends LineItemGroup<ProductLineItemModel> {
  product: ProductModel;

  constructor(lineItem: ProductLineItemModel, product: ProductModel) {
    super(lineItem);
    this.product = product;
  }

  get giftEmail(): string | null {
    return this.lineItems[0].giftEmail;
  }
  get giftRecipientName(): string | null {
    return this.lineItems[0].giftRecipientName;
  }
  get isFutureGifted() {
    return this.lineItems[0].isFutureGifted;
  }
  get purchaseEventId() {
    return this.lineItems[0].eventId;
  }
  get inventory() {
    return this.lineItems[0].inventory;
  }

  get isDonation() {
    return this.product.isDonationProduct;
  }

  isGroupableWith(lineItem: ProductLineItemModel): boolean {
    if (
      lineItem.product instanceof MoneyVoucherProductModel &&
      lineItem.giftEmail
    ) {
      return false;
    }

    return (
      lineItem.name === this.name &&
      lineItem.price === this.price &&
      lineItem.product === this.product &&
      lineItem.giftEmail === this.giftEmail &&
      lineItem.inventory === this.inventory
    );
  }
}
export class TicketGroup extends LineItemGroup<TicketLineItemModel> {
  ticketPrice: EventTicketPriceModel;
  ticketAllocation: TicketAllocationModel;
  event: EventModel;
  masterAllocationName: string | null;

  constructor(
    lineItem: TicketLineItemModel,
    ticketPrice: EventTicketPriceModel,
    ticketAllocation: TicketAllocationModel,
    event: EventModel
  ) {
    super(lineItem);
    this.ticketPrice = ticketPrice;
    this.ticketAllocation = ticketAllocation;
    this.event = event;
    this.masterAllocationName = lineItem.masterAllocationName;
  }

  get isGroupTicket() {
    return this.ticketPrice?.groupSize > 1;
  }

  get hasSeats() {
    return this.seatAssignments.length > 0;
  }

  get name() {
    if (this.isGroupTicket) {
      return `${super.name} * ${this.ticketPrice.groupSize}`;
    }
    return super.name;
  }

  get quantity() {
    const quantity = super.quantity;
    if (this.isGroupTicket) {
      return quantity / this.ticketPrice.groupSize;
    }
    return quantity;
  }

  get seatAssignments() {
    const lineItems = this.lineItems as TicketLineItemModel[];
    return orderBy(
      compact(lineItems.map(({ seatAssignment }) => seatAssignment)),
      [({ row }) => row, ({ number }) => number]
    );
  }

  get uniqLineItems() {
    if (this.isGroupTicket) {
      return uniqBy(this.lineItems, lineItem => lineItem.linkId);
    }
    return this.lineItems;
  }

  isGroupableWith(lineItem: TicketLineItemModel): boolean {
    const groupName =
      lineItem.eventTicketPrice?.groupSize > 1
        ? `${lineItem.name} * ${lineItem.eventTicketPrice.groupSize}`
        : lineItem.name;

    return (
      groupName === this.name &&
      lineItem.price === this.price &&
      lineItem.event === this.event &&
      lineItem.eventTicketPrice === this.ticketPrice &&
      lineItem.ticketAllocation === this.ticketAllocation
    );
  }

  compare(
    event: EventModel,
    eventTicketPrice: EventTicketPriceModel,
    ticketAllocations: TicketAllocationModel[]
  ) {
    return (
      this.event === event &&
      this.ticketPrice === eventTicketPrice &&
      ticketAllocations.includes(this.ticketAllocation)
    );
  }
}
export class BookingChargeGroup extends LineItemGroup<BookingChargeLineItemModel> {
  isGroupableWith(lineItem: BookingChargeLineItemModel): boolean {
    return (
      lineItem.name === this.name &&
      lineItem.price === this.price &&
      lineItem.description === this.description
    );
  }
}

export function isTicketGroup(group: AnyLineItemGroup): group is TicketGroup {
  return group instanceof TicketGroup;
}
export function isProductGroup(group: AnyLineItemGroup): group is ProductGroup {
  return group instanceof ProductGroup;
}
export function isBookingChargeGroup(
  group: AnyLineItemGroup
): group is BookingChargeGroup {
  return group instanceof BookingChargeGroup;
}
export function isFulfillmentProductGroup(
  group: AnyLineItemGroup
): group is ProductGroup {
  return (
    group instanceof ProductGroup &&
    group.product instanceof FulfillmentProductModel
  );
}

export default class LineItemModel extends AnalyticsModel {
  @attr('string') name!: string;
  @attr('number') quantity!: number;
  @attr('number') price!: number;
  @attr('number') total!: number;
  @attr('number') discount!: number;
  @attr('string') description!: string;
  @attr('number') payNow!: number;
  // gifting
  @attr('string') giftEmail!: string | null;
  @attr('string') giftRecipientName!: string | null;
  @attr('string') giftMessage!: string | null;
  @attr('date') giftDate!: Date | null;
  // event purchases
  @attr('string') eventId!: string | null;

  @attr('string') linkId!: string | null;

  // Used by product-line-items with donation products
  get comment(): string {
    return '';
  }
  get isBenefitGift() {
    return !isBlank(this.giftEmail);
  }
  get isBenefitForUser() {
    return isBlank(this.giftEmail);
  }

  get googleAnalyticsSku(): string {
    return '';
  }

  get googleAnalyticsName(): string {
    return '';
  }

  get googleAnalyticsBrand(): string {
    return '';
  }

  get googleAnalyticsCategory(): string {
    return '';
  }

  get googleAnalyticsVariant(): string {
    return '';
  }

  groupWith(groups: LineItemGroup[]) {
    const group = groups.find(group => group.isGroupableWith(this));

    if (group) {
      group.lineItems.push(this);

      return groups;
    }

    return [...groups, this.createGroup(this)];
  }

  createGroup(lineItem: LineItemModel): LineItemGroup {
    return new LineItemGroup(lineItem);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'line-item': LineItemModel;
  }
}
