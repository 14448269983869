/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import Component from '@glimmer/component';
import type RouterService from 'node_modules/@types/ember__routing/router-service';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type CartModel from 'ticketbooth/models/cart';
import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';

interface HeaderCartSummarySignature {
  Args: {
    cart: CartModel;
  };
}

export default class HeaderCartSummaryComponent extends Component<HeaderCartSummarySignature> {
  @service private checkoutFlow!: CheckoutFlowService;
  @service private router!: RouterService;

  get isEventHub() {
    return this.router.currentRouteName.includes('event-hub');
  }

  get continueShoppingRoute() {
    return this.isEventHub ? 'event-hub.pre-order' : 'listings.shows';
  }

  get cartRoute() {
    return this.isEventHub ? 'event-hub.cart' : 'cart';
  }

  get displayItems() {
    const { cart } = this.args;
    return cart.hasItems && cart.isNotConfirmed;
  }

  @action
  resetReferrer() {
    this.checkoutFlow.resetReferrer();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Header::Cart::Summary': typeof HeaderCartSummaryComponent;
    'header/cart/summary': typeof HeaderCartSummaryComponent;
  }
}
