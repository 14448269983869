/* import __COLOCATED_TEMPLATE__ from './calendar.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';

interface CalendarSignature {
  Args: {
    selectedDate: Date;
    minDate: Date;
    maxDate: Date;
    onSelectDate?: (date: Date) => Promise<void>;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class CalendarComponent extends Component<CalendarSignature> {
  @action
  selectDate(date: Date) {
    this.args.onSelectDate?.(date);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Calendar: typeof CalendarComponent;
    calendar: typeof CalendarComponent;
  }
}
