import { fn } from '@ember/helper';

import ButtonLink from 'ticketbooth/components/button/link';
import CartProvider from 'ticketbooth/components/cart-provider';
import translate from 'ticketbooth/helpers/translate';
import type OrderModel from 'ticketbooth/models/order';

interface HppCallbackComponentSig {
  Args: {
    uuid: string | undefined;
    order: OrderModel | null | undefined;
    completedRoute?: string;
    connectedOrderId?: string;
    completedRouteQueryParams?: object;
  };
}

const HppCallbackComponent: TemplateOnlyComponent<HppCallbackComponentSig> = <template>
    {{#unless @order}}
      <div class="flex flex-col items-center">
        <div class="text-3xl font-bold mt-16 mb-6">{{translate "cart.confirmation_failed_title"}}</div>
        <div class="my-6 max-w-100 text-lg text-center">{{translate "cart.confirmation_failed_message" uuid=@uuid}}</div>
        <div>
          <CartProvider @completedRoute={{@completedRoute}} @completedRouteQueryParams={{@completedRouteQueryParams}} as |provider|>
            <ButtonLink @onClick={{fn provider.actions.confirmUUID @uuid @connectedOrderId}}>
              {{translate "cart.confirmation_retry"}}
            </ButtonLink>
          </CartProvider>
        </div>
      </div>
    {{/unless}}
  </template>;

export default HppCallbackComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HppCallback: typeof HppCallbackComponent;
    'hpp-callback': typeof HppCallbackComponent;
  }
}
