/* import __COLOCATED_TEMPLATE__ from './order-summary.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OrderSummarySignature {
  Args: {};
}

const OrderSummaryComponent = templateOnlyComponent<OrderSummarySignature>();

export default OrderSummaryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrderSummary: typeof OrderSummaryComponent;
    'order-summary': typeof OrderSummaryComponent;
  }
}
