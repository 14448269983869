import type Store from '@ember-data/store';
import type ApplicationInstance from '@ember/application/instance';

import type LocaleService from 'ticketbooth/services/locale';
import type PreloadService from 'ticketbooth/services/preload';
import {
  isRunningIframeTest,
  addIframeTestPreload
} from 'ticketbooth/utils/test';

export function initialize(appInstance: ApplicationInstance): void {
  const locale = appInstance.lookup('service:locale') as LocaleService;
  const preload = appInstance.lookup('service:preload') as PreloadService;
  const store = appInstance.lookup('service:store') as Store;

  const preloadEl = document.getElementById('preload');
  if (preloadEl) {
    const { data: preloadData } = isRunningIframeTest()
      ? addIframeTestPreload(JSON.parse(preloadEl.innerHTML))
      : JSON.parse(preloadEl.innerHTML);

    preload.setPreloadData(preloadData);

    const locales = preload.getValue('locales');
    if (locales) {
      store.createRecord('setting', {
        id: store.peekAll('setting').slice().length + 1,
        name: 'locales',
        value: locales
      });

      locale.setCurrentLocale(locale.currentLocale);
    }
    const snippets = preload.getValue('snippets');
    if (snippets) {
      store.createRecord('setting', {
        id: store.peekAll('setting').slice().length + 1,
        name: 'preloadLocaleObject',
        value: snippets
      });
    }

    const currency = preload.getValue('currency');
    if (currency) {
      store.createRecord('setting', {
        id: store.peekAll('setting').slice().length + 1,
        name: 'currency',
        value: currency
      });
    }

    const hideExpiredOnlineSalesGuardEvents = preload.getValue(
      'hide_expired_online_sales_guard_events'
    );
    if (hideExpiredOnlineSalesGuardEvents) {
      store.createRecord('setting', {
        id: store.peekAll('setting').slice().length + 1,
        name: 'hideExpiredOnlineSalesGuardEvents',
        value: hideExpiredOnlineSalesGuardEvents
      });
    }
  }
}

export default {
  name: 'settings',
  initialize
};
