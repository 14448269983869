interface SkipLinkSignature {
  Args: {};
}

const SkipLinkComponent: TemplateOnlyComponent<SkipLinkSignature> = <template>
  <div class="fixed top-0 left-0 p-2 text-white bg-gray-500 rounded-br transform focus-within:translate-y-0 -translate-y-10 transition-transform duration-200 ease-in-out">
    <a href="#maincontent">
      Skip to main content
    </a>
  </div>
</template>

export default SkipLinkComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SkipLink: typeof SkipLinkComponent;
    'skip-link': typeof SkipLinkComponent;
  }
}
