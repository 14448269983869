/**
 * Fetch list of shows from API
 *
 * Use sparse fieldset to load only data required for displaying an overview.
 *
 * Use `INCLUDE_FOR_SHOW` below without sparse fieldset, for loading the full
 * details of a show.
 */
export function includeForShows({
  includeCategory,
  includeCompany,
  includeVenues
}: {
  includeCategory: boolean;
  includeCompany: boolean;
  includeVenues: boolean;
}): { include: string; fields: object } {
  const includes = ['default-attachment'];
  const fields = [
    'name',
    'date-description',
    'default-attachment',
    'soldout',
    'low-availability',
    'next-event-dates',
    'discounts-available'
  ];
  if (includeCompany) {
    includes.push('production-company');
    fields.push('production-company');
  }
  if (includeCategory) {
    includes.push('event-category');
    fields.push('event-category');
  }
  if (includeVenues) {
    includes.push('venues');
    fields.push('venues');
  }
  return { include: includes.join(','), fields: { shows: fields.join(',') } };
}

export const INCLUDE_FOR_SHOW = [
  'default-attachment',
  'event-category',
  'production-company',
  'venues',
  'recommendations.recommendee.default-attachment',
  'show-properties'
].join(',');
