import type { Store } from '@ember-data/store';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type ShowModel from 'ticketbooth/models/show';
import { extractIdFromParam } from 'ticketbooth/utils/query-param';
import { INCLUDE_FOR_SHOW } from 'ticketbooth/utils/show-api';

type RouteParams = { show_id: string };

export default class ShowRoute extends Route<ShowModel> {
  @service private router!: RouterService;
  @service store!: Store;

  private get params(): Partial<RouteParams> {
    return this.paramsFor('listings.show');
  }

  beforeModel() {
    const { show_id } = this.params;

    if (show_id && extractIdFromParam(show_id) === null) {
      this.router.transitionTo('listings.shows');
    }
  }

  async model({ show_id }: RouteParams): Promise<ShowModel> {
    return await this.store.loadRecord('show', extractIdFromParam(show_id)!, {
      include: INCLUDE_FOR_SHOW,
      reload: true
    });
  }
}
