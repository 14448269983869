/* import __COLOCATED_TEMPLATE__ from './day.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type PreloadService from 'ticketbooth/services/preload';

interface EventCalendarDaySignature {
  Args: {
    day: Date;
    events: EventModel[];
    selectedEvent: EventModel;
    selectDate(date: Date): Promise<void>;
  };
}

export default class EventCalendarDayComponent extends Component<EventCalendarDaySignature> {
  @service private cartProvider!: CartProviderService;
  @service private preload!: PreloadService;

  @tracked isTooltipVisible = false;

  get dayNumber() {
    return this.args.day.getDate();
  }

  get ariaLabel() {
    return format(this.args.day, 'do, EEEE MMMM yyyy');
  }

  get events() {
    return this.args.events.filter(event =>
      isSameDay(event.dateTime, this.args.day)
    );
  }
  get cartQuantity() {
    return this.events
      .map(event => this.cartProvider.cart.getLineItemGroups({ event }))
      .flat()
      .reduce((sum, { quantity }) => sum + quantity, 0);
  }
  get hasEvents() {
    return this.events.length > 0;
  }
  get isSelected() {
    return isSameDay(this.args.selectedEvent.dateTime, this.args.day);
  }
  get allSoldout() {
    return this.hasEvents && this.events.every(event => event.soldout);
  }

  get anyLowAvailability() {
    return this.hasEvents && this.events.some(event => event.lowAvailability);
  }

  get anyWaitingListEnabled() {
    return (
      this.hasEvents && this.events.some(event => event.waitingListEnabled)
    );
  }
  get noWaitingListEnabled() {
    return !this.anyWaitingListEnabled;
  }

  get crossSellEnabled() {
    return this.preload.getValue('event_cross_sell_enabled');
  }

  get isDisabled() {
    return (
      !this.hasEvents ||
      (this.allSoldout &&
        this.noWaitingListEnabled &&
        !isToday(this.args.day) &&
        !this.crossSellEnabled)
    );
  }
  get displayCrossSellHint() {
    return this.hasEvents && this.allSoldout && this.crossSellEnabled;
  }

  @action
  displayTooltip() {
    this.isTooltipVisible = true;
  }

  @action
  hideTooltip() {
    this.isTooltipVisible = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EventCalendar::Day': typeof EventCalendarDayComponent;
    'event-calendar/day': typeof EventCalendarDayComponent;
  }
}
