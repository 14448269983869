/* import __COLOCATED_TEMPLATE__ from './gift-email-input.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { object, string } from 'yup';

import { action } from '@ember/object';

import FormObject from 'tangram/utils/form-object';

interface SelectBenefitGiftEmailInputSignature {
  Args: {
    onSubmit: (email: string) => void;
    onCancel: () => void;
  };
}

class GiftEmailForm extends FormObject<{ email: string }> {
  @tracked email!: string;
}

export default class SelectBenefitGiftEmailInputComponent extends Component<SelectBenefitGiftEmailInputSignature> {
  @tracked formObject: GiftEmailForm;

  schema = object().shape({
    email: string().email('Email must be valid').required()
  });

  constructor(
    owner: SelectBenefitGiftEmailInputComponent,
    args: SelectBenefitGiftEmailInputSignature['Args']
  ) {
    super(owner, args);
    this.formObject = new GiftEmailForm({ email: '' });
  }

  @action
  onKeyUp({ key }: KeyboardEvent) {
    if (key === 'Escape') return this.args.onCancel();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelectBenefit::GiftEmailInput': typeof SelectBenefitGiftEmailInputComponent;
    'select-benefit/gift-email-input': typeof SelectBenefitGiftEmailInputComponent;
  }
}
