import Model, { attr } from '@ember-data/model';

export default class SettingModel extends Model {
  @attr('string') name!: string;
  @attr() value!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    setting: SettingModel;
  }
}
