import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { object, boolean } from 'yup';

import { fn, hash } from '@ember/helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import Form, { type FormSignature } from 'tangram/components/form';
import FormObject from 'tangram/utils/form-object';
import type { GiftAidPreference } from 'ticketbooth/models/customer';
import type CartProviderService from 'ticketbooth/services/cart-provider';

export class GiftAidPromptForm extends FormObject<{
  preference: GiftAidPreference | null;
  optOut: boolean;
}> {
  @tracked preference!: GiftAidPreference | null;
  @tracked optOut!: boolean;

  get hasMadeDecision() {
    return this.optOut || this.preference !== null;
  }

  changePreference(change: Form['change'], preference: GiftAidPreference) {
    change('preference', preference);
    change('optOut', false);
  }

  changeOptOut(change: Form['change'], optOut: boolean) {
    change('preference', null);
    change('optOut', optOut);
  }
}

interface Signature {
  Args: {
    onSubmit: (
      optOut: boolean,
      preference: GiftAidPreference | null
    ) => Promise<void>;
    onSuccess?: () => void;
  };
  Blocks: {
    default: [
      {
        state: {
          f: FormSignature<GiftAidPromptForm>['Blocks']['default'][0];
          form: GiftAidPromptForm;
          isSubmitBusy: boolean;
          isSubmitDisabled: boolean;
        };
        actions: {
          changePreference: (preference: GiftAidPreference) => void;
          changeOptOut: (property: string, optOut: boolean) => void;
          submit: () => void;
        };
      }
    ];
  };
}

export default class GiftAidPrompt extends Component<Signature> {
  @service private cartProvider!: CartProviderService;

  @tracked formObject: GiftAidPromptForm;
  @tracked schema = object().shape({
    hasMadeDecision: boolean().isTrue(
      'Please select an option or check this box'
    )
  });

  get cartGiftAidPreference() {
    return this.cartProvider.cart.giftAidPreference ?? null;
  }

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.formObject = this.createForm();
  }

  private createForm() {
    const optOut = this.cartProvider.uiSessionData.giftAidPreference?.optOut;
    return new GiftAidPromptForm({
      optOut: optOut ?? false,
      preference: optOut ? null : this.cartGiftAidPreference
    });
  }

  @action
  async onSubmit({ optOut, preference }: GiftAidPromptForm) {
    this.cartProvider.uiSessionData = { giftAidPreference: { optOut } };
    await this.args.onSubmit(optOut, preference);
  }

  @action
  changePreference(change: Form['change'], preference: GiftAidPreference) {
    this.formObject.changePreference(change, preference);
  }

  @action
  changeOptOut(change: Form['change'], _property: 'optOut', optOut: boolean) {
    this.formObject.changeOptOut(change, optOut);
  }

  <template>
    <Form
      @formObject={{this.formObject}}
      @schema={{this.schema}}
      @onSubmit={{this.onSubmit}}
      @onSuccess={{@onSuccess}}
      @isSubmittableUnchanged={{this.formObject.hasMadeDecision}}
      as |f|
    >
      {{yield
        (hash
          state=(hash
            f=f
            form=f.state.formObject
            isSubmitBusy=f.state.isBusy
            isSubmitDisabled=f.state.isInvalid
          )
          actions=(hash
            changePreference=(fn this.changePreference f.actions.change)
            changeOptOut=(fn this.changeOptOut f.actions.change)
            submit=f.actions.markSubmittedAndSubmit
          )
        )
      }}
    </Form>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GiftAidPromptForm: typeof GiftAidPrompt;
  }
}
