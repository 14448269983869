import EmberRouter from '@ember/routing/router';

import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('listings', { path: '/' }, function () {
    this.route('shows');
    this.route('show', { path: 'shows/:show_id' }, function () {
      this.route('event', { path: 'events/:event_id' }, function () {
        this.route('index', { path: '/' });
        this.route('products');
        this.route('seats');
        this.route('waiting-list');
      });
    });
    this.route('event-category', {
      path: 'event-categories/:event_category_id'
    });
    this.route('venue', { path: 'venues/:venue_id' });
    this.route('products', function () {
      this.route('regular');
      this.route('benefit');
      this.route('donation');
    });
  });
  this.route('cart');
  this.route('donations-prompt', { path: '/confirm-donations' });
  this.route('gift-aid-prompt', { path: '/giftaid-preferences' });
  this.route('products-prompt', { path: '/suggested-products' });
  this.route('extras-prompt', { path: '/extras' });
  this.route('login');
  this.route('login-wall', function () {
    this.route('forgot-password');
  });
  this.route('forgot-password');
  this.route('reset-password');
  this.route('checkout');
  this.route('confirm-order', function () {
    this.route('voucher');
  });
  this.route('hpp-callback', { path: 'hpp-callback/:uuid' });
  this.route('order-completed', { path: 'orders' }, function () {
    this.route('payment');
  });
  this.route('sign-up');
  this.route('members', function () {
    this.route('profile');
    this.route('orders');
    this.route('order', { path: 'order/:order_id' });
    this.route('redemptions');
  });
  this.route('cookies-disabled');

  this.route('event-hub', function () {
    this.route('index', { path: '/' });
    this.route('event-information');
    this.route('pre-order');
    this.route('cart');
    this.route('map');
    this.route('confirm-order');
    this.route('hpp-callback', { path: 'hpp-callback/:uuid' });
    this.route('order-completed');
  });

  this.route('four-oh-four', { path: '*path' });
});
