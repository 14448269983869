import Icon from 'tangram/components/icon';
import isAppleDevice from 'ticketbooth/helpers/is-apple-device';
import MultipassModel from 'ticketbooth/models/multipass';
import type PassModel from 'ticketbooth/models/pass';

interface Signature {
  Args: {
    pass: MultipassModel | PassModel;
  };
}

function appleWalletUrl(pass: MultipassModel | PassModel) {
  return pass instanceof MultipassModel
    ? pass.appleMultipassUrl
    : pass.applePassUrl;
}
function googleWalletUrl(pass: MultipassModel | PassModel) {
  return pass instanceof MultipassModel
    ? pass.googleMultipassUrl
    : pass.googlePassUrl;
}

const AddToWalletLink: TemplateOnlyComponent<Signature> = <template>
  {{#if (isAppleDevice)}}
    {{!-- Opens native ios dialog --}}
    <a href={{appleWalletUrl @pass}} target="_self" rel="noopener noreferrer">
      {{!--  @glint-ignore --}}
      <Icon @name="add-to-apple-wallet" @w="" />
    </a>
  {{else}}
    {{!-- Opens pay.google.com --}}
    <a href={{googleWalletUrl @pass}} target="_blank" rel="noopener noreferrer">
      {{!--  @glint-ignore --}}
      <Icon @name="add-to-google-wallet" @w="" />
    </a>
  {{/if}}
</template>

export default AddToWalletLink;
