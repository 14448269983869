/* import __COLOCATED_TEMPLATE__ from './remove-button.hbs'; */
import Component from '@glimmer/component';

import type { LineItemGroup } from 'ticketbooth/models/line-item';
import { isProductGroup, isTicketGroup } from 'ticketbooth/models/line-item';

interface CartLineItemsTableRemoveButtonSignature {
  Args: {
    lineItemGroup: LineItemGroup;
    onClick: () => void;
  };
}

export default class CartLineItemsTableRemoveButtonComponent extends Component<CartLineItemsTableRemoveButtonSignature> {
  get canRemoveFromCart(): boolean {
    const { lineItemGroup } = this.args;
    if (isTicketGroup(lineItemGroup)) {
      return true;
    }
    if (isProductGroup(lineItemGroup)) {
      return lineItemGroup.product.canRemoveFromCart;
    }
    // Cannot remove booking charges
    return false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CartLineItemsTable::RemoveButton': typeof CartLineItemsTableRemoveButtonComponent;
    'cart-line-items-table/remove-button': typeof CartLineItemsTableRemoveButtonComponent;
  }
}
