/* import __COLOCATED_TEMPLATE__ from './inline-suggestions.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ShowEventsListInlineSuggestionsSignature {
  Args: {};
}

const ShowEventsListInlineSuggestionsComponent =
  templateOnlyComponent<ShowEventsListInlineSuggestionsSignature>();

export default ShowEventsListInlineSuggestionsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::InlineSuggestions': typeof ShowEventsListInlineSuggestionsComponent;
    'show/events-list/inline-suggestions': typeof ShowEventsListInlineSuggestionsComponent;
  }
}
