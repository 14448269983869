import { createMachine, assign } from 'xstate';

import { action } from '@ember/object';
import Service from '@ember/service';

import { useMachine } from 'ember-statecharts';

const appMachine = createMachine(
  {
    id: 'app',
    predictableActionArguments: true,
    type: 'parallel',
    context: {
      modalData: {}
    },
    states: {
      modals: {
        initial: 'closed',
        states: {
          closed: {
            on: {
              OPEN_MODAL: [
                { target: 'open.createGift', cond: 'isCreateGiftModal' }
              ]
            }
          },
          open: {
            entry: ['assignModalData'],
            initial: 'never',
            states: {
              never: {},
              createGift: { id: 'create-gift' }
            },
            on: {
              CLOSE_MODAL: 'closed'
            }
          }
        }
      }
    }
  },
  {
    actions: {
      // @ts-ignore
      assignModalData: assign((_context, { type, modalName, ...modalData }) => {
        return {
          modalData
        };
      })
    },
    guards: {
      isCreateGiftModal: (_context, event) => {
        return event.modalName === 'createGift';
      }
    }
  }
);

/**
 * A service to implement application wide **behavior** driven by a statechart.
 */
export default class AppService extends Service {
  statechart = useMachine(this, () => {
    return {
      machine: appMachine
    };
  });

  get state() {
    const { isDisplayingCreateGiftModal, statechart } = this;

    return {
      isDisplayingCreateGiftModal,
      modalData: statechart.state?.context.modalData
    };
  }

  get actions() {
    const { openModal, closeModal } = this;

    return {
      openModal,
      closeModal
    };
  }

  get isDisplayingCreateGiftModal() {
    return this.statechart.state?.matches({ modals: { open: 'createGift' } });
  }

  @action openModal(modalData: Record<string, unknown>) {
    const data = { ...modalData };

    this.statechart.send('OPEN_MODAL', data);
  }

  @action closeModal() {
    this.statechart.send('CLOSE_MODAL');
  }
}
