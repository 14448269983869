import { captureException } from '@sentry/ember';

export const TRACKERS_PARAMS = [
  'mc_eid',
  'mc_cid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'gclid',
  'msclkid',
  'utm_term',
  'utm_content',
  'fbclid',
  'utm_id',
  'utm_social',
  'dclid',
  'gbraid',
  'wbraid'
];

function setCookie(doc: Document, name: string, value: string) {
  const date = new Date();
  date.setTime(date.getTime() + 5 * 60 * 1000);
  const expires = `expires=${date.toUTCString()};`;
  const sameSite = 'SameSite=Lax;';
  doc.cookie = `${name}=${value};${expires} path=/; ${sameSite}`;
}

export default {
  name: 'set-trackers-query-string-params',
  after: 'restore-blocked-session',

  initialize() {
    try {
      const params = new URLSearchParams(location.search);

      const trackers = TRACKERS_PARAMS.reduce((trackers, param) => {
        if (params.has(param)) {
          return { ...trackers, [param]: params.get(param) };
        }
        return trackers;
      }, {});
      if (Object.keys(trackers).length > 0) {
        setCookie(document, 'trackers', JSON.stringify(trackers));
      }
    } catch (e) {
      captureException(e);
    }
  }
};
