import { hash } from '@ember/helper';

import not from 'ember-truth-helpers/helpers/not';

import ButtonTopBase from 'tangram/components/button/top/base';
import Icon from 'tangram/components/icon';
import { htmlSafeSanitized } from 'tangram/helpers/html-safe-sanitized';
import isDark from 'tangram/helpers/is-dark';

import lookAndFeel from '../helpers/look-and-feel';
import translate from '../helpers/translate';
import branding from '../modifiers/branding';
import cardSecondaryBackgroundColor from '../modifiers/card-secondary-background-color';
import cardSecondaryTextColor from '../modifiers/card-secondary-text-color';

interface Signature {
  Args: {
    onClick: () => void;
    title: string;
    text: string;
    suggested?: boolean;
    selected: boolean;
    options: readonly {
      title: string;
      text: string;
    }[];
  };
}

const DARK_GREEN = '#517719';
const MEDIUM_GREEN = '#7FB828';
const LIGHT_GREEN = '#DAEAC1';

const style = {
  selectedOuterBorder: htmlSafeSanitized([`border: 4px solid ${LIGHT_GREEN}`]),
  selectedInnerBorder: htmlSafeSanitized([`border: 1px solid ${MEDIUM_GREEN}`]),

  suggestedText: htmlSafeSanitized([
    ['top: 4px', 'left: -6px', `background: ${MEDIUM_GREEN}`].join(';')
  ]),
  suggestedBox: htmlSafeSanitized([
    [
      'width: 8px',
      'height: 8px',
      'top: 22.57px',
      'left: -4px',
      'transform: rotate(-45deg)',
      `background: ${DARK_GREEN}`
    ].join(';')
  ]),

  innerBackgroundDark: htmlSafeSanitized([
    'background: rgba(255, 255, 255, 0.05)'
  ]),
  innerBackgroundLight: htmlSafeSanitized(['background: rgba(0, 0, 0, 0.05)']),

  ctaBackground: htmlSafeSanitized(['background: rgba(0, 0, 0, 0.85)']),
  ctaBackgroundSelected: htmlSafeSanitized([`background: ${MEDIUM_GREEN}`])
} as const;

const GiftAidPromptOption: TemplateOnlyComponent<Signature> = <template>
  {{#let (isDark (lookAndFeel 'card_secondary_bg_color')) as |isDarkMode|}}
    <div
      class='flex-1 flex rounded-lg relative border-4 border-white'
      style={{if @selected style.selectedOuterBorder}}
    >
      {{#if @suggested}}
        <div
          class='z-20 text-white uppercase absolute px-2 py-1 text-xs'
          style={{style.suggestedText}}
        >
          {{translate 'gift_aid_prompt.suggested'}}
        </div>
        <div class='absolute' style={{style.suggestedBox}}></div>
      {{/if}}
      <div
        class='z-10 flex-1 flex flex-col p-4 rounded border border-transparent
          {{if @selected (if isDarkMode "bg-gray-900" "bg-white")}}'
        style={{if @selected style.selectedInnerBorder}}
        {{! @glint-ignore branding-modifier }}
        {{cardSecondaryBackgroundColor isActive=(not @selected)}}
      >
        <div
          class='text-2xl font-semibold text-center pb-1'
          {{cardSecondaryTextColor}}
        >
          {{@title}}
        </div>
        <div
          class='text-center pb-3 {{unless @options "pt-8"}}'
          {{! @glint-ignore - branding modifier }}
          {{branding
            'color'
            'card_secondary_fg_color'
            mix=(if
              isDarkMode
              (hash color1='card_secondary_fg_color' color2='black' weight=90)
              (hash color1='card_secondary_fg_color' color2='white' weight=60)
            )
          }}
        >
          {{@text}}
        </div>

        <div class='flex-1 flex flex-col md:flex-row lg:flex-col gap-2 pb-6'>
          {{#each @options as |option|}}
            <div
              class='flex-1 lg:flex-initial flex flex-col gap-1 p-3 pt-4 rounded-lg'
              style={{if
                isDarkMode
                style.innerBackgroundDark
                style.innerBackgroundLight
              }}
            >
              <div class='flex gap-1 justify-center' {{cardSecondaryTextColor}}>
                <Icon @name='check' />
                {{option.title}}
              </div>
              <div
                class='text-xs text-center'
                {{cardSecondaryTextColor}}
                {{! @glint-ignore - branding modifier }}
                {{branding
                  'color'
                  'card_secondary_fg_color'
                  mix=(if
                    isDarkMode
                    (hash
                      color1='card_secondary_fg_color' color2='black' weight=90
                    )
                    (hash
                      color1='card_secondary_fg_color' color2='white' weight=60
                    )
                  )
                }}
              >
                {{option.text}}
              </div>
            </div>
          {{/each}}
        </div>

        <ButtonTopBase
          @onClick={{@onClick}}
          class='text-white'
          style={{if @selected style.ctaBackgroundSelected style.ctaBackground}}
          data-test-gift-aid={{@title}}
          data-test-gift-aid-selected={{@selected}}
        >
          {{#if @selected}}
            <div class='flex items-center gap-1 justify-center'>
              <Icon @name='check' />
              {{translate 'gift_aid_prompt.selected'}}
            </div>
          {{else}}
            {{translate 'gift_aid_prompt.select'}}
          {{/if}}
        </ButtonTopBase>

      </div>
    </div>
  {{/let}}
</template>;

export default GiftAidPromptOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GiftAidPromptOption: typeof GiftAidPromptOption;
  }
}
