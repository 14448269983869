/* import __COLOCATED_TEMPLATE__ from './customer-summary.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarCardsCustomerSummarySignature {
  Args: {};
}

const SidebarCardsCustomerSummaryComponent =
  templateOnlyComponent<SidebarCardsCustomerSummarySignature>();

export default SidebarCardsCustomerSummaryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sidebar::Cards::CustomerSummary': typeof SidebarCardsCustomerSummaryComponent;
    'sidebar/cards/customer-summary': typeof SidebarCardsCustomerSummaryComponent;
  }
}
