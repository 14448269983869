import { tracked } from '@glimmer/tracking';

import Controller from '@ember/controller';

export default class extends Controller {
  queryParams = ['email', 'hash', 'checkout'];

  @tracked email: string | null = null;
  @tracked hash: string | null = null;
  @tracked checkout: 'true' | 'false' | boolean | null = null;

  get redirectToCheckout() {
    return this.checkout === true || this.checkout === 'true';
  }
}
