/* import __COLOCATED_TEMPLATE__ from './load-event-ticket-prices.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

import type EventModel from 'ticketbooth/models/event';
import type EventTicketPriceModel from 'ticketbooth/models/event-ticket-price';
import type TicketAllocationModel from 'ticketbooth/models/ticket-allocation';

interface ShowLoadEventTicketPricesSignature {
  Args: {
    event: EventModel;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class ShowLoadEventTicketPricesComponent extends Component<ShowLoadEventTicketPricesSignature> {
  @tracked loadedEvent: EventModel | null = null;

  @action
  load(loadTicketPrices: Function) {
    loadTicketPrices(this.args.event);
  }

  @action
  async loadTicketPrices(event: EventModel): Promise<void> {
    await event.loadAllTicketPrices();
    this.loadedEvent = event;
  }

  @action
  findTicketAllocations(
    eventTicketPrice: EventTicketPriceModel
  ): TicketAllocationModel[] {
    const ticketAllocations = this.loadedEvent?.ticketAllocations ?? [];
    return ticketAllocations.filter(({ visibleTicketPrices }) =>
      visibleTicketPrices.includes(eventTicketPrice)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::LoadEventTicketPrices': typeof ShowLoadEventTicketPricesComponent;
    'show/load-event-ticket-prices': typeof ShowLoadEventTicketPricesComponent;
  }
}
