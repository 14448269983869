import Component from '@glimmer/component';

import { fn } from '@ember/helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

// @ts-ignore
import media from 'ember-responsive/helpers/media';
import eq from 'ember-truth-helpers/helpers/eq';

import type { FormSignature } from 'tangram/components/form';
import FormCheckbox from 'tangram/components/form/checkbox';
import FormCurrency from 'tangram/components/form/currency';
import floatNumber from 'tangram/helpers/float-number';
import type { FormObjectBase } from 'tangram/utils/form-object';
import ButtonTbCard from 'ticketbooth/components/button/tb/card';
import type DonationProductModel from 'ticketbooth/models/donation-product';
import type PreloadService from 'ticketbooth/services/preload';

import translate from '../helpers/translate';
import cardButtonBackgroundColor from '../modifiers/card-button-background-color';
import cardButtonBorderColor from '../modifiers/card-button-border-color';
import cardButtonPlaceholderColor from '../modifiers/card-button-placeholder-color';
import cardButtonTextColor from '../modifiers/card-button-text-color';

interface DonationFormFields extends FormObjectBase {
  price: number;
  priceOverride: number;
  agreed: boolean;
  optOut: boolean;
}

interface Signature {
  Args: {
    f: FormSignature<DonationFormFields>['Blocks']['default'][0];
    product: DonationProductModel;
    onClickDonationAmount: (price: number) => void;
    onInputPriceOverride: (priceOverride: string) => void;
    onChangeOptOut?: (optOut: boolean) => void;
  };
}

export default class DonationProductFormLayout extends Component<Signature> {
  @service private preload!: PreloadService;

  @action
  onInputPriceOverride(_property: undefined, value: string) {
    this.args.onInputPriceOverride(value);
  }

  @action
  onChangeOptOut(_property: undefined, value: boolean) {
    this.args.onChangeOptOut?.(value);
  }

  get price() {
    return this.args.f.state.formObject.price;
  }
  get hasPriceOverride() {
    return !!this.args.f.state.formObject.priceOverride;
  }
  get displayAgreed() {
    const { product } = this.args;
    return product.declaration && !product.giftaidable;
  }

  <template>
    {{! @glint-nocheck: not typesafe yet }}
    <div class="mx-2">
      <div class="py-1">
        {{translate "checkout.donation_prompt"}}
      </div>

      <div class="pt-2 text-lg">
        <div class="pb-3 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-3 gap-3">
          {{#each @product.donationAmounts as |donationAmount idx|}}
            <ButtonTbCard
              @isActive={{eq this.price donationAmount}}
              @onClick={{fn @onClickDonationAmount donationAmount}}
              data-test-donation-amount={{idx}}
            >
              {{floatNumber donationAmount isCurrency=true}}
            </ButtonTbCard>
          {{/each}}
        </div>
        {{#if @product.allowPriceOverride}}
          <div class="pb-3">
            <FormCurrency
              @f={{@f}}
              @inline={{true}}
              @property="priceOverride"
              @placeholder={{translate (if (media "isSm") "donations.your_amount_short" "donations.your_amount")}}
              @onChange={{this.onInputPriceOverride}}
              {{!-- Deliberately provide an empty string so the icon colour is inherited from branding --}}
              @symbolClass=""
              {{cardButtonBackgroundColor isActive=this.hasPriceOverride}}
              {{cardButtonBorderColor isActive=this.hasPriceOverride}}
              {{cardButtonTextColor isActive=this.hasPriceOverride}}
              {{cardButtonPlaceholderColor}}
            />
          </div>
        {{/if}}
      </div>
    </div>

    {{#if this.displayAgreed}}
      <div class="my-2">
        <FormCheckbox
          @f={{@f}}
          @alignCenter={{false}}
          @property="agreed"
          @label={{@product.declaration}}
          @labelClass="text-sm leading-6 -mt-1"
        />
      </div>
    {{/if}}

    {{#if @onChangeOptOut}}
      <div class="my-2">
        <FormCheckbox
          @f={{@f}}
          @alignCenter={{false}}
          @property="optOut"
          @label={{translate "checkout.donation_prefer_not"}}
          @labelClass="text-sm leading-6 -mt-1"
          @onChange={{this.onChangeOptOut}}
          @minHeight="min-h-6"
        />
      </div>
    {{/if}}
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DonationProductFormLayout: typeof DonationProductFormLayout;
  }
}
