import Model from '@ember-data/model';
import { belongsTo } from '@ember-data/model';

import type AccountModel from './account';
import type ProductModel from './product';

export default class InventoryModel extends Model {
  @belongsTo('product', {
    polymorphic: true,
    async: false,
    inverse: 'accountInventory'
  })
  product!: ProductModel;

  @belongsTo('account', { async: false, inverse: null })
  source!: AccountModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    inventory: InventoryModel;
  }
}
