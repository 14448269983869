import type {
  NewResource,
  Resource,
  ResourceIdentifier
} from 'ticketoffice-api';

import type Model from '@ember-data/model';

import { identity } from 'tangram/utils/json-api';
import type EventModel from 'ticketbooth/models/event';
import type ProductModel from 'ticketbooth/models/product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';

const PRODUCT_LINE_ITEM_TYPE = 'product-line-items';

export type ProductOperationsOpts = {
  CreateProductOpts: {
    eventId?: EventModel['id'] | null;
    eventHubContext?: ProductLineItemModel['eventHubContext'];
    eventHubContextCategory?: ProductLineItemModel['eventHubContextCategory'];
    attributes?: Partial<
      Pick<
        ProductLineItemModel,
        | 'price'
        | 'quantity'
        | 'agreed'
        | 'eventHubContext'
        | 'eventHubContextCategory'
      > & {
        'event-id': { id: EventModel['id']; type: 'events' };
        'event-hub-context': string;
        'event-hub-context-category': string;
      }
    >;
    relationships?: object;
    meta?: {
      hint?: string;
    };
  };
  CreateDonationProduct: ProductOperationsOpts['CreateProductOpts'] & {
    attributes: {
      price: number;
      agreed: boolean;
    };
  };
  CreateBenefitProduct: ProductOperationsOpts['CreateProductOpts'] & {
    attributes: {
      agreed: boolean;
      'gift-email': string;
    };
  };
};

/*
 * ProductOperationsModule
 * ---
 * Providers data formatting help when building operations related to
 * product line items or for specific product models.
 */

export const ProductOperationsModule = {
  createProductOp(
    product: ProductModel,
    opts?: ProductOperationsOpts['CreateProductOpts']
  ) {
    return OperationsModule.genCreateOperation({
      type: PRODUCT_LINE_ITEM_TYPE,
      attributes: {
        quantity: 1,
        ...opts?.attributes,
        ...this.attachEvent(opts?.eventId),
        ...this.attachContext(opts?.eventHubContext),
        ...this.attachContextCategory(opts?.eventHubContextCategory)
      },
      relationships: {
        product: { data: identity(product) },
        ...opts?.relationships
      },
      meta: opts?.meta ?? {}
    });
  },

  attachEvent(id?: EventModel['id'] | null) {
    return id ? { 'event-id': { id, type: 'events' } } : {};
  },

  attachContext(id?: ProductLineItemModel['eventHubContext']) {
    return id ? { 'event-hub-context': id } : {};
  },

  attachContextCategory(id?: ProductLineItemModel['eventHubContextCategory']) {
    return id ? { 'event-hub-context-category': id } : {};
  },

  createDonationProductOp(
    product: ProductModel,
    opts: ProductOperationsOpts['CreateDonationProduct']
  ) {
    return this.createProductOp(product, opts);
  },

  createBenefitProductOp(
    product: ProductModel,
    opts: ProductOperationsOpts['CreateBenefitProduct']
  ) {
    return this.createProductOp(product, opts);
  }
};

export type ResourceOperations = {
  ops: ResourceOperation[];
};

export type ResourceOperation =
  | DeleteOperation
  | CreateOperation
  | UpdateOperation;
export type DeleteOperation = { action: 'delete'; data: ResourceIdentifier };
export type CreateOperation = { action: 'create'; data: NewResource };
export type UpdateOperation = {
  action: 'update';
  data: Resource;
};

/*
 * OperationsModule
 * ---
 * Provides the base building blocks for the operations carried out when
 * updating the cart.
 */
export const OperationsModule = {
  isDeleteOperation(op: ResourceOperation): op is DeleteOperation {
    return op.action === 'delete';
  },
  isCreateOperation(op: ResourceOperation): op is CreateOperation {
    return op.action === 'create';
  },
  isUpdateOperation(op: ResourceOperation): op is UpdateOperation {
    return op.action === 'update';
  },

  genDeleteOperation(lineItem: Model): DeleteOperation {
    return { action: 'delete', data: identity(lineItem) };
  },
  genCreateOperation(data: NewResource): CreateOperation {
    return { action: 'create', data };
  },
  genUpdateOperation(data: Resource): UpdateOperation {
    return { action: 'update', data };
  }
};
