/* import __COLOCATED_TEMPLATE__ from './next-month-button.hbs'; */
import Component from '@glimmer/component';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';

import { action } from '@ember/object';

interface CalendarNextMonthButtonSignature {
  Args: {
    maxDate: Date;
    selectedDate: Date;
    selectDate(date: Date): Promise<void>;
  };
}

export default class CalendarNextMonthButtonComponent extends Component<CalendarNextMonthButtonSignature> {
  get nextMonth(): Date {
    return addMonths(this.args.selectedDate, 1);
  }
  get hasNextMonth(): boolean {
    return !isSameMonth(this.args.selectedDate, this.args.maxDate);
  }
  get ariaLabel(): string {
    return `Next month, ${format(this.nextMonth, 'MMMM yyyy')}`;
  }

  @action
  selectNextMonth() {
    return this.args.selectDate(this.nextMonth);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calendar::NextMonthButton': typeof CalendarNextMonthButtonComponent;
    'calendar/next-month-button': typeof CalendarNextMonthButtonComponent;
  }
}
