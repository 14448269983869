import { UnauthorizedError } from '@ember-data/adapter/error';
import AdapterError, {
  InvalidError,
  NotFoundError,
  ForbiddenError,
  AbortError
} from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import ErrorsService from 'tangram/services/-errors-base';
import { getAllUserMessages } from 'tangram/utils/errors';

import type CartProviderService from './cart-provider';
import type DocumentService from './document';
import type LocaleService from './locale';
import type MembershipService from './membership';

export function shouldLogEmberDataError(error: AdapterError): boolean {
  // 401 || 403 || 404 || 422 || 0
  return !(
    error instanceof UnauthorizedError ||
    error instanceof ForbiddenError ||
    error instanceof NotFoundError ||
    error instanceof InvalidError ||
    error instanceof AbortError
  );
}

export function shouldLogErrorByStack(stack: Error['stack']): boolean {
  // Ignore errors coming from scripts loaded by google tag manager
  return (
    !!stack &&
    !(
      stack.includes('gtm.js') ||
      stack.includes('gtag/js') ||
      stack.includes('gtag.js') ||
      stack.includes('/gtag/')
    )
  );
}

const QUEUE_RESET_ERROR = 'Your queue position has been reset';

export default class TicketboothErrorsService extends ErrorsService {
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;
  @service private locale!: LocaleService;
  @service private document!: DocumentService;

  shouldLogError(error: any) {
    if (error instanceof AdapterError) {
      return shouldLogEmberDataError(error);
    }
    if (error instanceof Error) {
      return shouldLogErrorByStack(error.stack);
    }
    return super.shouldLogError(error);
  }

  @action
  onMemberReload() {
    super.setExtra('member', this.membership.member?.id ?? '-');
  }

  @action
  onCartChange() {
    super.setExtra('cart', this.cartProvider.cart?.id ?? '-');
    super.setExtra('customer', this.cartProvider.cart?.cartCustomer?.id ?? '-');
  }

  @action
  onLocaleChanged() {
    super.setExtra('locale', this.locale.currentLocale ?? '-');
  }

  @action
  async onSetup() {
    super.setTag('fetch_support', 'fetch' in window ? 'yes' : 'no');

    try {
      super.setTag(
        'cookie_support',
        (await this.document.testCookieSupport()) ? 'yes' : 'no'
      );
    } catch (error) {
      this.log(new Error(`Cookie support check failed`), { extra: { error } });
    }
  }

  @action
  onIframeSetup(
    targetOrigin: string,
    location: string,
    iframeHandlingVersion: string
  ) {
    super.setPrefix('[iframe]: ');
    super.setExtra('iframe_referrer', document.referrer);
    super.setExtra('iframe_target_origin', targetOrigin);
    super.setExtra('iframe_parent_location', location);
    super.setExtra('iframe_handling_version', iframeHandlingVersion);
  }

  isQueueItError(error: any): boolean {
    const messages = getAllUserMessages(error);
    return messages.some(msg => new RegExp(QUEUE_RESET_ERROR, 'i').test(msg));
  }
}

declare module '@ember/service' {
  interface Registry {
    errors: TicketboothErrorsService;
  }
}
