import Model, { attr, hasMany } from '@ember-data/model';
import type { SyncHasMany } from '@ember-data/model';

import type ProductLineItemModel from './product-line-item';

export default class ConntectedOrderModel extends Model {
  @attr('string') uuid!: string;
  @attr('number') orderNumber!: number;
  @attr('number') total!: number;
  @attr('date') createdAt!: Date;

  @hasMany('product-line-item', { async: false, inverse: null })
  effectiveProductLineItems!: SyncHasMany<ProductLineItemModel>;

  get totalProductQuantity(): number {
    return this.effectiveProductLineItems
      .slice()
      .mapBy('quantity')
      .reduce((total, q) => total + q, 0);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    connectedOrder: ConntectedOrderModel;
  }
}
