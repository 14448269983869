import type { SyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';

import type ContextCategoryModel from './context-category';

export default class ContextModel extends Model {
  @attr('string') name!: string;
  @attr('number') preorderOpens!: number;
  @attr('number') preorderEnds!: number;

  @hasMany('context-categories', { async: false, inverse: null })
  contextCategories!: SyncHasMany<ContextCategoryModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    context: ContextModel;
  }
}
