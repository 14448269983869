const INCLUDES = {
  CUSTOMER: ['customer', 'customer.permissions', 'customer.permission-options'],
  WAITING_LIST: ['waiting-list-entries', 'waiting-list-entries.event'],
  BENEFIT: ['active-benefit', 'active-benefit.benefit-product'],
  REWARD_REDEMPTION: 'reward-redemption'
};

export const INCLUDE_ALL = [
  ...INCLUDES.CUSTOMER,
  ...INCLUDES.WAITING_LIST,
  INCLUDES.BENEFIT,
  INCLUDES.REWARD_REDEMPTION
].join(',');

export const INCLUDE_ALL_STRING = `include=${INCLUDE_ALL}`;

export const INCLUDE_ALL_FIELDS = {
  events: 'id'
};

export const INCLUDE_ALL_FIELDS_STRING = 'fields[events]=id';
