const INCLUDES = {
  FULL_LINE_ITEMS: [
    'effective-ticket-line-items',
    'effective-ticket-line-items.event-ticket-price',
    'effective-ticket-line-items.ticket-allocation',
    'effective-ticket-line-items.event',
    'effective-ticket-line-items.event.venue',
    'effective-ticket-line-items.event.show',
    'effective-ticket-line-items.seat-assignment',
    'effective-product-line-items',
    'effective-product-line-items.product',
    'effective-booking-charge-line-items'
  ],
  FULFILLMENT_PRODUCT: 'fulfillment-product',
  CUSTOMER: 'customer',
  PAYMENTS: [
    'voucher-payments',
    'voucher-payments.redemption',
    'voucher-payments.reward-redemption',
    'credit-card-payments'
  ],
  EVENT_HUB: [
    'effective-ticket-line-items.event',
    'effective-ticket-line-items.seat-assignment',
    'effective-ticket-line-items.event-ticket-price',
    'effective-product-line-items.product',
    'connected-orders.effective-product-line-items.product'
  ]
};

export const INCLUDE_EVENT_HUB = INCLUDES.EVENT_HUB.join(',');

export const INCLUDE_ALL = [
  ...INCLUDES.FULL_LINE_ITEMS,
  INCLUDES.FULFILLMENT_PRODUCT,
  INCLUDES.CUSTOMER,
  ...INCLUDES.PAYMENTS
].join(',');

export const INCLUDE_OVERVIEW = [INCLUDES.FULFILLMENT_PRODUCT].join(',');
