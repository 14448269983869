/* import __COLOCATED_TEMPLATE__ from './permissions-form.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CheckoutFormPermissionsFormSignature {
  Args: {};
}

const CheckoutFormPermissionsFormComponent =
  templateOnlyComponent<CheckoutFormPermissionsFormSignature>();

export default CheckoutFormPermissionsFormComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutForm::PermissionsForm': typeof CheckoutFormPermissionsFormComponent;
    'checkout-form/permissions-form': typeof CheckoutFormPermissionsFormComponent;
  }
}
