/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type CheckoutFlowService from 'ticketbooth/services/checkout-flow';
import type { CheckoutStep } from 'ticketbooth/services/checkout-flow';

interface CheckoutFlowFooterSignature {
  Args: {
    previousItem: CheckoutStep | null;
    nextItem: CheckoutStep | null;
    clickNextButton?: () => void;
    disableNextButton?: boolean;
    isBusy?: boolean;
  };
  Element: HTMLDivElement;
}

export default class CheckoutFlowFooterComponent extends Component<CheckoutFlowFooterSignature> {
  @service private checkoutFlow!: CheckoutFlowService;

  @action
  navigateBack() {
    this.checkoutFlow.navigateBack();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckoutFlow::Footer': typeof CheckoutFlowFooterComponent;
    'checkout-flow/footer': typeof CheckoutFlowFooterComponent;
  }
}
