import type { SyncHasMany } from '@ember-data/model';
import Model, { hasMany } from '@ember-data/model';

import type MultipassModel from './multipass';
import type PassModel from './pass';

export default class WalletModel extends Model {
  @hasMany('passes', { async: false, inverse: null })
  passes!: SyncHasMany<PassModel>;

  @hasMany('multipasses', { async: false, inverse: null })
  multipasses!: SyncHasMany<MultipassModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    wallet: WalletModel;
  }
}
