/* import __COLOCATED_TEMPLATE__ from './event-ticket-price-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ShowEventsListEventTicketPriceItemSignature {
  Args: {};
}

const ShowEventsListEventTicketPriceItemComponent =
  templateOnlyComponent<ShowEventsListEventTicketPriceItemSignature>();

export default ShowEventsListEventTicketPriceItemComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::EventTicketPriceItem': typeof ShowEventsListEventTicketPriceItemComponent;
    'show/events-list/event-ticket-price-item': typeof ShowEventsListEventTicketPriceItemComponent;
  }
}
